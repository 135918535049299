import { forwardRef } from "react";
import { Chip } from "../../../..";
import { IconCaretDown } from "../../../../icons";
import { GenericSelectClonedTriggerProps } from "./GenericSelect";

interface ChipSelectTriggerProps extends GenericSelectClonedTriggerProps {
  /** Text displayed inside the chip */
  value: string;
}

/** A custom trigger component for ChipSelect. Looks like a Chip with a dropdown icon, triggers the listbox when clicked. */
export const ChipSelectTrigger = forwardRef<HTMLDivElement, ChipSelectTriggerProps>(
  function ChipSelectTrigger(props: ChipSelectTriggerProps, ref) {
    const {
      value,
      // Ignore these prop values coming from GenericSelect
      error: _error,
      warning: _warning,
      disabled: _disabled,
      isLocked: _isLocked,
      hasValue: _hasValue,
      ...rest
    } = props;

    return <Chip ref={ref} value={value} Icon={<IconCaretDown />} {...rest} />;
  }
);
