/** Recursively reads an object and returns true if all values are undefined, null, or empty strings */
export function objectIsEmpty(obj?: Record<string, any> | null): boolean {
  if (obj === undefined || obj === null) return true;

  for (const key of Object.keys(obj)) {
    const value = obj[key];

    if (typeof value === "object" && !Array.isArray(value)) {
      if (!objectIsEmpty(value)) {
        return false;
      }
    } else if (value !== undefined && value !== null && value !== "") {
      return false;
    }
  }

  return true;
}
