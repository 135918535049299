import { Application, CreateApplication, UpdateApplication } from "api/Application.model";
import { http } from "./http";

export function listApplications(facilitatorAccountID: string): Promise<Application[]> {
  return http("/applications", { xAccountID: facilitatorAccountID });
}

export function getApplication(
  facilitatorAccountID: string,
  applicationID: string
): Promise<Application> {
  return http(`/applications/${applicationID}`, { xAccountID: facilitatorAccountID });
}

export function createApplication(
  facilitatorAccountID: string,
  application: CreateApplication
): Promise<Application> {
  return http("/applications", {
    method: "POST",
    xAccountID: facilitatorAccountID,
    json: application
  });
}

export function updateApplication(
  facilitatorAccountID: string,
  application: UpdateApplication
): Promise<Application> {
  return http(`/applications/${application.applicationID}`, {
    method: "PUT",
    xAccountID: facilitatorAccountID,
    json: application
  });
}

export function deleteApplication(
  facilitatorAccountID: string,
  applicationID: string
): Promise<null> {
  return http(`/applications/${applicationID}`, {
    method: "DELETE",
    xAccountID: facilitatorAccountID
  });
}
