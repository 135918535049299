import { useMemo } from "react";

/**
 * Function to generate a unique ID if none is passed, optionally based on a seed.
 *
 * @param id - if this is defined, it will be returned instead of generating a new ID.
 * This is just a facility so we can always pass id in from the component, even if undefined.
 * @param seed - The optional seed to use for generating the ID. If not provided, a new RANDOM id will be generated.
 * @returns The generated ID.
 */
export const useId = (id?: string, seed?: string) => {
  return useMemo(() => {
    if (id) return id;
    const zeed = seed ?? (Date.now() * Math.random()).toString();
    const hash = Math.abs(
      zeed.split("").reduce((acc, char) => {
        const hash = (acc << 5) - acc + char.charCodeAt(0);
        return hash & hash;
      }, 0)
    );
    return `id_${seed ?? ""}${hash.toString(16).padStart(6, "0").slice(-6)}`;
  }, [id, seed]);
};
