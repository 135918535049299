import { StrictMode } from "react";
import { pdfjs } from "react-pdf";
import { initBrowserMocks } from "@moovfinancial/common/tests/mockBrowser";
import { shouldEnableMocks } from "@moovfinancial/common/utils/env";
import pdfjsWorkerUrl from "pdfjs-dist/build/pdf.worker.min.mjs?url";
import { createRoot } from "react-dom/client";
import { httpHandlers } from "tests/httpHandlers";
import App from "./App";
import "./index.scss";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(pdfjsWorkerUrl, import.meta.url).toString();

const container = document.getElementById("root");
const root = createRoot(container!);

const initMocks = async () => {
  try {
    await initBrowserMocks(httpHandlers);
  } catch {
    // Silently continue if mocks fail to initialize
  }
};

const renderApp = async () => {
  if (shouldEnableMocks()) await initMocks();

  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
};

void renderApp();
