import { memo } from "react";
import {
  formattedEINToString,
  stringToFormattedEIN
} from "@moovfinancial/common/utils/format/formatEIN";
import { StringMaskingInput, StringMaskingInputProps } from "../StringMaskingInput";

export interface EINInputProps
  extends Omit<
    StringMaskingInputProps,
    "acceptedChars" | "formattedToUnformatted" | "inputFormatter" | "unformattedToFormatted"
  > {}

/**
 * Masking input that onValueChange sends the value as a string of numbers
 */
export const EINInput = memo(function EINInput(props: EINInputProps) {
  return (
    <StringMaskingInput
      acceptedChars={/\d+/g}
      formattedToUnformatted={formattedEINToString}
      inputFormatter={stringToFormattedEIN}
      inputMode="numeric"
      unformattedToFormatted={stringToFormattedEIN}
      {...props}
    />
  );
});
