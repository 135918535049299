import { CreateInvite, Invite, InviteVerification } from "api/Invite.model";
import { http } from "./http";

export function listInvites(facilitatorAccountID: string): Promise<Invite[]> {
  return http("/invites", { xAccountID: facilitatorAccountID });
}

export function createInvite(facilitatorAccountID: string, invite: CreateInvite) {
  return http("/invites", {
    method: "POST",
    xAccountID: facilitatorAccountID,
    json: invite
  });
}

export function deleteInvite(facilitatorAccountID: string, inviteID: string): Promise<null> {
  return http(`/invites/${inviteID}`, {
    method: "DELETE",
    xAccountID: facilitatorAccountID
  });
}

export function acceptInvite(facilitatorAccountID: string, inviteID: string): Promise<void> {
  return http(`/invites/${inviteID}/accept`, {
    method: "PUT",
    xAccountID: facilitatorAccountID
  });
}

export function resendInvite(facilitatorAccountID: string, inviteID: string): Promise<void> {
  return http("/invites/resend", {
    method: "POST",
    xAccountID: facilitatorAccountID,
    json: { inviteID }
  });
}

export function confirmInvite(token: string): Promise<InviteVerification> {
  return http("/confirm/invite", {
    method: "GET",
    query: {
      token
    }
  });
}
