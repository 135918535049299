import { useCallback, useLayoutEffect, useMemo, useReducer, useRef } from "react";

/* Calculates a new cursor position.
 * Ex: A formatter adds periods after each character. You have `1.2|.4`, then press `3`. The new cursor position should be `1.2.3|.4`.
 * Assumes the `format` process doesn't change the order of raw characters. */
const getCursorPosition = (
  // The new value of the input
  newValue: string,
  // The previous value of the input
  oldValue: string,
  // The previous start of the user's selection
  oldCursorPosition: number,
  // Returns the raw characters from a given string
  getRaw: (mixedStr: string) => string
) => {
  const newRawValue = getRaw(newValue);
  const oldRawValueBeforeCursor = getRaw(oldValue.substr(0, oldCursorPosition));

  let pos = 0;
  let rawPos = 0;
  for (let i = 0; i !== oldRawValueBeforeCursor.length; ++i) {
    const rawChar = oldRawValueBeforeCursor[i];
    const nextPos = newValue.indexOf(rawChar, pos) + 1;
    const nextRawPos = newRawValue.indexOf(rawChar, rawPos) + 1;
    /* Fixes one edge case where order of raw characters changes.
     * Ex: Fixed point number inputs. You have `0|.00`, then press `1`. It becomes `1.00` after formatting. Cursor should stay at `1|.00`. */
    const orderOfRawCharsChanged = nextRawPos - rawPos > 1;
    if (orderOfRawCharsChanged) continue; // Bail
    rawPos = Math.max(rawPos, nextRawPos);
    pos = Math.max(pos, nextPos);
  }

  return pos;
};

interface EventInfo {
  eventValue: string;
  input: HTMLInputElement;
  isSizeIncreaseOperation: boolean;
  isDeleteButtonDown: boolean;
  isNoOperation: boolean;
}

interface UseInputMaskArgs {
  // Takes a formatted string and adds trailing format characters (ex: MM-DD| -> MM-DD-|)
  append?: (formattedStr: string) => string;
  // Takes an unprocessed string (could include raw chars, invalid chars, or format chars) and returns a formatted string.
  format?: (mixedStr: string) => string;
  // Set to true if format placeholder characters are constantly displayed. Use `replace` to manage these characters.
  guided?: boolean;
  // Accepts formatted string. Use this function to update state.
  onFormatComplete: (formattedStr: string) => void;
  // Matches all accepted "raw" characters. Raw characters are the characters typed by the user.
  // 🔴 @TODO: This is used by 2 slightly different things. It should be refactored.
  // 1. To determine if a single character is accepted (order and quantity NOT relevant Regex)
  // 2. To get the "raw" characters in the input (order and quantity relevant in Regex)
  acceptedChars?: RegExp;
  // Takes a formatted string and replaces specific characters (ex: forcing upper-case characters, displaying placeholder characters)
  replace?: (formattedStr: string) => string;
  // Current value of input
  value: string;
}

interface UseInputMaskReturnValue {
  // Formatted string
  value: string;
  // Input event handler. Pass as a prop to your input
  onInput: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  // BeforeInput event handler. Pass as a prop to your input
  onBeforeInput: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

/* Enables formatting of user input. Intelligently manages cursor position changes caused by formatting.
 * NOTE: Input type=number and input type=date are not supported.
 * NOTE: Incompatible with the `maxLength` attribute. Use the `format` function to enforce maxLength instead. */
const useInputMask = ({
  append,
  format = (str) => str,
  guided = false,
  onFormatComplete,
  acceptedChars = /[\d]+/g,
  replace = (str) => str,
  value
}: UseInputMaskArgs): UseInputMaskReturnValue => {
  const [, refresh] = useReducer((prev) => prev + 1, 0);
  const eventInfoRef = useRef<EventInfo | undefined>();
  const currentFormattedValue = useMemo(() => {
    return replace(format(value)) ?? "";
  }, [format, replace, value]);
  const getRaw = useCallback(
    (str: string) => (str.match(acceptedChars) || []).join(""),
    [acceptedChars]
  );

  const onBeforeInput = useCallback(
    (e: any) => {
      if (!e.data) return;
      const containsInvalidChars = e.data.length !== getRaw(e.data).length;
      if (containsInvalidChars) e.preventDefault();
    },
    [acceptedChars]
  );

  const onInput = useCallback(
    (e: any) => {
      const target = e.target as HTMLInputElement;
      eventInfoRef.current = {
        eventValue: target.value as string,
        input: target,
        isSizeIncreaseOperation: target.value.length > currentFormattedValue.length,
        isDeleteButtonDown: e.nativeEvent.inputType === "deleteContentForward",
        isNoOperation: currentFormattedValue === format(target.value)
      };
      refresh(); // Refreshing state forces our layout effect to run.
    },
    [currentFormattedValue, format]
  );

  const inputMask = useCallback(() => {
    if (!eventInfoRef.current) return;

    const { eventValue, input, isSizeIncreaseOperation, isDeleteButtonDown, isNoOperation } =
      eventInfoRef.current;
    eventInfoRef.current = undefined;

    // Usually occurs when deleting format characters.
    // In case of isDeleteButtonDown cursor should move differently vs backspace
    const deleteWasNoOp = isDeleteButtonDown && isNoOperation;
    const valueAfterSelectionStart = eventValue.slice(input.selectionStart as number);
    const rawCharIndexAfterDelete = valueAfterSelectionStart.search(acceptedChars);
    const charsToSkipAfterDelete = rawCharIndexAfterDelete > -1 ? rawCharIndexAfterDelete : 0;
    const oldCursorPosition = input.selectionStart as number;

    // If guided, replace placeholder characters as the user types
    let guidedEventValue = eventValue;
    // 🔴 @TODO: should replace the substr with slice
    if (guided && isSizeIncreaseOperation && !isNoOperation) {
      let start = getCursorPosition(guidedEventValue, guidedEventValue, oldCursorPosition, getRaw);
      const rawCharAfterCursor = getRaw(guidedEventValue.substr(start))[0];
      start = guidedEventValue.indexOf(rawCharAfterCursor, start);
      // Removing the placeholder character at the cursor position
      guidedEventValue = `${guidedEventValue.substr(0, start)}${guidedEventValue.substr(
        start + 1
      )}`;
    }

    let formattedValue = format(guidedEventValue);
    const cursorAtEnd = input.selectionStart === guidedEventValue.length;
    if (append && cursorAtEnd && !isNoOperation) {
      if (isSizeIncreaseOperation) {
        formattedValue = append(formattedValue);
      } else {
        // Remove trailing format characters after a backspace
        // Ex: You had `12-3|`, then backspaced. Now should be `12|`.
        if (getRaw(formattedValue.slice(-1)) === "") {
          formattedValue = formattedValue.slice(0, -1);
        }
      }
    }

    const replacedValue = replace(formattedValue);

    if (currentFormattedValue === replacedValue) {
      refresh(); // Nothing changed in value, just refresh to apply currentFormattedValue
    } else {
      onFormatComplete(replacedValue);
    }

    // Clean up function:
    // "After every re-render with changed dependencies, React will first run the cleanup function
    // with the old values, and then run your setup function with the new values."
    //
    // https://react.dev/reference/react/useLayoutEffect
    return () => {
      let start = getCursorPosition(formattedValue, guidedEventValue, oldCursorPosition, getRaw);

      // Improves cursor position for guided mode.
      // Ex: Date input was `5|1-24-3` then user pressed `6`. This code updates cursor to `56-|12-43`.
      const isDeleting = isDeleteButtonDown && !deleteWasNoOp;
      if (guided && (isSizeIncreaseOperation || isDeleting)) {
        while (formattedValue[start] && getRaw(formattedValue[start]) === "") {
          start += 1;
        }
      }

      const selectionCorrection = deleteWasNoOp ? 1 + charsToSkipAfterDelete : 0;
      const newCursorPosition = start + selectionCorrection;
      input.selectionStart = input.selectionEnd = newCursorPosition;

      // Brute force hack to re-apply the cursor position in cases
      // where a bad rerender causes it to jump to the end of the input
      const cursorPositionBeforeTimeout = newCursorPosition;
      const inputLengthBeforeTimeout = input.value.length;
      setTimeout(() => {
        if (
          cursorPositionBeforeTimeout < inputLengthBeforeTimeout &&
          input.selectionStart !== cursorPositionBeforeTimeout &&
          input.selectionStart === input.value.length
        ) {
          input.selectionStart = input.selectionEnd = cursorPositionBeforeTimeout;
        }
      }, 0);
    };
  }, [
    currentFormattedValue,
    format,
    getRaw,
    guided,
    onFormatComplete,
    replace,
    acceptedChars,
    append
  ]);

  // Formats the event value and calculates the new cursor position
  useLayoutEffect(inputMask, [inputMask]);

  return {
    value: eventInfoRef.current ? eventInfoRef.current.eventValue : currentFormattedValue,
    onBeforeInput,
    onInput
  };
};

export default useInputMask;
