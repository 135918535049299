import React, { useMemo } from "react";
import Badge, { IconColor } from "components/badge/Badge";
import CheckIcon from "components/icons/checkmark_circle.svg?react";
import CheckIconOutlined from "components/icons/checkmark_circle_outlined.svg?react";
import RejectedIcon from "components/icons/clear_circle.svg?react";
import Time from "components/icons/time.svg?react";

interface AcctStatusProps extends React.HTMLAttributes<HTMLDivElement> {
  status?: string;
  noLabel?: boolean;
}

function AccountStatus({ status, className, noLabel = true }: AcctStatusProps) {
  const statusData = useMemo(() => {
    const enumerationMap = {
      new: { label: "New", icon: CheckIconOutlined, color: "blue" },
      verified: { label: "Verified", icon: CheckIcon, color: "green" },
      pending: { label: "Pending", icon: Time, color: "gray" },
      errored: { label: "Errored", icon: RejectedIcon, color: "red" },
      verificationfailed: {
        label: "Verification Failed",
        icon: RejectedIcon,
        color: "red"
      }
    };
    const lowerStatus = status ? status.toLowerCase() : "";
    const statusMap = lowerStatus as "verified" | "new" | "verificationfailed";
    return enumerationMap[statusMap];
  }, [status]);

  return (
    <Badge
      className={className}
      label={statusData.label}
      Icon={statusData.icon}
      noLabel={noLabel}
      color={statusData.color as IconColor}
    />
  );
}

export default React.memo(AccountStatus);
