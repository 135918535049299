import { Enum } from "@moovfinancial/common/types/Enum";
import { Step } from "components/dialog/StepModal";
import { BusinessBasicDetails } from "./BusinessBasicDetails/BusinessBasicDetails";
import { BusinessContact } from "./BusinessContact/BusinessContact";
import { BusinessDetails } from "./BusinessDetails/BusinessDetails";
import { CreateNewAccount } from "./CreateNewAccount/CreateNewAccount";
import { IndividualBasicDetails } from "./IndividualBasicDetails/IndividualBasicDetails";
import { IndividualContact } from "./IndividualContact/IndividualContact";
import { IndividualDetails } from "./IndividualDetails/IndividualDetails";
import { StatementUpload } from "./StatementUpload/StatementUpload";
import { BasicSuccess } from "./Success/BasicSuccess";
import { BusinessSuccess } from "./Success/BusinessSuccess";
import { IndividualSuccess } from "./Success/IndividualSuccess";
import { TransactionDetails } from "./TransactionDetails/TransactionDetails";

export const STEP_IDS = {
  NEW: "new",
  BUS_BASIC_DETAILS: "busBasic",
  BUS_DETAILS: "busDetails",
  BUS_CONTACT: "busContact",
  TRANSACTIONS: "busTransactions",
  STATEMENTS: "busStatements",
  BUS_SUCCESS: "busSuccess",
  INDV_BASIC_DETAILS: "indvBasic",
  INDV_DETAILS: "indvDetails",
  INDV_CONTACT: "indvContact",
  INDV_SUCCESS: "indvSuccess",
  BASIC_SUCCESS: "basicSuccess"
} as const;

export type StepID = Enum<typeof STEP_IDS>;

interface AccountStep extends Step {
  id: StepID;
}

export const stepMap: Record<StepID, AccountStep> = {
  new: {
    content: <CreateNewAccount />,
    title: "Create new account",
    id: STEP_IDS.NEW
  },
  busBasic: {
    content: <BusinessBasicDetails />,
    title: "Business details",
    id: STEP_IDS.BUS_BASIC_DETAILS
  },
  busDetails: {
    content: <BusinessDetails />,
    title: "Business details",
    id: STEP_IDS.BUS_DETAILS
  },
  busContact: {
    content: <BusinessContact />,
    title: "Business contact details",
    id: STEP_IDS.BUS_CONTACT
  },
  busTransactions: {
    content: <TransactionDetails />,
    title: "Transaction details",
    id: STEP_IDS.TRANSACTIONS
  },
  busStatements: {
    content: <StatementUpload />,
    title: "Processing statements",
    id: STEP_IDS.STATEMENTS
  },
  busSuccess: {
    content: <BusinessSuccess />,
    title: "Account successfully created",
    id: STEP_IDS.BUS_SUCCESS
  },
  indvBasic: {
    content: <IndividualBasicDetails />,
    title: "Individual basic details",
    id: STEP_IDS.INDV_BASIC_DETAILS
  },
  indvDetails: {
    content: <IndividualDetails />,
    title: "Individual details",
    id: STEP_IDS.INDV_DETAILS
  },
  indvContact: {
    content: <IndividualContact />,
    title: "Individual contact",
    id: STEP_IDS.INDV_CONTACT
  },
  indvSuccess: {
    content: <IndividualSuccess />,
    title: "Account successfully created",
    id: STEP_IDS.INDV_SUCCESS
  },
  basicSuccess: {
    content: <BasicSuccess />,
    title: "Account successfully created",
    id: STEP_IDS.BASIC_SUCCESS
  }
};

export const onboardingSteps = {
  business: {
    default: [stepMap.new, stepMap.busBasic, stepMap.basicSuccess],
    expanded: [stepMap.new, stepMap.busDetails, stepMap.busContact, stepMap.busSuccess],
    transactions: [
      stepMap.new,
      stepMap.busDetails,
      stepMap.busContact,
      stepMap.busTransactions,
      stepMap.busSuccess
    ],
    statements: [
      stepMap.new,
      stepMap.busDetails,
      stepMap.busContact,
      stepMap.busTransactions,
      stepMap.busStatements,
      stepMap.busSuccess
    ]
  },
  individual: {
    default: [stepMap.new, stepMap.indvBasic, stepMap.basicSuccess],
    expanded: [stepMap.new, stepMap.indvDetails, stepMap.indvContact, stepMap.indvSuccess]
  }
};

export const editSteps = {
  individual: [stepMap.indvDetails, stepMap.indvContact],
  business: [stepMap.busDetails, stepMap.busContact, stepMap.busTransactions, stepMap.busStatements]
};
