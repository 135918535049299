import React, { createContext } from "react";
import { Cache } from "@moovfinancial/common/utils/cache/cache";

interface ContextProps {
  children: React.ReactNode;
}

// this is the "core" cache type, i.e. the type of the object persisted to the cache
type CacheStore = string | null;

export interface AvatarUrlsContext {
  avatarUrlCache: {
    get: (key?: string) => CacheStore | undefined;
    add: (key: string, value: Blob | null) => void;
  };
}

/** A context to cache all the calls to /avatars/* endpoints for the duration of the session */
export const AvatarUrlsContext = createContext<AvatarUrlsContext>({
  avatarUrlCache: {
    get: () => null,
    add: () => null
  }
});

const cache = Cache.init<CacheStore>({
  // N = number of items with actual blob images (`ObjectURL`) to keep in the cache
  // note that the `cache` will contain more than N items because it will keep the items
  // that failed to load (`null`) forever
  //
  // Back of the envelope calculation:
  //
  // - 1 image is about ~50kb
  // - 250 images is about ~12.5MB
  //
  // Which should be fine for most computers and phones
  N: 250,
  // When we remove an item from the cache, we want to free up the `ObjectURL` from the browser's memory
  onRemove: (_key: string, value: CacheStore) => {
    // free up the `ObjectURL` from the browser's memory
    if (value) {
      URL.revokeObjectURL(value);
    }
  }
});

export default function AvatarUrlsContextProvider({ children }: ContextProps) {
  return (
    <AvatarUrlsContext.Provider
      value={{
        avatarUrlCache: {
          get: cache.get,
          add: (key: string, value: Blob | null) => {
            if (value === null) {
              // if the rrequest to the server failed, we want to cache the result forever
              cache.add(key, value, true);
              return;
            }
            // if the request didn't fail, we create the `ObjectURL` and cache it for a limited time
            const urlString = URL.createObjectURL(value);
            cache.add(key, urlString, false);
          }
        }
      }}
    >
      {children}
    </AvatarUrlsContext.Provider>
  );
}
