import { Queue } from "@moovfinancial/common/data-structures/queue";

interface CacheParams<CacheStore> {
  // The maximum number of items to keep in the queue (note: might be different than the number of items in the cache)
  // as the consumer might decide to keep an item in the cache forever with the `permanent` flag (see below)
  N?: number;
  // Allows the consumer to do something when an entry is removed from the cache
  // like freeing up resources, etc.
  onRemove?: (key: string, value: CacheStore) => void;
  // Should return the testing object?
  test?: boolean;
}

interface Cache<T> {
  get: (key?: string) => T | undefined;
  add: (key: string, value: T, permanent?: boolean) => void;
}

export interface CacheWithTest<T> extends Cache<T> {
  _test: {
    N: number;
    keyQueue: Queue<T>;
    cache: Map<string, T | null>;
  };
}

function init<CacheStore>({
  N = 250,
  onRemove,
  test = false
}: CacheParams<CacheStore>): Cache<CacheStore> {
  // This queue will keep track of the order in which objects are added to the cache
  // so that we can remove the oldest ones when the cache is full
  const keyQueue = new Queue<string>();
  const cache = new Map<string, CacheStore>();

  // This function will be called every time we add a new entry to the cache to
  // remove the oldest entries if the cache size is greater than N
  const purgeIfNeeded = () => {
    if (keyQueue.size() > N) {
      while (keyQueue.size() > N) {
        // remove the item from the key from the queue
        const key = keyQueue.dequeue();
        // key might be undefined
        if (key) {
          const value = cache.get(key);
          // delete the entry from the cache
          cache.delete(key);
          if (value && onRemove) {
            onRemove(key, value);
          }
        }
      }
    }
  };

  // Add items to the cache, with the optional `permanent` flag to indicate
  // that the item should never be purged from the cache
  const add = (key: string, value: CacheStore, permanent = false) => {
    cache.set(key, value);
    // if the item should be in cache forever, don't add it to the queue
    // to be removed later
    if (!permanent) {
      keyQueue.enqueue(key);
    }
    // If queue size is > 10% of the desired cache size, purge the cache
    if (keyQueue.size() > Math.round(N * 1.1)) {
      purgeIfNeeded();
    }
  };

  const get = (key?: string) => {
    if (!key) return undefined;
    const value = cache.get(key);
    return value;
  };

  const publicFns = {
    get,
    add
  };

  const ret = !test
    ? publicFns
    : {
        ...publicFns,
        _test: {
          N,
          keyQueue,
          cache
        }
      };

  return ret;
}

export const Cache = { init };
