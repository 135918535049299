/** A custom fetch function that processes requests through a web worker,
 * to avoid automatic logging by the browser */
export const silentFetch = (
  input: RequestInfo | URL,
  init?: RequestInit | undefined
): Promise<Response> => {
  // 1. Wrap all work in a Response Promise
  const responsePromise = new Promise<Response>((resolve) => {
    // 2. Spawn a web worker
    const worker = new Worker("/silentFetch.worker.js");
    // 3. Set up a listener that will resolve our promise and terminate the worker
    worker.onmessage = (m) => {
      if (m.data.partialResponse) {
        // Construct a new Response object using received data
        m.data.partialResponse.headers = new Headers(m.data.partialHeaders);
        const useNullBody = [101, 204, 205, 304].includes(m.data.partialResponse.status);
        const response = new Response(
          useNullBody ? null : m.data.partialResponse.body,
          m.data.partialResponse
        ).clone();
        // Resolve the promise. 0-second timeout fixes race condition when reading the body
        setTimeout(() => {
          resolve(response);
          worker.terminate();
        }, 0);
      }
    };
    // 4. Tell the worker to kick off our request
    worker.postMessage({ input, init });
  });
  return responsePromise;
};
