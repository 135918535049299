import clsx from "clsx";
import { ChangeEvent, FormEventHandler, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  CharCounterInput,
  EINInput,
  FloatingLabelInput,
  FloatingLabelWrapper,
  FormGroup,
  Icon,
  PhoneNumberInput,
  SSNInput,
  Select
} from "@moovfinancial/cargo";
import { IconTechnologyAndDataAi } from "@moovfinancial/cargo/icons";
import { isSuccess } from "@moovfinancial/common/api/response";
import { useOpenApi } from "@moovfinancial/common/hooks/useOpenApi";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import {
  Industry,
  backupIndustryCodes,
  getCodesByTitle,
  getTitleByCodes,
  isFinancialInstitution
} from "@moovfinancial/common/utils/Industry";
import { REGEX } from "@moovfinancial/common/utils/regex";
import FloatingLabelComboBox from "components/form/FloatingLabelComboBox";
import { Checkbox } from "components/form/Form";
import { primaryRegulatorMap } from "api/v2";
import { businessTypeMap } from "api/v2/accounts.model";
import { type Account, OnboardingContext, type PatchAccount } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { type OnboardingErrors, handleError, parseErrors } from "../helpers/errors";
import styles from "./CompanyInformation.module.scss";

type BusinessType = components["schemas"]["BusinessType"];
type PrimaryRegulator = components["schemas"]["PrimaryRegulator"];

interface FormState {
  businessType: BusinessType | "";
  description: string;
  doingBusinessAs: string;
  ein: string;
  email: string;
  industryCodes: {
    mcc: string;
    naics: string;
    sic: string;
  };
  legalBusinessName: string;
  phone: string;
  taxIDProvided: boolean;
  website: string;
  primaryRegulator?: PrimaryRegulator | "";
}

const mapAccountToFormState = (account: Partial<Account>): FormState => ({
  businessType: account.profile?.business?.businessType ?? "",
  description: account.profile?.business?.description ?? "",
  doingBusinessAs: account.profile?.business?.doingBusinessAs ?? "",
  ein: account.profile?.business?.taxID?.ein?.number ?? "",
  email: account.profile?.business?.email ?? "",
  industryCodes: {
    mcc: account.profile?.business?.industryCodes?.mcc ?? "",
    naics: account.profile?.business?.industryCodes?.naics ?? "",
    sic: account.profile?.business?.industryCodes?.sic ?? ""
  },
  legalBusinessName: account.profile?.business?.legalBusinessName ?? "",
  phone: account.profile?.business?.phone?.number ?? "",
  taxIDProvided: account.profile?.business?.taxIDProvided ?? false,
  website: account.profile?.business?.website ?? "",
  primaryRegulator: account.profile?.business?.primaryRegulator ?? ""
});

const mapFormStateToAccount = (formState: FormState): PatchAccount => ({
  profile: {
    business: {
      businessType: formState.businessType || undefined,
      description: formState.description,
      doingBusinessAs: formState.doingBusinessAs,
      taxID: { ein: { number: formState.ein } },
      email: formState.email,
      industryCodes: formState.industryCodes,
      legalBusinessName: formState.legalBusinessName,
      phone: {
        number: formState.phone
      },
      // TODO: This field should be sent to prevent a 422 if an EIN was provided before, but is not being sent now
      /* @ts-expect-error - openApiSpecIsWrong - This field should exist on the PatchAccount type */
      taxIDProvided: formState.taxIDProvided,
      website: formState.website,
      primaryRegulator: formState.primaryRegulator || undefined
    }
  }
});

export function CompanyInformation() {
  const navigate = useNavigate();
  const { getNextStepUrl, hasExpectedActivityStep } = useContext(OnboardingStepsContext);
  const { account, patchAccount } = useContext(OnboardingContext);
  const formRef = useRef<HTMLFormElement>(null);
  const [formState, setFormState] = useState<FormState>(mapAccountToFormState(account));
  const [errors, setErrors] = useState<OnboardingErrors<Account>>({});
  const [EINorSSN, setEINorSSN] = useState<"EIN" | "SSN">("EIN");
  const [isDBA, setIsDBA] = useState(false);
  const [industry, setIndustry] = useState("");
  const [industries, setIndustries] = useState<Industry[]>(backupIndustryCodes);
  const { openApi } = useOpenApi();

  useEffect(() => {
    async function getIndustryCodes() {
      const { response, data } = await openApi.GET("/industries");
      if (isSuccess(response) && data?.industries) setIndustries(data.industries);
    }
    void getIndustryCodes();
  }, []);

  useEffect(() => {
    const codes = formState.industryCodes;
    if (codes?.mcc || codes?.sic || codes?.naics) {
      const industryValue = getTitleByCodes(codes, industries);
      if (industryValue) setIndustry(industryValue);
    }
  }, [formState.industryCodes, industries]);

  useEffect(() => {
    const codes = getCodesByTitle(industry, industries);
    if (codes) {
      const { mcc, naics, sic } = codes;
      setFormState((prev) => ({
        ...prev,
        industryCodes: { mcc: mcc ?? "", naics: naics ?? "", sic: sic ?? "" }
      }));
    }
  }, [industries, industry]);

  useEffect(() => {
    if (formState.doingBusinessAs) {
      setIsDBA(true);
    }
  }, [formState.doingBusinessAs]);

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    const apiResponse = await patchAccount(mapFormStateToAccount(formState));

    if (apiResponse.error) {
      // TODO FE-1084: Figure out type issues and remove this comment
      setErrors(parseErrors<Account>(apiResponse.error));
      toast("Unable to save account. Please check the form for errors and try again.");
      return;
    }

    if (apiResponse.data) {
      navigate(getNextStepUrl());
    }
  };

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Business information</title>
      </Helmet>
      <h2 className={styles.header}>Business information</h2>
      <div className={clsx(styles.row, styles.autofill)}>
        <Icon iconComponent={IconTechnologyAndDataAi} size="M" />
        <p>We autofilled some fields based on your work email. Please check them for accuracy.</p>
      </div>
      <form onSubmit={handleSubmit} ref={formRef}>
        <FormGroup noGap={false}>
          <FloatingLabelInput
            error={handleError(errors.profile?.business?.legalBusinessName)}
            label="Legal business name"
            name="businessName"
            onChange={(e) =>
              setFormState((prev) => ({ ...prev, legalBusinessName: e.target.value }))
            }
            required
            value={formState.legalBusinessName}
          />
        </FormGroup>
        <FormGroup noGap={false}>
          <Checkbox
            checked={isDBA}
            label="I use a registered DBA to conduct business."
            onChange={(e) => setIsDBA(e.target.checked)}
          />
        </FormGroup>
        {isDBA && (
          <FormGroup noGap={false}>
            <FloatingLabelInput
              error={handleError(errors.profile?.business?.doingBusinessAs)}
              label="Doing business as"
              name="doingBusinessAs"
              onChange={(e) =>
                setFormState((prev) => ({ ...prev, doingBusinessAs: e.target.value }))
              }
              value={formState.doingBusinessAs}
              required={isDBA}
            />
          </FormGroup>
        )}
        <FormGroup noGap={false}>
          <FloatingLabelComboBox
            autoComplete="off"
            error={handleError(
              errors.profile?.business?.industryCodes?.mcc ||
                errors.profile?.business?.industryCodes?.naics ||
                errors.profile?.business?.industryCodes?.sic
            )}
            className={styles.industry}
            data={industries.map((code) => ({
              value: code.title ?? "",
              label: `${code.mcc}`
            }))}
            format="both"
            label="Industry"
            name="SICCode"
            noMargins
            onChange={(e) => setIndustry(e.target.value)}
            required
            value={industry}
          />
        </FormGroup>
        {isFinancialInstitution(formState.industryCodes) && (
          <FormGroup noGap={false}>
            <Select
              error={handleError(errors.profile?.business?.primaryRegulator)}
              floatingLabelStyle
              label="Primary regulator"
              name="primaryRegulator"
              onChange={(e) =>
                setFormState((prev) => ({
                  ...prev,
                  primaryRegulator: e.target.value as PrimaryRegulator
                }))
              }
              placeholder="--"
              required={isFinancialInstitution(formState.industryCodes)}
              value={formState.primaryRegulator}
            >
              <hr />
              {Object.entries(primaryRegulatorMap).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
          </FormGroup>
        )}
        <FormGroup noGap={false}>
          <Select
            error={handleError(errors.profile?.business?.businessType)}
            floatingLabelStyle
            label="Business type"
            name="businessType"
            onChange={(e) =>
              setFormState((prev) => ({ ...prev, businessType: e.target.value as BusinessType }))
            }
            placeholder="--"
            required
            value={formState.businessType ?? ""}
          >
            <hr />
            {Object.entries(businessTypeMap).map(([key, value]) => (
              <option key={key + value} value={key}>
                {value}
              </option>
            ))}
          </Select>
        </FormGroup>
        <div className={styles.row}>
          <FormGroup noGap={false}>
            <Select
              floatingLabelStyle
              label="Tax ID type"
              onChange={(e) => setEINorSSN(e.target.value as "EIN" | "SSN")}
              required
              value={EINorSSN}
            >
              <option value="EIN">EIN</option>
              <option value="SSN">SSN/ITIN</option>
            </Select>
          </FormGroup>
          <FormGroup noGap={false}>
            {EINorSSN === "SSN" ? (
              <FloatingLabelWrapper
                as={SSNInput}
                error={handleError(errors.profile?.business?.taxID?.ein?.number)}
                forceFloating={formState.taxIDProvided}
                label="Tax ID"
                name="ein"
                onValueChange={(ssn: string) => setFormState((prev) => ({ ...prev, ein: ssn }))}
                pattern={REGEX.SSN}
                placeholder={formState.taxIDProvided ? "•••-••-••••" : undefined}
                required={!formState.taxIDProvided}
                value={formState.ein}
              />
            ) : (
              <FloatingLabelWrapper
                as={EINInput}
                error={handleError(errors.profile?.business?.taxID?.ein?.number)}
                forceFloating={formState.taxIDProvided}
                label="Tax ID"
                name="ein"
                onValueChange={(ein: string) => setFormState((prev) => ({ ...prev, ein }))}
                pattern={REGEX.EIN}
                placeholder={formState.taxIDProvided ? "••-•••••••" : undefined}
                required={!formState.taxIDProvided}
                value={formState.ein}
              />
            )}
          </FormGroup>
        </div>
        <FormGroup noGap={false}>
          <FloatingLabelInput
            autoComplete="email work"
            error={handleError(errors.profile?.business?.email)}
            label="Business email"
            name="email"
            onChange={(e) => setFormState((prev) => ({ ...prev, email: e.target.value }))}
            pattern={REGEX.EMAIL}
            required
            type="email"
            value={formState.email}
          />
        </FormGroup>
        <FormGroup noGap={false}>
          <FloatingLabelWrapper
            as={PhoneNumberInput}
            error={handleError(errors.profile?.business?.phone?.number)}
            label="Phone number"
            name="phone"
            required
            onValueChange={(phone: string) => setFormState((prev) => ({ ...prev, phone }))}
            value={formState.phone}
          />
        </FormGroup>
        <FormGroup noGap={false}>
          <FloatingLabelInput
            error={handleError(errors.profile?.business?.website)}
            label="Company website"
            name="website"
            onChange={(e) => setFormState((prev) => ({ ...prev, website: e.target.value }))}
            pattern={REGEX.URL}
            required
            value={formState.website}
          />
        </FormGroup>
        <FormGroup noGap={false}>
          <FloatingLabelWrapper
            as={CharCounterInput}
            error={handleError(errors.profile?.business?.description)}
            helper="100 character limit"
            label="Tell us about what your business does"
            maxLength={100}
            minLength={10}
            name="description"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormState((prev) => ({ ...prev, description: e.target.value }))
            }
            required={hasExpectedActivityStep()}
            value={formState.description}
          />
        </FormGroup>
        <Button buttonSize="M" buttonStyle="fill" buttonType="primary" type="submit">
          Save & continue
        </Button>
      </form>
    </div>
  );
}
