export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function retryRequest<T>(
  apiRequest: () => Promise<T>,
  attempts: number = 4
): Promise<T> {
  try {
    return await apiRequest();
  } catch (err) {
    const actualAttempts = attempts - 1;
    await wait(800 / actualAttempts);
    if (actualAttempts > 0) {
      return retryRequest(apiRequest, actualAttempts);
    }
    throw err;
  }
}

export async function retryRequestIf<T>(
  apiRequest: () => Promise<T>,
  shouldRetry: (result?: T) => boolean,
  attempts: number = 4
): Promise<T | undefined> {
  const localAttempts = attempts - 1;
  let result;
  let error;

  try {
    result = await apiRequest();
  } catch (err) {
    error = err;
  }

  if (error && shouldRetry(result) && localAttempts > 0) {
    await wait(800 / localAttempts);
    return retryRequestIf(apiRequest, shouldRetry, localAttempts);
  }

  // eslint-disable-next-line @typescript-eslint/only-throw-error
  if (error) throw error;
  return result;
}
