import { useContext } from "react";
import { Button, LinkAsButton, Modal } from "@moovfinancial/cargo";
import { StepContext } from "components/dialog/StepModal";
import { Text } from "cleanComponents/Typography/Text";
import { AccountSetupContext } from "../../AccountSetupContext";
import styles from "./Success.module.scss";

export const BasicSuccess = () => {
  const {
    account: { accountID }
  } = useContext(AccountSetupContext);
  const { close } = useContext(StepContext);

  return (
    <>
      <Modal.Body>
        <Text textStyle="paragraph-m-regular">
          Your account is created and the transfers capability is enabled. Additional capabilities
          can be requested any time on the capabilities tab.
        </Text>
      </Modal.Body>
      <Modal.Footer className={styles.successActions}>
        <LinkAsButton
          buttonType="secondary"
          buttonStyle="fill"
          to={`/accounts/${accountID}/capabilities`}
        >
          Request capabilities
        </LinkAsButton>
        <Button buttonType="primary" onClick={close}>
          Done
        </Button>
      </Modal.Footer>
    </>
  );
};
