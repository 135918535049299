import GoogleRecaptcha from "@moovfinancial/common/utils/GoogleRecaptcha";
import { getFingerprint } from "@moovfinancial/common/utils/fingerprint";
import { RegisterUser, UpdatePassword, UpdateUser, User, type UserInvite } from "api/User.model";
import { http } from "api/http";
import type { DeviceVerification, UserAccount } from "./v2";

export interface UsersConfig {
  fingerprintFn: () => Promise<string>;
}

const defaultConfig: UsersConfig = {
  fingerprintFn: getFingerprint
};

let activeConfig = defaultConfig;

export function getConfig(): UsersConfig {
  return activeConfig;
}

export function setConfig(config: Partial<UsersConfig>) {
  activeConfig = { ...activeConfig, ...config };
}

export function resetConfig() {
  activeConfig = defaultConfig;
}

export async function signUp(email: string, referral?: string) {
  const body = { email, referral };
  if (!referral) delete body.referral;
  const recaptchaToken = await GoogleRecaptcha.execute("signUp");
  return http<null>("/signup", {
    method: "POST",
    json: body,
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export function updateUser(userID: string, updateUser: UpdateUser) {
  return http<UpdateUser>(`/users/${userID}`, {
    method: "PUT",
    json: updateUser
  });
}

export async function signIn(email: string, password: string) {
  const recaptchaToken = await GoogleRecaptcha.execute("signIn");
  const user = await http<User>("/auth/signin/password", {
    method: "POST",
    json: { email, password },
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
  return user;
}

export async function changePassword(updatePassword: UpdatePassword) {
  const recaptchaToken = await GoogleRecaptcha.execute("changePassword");
  return http<null>("/auth/signin/password/change-password", {
    method: "POST",
    json: updatePassword,
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export async function recover(email: string) {
  const recaptchaToken = await GoogleRecaptcha.execute("recoverPassword");
  return http<User>("/auth/signin/password/recover", {
    method: "POST",
    json: { email },
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export async function resetPasswordForRecovery(
  email: string,
  password: string,
  confirmPassword: string,
  token: string
) {
  const recaptchaToken = await GoogleRecaptcha.execute("resetPassword");
  return http<User>("/auth/signin/password/recover/reset-password", {
    method: "POST",
    json: { email, password, confirmPassword, token },
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export async function register(registerUser: RegisterUser) {
  const fingerprint = await activeConfig.fingerprintFn();
  window.sessionStorage.setItem("fingerprint", fingerprint);
  const recaptchaToken = await GoogleRecaptcha.execute("register");
  const user = await http<User>("/auth/signin/password/register", {
    method: "POST",
    json: { ...registerUser, fingerprint },
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
  return user;
}

export async function verifyDevice(fingerprintParam?: string | null) {
  const fingerprint = fingerprintParam || (await activeConfig.fingerprintFn());
  if (!fingerprintParam) {
    window.sessionStorage.setItem("fingerprint", fingerprint);
  }
  const recaptchaToken = await GoogleRecaptcha.execute("verify");
  return http<DeviceVerification[]>("/auth/verify", {
    method: "POST",
    json: { fingerprint },
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export async function sendVerifyDeviceCode() {
  const recaptchaToken = await GoogleRecaptcha.execute("sendVerify");
  return http<null>("/auth/verify/email/send", {
    method: "POST",
    json: {},
    headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
  });
}

export function registerDevice(code: string) {
  return activeConfig.fingerprintFn().then((fingerprint: string) => {
    window.sessionStorage.setItem("fingerprint", fingerprint);
    return http<DeviceVerification>("/auth/verify/email", {
      method: "POST",
      json: { code, fingerprint }
    });
  });
}

export function ping() {
  return http<null>("/ping");
}

export function listUserAccounts(userID: string) {
  return http<UserAccount[]>(`/users/${userID}/accounts`);
}

export async function listUserInvites(userID: string) {
  try {
    return await http<UserInvite[]>(`/users/${userID}/invites`);
  } catch (e) {
    if ((e as { status: number }).status === 404) {
      return [];
    }
    throw e;
  }
}
