import { Alert } from "@moovfinancial/cargo";
import styles from "../../AccountSetup.module.scss";

export function VerificationLockedBanner() {
  return (
    <Alert className={styles.lockedAlert} inlineLinksAsActions type="info">
      Verification is complete or in progress. To edit locked fields,{" "}
      <a href="https://support.moov.io/hc/en-us/requests/new?" rel="noreferrer" target="_blank">
        contact support
      </a>
      .
    </Alert>
  );
}

export function UnderwritingLockedBanner() {
  return (
    <Alert className={styles.lockedAlert} inlineLinksAsActions type="info">
      Underwriting is complete or in progress. To edit locked fields,{" "}
      <a href="https://support.moov.io/hc/en-us/requests/new?" rel="noreferrer" target="_blank">
        contact support
      </a>
      .
    </Alert>
  );
}
