import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import pruneObject from "@moovfinancial/common/utils/pruneObject";
import { Business, Capability } from "api/v2";
import {
  Account,
  BusinessType,
  DateObject,
  GovernmentID,
  Individual,
  PatchAccount,
  PatchBusiness,
  PatchIndividual,
  Phone,
  businessTypeMap
} from "api/v2/accounts.model";
import { hasUnfixableError } from "./capabilities";
import deepDiff from "./deepDiff";
import { objectIsEmpty } from "./objectIsEmpty";

export function isNonprofit(account: Account): boolean {
  const businessType = account.profile?.business?.businessType;
  return !!businessType?.includes("NonProfit");
}

export function requiresManualVerification(account: Account, capabilities: Capability[]) {
  const verificationStatus = account.verification?.verificationStatus;
  const hasUnfixableReq = hasUnfixableError(capabilities);
  return verificationStatus === "failed" || hasUnfixableReq;
}

export function getBusinessTypeLabel(businessType?: BusinessType): string {
  if (businessType === undefined) return "";
  switch (businessType) {
    case "soleProprietorship":
      return businessTypeMap.soleProprietorship;
    case "llc":
      return businessTypeMap.llc;
    case "trust":
      return businessTypeMap.trust;
    case "publicCorporation":
      return businessTypeMap.publicCorporation;
    case "privateCorporation":
      return businessTypeMap.privateCorporation;
    case "partnership":
      return businessTypeMap.partnership;
    case "unincorporatedAssociation":
      return businessTypeMap.unincorporatedAssociation;
    case "unincorporatedNonProfit":
      return businessTypeMap.unincorporatedNonProfit;
    case "incorporatedNonProfit":
      return businessTypeMap.incorporatedNonProfit;
    case "governmentEntity":
      return businessTypeMap.governmentEntity;
    default:
      return "";
  }
}

/**
 * Pares a full account object down to a patch account object.
 */
export function getAccountPatch(account: Partial<Account>): PatchAccount {
  const patch: PatchAccount = {
    metadata: account.metadata,
    foreignID: account.foreignID,
    customerSupport: account.customerSupport,
    settings: account.settings
  };

  if (patch.customerSupport) {
    if (objectIsEmpty(patch.customerSupport.address)) {
      delete patch.customerSupport.address;
    }
    if (objectIsEmpty(patch.customerSupport.phone)) {
      delete patch.customerSupport?.phone;
    } else {
      patch.customerSupport.phone = cleanPhone(patch.customerSupport.phone);
    }
    if (objectIsEmpty(patch.customerSupport)) {
      delete patch.customerSupport;
    }
  }

  if (account.accountType === "individual" && account.profile?.individual) {
    const acctIndividual = account.profile.individual;

    const individual: PatchIndividual = {
      name: acctIndividual.name,
      phone: cleanPhone(acctIndividual.phone),
      email: acctIndividual.email,
      address: acctIndividual.address
    };

    // We only patch these properties if 1) they haven't yet been provided or 2) they've been
    // provided and the user entered some new values for them.
    if (!acctIndividual.birthDateProvided || !dateIsEmpty(acctIndividual.birthDate)) {
      individual.birthDate = acctIndividual.birthDate;
    }
    if (!acctIndividual.governmentIDProvided || !objectIsEmpty(acctIndividual.governmentID)) {
      individual.governmentID = cleanGovernmentID(acctIndividual.governmentID);
    }

    patch.profile = { individual };
  } else {
    const acctBusiness = account.profile?.business;

    const business: PatchBusiness = {
      legalBusinessName: acctBusiness?.legalBusinessName,
      doingBusinessAs: acctBusiness?.doingBusinessAs,
      businessType: acctBusiness?.businessType,
      address: acctBusiness?.address,
      phone: cleanPhone(acctBusiness?.phone),
      email: acctBusiness?.email,
      website: acctBusiness?.website,
      description: acctBusiness?.description,
      ownersProvided: acctBusiness?.ownersProvided,
      industryCodes: acctBusiness?.industryCodes
    };

    // We only patch these properties if 1) they haven't yet been provided or 2) they've been
    // provided and the user entered some new values for them.
    if (!acctBusiness?.taxIDProvided || !objectIsEmpty(acctBusiness?.taxID)) {
      business.taxID = acctBusiness?.taxID;
    }

    patch.profile = { business };
  }

  return patch;
}

function dateIsEmpty(date?: DateObject): boolean {
  if (date === undefined || date === null) return true;

  return objectIsEmpty(date) || (date.year === 0 && date.month === 0 && date.day === 0);
}

export function cleanPhone(phone?: Phone | null): Phone | undefined {
  if (!phone?.number && phone?.countryCode) {
    return {
      number: "",
      countryCode: ""
    };
  } else {
    return phone || undefined;
  }
}

function cleanGovernmentID(governmentID?: GovernmentID): GovernmentID | undefined {
  if (!governmentID) return undefined;
  const cleaned: GovernmentID = {
    itin: null,
    ssn: null
  };

  if (!objectIsEmpty(governmentID.itin)) {
    cleaned.itin = { ...governmentID.itin };
  }
  if (!objectIsEmpty(governmentID.ssn)) {
    cleaned.ssn = { ...governmentID.ssn };
  }

  return objectIsEmpty(cleaned) ? undefined : cleaned;
}

export const pruneAccount = (account: DeepPartial<Account>): DeepPartial<Account> => {
  const shouldPrune = (key: string, value: unknown) => {
    if (value === null || value === undefined || value === "") return true;
    if (account.accountType === "business" && key === "individual") return true;
    if (account.accountType === "individual" && key === "business") return true;
    return false;
  };
  return pruneObject(account, shouldPrune);
};

export const pruneAccountForPatch = (
  existingAccount: DeepPartial<Account>,
  newAccount: DeepPartial<Account>
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const patchData: Partial<Account> = deepDiff(existingAccount, newAccount);
  const indvProfile: Partial<Individual> | undefined = patchData.profile?.individual;
  const busProfile: Partial<Business> | undefined = patchData.profile?.business;

  delete patchData.updatedOn;
  delete patchData.createdOn;
  delete patchData.termsOfService;

  if (existingAccount.accountType === "business") {
    delete patchData.profile?.individual;

    if (busProfile) {
      delete busProfile.representatives;

      if (Object.keys(busProfile).length === 0) {
        delete patchData.profile;
      }
    }
  }
  if (existingAccount.accountType === "individual") {
    delete patchData.profile?.business;

    if (indvProfile) {
      if (indvProfile.birthDate && Object.values(indvProfile.birthDate).every((val) => val === 0)) {
        delete indvProfile.birthDate;
      }

      if (Object.keys(indvProfile).length === 0) {
        delete patchData.profile;
      }
    }
  }

  return patchData;
};
