import { useContext, useState } from "react";
import { FormGroup, Select } from "@moovfinancial/cargo";
import { isFinancialInstitution } from "@moovfinancial/common/utils/Industry";
import { Label } from "components/form/Form";
import { type PrimaryRegulator, primaryRegulatorMap } from "api/v2";
import { AccountSetupContext } from "../../AccountSetupContext";

interface PrimaryRegulatorSelectorProps {
  isLocked?: boolean;
  warn?: boolean;
}

export const PrimaryRegulatorSelector = ({ isLocked, warn }: PrimaryRegulatorSelectorProps) => {
  const { dispatch, account, isErrored, resetInput } = useContext(AccountSetupContext);
  const [isValid, setIsValid] = useState(true);

  // hides field if industry type is not that of a financial institution
  if (
    !account.profile?.business?.industryCodes ||
    !isFinancialInstitution(account.profile?.business?.industryCodes)
  )
    return;

  const validate = (e: React.FocusEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    resetInput("primaryRegulator");
    if (account.accountType === "business") {
      dispatch({
        type: "business",
        value: { primaryRegulator: e.target.value as PrimaryRegulator }
      });
    }
  };

  const getWarningMessage = () => {
    if (warn) {
      return "Primary regulator is missing.";
    }
  };

  const isErroring = isErrored("primaryRegulator") || !isValid;

  const getErrorMessage = () => {
    if (isErroring) {
      return "Primary regulator is invalid.";
    }
  };

  return (
    <FormGroup errorMessage={getErrorMessage()} warningMessage={getWarningMessage()}>
      <Label label="Primary regulator" />
      <Select
        name="primaryRegulator"
        value={account.profile?.business?.primaryRegulator}
        placeholder="--"
        autoComplete="off"
        disabled={isLocked}
        isErroring={isErroring}
        isWarning={warn}
        onChange={handleChange}
        onBlur={validate}
      >
        {Object.entries(primaryRegulatorMap).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </Select>
    </FormGroup>
  );
};
