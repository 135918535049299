import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { shouldShowDebug } from "@moovfinancial/common/utils/env";
import Container from "components/container/Container";

function FourOhFour({ debug }: { debug?: string }) {
  if (debug && shouldShowDebug()) {
    // eslint-disable-next-line no-console
    console.log("🐞 Showing 404 with debug message: ", debug);
  }
  const currentLocation = useLocation();
  return (
    <Container>
      <Helmet>
        <title>404 Not found</title>
      </Helmet>
      <h1>404</h1>
      <p>Page could not be found: "{currentLocation.pathname}"</p>
    </Container>
  );
}

export default FourOhFour;
