import clsx from "clsx";
import { type FormEvent, ReactNode } from "react";
import type { Theme } from "@moovfinancial/common/types/Theme";
import { CargoElement } from "../CargoElement";
import { Loading } from "../Foundations";
import { Helper } from "./Helper";
import { ValidationMessage } from "./ValidationMessage";
import styles from "./FormGroup.module.scss";

export type FormGroupTheme = Theme<typeof styles>;
export interface FormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The HTML element to render as
   * @default "div"
   */
  as?: string;
  /**
   * validation message to display under the form group.
   * If passed, it will take precedence over `warn` and `error` and `errorMessage` and `warningMessage`
   *
   * Severity level of the error message. Defaults to "warning" for backwards compatibility reaons.
   */
  validationMessage?: {
    message: string | ReactNode;
    /**
     *
     * @default "warning"
     */
    severity: "error" | "warning";
  };
  /**
   * If true, the `errorMessage` will be displayed as a warning
   */
  /**
   * @deprecated Use `validationMessage`, `warningMessage` or `ErrorMessage`
   */
  warn?: boolean;
  /**
   * If true, the `errorMessage` will be displayed as an error
   */
  /**
   * @deprecated Use `validationMessage`, `warningMessage` or `ErrorMessage`
   */
  error?: boolean;
  // @TODO @gamell rename to `validationMessage`
  /**
   * Error message to display under the form group
   * If both `errorMessage` and `validationMessage` are passed, `validationMessage` will take precedence
   */
  errorMessage?: string | false | ReactNode;
  /**
   * Warning message to display under the form group
   * If both `warningMessage` and `validationMessage` are passed, `validationMessage` will take precedence
   */
  warningMessage?: string | false | ReactNode;
  /**
   * Remove all margins and paddings so the parent can position this element.
   * Defaults to false for backwards compatibility reasons, we should refactor it to never have margins by default
   * @default false
   */
  noMargins?: boolean;
  /**
   * Don't automatically apply `gap` between children.
   * Defaults to true for backwards compatibility reasons, we should refactor it to always have gap by default
   * @default true (for backwards compatibility reasons, we should refactor it to always have gap by default)
   */
  noGap?: boolean;
  /**
   * Helper text to display under the form group
   */
  helper?: string | ReactNode;
  /**
   * Shows an overlay on top of the form group and a loading spinner
   */
  isLoading?: boolean;
  /**
   * Custom theme to apply to the form group
   */
  theme?: FormGroupTheme;
}

export const FormGroup = ({
  children,
  className,
  errorMessage,
  warningMessage,
  helper,
  warn = false,
  error = false,
  noMargins = false,
  noGap = true,
  as,
  isLoading,
  validationMessage: validationMessageProp,
  onChange,
  theme,
  ...rest
}: FormGroupProps) => {
  const handleLocalChange = (e: FormEvent<HTMLDivElement>) => {
    onChange && onChange(e);
  };

  const getValidationMessage = () => {
    if (validationMessageProp) {
      return validationMessageProp.message;
    } else if (errorMessage) {
      return errorMessage;
    } else {
      return warningMessage;
    }
  };

  const validationMessage = getValidationMessage();

  const getSeverity = () => {
    if (validationMessageProp) return validationMessageProp.severity;
    if (error && !warn) return "error";
    if (errorMessage) return !warn ? "error" : "warning";
    return "warning";
  };

  const severity = getSeverity();

  return (
    <CargoElement
      renderAs={as || "div"}
      onChange={handleLocalChange}
      className={clsx(
        styles.FormGroup,
        noMargins && styles.noMargins,
        theme?.noMargins,
        noGap && styles.noGap,
        theme?.noGap,
        className,
        theme?.FormGroup
      )}
      {...rest}
    >
      {isLoading && (
        <div className={clsx(styles.isLoading, theme?.isLoading)}>
          <Loading size={2} />
        </div>
      )}
      {children}
      {helper && <Helper>{helper}</Helper>}
      {validationMessage && validationMessage !== "" && (
        <ValidationMessage
          message={validationMessage}
          severity={severity}
          className={clsx(styles.validationMessage, theme?.validationMessage)}
          noMargins
        />
      )}
    </CargoElement>
  );
};
