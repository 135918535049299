import { MouseEvent, useContext, useEffect, useState } from "react";
import { ButtonLegacy, FormGroup, GovernmentIDInput, Icon, Select } from "@moovfinancial/cargo";
import { IconInvisible } from "@moovfinancial/cargo/icons";
import { BirthDateMaskingInput } from "@moovfinancial/cargo/src/components/BusinessLogicCoupled/Form/Input/MaskingInputs/BirthDateMaskingInput";
import useAccountLocking from "hooks/useAccountLocking";
import useAccountRequirements from "hooks/useAccountRequirements";
import { useAuthenticatedAdminRoute } from "hooks/useAuthenticatedAdminRoute";
import { useDecryptValues } from "hooks/useDecryptValues";
import { AccountInput } from "../components/AccountInput";
import { PrefillAlert } from "../components/PrefillAlert";
import { Step } from "components/dialog/StepModal";
import { Label } from "components/form/Form";
import Toaster from "components/toaster/Toaster";
import Tippy from "@tippyjs/react";
import { Action, MoovAdminResource } from "api/Role.model";
import { Text } from "cleanComponents/Typography/Text";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { UserContext } from "contexts/UserContext";
import { AccountSetupContext } from "../../AccountSetupContext";
import styles from "./IndividualDetails.module.scss";

export const IndividualDetails = () => {
  const { userCan } = useContext(UserContext);
  const { facilitatorID } = useContext(FacilitatorContext);
  const {
    account,
    dispatch,
    resetInput,
    isErrored,
    errorMessages,
    capabilityRequirements,
    invalidInputs,
    setErrorMessages
  } = useContext(AccountSetupContext);

  const isAdminRoute = useAuthenticatedAdminRoute();
  const canDecrypt =
    userCan(Action.Read, MoovAdminResource.encryptedData) && isAdminRoute && account.accountID;
  const {
    decryptedGovernmentID,
    decryptedBirthday,
    decryptAccount,
    error: decryptError,
    loading: decryptLoading
  } = useDecryptValues();
  const { accountLocks } = useAccountLocking();
  const { accountRequirements } = useAccountRequirements(capabilityRequirements);

  const [showingDecryptedElements, setShowingDecryptedElements] = useState<
    ("taxID" | "birthDate")[]
  >([]);

  useEffect(() => {
    if (decryptError)
      setErrorMessages([
        {
          message: "There was an error decrypting the EIN.",
          status: "error",
          key: "decryptError"
        }
      ]);
  }, [decryptError]);

  const getGovernmentIDWarning = () => {
    if (
      accountRequirements.profile?.individual?.governmentID?.ssn?.full ||
      accountRequirements.profile?.individual?.governmentID?.ssn?.lastFour ||
      accountRequirements.profile?.individual?.governmentID?.itin?.full ||
      accountRequirements.profile?.individual?.governmentID?.itin?.lastFour
    ) {
      return "SSN or ITIN is missing.";
    }
  };

  const getGovernmentIDError = () => {
    if (isErrored("governmentID") || invalidInputs.includes("governmentID")) {
      return "Please enter a valid SSN or ITIN.";
    }
  };

  const handleDecryptClick = (e: MouseEvent<HTMLButtonElement>, name: "birthDate" | "taxID") => {
    e.preventDefault();
    if (account.accountID) {
      setShowingDecryptedElements((prev) => [...prev, name]);
      decryptAccount(account.accountID, facilitatorID);
    }
  };

  const requestFullSSN = capabilityRequirements.some(
    (capability) =>
      capability.requirements.currentlyDue?.some((due) => due === "individual.ssn") ||
      capability.requirements.errors?.some((error) => error.requirement === "individual.ssn")
  );

  return (
    <Step className={styles.individualDetails}>
      {!!errorMessages.length && <Toaster toastInput={errorMessages} duration={4000} />}
      <PrefillAlert />
      <Text textStyle="paragraph-m-regular">
        All fields, unless labeled optional, are required to create an account and enable
        capabilities. However some information can be provided later.
      </Text>
      <div className={styles.grid}>
        <AccountInput
          label="First name"
          name="firstName"
          value={account.profile?.individual?.name?.firstName}
          showDefaultWarningMessage={!!accountRequirements?.profile?.individual?.name?.firstName}
          isLocked={!!accountLocks?.profile?.individual?.name?.firstName}
          dispatchType="individualName"
          required
        />
        <AccountInput
          label="Middle name"
          name="middleName"
          value={account.profile?.individual?.name?.middleName}
          showDefaultWarningMessage={!!accountRequirements?.profile?.individual?.name?.middleName}
          isLocked={!!accountLocks?.profile?.individual?.name?.middleName}
          dispatchType="individualName"
          optional
        />
        <AccountInput
          label="Last name"
          name="lastName"
          value={account.profile?.individual?.name?.lastName}
          showDefaultWarningMessage={!!accountRequirements?.profile?.individual?.name?.lastName}
          isLocked={!!accountLocks?.profile?.individual?.name?.lastName}
          dispatchType="individualName"
          required
        />
        <FormGroup as="label" warn={!!accountRequirements?.profile?.individual?.name?.suffix}>
          <Label>Suffix</Label>
          <Select
            name="suffix"
            value={account.profile?.individual?.name?.suffix || ""}
            autoComplete="off"
            disabled={!!accountLocks?.profile?.individual?.name?.suffix}
            onChange={(e) =>
              dispatch({
                type: "individualName",
                value: { suffix: e.target.value }
              })
            }
          >
            <option value="">None</option>
            <option value="Sr.">Sr.</option>
            <option value="Jr.">Jr.</option>
            <option value="II">II</option>
            <option value="III">III</option>
            <option value="IV">IV</option>
            <option value="V">V</option>
          </Select>
        </FormGroup>
        <div className={styles.decryptableInput}>
          <BirthDateMaskingInput
            label="Date of birth"
            name="birthDate"
            value={
              decryptedBirthday && showingDecryptedElements.includes("birthDate")
                ? decryptedBirthday.object
                : account.profile?.individual?.birthDate
            }
            onValueChange={(e) => {
              resetInput("birthDate");
              dispatch({ type: "individualBirthdate", value: e });
            }}
            placeholder={
              account.profile?.individual?.birthDateProvided ? "••/••/••••" : "MM/DD/YYYY"
            }
            disabled={!!accountLocks?.profile?.individual?.birthDate?.day}
            isLocked={!!accountLocks?.profile?.individual?.birthDate?.day}
            warning={
              accountRequirements?.profile?.individual?.birthDate?.day
                ? "Birth date is missing."
                : undefined
            }
            error={isErrored("birthDate") ? "Birth date is invalid." : undefined}
          />
          {canDecrypt &&
            account.profile?.individual?.birthDateProvided &&
            !showingDecryptedElements.includes("birthDate") && (
              <Tippy content="Show">
                <ButtonLegacy
                  buttonStyle="linkBare"
                  className={styles.decryptableButton}
                  onClick={(e) => handleDecryptClick(e, "birthDate")}
                  loading={decryptLoading && showingDecryptedElements.includes("birthDate")}
                >
                  <Icon iconComponent={IconInvisible} />
                </ButtonLegacy>
              </Tippy>
            )}
        </div>
        <div className={styles.decryptableInput}>
          <GovernmentIDInput
            name="governmentID"
            hasFloatingLabels={false}
            placeholder={
              !requestFullSSN && account.profile?.individual?.governmentIDProvided
                ? "•••-••-••••"
                : "000-00-0000"
            }
            value={
              decryptedGovernmentID && showingDecryptedElements.includes("taxID")
                ? decryptedGovernmentID.object
                : account.profile?.individual?.governmentID
            }
            onChange={(value) => {
              dispatch({ type: "individual", value: { governmentID: value } });
            }}
            isLocked={
              !!accountLocks?.profile?.individual?.governmentID?.ssn?.full ||
              !!accountLocks?.profile?.individual?.governmentID?.ssn?.lastFour ||
              !!accountLocks?.profile?.individual?.governmentID?.itin?.full ||
              !!accountLocks?.profile?.individual?.governmentID?.itin?.lastFour
            }
            error={getGovernmentIDError()}
            warning={getGovernmentIDWarning()}
            theme={{ container: styles.governmentID }}
          />
          {canDecrypt &&
            account.profile?.individual?.governmentIDProvided &&
            !showingDecryptedElements.includes("taxID") && (
              <Tippy content="Show">
                <ButtonLegacy
                  buttonStyle="linkBare"
                  className={styles.decryptableButton}
                  onClick={(e) => handleDecryptClick(e, "taxID")}
                  loading={decryptLoading && showingDecryptedElements.includes("birthDate")}
                >
                  <Icon iconComponent={IconInvisible} />
                </ButtonLegacy>
              </Tippy>
            )}
        </div>
      </div>
    </Step>
  );
};
