import { OnboardingStateValue } from "api/Dashboard.model";

const numericState: Record<OnboardingStateValue, number> = {
  [OnboardingStateValue.Unknown]: 0,
  [OnboardingStateValue.Created]: 1,
  [OnboardingStateValue.SubmittedForReview]: 2,
  [OnboardingStateValue.InReview]: 3,
  [OnboardingStateValue.Reviewed]: 4
};

export function hasBeenSubmitted(state?: OnboardingStateValue): boolean {
  return !!state && numericState[state] >= numericState[OnboardingStateValue.SubmittedForReview];
}
