import { DeepConvertValueType, DeepPartial } from "@moovfinancial/common/types/DeepTypes";

export type OnboardingErrors<T> = DeepConvertValueType<DeepPartial<T>>;
export type UnparsedOnboardingErrors<T> = { error: DeepConvertValueType<DeepPartial<T>> };

/**
 * This is a temporary function to support both the old and new error formats for some endpoints.
 * Once the new format is in place, this function can be removed.
 *
 * @deprecated
 */
export function parseErrors<T>(
  errors: UnparsedOnboardingErrors<T> | OnboardingErrors<T>
): OnboardingErrors<T> {
  if (!!errors && "error" in errors) {
    return errors.error;
  }
  return errors;
}

export function handleError(e: string | undefined) {
  if (!e) return;
  switch (e.trim().toLowerCase()) {
    case "unsupported or missing business type":
      return "Unsupported business type.";
    case "cannot be blank":
    default:
      // Capitalize the first letter of the error message
      return e
        .trim()
        .toLowerCase()
        .replace(/^\w/, (c) => c.toUpperCase());
  }
}
