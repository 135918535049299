import clsx from "clsx";
import React, { useRef } from "react";
import { ButtonLegacy } from "@moovfinancial/cargo";
import CheckIcon from "components/icons/checkmark.svg?react";
import FileIcon from "components/icons/file_outlined.svg?react";
import DeleteIcon from "components/icons/trash-can.svg?react";
import { Input } from "./Form";
import styles from "./DocumentUpload.module.scss";

export interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDelete: (name: string) => void;
  onFileInputClick?: (e: any) => void;
  className?: string;
  value: string;
  complete?: boolean;
  errorMessage?: string;
}

export default function FileInput({
  onDelete,
  onFileInputClick,
  className,
  value,
  complete,
  errorMessage,
  ...rest
}: FileInputProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div>
      <div
        className={clsx(styles.fileInput, errorMessage && styles.error, className)}
        onClick={onFileInputClick}
      >
        <Input ref={inputRef} type="text" value={value} readOnly={true} {...rest} />
        <FileIcon className={clsx(styles.icon, styles.fileIcon)} />
        {complete ? (
          <CheckIcon className={styles.complete} />
        ) : (
          !errorMessage && (
            <ButtonLegacy
              onClick={() => onDelete(value)}
              buttonStyle="icon"
              className={clsx(styles.icon)}
              type="button"
              disabled={complete}
            >
              <DeleteIcon />
            </ButtonLegacy>
          )
        )}
      </div>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
}
