export type TshirtSizes = "XXS" | "XS" | "S" | "M" | "L" | "XL" | "XXL";
export type Sizes = TshirtSizes | number;
export type ApplyAutoProperties = "height" | "width";
/** Class so each component can create a StandardSizes map, e.g. S = 1rem, M = 2rem, etc. and
 *  easily retrieve the size in rem or as a style object
 */
export class StandardSizes<
  PartialSizeMap extends Partial<Record<Sizes, number>>,
  PartialSizes extends keyof PartialSizeMap,
  Default extends PartialSizes | number
> {
  private sizes: PartialSizeMap;
  private default: Default;

  constructor(sizes: PartialSizeMap, defaultSize: Default) {
    this.sizes = sizes;
    this.default = defaultSize;
  }

  get(size?: PartialSizes | number) {
    if (!size) return this.default;
    if (typeof size === "number") return size;
    return this.sizes[size];
  }

  getStyle = (
    size?: PartialSizes | number,
    applyAuto?: ApplyAutoProperties
  ): {
    width?: string;
    height?: string;
  } => {
    if (!size) {
      if (!this.default) return {};
      if (this.default) return this.getStyle(this.default);
    }
    const standardSize = this.get(size);
    const standardSizeRem = `${standardSize}rem`;

    if (applyAuto === "height") {
      return {
        width: standardSizeRem,
        height: "auto"
      };
    }

    if (applyAuto === "width") {
      return {
        width: "auto",
        height: standardSizeRem
      };
    }

    return {
      width: standardSizeRem,
      height: standardSizeRem
    };
  };
}
