import { useContext, useEffect, useState } from "react";
import { BaseButton } from "@moovfinancial/cargo";
import useDebounce from "hooks/useDebounce";
import AddressComboBox from "components/form/AddressComboBox";
import { Label } from "components/form/Form";
import { Address, AddressSuggestion } from "api/v2";
import { APIContext } from "contexts/APIContext";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { AccountSetupContext } from "../../AccountSetupContext";
import styles from "../../AccountSetup.module.scss";

interface AddressFieldProps {
  address?: Address;
  isLocked?: boolean;
  warn?: boolean;
  onBlur?: (e: any) => void;
}

export const AddressField = ({ address = {}, isLocked, warn, onBlur }: AddressFieldProps) => {
  const { moov } = useContext(APIContext);
  const { account, dispatch, manualAddress, resetInput } = useContext(AccountSetupContext);
  const { facilitatorID } = useContext(FacilitatorContext);

  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [hideResults, setHideResults] = useState(true);
  const [isUpdating, setIsUpdating] = useState(
    Object.values(address).every((field) => !field) ? false : true
  );

  const formattedAddress = `${address.addressLine1}, ${
    address.addressLine2 ? address.addressLine2 + ", " : ""
  }${address.city}, ${address.stateOrProvince}, ${address.postalCode}`;

  useEffect(() => {
    if (address.addressLine1) {
      setSearchText(formattedAddress);
    }
  }, [manualAddress]);

  useEffect(() => {
    if (debouncedSearchText && debouncedSearchText.length > 2) {
      moov.profileEnrichment
        .autoCompleteAddress(facilitatorID, debouncedSearchText)
        .then(([response]) => {
          const firstFour = response?.suggestions?.slice(0, 4) ?? [];

          setHideResults(false);
          setAddresses(firstFour);
        });
    } else {
      setHideResults(true);
      if (addresses.length) setAddresses([]);
    }
  }, [debouncedSearchText]);

  function handleAddressChange(e: any) {
    resetInput("address");
    if (isUpdating) {
      setIsUpdating(false);
    }
    if (!e.target.value.includes("entries")) {
      setSearchText(e.target.value);
    }
  }

  function handleMultipleEntries(value: Address) {
    if (searchText) {
      moov.profileEnrichment
        .getMultipleAddresses(facilitatorID, searchText, value)
        .then(([results]) => {
          setAddresses(results?.suggestions || []);
        });
    }
  }

  function onSelectHandler(value: AddressSuggestion) {
    if (value.entries && value.entries > 1) {
      handleMultipleEntries(value);
    } else {
      dispatch({
        type: account.accountType === "business" ? "businessAddress" : "individualAddress",
        value: value
      });
    }
  }

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch({ type: "manualAddress", value: true });
  };

  return (
    <div>
      <Label className={styles.toggleLabel} label={"Address"} htmlFor={"addressField"}>
        {!isLocked && (
          <BaseButton
            className={styles.toggleButton}
            role="button"
            type="button"
            onClick={handleToggle}
          >
            Enter manually
          </BaseButton>
        )}
      </Label>
      <AddressComboBox
        addressObject={address}
        data={addresses}
        disabled={isLocked}
        hideResults={hideResults}
        id="addressField"
        onBlur={onBlur}
        value={searchText}
        warn={warn}
        onChange={(e) => {
          handleAddressChange(e);
        }}
        onChoose={onSelectHandler}
      />
    </div>
  );
};
