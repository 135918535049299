import clsx from "clsx";
import { forwardRef } from "react";
import { Theme } from "@moovfinancial/common/types/Theme";
import { Input, InputProps } from "./Input";
import styles from "./TextInput.module.scss";

export type TextInputTheme = Theme<typeof styles>;
export interface TextInputProps extends InputProps {
  /**
   * is the input in an error state
   */
  isErroring?: boolean;
  /**
   * is the input in a warning state
   */
  isWarning?: boolean;
  /**
   * Optional ReactNode that will be positioned on the right side of the input to add "actions" or "decorations" to the input
   */
  Decoration?: React.ReactNode;
  /**
   * Optional theme object to override or change the element's style
   */
  theme?: TextInputTheme;
  /**
   * Is the input disabled?
   */
  disabled?: boolean;
  /**
   * Is the input locked (disabled with a lock icon)?
   */
  isLocked?: boolean;
}

/**
 * This is Moov's basic input component. It's a wrapper around the native HTML input element and we only add
 * styling and support for error and warning messages through wrapping it in a `FormGroup` component.
 */
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  {
    Decoration,
    isErroring,
    isWarning,
    label,
    theme,
    disabled = false,
    isLocked = false,
    optional = false,
    ...rest
  }: TextInputProps,
  ref
) {
  const validationClassName = clsx({
    [styles.error]: isErroring && !disabled,
    [styles.warning]: isWarning && !isErroring && !disabled
  });

  return (
    <Input
      InputComponent={
        <div className={clsx(styles.outerWrapper, validationClassName, theme?.outerWrapper)}>
          <input
            id={
              label && typeof label === "string"
                ? `input-${label.replace(/\s/g, "-").toLowerCase()}`
                : undefined
            }
            ref={ref}
            className={clsx(styles.inputElement, isLocked && styles.isLocked, theme?.inputElement)}
            disabled={isLocked || disabled}
            {...rest}
          />
          {Decoration}
        </div>
      }
      label={label}
      optional={optional}
      {...rest}
    />
  );
});
