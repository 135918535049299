import PageSheet from "layout/PageSheet";

export const ErrorPage = () => {
  return (
    <PageSheet>
      <div role="alert" className="emptyRoot">
        <div>
          <h3>Something went wrong.</h3>
          <p>The team has been alerted. Please refresh the page to try again.</p>
        </div>
      </div>
    </PageSheet>
  );
};
