import { AddressSuggestion } from "./accounts.model";
import { LiveMoovAPIClient } from "./api";
import {
  AddressSuggestions,
  EnrichmentResponse,
  IndustryCodesResponse
} from "./profileEnrichment.model";
import { APIResponse } from "./request";

export interface ProfileEnrichmentAPI {
  // Enriched profile data
  profileEnrichment(email: string): APIResponse<EnrichmentResponse>;

  // Address autocomplete
  autoCompleteAddress(facilitatorID: string, searchParam: string): APIResponse<AddressSuggestions>;

  // Gets multiple addresses
  getMultipleAddresses(
    facilitatorID: string,
    searchParam: string,
    address: AddressSuggestion
  ): APIResponse<AddressSuggestions>;

  // Gets all business industries
  getIndustries(): APIResponse<IndustryCodesResponse>;
}

export class LiveProfileEnrichmentAPI implements ProfileEnrichmentAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async profileEnrichment(email: string): APIResponse<EnrichmentResponse> {
    const [result, err] = await this._client.request<EnrichmentResponse>(
      `/enrichment/profile?email=${email}`
    );
    return [result, err];
  }

  async autoCompleteAddress(
    facilitatorID: string,
    searchParam: string
  ): APIResponse<AddressSuggestions> {
    const [result, err] = await this._client.request<AddressSuggestions>(
      `/enrichment/address?search=${searchParam}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async getMultipleAddresses(
    facilitatorID: string,
    searchParam: string,
    address: AddressSuggestion
  ): APIResponse<AddressSuggestions> {
    const selected = `${address.addressLine1} ${address.addressLine2} (${address.entries}) ${address.city} ${address.stateOrProvince} ${address.postalCode}`;
    const [result, err] = await this._client.request<AddressSuggestions>(
      `/enrichment/address?search=${searchParam}&selected=${selected}&source=all`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async getIndustries(): APIResponse<IndustryCodesResponse> {
    const [result, err] = await this._client.request<IndustryCodesResponse>("/industries");
    return [result, err];
  }
}
