import { StatementDetails, StatementRow } from "api/v2";
import { InteractiveStatementRow } from "../pages/admin/underwriting/underwriting-dialog/UnderwritingDialog";

const statementTotals = (
  statementRows: InteractiveStatementRow[]
): Record<keyof StatementDetails, number> => {
  const newTotals = statementRows.reduce(
    (prev, current) => {
      return {
        transactions: prev.transactions + parseInt(current.transactions || "0"),
        totalPaymentVolume: prev.totalPaymentVolume + parseInt(current.totalPaymentVolume || "0"),
        refundCount: prev.refundCount + parseInt(current.refundCount || "0"),
        refundAmount: prev.refundAmount + parseInt(current.refundAmount || "0"),
        chargebackCount: prev.chargebackCount + parseInt(current.chargebackCount || "0"),
        chargebackAmount: prev.chargebackAmount + parseInt(current.chargebackAmount || "0")
      };
    },
    {
      transactions: 0,
      totalPaymentVolume: 0,
      refundCount: 0,
      refundAmount: 0,
      chargebackCount: 0,
      chargebackAmount: 0
    }
  );
  return {
    ...newTotals,
    refundCountPercentage:
      newTotals.refundCount && newTotals.transactions
        ? (newTotals.refundCount / newTotals.transactions || 0) * 100
        : 0,
    refundAmountPercentage:
      newTotals.refundCount && newTotals.transactions
        ? (newTotals.refundAmount / newTotals.totalPaymentVolume || 0) * 100
        : 0,
    chargebackCountPercentage:
      newTotals.chargebackCount && newTotals.transactions
        ? (newTotals.chargebackCount / newTotals.transactions || 0) * 100
        : 0,
    chargebackAmountPercentage:
      newTotals.chargebackAmount && newTotals.totalPaymentVolume
        ? (newTotals.chargebackAmount / newTotals.totalPaymentVolume || 0) * 100
        : 0
  };
};

const getPopulatedRows = (statementRows: InteractiveStatementRow[]): InteractiveStatementRow[] => {
  return statementRows.filter((row) => !Object.values(row).every((val) => !val));
};

const getDivisor = (length: number) => (length < 3 ? length : 3);

const tpvNinetyDayAverageAmount = (statementRows: InteractiveStatementRow[]): number => {
  const populatedRows = getPopulatedRows(statementRows);
  const divisor = getDivisor(populatedRows.length);
  const average =
    populatedRows
      .splice(-3)
      .reduce((prev, current) => prev + parseInt(current.totalPaymentVolume) || 0, 0) / divisor;
  return Math.round(average);
};

const tpvNinetyDayRefundAverageRate = (statementRows: InteractiveStatementRow[]): number => {
  const populatedRows = getPopulatedRows(statementRows);
  const divisor = getDivisor(populatedRows.length);
  const average =
    populatedRows
      .splice(-3)
      .reduce((prev, current) => prev + parseInt(current.refundAmountPercentage) || 0, 0) / divisor;
  return Math.round(average);
};

const refundExposureAmount = (statementRows: InteractiveStatementRow[]): number => {
  const populatedRows = getPopulatedRows(statementRows);
  const divisor = getDivisor(populatedRows.length);
  const exposureAmount =
    populatedRows
      .splice(-3)
      .reduce((prev, current) => prev + parseInt(current.refundAmount) || 0, 0) / divisor;
  return Math.round(exposureAmount);
};

const tpvNinetyDayChargebackAverageRate = (statementRows: InteractiveStatementRow[]): number => {
  const populatedRows = getPopulatedRows(statementRows);
  const divisor = getDivisor(populatedRows.length);
  const averageRate =
    populatedRows
      .splice(-3)
      .reduce((prev, current) => prev + parseInt(current.chargebackAmountPercentage) || 0, 0) /
    divisor;
  return Math.round(averageRate);
};

const chargebackExposureAmount = (statementRows: InteractiveStatementRow[]): number => {
  const populatedRows = getPopulatedRows(statementRows);
  const divisor = getDivisor(populatedRows.length);
  const exposureAmount =
    populatedRows
      .splice(-3)
      .reduce((prev, current) => prev + parseInt(current.chargebackAmount) || 0, 0) / divisor;
  return Math.round(exposureAmount);
};

const totalRiskExposure = (
  refundExposure = 0,
  chargebackExposure = 0,
  nonDeliveryExposure = 0
): number => {
  return Math.round(refundExposure + chargebackExposure + nonDeliveryExposure);
};

const sortStatementRows = (statementRows: StatementRow[]): StatementRow[] => {
  const unsortedRows: StatementRow[] = [];
  const emptyRows: StatementRow[] = [];
  const populatedRows: StatementRow[] = [];

  statementRows.forEach((row) => {
    if (Object.values(row).every((val) => !val)) {
      emptyRows.push(row);
    } else if (!row.statementDate || !row.statementDate.includes("/")) {
      unsortedRows.push(row);
    } else {
      populatedRows.push(row);
    }
  });

  const sortedRows = populatedRows.sort((a, b) => {
    const [aMonth, aYear] = a.statementDate.split("/");
    const [bMonth, bYear] = b.statementDate.split("/");
    if (parseInt(aMonth) > 12) return -1;

    const aDate = new Date(parseInt(aYear), parseInt(aMonth) - 1);
    const bDate = new Date(parseInt(bYear), parseInt(bMonth) - 1);

    return aDate > bDate ? 1 : -1;
  });

  return [...unsortedRows, ...sortedRows, ...emptyRows];
};

export default {
  statementTotals,
  tpvNinetyDayAverageAmount,
  tpvNinetyDayRefundAverageRate,
  refundExposureAmount,
  tpvNinetyDayChargebackAverageRate,
  chargebackExposureAmount,
  totalRiskExposure,
  sortStatementRows
};
