import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import {
  Account,
  AccountListFilter,
  CreateAccount,
  DecryptedAccountData,
  EnrichedFacilitatorAccount,
  FacilitatorAccount,
  PatchAccount
} from "./accounts.model";
import { AdminMetadataAPI, LiveAdminMetadataAPI } from "./accounts/adminMetadata";
import { ApplePayAPI, LiveApplePayAPI } from "./accounts/applePay";
import { CardFeaturesConfigAPI, LiveCardFeaturesConfigAPI } from "./accounts/cardFeaturesConfig";
import { FilesAPI, LiveFilesAPI } from "./accounts/files";
import { LiveMatchAPI, MatchAPI } from "./accounts/match";
import { LiveNetworkIDsAPI, NetworkIDsAPI } from "./accounts/networkIDs";
import { LiveRepresentativesAPI, RepresentativeAPI } from "./accounts/representatives";
import { LiveTOSAllowedAPI, TOSAllowedAPI } from "./accounts/tosAllowed";
import { LiveVerificationAPI, VerificationAPI } from "./accounts/verification";
import { LiveMoovAPIClient } from "./api";
import { PatchSweepConfig, SweepConfig } from "./automaticSweep.model";
import { APIResponse, ErrorResponse } from "./request";

export interface AccountsAPI {
  /** List all accounts connected to a facilitator */
  list(facilitatorID: string, filter?: AccountListFilter): APIResponse<Account[]>;
  /** Get an account */
  get(facilitatorID: string, accountID: string): APIResponse<Account>;
  /** Get a facilitator account with facilitator settings embedded */
  getFacilitator(facilitatorID: string): APIResponse<EnrichedFacilitatorAccount>;
  /** Create an account */
  create(facilitatorID: string, account: DeepPartial<CreateAccount>): APIResponse<Account>;
  /** Patch an account */
  patch(
    facilitatorID: string,
    accountID: string,
    account: PatchAccount | Partial<Account>
  ): APIResponse<Account>;
  /** Disconnect an account from Moov */
  disconnect(facilitatorID: string, accountID: string): APIResponse<undefined>;
  /** List accounts across all facilitators. Moov admin only. */
  adminList(facilitatorID: string, filter?: AccountListFilter): APIResponse<Account[]>;
  /** Get an account by id only. Moov admin only. */
  adminGet(accountID: string): APIResponse<Account>;
  /** Patch an account, bypassing locked fields. Moov admin only. */
  adminPatch(
    facilitatorID: string,
    accountID: string,
    account: PatchAccount | Partial<Account>
  ): APIResponse<Account>;
  /** Download agreement document. */
  adminDownloadAgreement(
    connectedAccountID: string,
    facilitatorID: string
  ): Promise<[File | undefined, ErrorResponse | undefined]>;
  /** Overrides a facilitator's sweep config. Moov admin only. */
  opsPatchAutomaticSweepConfig(
    facilitatorID: string,
    sweepConfigID: string,
    sweepConfigRequest: PatchSweepConfig
  ): APIResponse<SweepConfig>;
  /**
   * List connections between this account and other Moov accounts.
   * Typically used to look up the facilitator for some connected account.
   */
  listConnections(accountID: string): APIResponse<FacilitatorAccount[]>;
  /** Generates a terms of service token */
  getTOSToken(): APIResponse<{ token: string }>;
  /* Gets decrypted account */
  decrypt( // TODO: swap these parameters, facilitator comes first
    accountID: string,
    facilitatorID: string
  ): APIResponse<DecryptedAccountData>;

  /** Contains apple pay API */
  applePay: ApplePayAPI;
  /** Contains card features config API */
  cardFeaturesConfig: CardFeaturesConfigAPI;
  /** Contains account files API */
  files: FilesAPI;
  /** Contains account verification API */
  verification: VerificationAPI;
  /** Contains card-rail account metadata API */
  adminMetadata: AdminMetadataAPI;
  /** Contains match API */
  match: MatchAPI;
  /* Contains card brand network id API */
  networkIDs: NetworkIDsAPI;
  /** Contains representative API */
  representatives: RepresentativeAPI;
  /** Contains TOS Allowed API */
  tosAllowed: TOSAllowedAPI;
}

export class LiveAccountsAPI implements AccountsAPI {
  private _client: LiveMoovAPIClient;

  applePay: ApplePayAPI;
  cardFeaturesConfig: CardFeaturesConfigAPI;
  files: FilesAPI;
  verification: VerificationAPI;
  adminMetadata: AdminMetadataAPI;
  match: MatchAPI;
  networkIDs: NetworkIDsAPI;
  representatives: RepresentativeAPI;
  tosAllowed: TOSAllowedAPI;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
    this.applePay = new LiveApplePayAPI(this._client);
    this.cardFeaturesConfig = new LiveCardFeaturesConfigAPI(this._client);
    this.files = new LiveFilesAPI(this._client);
    this.verification = new LiveVerificationAPI(this._client);
    this.adminMetadata = new LiveAdminMetadataAPI(this._client);
    this.match = new LiveMatchAPI(this._client);
    this.networkIDs = new LiveNetworkIDsAPI(this._client);
    this.representatives = new LiveRepresentativesAPI(this._client);
    this.tosAllowed = new LiveTOSAllowedAPI(this._client);
  }

  async list(facilitatorID: string, filter?: AccountListFilter): APIResponse<Account[]> {
    const [result, err] = await this._client.request<Account[]>("/accounts", {
      query: filter,
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async get(facilitatorID: string, accountID: string): APIResponse<Account> {
    const [result, err] = await this._client.request<Account>(`/accounts/${accountID}`, {
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async getFacilitator(facilitatorID: string): APIResponse<EnrichedFacilitatorAccount> {
    const [result, err] = await this._client.request<EnrichedFacilitatorAccount>(
      `/dashboard/facilitators/${facilitatorID}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async create(facilitatorID: string, account: DeepPartial<CreateAccount>): APIResponse<Account> {
    const [result, err] = await this._client.request<Account>("/accounts", {
      method: "POST",
      json: account,
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async patch(
    facilitatorID: string,
    accountID: string,
    account: PatchAccount
  ): APIResponse<Account> {
    const [result, err] = await this._client.request<Account>(`/accounts/${accountID}`, {
      method: "PATCH",
      json: account,
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async disconnect(facilitatorID: string, accountID: string): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(
      `/accounts/${facilitatorID}/connections/${accountID}`,
      {
        method: "DELETE",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminList(facilitatorID: string, filter?: AccountListFilter): APIResponse<Account[]> {
    const [result, err] = await this._client.request<Account[]>("/admin/accounts", {
      query: filter,
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async adminGet(connectedAccountID: string): APIResponse<Account> {
    const [result, err] = await this._client.request<Account>(`/accounts/${connectedAccountID}`, {
      xAccountID: connectedAccountID
    });
    return [result, err];
  }

  async adminPatch(
    facilitatorID: string,
    connectedAccountID: string,
    account: PatchAccount
  ): APIResponse<Account> {
    const [result, err] = await this._client.request<Account>(
      `/admin/accounts/${connectedAccountID}`,
      {
        method: "PATCH",
        json: account,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async opsPatchAutomaticSweepConfig(
    facilitatorID: string,
    sweepConfigID: string,
    sweepConfigRequest: PatchSweepConfig
  ): APIResponse<SweepConfig> {
    const [result, err] = await this._client.request<SweepConfig>(
      `/ops/sweep-configs/${sweepConfigID}/overrides`,
      {
        method: "PATCH",
        xAccountID: facilitatorID,
        json: sweepConfigRequest
      }
    );

    return [result, err];
  }

  async adminDownloadAgreement(
    connectedAccountID: string,
    facilitatorID: string
  ): Promise<[File | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<File>(
      `/ops/accounts/${connectedAccountID}/pdf`,
      {
        headers: {
          "X-Account-ID": facilitatorID
        },
        method: "POST"
      }
    );
    return [result, err];
  }

  async listConnections(connectedAccountID: string): APIResponse<FacilitatorAccount[]> {
    const [result, err] = await this._client.request<FacilitatorAccount[]>(
      `/accounts/${connectedAccountID}/connections?allow-disconnected=true`,
      {
        method: "GET",
        xAccountID: connectedAccountID
      }
    );
    return [result, err];
  }

  async getTOSToken(): APIResponse<{ token: string }> {
    return await this._client.request<{ token: string }>("/tos-token");
  }

  async decrypt(accountID: string, facilitatorID: string): APIResponse<DecryptedAccountData> {
    const [result, err] = await this._client.request<DecryptedAccountData>(
      `/admin/accounts/${accountID}/decrypted`,
      {
        method: "GET",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
