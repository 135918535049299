export const generateNames = () => {
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  const businessName = businessNames[Math.floor(Math.random() * businessNames.length)];
  const repFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const repLastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  return {
    name: {
      firstName,
      lastName
    },
    repName: {
      firstName: repFirstName,
      lastName: repLastName
    },
    businessName
  };
};

const lastNames = [
  "Smith",
  "Johnson",
  "Williams",
  "Brown",
  "Jones",
  "Miller",
  "Davis",
  "Garcia",
  "Rodriguez",
  "Wilson",
  "Martinez",
  "Anderson",
  "Taylor",
  "Thomas",
  "Hernandez",
  "Moore",
  "Martin",
  "Jackson",
  "Thompson",
  "White",
  "Lopez",
  "Lee",
  "Gonzalez",
  "Mitchell",
  "Roberts",
  "Carter",
  "Phillips",
  "Evans",
  "Turner",
  "Torres",
  "Parker",
  "Collins",
  "Edwards",
  "Stewart",
  "Flores",
  "Morris",
  "Nguyen",
  "Murphy",
  "Rivera",
  "Cook",
  "Rogers",
  "Morgan",
  "Peterson",
  "Cooper",
  "Reed",
  "Bailey",
  "Bell",
  "Gomez",
  "Kelly",
  "Howard",
  "Ward",
  "Cox",
  "Diaz",
  "Richardson",
  "Wood",
  "Watson",
  "Brooks",
  "Bennett",
  "Gray",
  "James",
  "Reyes",
  "Cruz",
  "Hughes",
  "Price",
  "Myers",
  "Long",
  "Foster",
  "Sanders",
  "Ross",
  "Morales",
  "Powell",
  "Sullivan",
  "Russell",
  "Ortiz",
  "Jenkins",
  "Gutierrez",
  "Perry",
  "Butler",
  "Barnes",
  "Fisher",
  "Henderson",
  "Coleman",
  "Simmons",
  "Patterson",
  "Jordan",
  "Reynolds",
  "Hamilton",
  "Graham",
  "Kim",
  "Gonzales",
  "Alexander",
  "Ramos",
  "Wallace",
  "Griffin",
  "West",
  "Cole",
  "Hayes",
  "Chavez",
  "Gibson",
  "Bryant",
  "Ellis",
  "Stevens",
  "Murray",
  "Ford",
  "Marshall",
  "Owens",
  "Mcdonald",
  "Harrison",
  "Ruiz",
  "Kennedy",
  "Wells",
  "Alvarez",
  "Woods",
  "Mendoza",
  "Castillo",
  "Olson",
  "Webb",
  "Washington",
  "Tucker",
  "Freeman",
  "Burns",
  "Henry",
  "Vasquez",
  "Snyder",
  "Simpson",
  "Crawford",
  "Jimenez",
  "Porter",
  "Mason",
  "Shaw",
  "Gordon",
  "Wagner",
  "Hunter",
  "Romero",
  "Hicks",
  "Dixon",
  "Hunt",
  "Palmer",
  "Robertson",
  "Black",
  "Holmes",
  "Stone",
  "Meyer",
  "Boyd",
  "Mills",
  "Warren",
  "Fox",
  "Rose",
  "Rice",
  "Moreno",
  "Schmidt",
  "Patel",
  "Ferguson",
  "Nichols",
  "Herrera",
  "Medina",
  "Ryan",
  "Fernandez",
  "Weaver",
  "Daniels",
  "Stephens",
  "Gardner",
  "Payne",
  "Kelley",
  "Dunn",
  "Pierce",
  "Arnold",
  "Tran",
  "Spencer",
  "Peters",
  "Hawkins",
  "Grant",
  "Hansen",
  "Castro",
  "Hoffman",
  "Hart",
  "Elliott",
  "Cunningham",
  "Knight",
  "Bradley",
  "Carroll",
  "Hudson",
  "Duncan",
  "Armstrong",
  "Berry",
  "Andrews",
  "Johnston",
  "Ray",
  "Lane",
  "Riley",
  "Carpenter",
  "Perkins",
  "Aguilar",
  "Silva",
  "Richards",
  "Willis",
  "Matthews",
  "Chapman",
  "Lawrence",
  "Garza",
  "Vargas",
  "Watkins",
  "Wheeler",
  "Larson",
  "Carlson",
  "Harper",
  "George",
  "Greene",
  "Burke",
  "Guzman",
  "Morrison",
  "Munoz",
  "Jacobs",
  "Obrien",
  "Lawson",
  "Franklin",
  "Lynch",
  "Bishop",
  "Carr",
  "Salazar",
  "Austin",
  "Mendez",
  "Gilbert",
  "Jensen",
  "Williamson",
  "Montgomery",
  "Harvey",
  "Oliver",
  "Howell",
  "Dean",
  "Hanson",
  "Weber",
  "Garrett",
  "Sims",
  "Burton",
  "Fuller",
  "Soto",
  "Mccoy",
  "Welch",
  "Chen",
  "Schultz",
  "Walters",
  "Reid",
  "Fields",
  "Walsh",
  "Little",
  "Fowler",
  "Bowman",
  "Davidson",
  "May",
  "Day",
  "Schneider",
  "Newman",
  "Brewer",
  "Lucas",
  "Holland",
  "Wong",
  "Banks",
  "Santos",
  "Curtis",
  "Pearson",
  "Delgado",
  "Valdez",
  "Pena",
  "Rios",
  "Douglas",
  "Sandoval",
  "Barrett",
  "Hopkins",
  "Keller",
  "Guerrero",
  "Stanley",
  "Bates",
  "Alvarado",
  "Beck",
  "Ortega",
  "Wade",
  "Estrada",
  "Contreras",
  "Barnett",
  "Caldwell",
  "Santiago",
  "Lambert",
  "Powers",
  "Chambers",
  "Nunez",
  "Craig",
  "Leonard",
  "Lowe",
  "Rhodes",
  "Byrd",
  "Gregory",
  "Shelton",
  "Frazier",
  "Becker",
  "Maldonado",
  "Fleming",
  "Vega",
  "Sutton",
  "Cohen",
  "Jennings",
  "Parks",
  "Mcdaniel",
  "Watts",
  "Barker",
  "Norris",
  "Vaughn",
  "Vazquez",
  "Holt",
  "Schwartz",
  "Steele",
  "Benson",
  "Neal",
  "Dominguez",
  "Horton",
  "Terry",
  "Wolfe",
  "Hale",
  "Lyons",
  "Graves",
  "Haynes",
  "Miles",
  "Park",
  "Warner",
  "Padilla",
  "Bush",
  "Thornton",
  "Mccarthy",
  "Mann",
  "Zimmerman",
  "Erickson",
  "Fletcher",
  "Mckinney",
  "Page",
  "Dawson",
  "Joseph",
  "Marquez",
  "Reeves",
  "Klein",
  "Espinoza",
  "Baldwin",
  "Moran",
  "Love",
  "Robbins",
  "Higgins",
  "Ball",
  "Cortez",
  "Le",
  "Griffith",
  "Bowen",
  "Sharp",
  "Cummings",
  "Ramsey",
  "Hardy",
  "Swanson",
  "Barber",
  "Acosta",
  "Luna",
  "Chandler",
  "Blair",
  "Daniel",
  "Cross",
  "Simon",
  "Dennis",
  "Oconnor",
  "Quinn",
  "Gross",
  "Navarro",
  "Moss",
  "Fitzgerald",
  "Doyle",
  "Mclaughlin",
  "Rojas",
  "Rodgers",
  "Stevenson",
  "Singh",
  "Yang",
  "Figueroa",
  "Harmon",
  "Newton",
  "Paul",
  "Manning",
  "Garner",
  "Mcgee",
  "Reese",
  "Francis",
  "Burgess",
  "Adkins",
  "Goodman",
  "Curry",
  "Brady",
  "Christensen",
  "Potter",
  "Walton",
  "Goodwin",
  "Mullins",
  "Molina",
  "Webster",
  "Fischer",
  "Campos",
  "Avila",
  "Sherman",
  "Todd",
  "Chang",
  "Blake",
  "Malone",
  "Wolf",
  "Hodges",
  "Juarez",
  "Gill",
  "Farmer",
  "Hines",
  "Gallagher",
  "Duran",
  "Hubbard",
  "Cannon",
  "Miranda",
  "Wang",
  "Saunders",
  "Tate",
  "Mack",
  "Hammond",
  "Carrillo",
  "Townsend",
  "Wise",
  "Ingram",
  "Barton",
  "Mejia",
  "Ayala",
  "Schroeder",
  "Hampton",
  "Rowe",
  "Parsons",
  "Frank",
  "Waters",
  "Strickland",
  "Osborne",
  "Maxwell",
  "Chan",
  "Deleon",
  "Norman",
  "Harrington",
  "Casey",
  "Patton",
  "Logan",
  "Bowers",
  "Mueller",
  "Glover",
  "Floyd",
  "Hartman",
  "Buchanan",
  "Cobb",
  "French",
  "Bright",
  "Cuevas",
  "Bolton",
  "Murillo",
  "Lutz",
  "Duarte",
  "Kidd",
  "Key",
  "Cooke"
];

const firstNames = [
  "Jacob",
  "Emma",
  "Michael",
  "Isabella",
  "Ethan",
  "Emily",
  "Joshua",
  "Madison",
  "Daniel",
  "Ava",
  "Alexander",
  "Olivia",
  "Anthony",
  "Sophia",
  "William",
  "Zoe",
  "Christopher",
  "Jessica",
  "Matthew",
  "Peyton",
  "Jayden",
  "Alexandra",
  "Andrew",
  "Claire",
  "Joseph",
  "Madelyn",
  "David",
  "Maria",
  "Noah",
  "Mackenzie",
  "Aiden",
  "Arianna",
  "James",
  "Jocelyn",
  "Ryan",
  "Amelia",
  "Logan",
  "Angelina",
  "John",
  "Trinity",
  "Nathan",
  "Andrea",
  "Elijah",
  "Maya",
  "Christian",
  "Valeria",
  "Gabriel",
  "Sophie",
  "Benjamin",
  "Rachel",
  "Jonathan",
  "Vanessa",
  "Tyler",
  "Aaliyah",
  "Samuel",
  "Mariah",
  "Nicholas",
  "Gabrielle",
  "Gavin",
  "Katelyn",
  "Dylan",
  "Ariana",
  "Jackson",
  "Bailey",
  "Brandon",
  "Camila",
  "Caleb",
  "Jennifer",
  "Mason",
  "Melanie",
  "Angel",
  "Gianna",
  "Isaac",
  "Charlotte",
  "Evan",
  "Paige",
  "Jack",
  "Autumn",
  "Kevin",
  "Payton",
  "Jose",
  "Faith",
  "Isaiah",
  "Sara",
  "Luke",
  "Isabelle",
  "Landon",
  "Caroline",
  "Justin",
  "Genesis",
  "Lucas",
  "Isabel",
  "Zachary",
  "Mary",
  "Jordan",
  "Zoey",
  "Robert",
  "Gracie",
  "Aaron",
  "Megan",
  "Brayden",
  "Haley",
  "Thomas",
  "Mya",
  "Cameron",
  "Michelle",
  "Hunter",
  "Molly",
  "Austin",
  "Stephanie",
  "Adrian",
  "Nicole",
  "Connor",
  "Jenna",
  "Owen",
  "Natalia",
  "Aidan",
  "Sadie",
  "Jason",
  "Jada",
  "Julian",
  "Serenity",
  "Wyatt",
  "Lucy",
  "Charles",
  "Ruby",
  "Luis",
  "Eva",
  "Carter",
  "Kennedy",
  "Juan",
  "Rylee",
  "Chase",
  "Jayla",
  "Diego",
  "Naomi",
  "Jeremiah",
  "Rebecca",
  "Brody",
  "Lydia",
  "Xavier",
  "Daniela",
  "Adam",
  "Bella",
  "Carlos",
  "Keira",
  "Sebastian",
  "Adriana",
  "Liam",
  "Lilly",
  "Hayden",
  "Hayden",
  "Nathaniel",
  "Miley",
  "Henry",
  "Katie",
  "Jesus",
  "Jade",
  "Ian",
  "Jordan",
  "Tristan",
  "Gabriela",
  "Bryan",
  "Amy",
  "Sean",
  "Angela",
  "Cole",
  "Melissa",
  "Alex",
  "Valerie",
  "Eric",
  "Giselle",
  "Brian",
  "Diana",
  "Jaden",
  "Amanda",
  "Carson",
  "Kate",
  "Blake",
  "Laila",
  "Ayden",
  "Reagan",
  "Cooper",
  "Jordyn",
  "Dominic",
  "Kylee",
  "Brady",
  "Danielle",
  "Caden",
  "Briana",
  "Josiah",
  "Marley",
  "Kyle",
  "Leslie",
  "Colton",
  "Kendall",
  "Kaden",
  "Catherine",
  "Eli",
  "Liliana",
  "Miguel",
  "Mckenzie",
  "Antonio",
  "Jacqueline",
  "Parker",
  "Ashlyn",
  "Steven",
  "Reese",
  "Alejandro",
  "Marissa",
  "Riley",
  "London",
  "Richard",
  "Juliana",
  "Timothy",
  "Shelby",
  "Devin",
  "Cheyenne",
  "Jesse",
  "Angel",
  "Victor",
  "Daisy",
  "Jake",
  "Makenzie",
  "Joel",
  "Miranda",
  "Colin",
  "Erin",
  "Kaleb",
  "Amber",
  "Bryce",
  "Alana",
  "Levi",
  "Ellie",
  "Oliver",
  "Breanna",
  "Oscar",
  "Ana",
  "Vincent",
  "Mikayla",
  "Ashton",
  "Summer",
  "Cody",
  "Piper",
  "Micah",
  "Adrianna",
  "Preston",
  "Jillian",
  "Marcus",
  "Sierra",
  "Max",
  "Jayden",
  "Patrick",
  "Sienna",
  "Seth",
  "Alicia",
  "Jeremy",
  "Lila",
  "Peyton",
  "Margaret",
  "Nolan",
  "Alivia",
  "Ivan",
  "Brooklynn",
  "Damian",
  "Karen",
  "Maxwell",
  "Violet",
  "Alan",
  "Sabrina",
  "Kenneth",
  "Stella",
  "Jonah",
  "Aniyah",
  "Jorge",
  "Annabelle",
  "Mark",
  "Alexandria",
  "Giovanni",
  "Kathryn",
  "Eduardo",
  "Skylar",
  "Grant",
  "Aliyah",
  "Collin",
  "Delilah",
  "Gage",
  "Julianna",
  "Omar",
  "Kelsey",
  "Emmanuel",
  "Khloe",
  "Trevor",
  "Carly",
  "Edward",
  "Amaya",
  "Ricardo",
  "Mariana",
  "Cristian",
  "Christina",
  "Nicolas",
  "Alondra",
  "Kayden",
  "Tessa",
  "George",
  "Eliana",
  "Jaxon",
  "Bianca",
  "Paul",
  "Jazmin",
  "Braden",
  "Clara",
  "Elias",
  "Vivian",
  "Andres",
  "Josephine",
  "Derek",
  "Delaney",
  "Garrett",
  "Scarlett",
  "Tanner",
  "Elena",
  "Malachi",
  "Cadence",
  "Conner",
  "Alexia",
  "Fernando",
  "Maggie",
  "Cesar",
  "Laura",
  "Javier",
  "Nora",
  "Miles",
  "Ariel",
  "Jaiden",
  "Elise",
  "Alexis",
  "Nadia",
  "Leonardo",
  "Mckenna",
  "Santiago",
  "Chelsea",
  "Francisco",
  "Lyla",
  "Cayden",
  "Alaina",
  "Shane",
  "Jasmin",
  "Edwin",
  "Hope",
  "Hudson",
  "Leila",
  "Travis",
  "Caitlyn",
  "Bryson",
  "Cassidy",
  "Erick",
  "Makenna",
  "Jace",
  "Allie",
  "Hector",
  "Izabella",
  "Josue",
  "Eden",
  "Peter",
  "Callie",
  "Jaylen",
  "Haylee",
  "Mario",
  "Caitlin",
  "Manuel",
  "Kendra",
  "Abraham",
  "Karina",
  "Grayson",
  "Kyra",
  "Damien",
  "Kayleigh",
  "Kaiden",
  "Addyson",
  "Spencer",
  "Kiara",
  "Stephen",
  "Jazmine",
  "Edgar",
  "Karla",
  "Wesley",
  "Camryn",
  "Shawn",
  "Alina",
  "Trenton",
  "Lola",
  "Jared",
  "Kyla",
  "Jeffrey",
  "Kelly",
  "Landen",
  "Fatima",
  "Johnathan",
  "Tiffany",
  "Bradley",
  "Kira",
  "Braxton",
  "Crystal",
  "Ryder",
  "Mallory",
  "Camden",
  "Esmeralda",
  "Roman",
  "Alejandra",
  "Asher",
  "Eleanor",
  "Brendan",
  "Angelica",
  "Maddox",
  "Jayda",
  "Sergio",
  "Abby",
  "Israel",
  "Kara",
  "Andy",
  "Veronica",
  "Lincoln",
  "Carmen",
  "Erik",
  "Jamie",
  "Donovan",
  "Ryleigh",
  "Raymond",
  "Valentina",
  "Avery",
  "Allyson",
  "Rylan",
  "Dakota",
  "Dalton",
  "Kamryn",
  "Harrison",
  "Courtney",
  "Andre",
  "Cecilia",
  "Martin",
  "Madeleine",
  "Keegan",
  "Aniya",
  "Marco",
  "Alison",
  "Jude",
  "Esther",
  "Sawyer",
  "Heaven",
  "Dakota",
  "Aubree",
  "Leo",
  "Lindsey",
  "Calvin",
  "Leilani",
  "Kai",
  "Nina",
  "Drake",
  "Melody",
  "Troy",
  "Macy",
  "Zion",
  "Ashlynn",
  "Clayton",
  "Joanna",
  "Roberto",
  "Cassandra",
  "Zane",
  "Alayna",
  "Gregory",
  "Kaydence",
  "Tucker",
  "Madilyn",
  "Rafael",
  "Aurora",
  "Kingston",
  "Heidi",
  "Dominick",
  "Emerson",
  "Ezekiel",
  "Kimora",
  "Griffin",
  "Madalyn",
  "Devon",
  "Erica",
  "Drew",
  "Josie",
  "Lukas",
  "Katelynn",
  "Johnny",
  "Guadalupe",
  "Ty",
  "Harper",
  "Pedro",
  "Ivy",
  "Tyson",
  "Lexi",
  "Caiden",
  "Camille",
  "Mateo",
  "Savanna",
  "Braylon",
  "Dulce",
  "Cash",
  "Daniella",
  "Aden",
  "Lucia",
  "Chance",
  "Emely",
  "Taylor",
  "Joselyn",
  "Marcos",
  "Kiley",
  "Maximus",
  "Kailey",
  "Ruben",
  "Miriam",
  "Amir",
  "Cynthia",
  "Zander",
  "Rihanna",
  "Adan",
  "Georgia",
  "Maximilian",
  "Rylie",
  "Randy",
  "Harmony",
  "Easton",
  "Kiera",
  "Dustin",
  "Kyleigh",
  "Luca",
  "Monica",
  "Phillip",
  "Bethany",
  "Julius",
  "Kaylie",
  "Charlie",
  "Cameron",
  "Ronald",
  "Teagan",
  "Jakob",
  "Cora",
  "Cade",
  "Brynn",
  "Brett",
  "Ciara",
  "Trent",
  "Genevieve",
  "Silas",
  "Alice",
  "Keith",
  "Maddison",
  "Emiliano",
  "Eliza",
  "Trey",
  "Tatiana",
  "Jalen",
  "Jaelyn",
  "Darius",
  "Erika",
  "Lane",
  "Ximena",
  "Jerry",
  "April",
  "Jaime",
  "Marely",
  "Scott",
  "Julie",
  "Graham",
  "Danica",
  "Weston",
  "Presley",
  "Braydon",
  "Brielle",
  "Anderson",
  "Julissa",
  "Rodrigo",
  "Angie",
  "Pablo",
  "Iris",
  "Saul",
  "Brenda",
  "Danny",
  "Hazel",
  "Donald",
  "Rose",
  "Elliot",
  "Malia",
  "Brayan",
  "Shayla",
  "Dallas",
  "Fiona",
  "Lorenzo",
  "Phoebe",
  "Casey",
  "Nayeli",
  "Mitchell",
  "Paola",
  "Alberto",
  "Kaelyn",
  "Tristen",
  "Selena",
  "Rowan",
  "Audrina",
  "Jayson",
  "Rebekah",
  "Gustavo",
  "Carolina",
  "Aaden",
  "Janiyah",
  "Amari",
  "Michaela",
  "Dean",
  "Penelope",
  "Braeden",
  "Janiya",
  "Declan",
  "Anastasia",
  "Chris",
  "Adeline",
  "Ismael",
  "Ruth",
  "Dane",
  "Sasha",
  "Louis",
  "Denise",
  "Arturo",
  "Holly",
  "Brenden",
  "Madisyn",
  "Felix",
  "Hanna",
  "Jimmy",
  "Tatum",
  "Cohen",
  "Marlee",
  "Tony",
  "Nataly",
  "Holden",
  "Helen",
  "Reid",
  "Janelle",
  "Abel",
  "Lizbeth",
  "Bennett",
  "Serena",
  "Zackary",
  "Anya",
  "Arthur",
  "Jaslene",
  "Nehemiah",
  "Kaylin",
  "Ricky",
  "Jazlyn",
  "Esteban",
  "Nancy",
  "Cruz",
  "Lindsay",
  "Finn",
  "Desiree",
  "Mauricio",
  "Hayley",
  "Dennis",
  "Itzel",
  "Judah",
  "Imani",
  "Jay",
  "Madelynn",
  "Lance",
  "Asia",
  "Beau",
  "Kadence",
  "Ali",
  "Madyson",
  "Landyn",
  "Talia",
  "Yahir",
  "Jane",
  "Phoenix",
  "Kayden",
  "Nickolas",
  "Annie",
  "Kobe",
  "Amari",
  "Bryant",
  "Bridget",
  "Maurice",
  "Raegan",
  "Russell",
  "Jadyn",
  "Leland",
  "Celeste",
  "Colten",
  "Jimena",
  "Reed",
  "Luna",
  "Davis",
  "Yasmin",
  "Joe",
  "Emilia",
  "Ernesto",
  "Annika",
  "Desmond",
  "Estrella",
  "Kade",
  "Sarai",
  "Reece",
  "Lacey",
  "Morgan",
  "Ayla",
  "Ramon",
  "Alessandra",
  "Rocco",
  "Willow",
  "Orlando",
  "Nyla",
  "Ryker",
  "Dayana",
  "Brodie",
  "Lilah",
  "Paxton",
  "Lilliana",
  "Jacoby",
  "Natasha",
  "Douglas",
  "Hadley",
  "Kristopher",
  "Harley",
  "Gary",
  "Priscilla",
  "Lawrence",
  "Claudia",
  "Izaiah",
  "Allisson",
  "Solomon",
  "Baylee",
  "Nikolas",
  "Brenna",
  "Mekhi",
  "Brittany",
  "Justice",
  "Skyler",
  "Tate",
  "Fernanda",
  "Jaydon",
  "Danna",
  "Salvador",
  "Melany",
  "Shaun",
  "Cali",
  "Alvin",
  "Lia",
  "Eddie",
  "Macie",
  "Kane",
  "Lyric",
  "Davion",
  "Logan",
  "Zachariah",
  "Gloria",
  "Dorian",
  "Lana",
  "Titus",
  "Mylee",
  "Kellen",
  "Cindy",
  "Camron",
  "Lilian",
  "Isiah",
  "Amira",
  "Javon",
  "Anahi",
  "Nasir",
  "Alissa",
  "Milo",
  "Anaya",
  "Johan",
  "Lena",
  "Byron",
  "Ainsley",
  "Jasper",
  "Sandra",
  "Jonathon",
  "Noelle",
  "Chad",
  "Marisol",
  "Marc",
  "Meredith",
  "Kelvin",
  "Kailyn",
  "Chandler",
  "Lesly",
  "Sam",
  "Johanna",
  "Cory",
  "Diamond",
  "Deandre",
  "Evangeline",
  "River",
  "Juliet",
  "Reese",
  "Kathleen",
  "Roger",
  "Meghan",
  "Quinton",
  "Paisley",
  "Talon",
  "Athena",
  "Romeo",
  "Hailee",
  "Franklin",
  "Rosa",
  "Noel",
  "Wendy",
  "Alijah",
  "Emilee",
  "Guillermo",
  "Sage",
  "Gunnar",
  "Alanna",
  "Damon",
  "Elaina",
  "Jadon",
  "Cara",
  "Emerson",
  "Nia",
  "Micheal",
  "Paris",
  "Bruce",
  "Casey",
  "Terry",
  "Dana",
  "Kolton",
  "Emery",
  "Melvin",
  "Rowan",
  "Beckett",
  "Aubrie",
  "Porter",
  "Kaitlin",
  "August",
  "Jaden",
  "Brycen",
  "Kenzie",
  "Dayton",
  "Kiana",
  "Jamarion",
  "Viviana",
  "Leonel",
  "Kristen",
  "Karson",
  "Amya",
  "Zayden",
  "Elle",
  "Keagan",
  "Kristina",
  "Carl",
  "Cheyanne",
  "Khalil",
  "Haylie",
  "Cristopher",
  "Patricia",
  "Nelson",
  "Anne",
  "Braiden",
  "Samara",
  "Moses",
  "Skye",
  "Isaias",
  "Kali",
  "Roy",
  "America",
  "Triston",
  "Lexie",
  "Walker",
  "Parker",
  "Kale",
  "Halle",
  "Jermaine",
  "Londyn",
  "Leon",
  "Abbigail",
  "Rodney",
  "Linda",
  "Kristian",
  "Hallie",
  "Mohamed",
  "Saniya",
  "Ronan",
  "Bryanna",
  "Pierce",
  "Bailee",
  "Trace",
  "Jaylynn",
  "Warren",
  "Mckayla",
  "Jeffery",
  "Quinn",
  "Maverick",
  "Jaelynn",
  "Cyrus",
  "Jaida",
  "Quincy",
  "Caylee",
  "Nathanael",
  "Jaiden",
  "Skylar",
  "Melina",
  "Tommy",
  "Abril",
  "Conor",
  "Sidney",
  "Noe",
  "Kassandra",
  "Ezequiel",
  "Elisabeth",
  "Demetrius",
  "Adalyn",
  "Jaylin",
  "Kaylynn",
  "Kendrick",
  "Mercedes",
  "Frederick",
  "Yesenia",
  "Terrance",
  "Elliana",
  "Kash",
  "Brylee",
  "Raiden",
  "Dylan",
  "Cullen",
  "Isabela",
  "Donte",
  "Ryan",
  "Jovani",
  "Ashlee",
  "Cordell",
  "Daphne",
  "Kasen",
  "Kenya",
  "Rory",
  "Marina",
  "Alfred",
  "Addisyn",
  "Darwin",
  "Aylin",
  "Ernest",
  "Dayanara",
  "Bailey",
  "Sylvia",
  "Gaige",
  "Clarissa",
  "Hassan",
  "Maritza",
  "Jamarcus",
  "Virginia",
  "Killian",
  "Braelyn",
  "Augustus",
  "Jolie",
  "Deegan",
  "Jaidyn",
  "Jaylan",
  "Kinsley",
  "Lennon",
  "Kirsten",
  "Kolten",
  "Zariah",
  "Damari",
  "Kaleigh",
  "Hugh",
  "Amani",
  "Jensen",
  "Carla",
  "Yurem",
  "Yareli",
  "Gwendolyn",
  "Paulina",
  "Nathalie",
  "Annabella",
  "Jaylin",
  "Tabitha",
  "Deanna",
  "Madalynn",
  "Journey",
  "Aiyana",
  "Skyla",
  "Yaretzi",
  "Ada",
  "Liana",
  "Karlee",
  "Jenny",
  "Myla"
];

const businessNames = [
  "3Com Corp",
  "3M Company",
  "A.G. Edwards Inc.",
  "Abbott Laboratories",
  "Abercrombie & Fitch Co.",
  "ABM Industries Incorporated",
  "Ace Hardware Corporation",
  "ACT Manufacturing Inc.",
  "Acterna Corp.",
  "Adams Resources & Energy, Inc.",
  "ADC Telecommunications, Inc.",
  "Adelphia Communications Corporation",
  "Administaff, Inc.",
  "Adobe Systems Incorporated",
  "Adolph Coors Company",
  "Advance Auto Parts, Inc.",
  "Advanced Micro Devices, Inc.",
  "AdvancePCS, Inc.",
  "Advantica Restaurant Group, Inc.",
  "The AES Corporation",
  "Aetna Inc.",
  "Affiliated Computer Services, Inc.",
  "AFLAC Incorporated",
  "AGCO Corporation",
  "Agilent Technologies, Inc.",
  "Agway Inc.",
  "Apartment Investment and Management Company",
  "Air Products and Chemicals, Inc.",
  "Airborne, Inc.",
  "Airgas, Inc.",
  "AK Steel Holding Corporation",
  "Alaska Air Group, Inc.",
  "Alberto-Culver Company",
  "Albertson's, Inc.",
  "Alcoa Inc.",
  "Alleghany Corporation",
  "Allegheny Energy, Inc.",
  "Allegheny Technologies Incorporated",
  "Allergan, Inc.",
  "ALLETE, Inc.",
  "Alliant Energy Corporation",
  "Allied Waste Industries, Inc.",
  "Allmerica Financial Corporation",
  "The Allstate Corporation",
  "ALLTEL Corporation",
  "The Alpine Group, Inc.",
  "Amazon.com, Inc.",
  "AMC Entertainment Inc.",
  "American Power Conversion Corporation",
  "Amerada Hess Corporation",
  "AMERCO",
  "Ameren Corporation",
  "America West Holdings Corporation",
  "American Axle & Manufacturing Holdings, Inc.",
  "American Eagle Outfitters, Inc.",
  "American Electric Power Company, Inc.",
  "American Express Company",
  "American Financial Group, Inc.",
  "American Greetings Corporation",
  "American International Group, Inc.",
  "American Standard Companies Inc.",
  "American Water Works Company, Inc.",
  "AmerisourceBergen Corporation",
  "Ames Department Stores, Inc.",
  "Amgen Inc.",
  "Amkor Technology, Inc.",
  "AMR Corporation",
  "AmSouth Bancorp.",
  "Amtran, Inc.",
  "Anadarko Petroleum Corporation",
  "Analog Devices, Inc.",
  "Anheuser-Busch Companies, Inc.",
  "Anixter International Inc.",
  "AnnTaylor Inc.",
  "Anthem, Inc.",
  "AOL Time Warner Inc.",
  "Aon Corporation",
  "Apache Corporation",
  "Apple Computer, Inc.",
  "Applera Corporation",
  "Applied Industrial Technologies, Inc.",
  "Applied Materials, Inc.",
  "Aquila, Inc.",
  "ARAMARK Corporation",
  "Arch Coal, Inc.",
  "Archer Daniels Midland Company",
  "Arkansas Best Corporation",
  "Armstrong Holdings, Inc.",
  "Arrow Electronics, Inc.",
  "ArvinMeritor, Inc.",
  "Ashland Inc.",
  "Astoria Financial Corporation",
  "AT&T Corp.",
  "Atmel Corporation",
  "Atmos Energy Corporation",
  "Audiovox Corporation",
  "Autoliv, Inc.",
  "Automatic Data Processing, Inc.",
  "AutoNation, Inc.",
  "AutoZone, Inc.",
  "Avaya Inc.",
  "Avery Dennison Corporation",
  "Avista Corporation",
  "Avnet, Inc.",
  "Avon Products, Inc.",
  "Baker Hughes Incorporated",
  "Ball Corporation",
  "Bank of America Corporation",
  "The Bank of New York Company, Inc.",
  "Bank One Corporation",
  "Banknorth Group, Inc.",
  "Banta Corporation",
  "Barnes & Noble, Inc.",
  "Bausch & Lomb Incorporated",
  "Baxter International Inc.",
  "BB&T Corporation",
  "The Bear Stearns Companies Inc.",
  "Beazer Homes USA, Inc.",
  "Beckman Coulter, Inc.",
  "Becton, Dickinson and Company",
  "Bed Bath & Beyond Inc.",
  "Belk, Inc.",
  "Bell Microproducts Inc.",
  "BellSouth Corporation",
  "Belo Corp.",
  "Bemis Company, Inc.",
  "Benchmark Electronics, Inc.",
  "Berkshire Hathaway Inc.",
  "Best Buy Co., Inc.",
  "Bethlehem Steel Corporation",
  "Beverly Enterprises, Inc.",
  "Big Lots, Inc.",
  "BJ Services Company",
  "BJ's Wholesale Club, Inc.",
  "The Black & Decker Corporation",
  "Black Hills Corporation",
  "BMC Software, Inc.",
  "The Boeing Company",
  "Boise Cascade Corporation",
  "Borders Group, Inc.",
  "BorgWarner Inc.",
  "Boston Scientific Corporation",
  "Bowater Incorporated",
  "Briggs & Stratton Corporation",
  "Brightpoint, Inc.",
  "Brinker International, Inc.",
  "Bristol-Myers Squibb Company",
  "Broadwing, Inc.",
  "Brown Shoe Company, Inc.",
  "Brown-Forman Corporation",
  "Brunswick Corporation",
  "Budget Group, Inc.",
  "Burlington Coat Factory Warehouse Corporation",
  "Burlington Industries, Inc.",
  "Burlington Northern Santa Fe Corporation",
  "Burlington Resources Inc.",
  "C. H. Robinson Worldwide Inc.",
  "Cablevision Systems Corp",
  "Cabot Corp",
  "Cadence Design Systems, Inc.",
  "Calpine Corp.",
  "Campbell Soup Co.",
  "Capital One Financial Corp.",
  "Cardinal Health Inc.",
  "Caremark Rx Inc.",
  "Carlisle Cos. Inc.",
  "Carpenter Technology Corp.",
  "Casey's General Stores Inc.",
  "Caterpillar Inc.",
  "CBRL Group Inc.",
  "CDI Corp.",
  "CDW Computer Centers Inc.",
  "CellStar Corp.",
  "Cendant Corp",
  "Cenex Harvest States Cooperatives",
  "Centex Corp.",
  "CenturyTel Inc.",
  "Ceridian Corp.",
  "CH2M Hill Cos. Ltd.",
  "Champion Enterprises Inc.",
  "Charles Schwab Corp.",
  "Charming Shoppes Inc.",
  "Charter Communications Inc.",
  "Charter One Financial Inc.",
  "ChevronTexaco Corp.",
  "Chiquita Brands International Inc.",
  "Chubb Corp",
  "Ciena Corp.",
  "Cigna Corp",
  "Cincinnati Financial Corp.",
  "Cinergy Corp.",
  "Cintas Corp.",
  "Circuit City Stores Inc.",
  "Cisco Systems Inc.",
  "Citigroup, Inc",
  "Citizens Communications Co.",
  "CKE Restaurants Inc.",
  "Clear Channel Communications Inc.",
  "The Clorox Co.",
  "CMGI Inc.",
  "CMS Energy Corp.",
  "CNF Inc.",
  "Coca-Cola Co.",
  "Coca-Cola Enterprises Inc.",
  "Colgate-Palmolive Co.",
  "Collins & Aikman Corp.",
  "Comcast Corp.",
  "Comdisco Inc.",
  "Comerica Inc.",
  "Comfort Systems USA Inc.",
  "Commercial Metals Co.",
  "Community Health Systems Inc.",
  "Compass Bancshares Inc",
  "Computer Associates International Inc.",
  "Computer Sciences Corp.",
  "Compuware Corp.",
  "Comverse Technology Inc.",
  "ConAgra Foods Inc.",
  "Concord EFS Inc.",
  "Conectiv, Inc",
  "Conoco Inc",
  "Conseco Inc.",
  "Consolidated Freightways Corp.",
  "Consolidated Edison Inc.",
  "Constellation Brands Inc.",
  "Constellation Emergy Group Inc.",
  "Continental Airlines Inc.",
  "Convergys Corp.",
  "Cooper Cameron Corp.",
  "Cooper Industries Ltd.",
  "Cooper Tire & Rubber Co.",
  "Corn Products International Inc.",
  "Corning Inc.",
  "Costco Wholesale Corp.",
  "Countrywide Credit Industries Inc.",
  "Coventry Health Care Inc.",
  "Cox Communications Inc.",
  "Crane Co.",
  "Crompton Corp.",
  "Crown Cork & Seal Co. Inc.",
  "CSK Auto Corp.",
  "CSX Corp.",
  "Cummins Inc.",
  "CVS Corp.",
  "Cytec Industries Inc.",
  "D&K Healthcare Resources, Inc.",
  "D.R. Horton Inc.",
  "Dana Corporation",
  "Danaher Corporation",
  "Darden Restaurants Inc.",
  "DaVita Inc.",
  "Dean Foods Company",
  "Deere & Company",
  "Del Monte Foods Co",
  "Dell Computer Corporation",
  "Delphi Corp.",
  "Delta Air Lines Inc.",
  "Deluxe Corporation",
  "Devon Energy Corporation",
  "Di Giorgio Corporation",
  "Dial Corporation",
  "Diebold Incorporated",
  "Dillard's Inc.",
  "DIMON Incorporated",
  "Dole Food Company, Inc.",
  "Dollar General Corporation",
  "Dollar Tree Stores, Inc.",
  "Dominion Resources, Inc.",
  "Domino's Pizza LLC",
  "Dover Corporation, Inc.",
  "Dow Chemical Company",
  "Dow Jones & Company, Inc.",
  "DPL Inc.",
  "DQE Inc.",
  "Dreyer's Grand Ice Cream, Inc.",
  "DST Systems, Inc.",
  "DTE Energy Co.",
  "E.I. Du Pont de Nemours and Company",
  "Duke Energy Corp",
  "Dun & Bradstreet Inc.",
  "DURA Automotive Systems Inc.",
  "DynCorp",
  "Dynegy Inc.",
  "E*Trade Group, Inc.",
  "E.W. Scripps Company",
  "Earthlink, Inc.",
  "Eastman Chemical Company",
  "Eastman Kodak Company",
  "Eaton Corporation",
  "Echostar Communications Corporation",
  "Ecolab Inc.",
  "Edison International",
  "EGL Inc.",
  "El Paso Corporation",
  "Electronic Arts Inc.",
  "Electronic Data Systems Corp.",
  "Eli Lilly and Company",
  "EMC Corporation",
  "Emcor Group Inc.",
  "Emerson Electric Co.",
  "Encompass Services Corporation",
  "Energizer Holdings Inc.",
  "Energy East Corporation",
  "Engelhard Corporation",
  "Enron Corp.",
  "Entergy Corporation",
  "Enterprise Products Partners L.P.",
  "EOG Resources, Inc.",
  "Equifax Inc.",
  "Equitable Resources Inc.",
  "Equity Office Properties Trust",
  "Equity Residential Properties Trust",
  "Estee Lauder Companies Inc.",
  "Exelon Corporation",
  "Exide Technologies",
  "Expeditors International of Washington Inc.",
  "Express Scripts Inc.",
  "ExxonMobil Corporation",
  "Fairchild Semiconductor International Inc.",
  "Family Dollar Stores Inc.",
  "Farmland Industries Inc.",
  "Federal Mogul Corp.",
  "Federated Department Stores Inc.",
  "Federal Express Corp.",
  "Felcor Lodging Trust Inc.",
  "Ferro Corp.",
  "Fidelity National Financial Inc.",
  "Fifth Third Bancorp",
  "First American Financial Corp.",
  "First Data Corp.",
  "First National of Nebraska Inc.",
  "First Tennessee National Corp.",
  "FirstEnergy Corp.",
  "Fiserv Inc.",
  "Fisher Scientific International Inc.",
  "FleetBoston Financial Co.",
  "Fleetwood Enterprises Inc.",
  "Fleming Companies Inc.",
  "Flowers Foods Inc.",
  "Flowserv Corp",
  "Fluor Corp",
  "FMC Corp",
  "Foamex International Inc",
  "Foot Locker Inc",
  "Footstar Inc.",
  "Ford Motor Co",
  "Forest Laboratories Inc.",
  "Fortune Brands Inc.",
  "Foster Wheeler Ltd.",
  "FPL Group Inc.",
  "Franklin Resources Inc.",
  "Freeport McMoran Copper & Gold Inc.",
  "Frontier Oil Corp",
  "Furniture Brands International Inc.",
  "Gannett Co., Inc.",
  "Gap Inc.",
  "Gateway Inc.",
  "GATX Corporation",
  "Gemstar-TV Guide International Inc.",
  "GenCorp Inc.",
  "General Cable Corporation",
  "General Dynamics Corporation",
  "General Electric Company",
  "General Mills Inc",
  "General Motors Corporation",
  "Genesis Health Ventures Inc.",
  "Gentek Inc.",
  "Gentiva Health Services Inc.",
  "Genuine Parts Company",
  "Genuity Inc.",
  "Genzyme Corporation",
  "Georgia Gulf Corporation",
  "Georgia-Pacific Corporation",
  "Gillette Company",
  "Gold Kist Inc.",
  "Golden State Bancorp Inc.",
  "Golden West Financial Corporation",
  "Goldman Sachs Group Inc.",
  "Goodrich Corporation",
  "The Goodyear Tire & Rubber Company",
  "Granite Construction Incorporated",
  "Graybar Electric Company Inc.",
  "Great Lakes Chemical Corporation",
  "Great Plains Energy Inc.",
  "GreenPoint Financial Corp.",
  "Greif Bros. Corporation",
  "Grey Global Group Inc.",
  "Group 1 Automotive Inc.",
  "Guidant Corporation",
  "H&R Block Inc.",
  "H.B. Fuller Company",
  "H.J. Heinz Company",
  "Halliburton Co.",
  "Harley-Davidson Inc.",
  "Harman International Industries Inc.",
  "Harrah's Entertainment Inc.",
  "Harris Corp.",
  "Harsco Corp.",
  "Hartford Financial Services Group Inc.",
  "Hasbro Inc.",
  "Hawaiian Electric Industries Inc.",
  "HCA Inc.",
  "Health Management Associates Inc.",
  "Health Net Inc.",
  "Healthsouth Corp",
  "Henry Schein Inc.",
  "Hercules Inc.",
  "Herman Miller Inc.",
  "Hershey Foods Corp.",
  "Hewlett-Packard Company",
  "Hibernia Corp.",
  "Hillenbrand Industries Inc.",
  "Hilton Hotels Corp.",
  "Hollywood Entertainment Corp.",
  "Home Depot Inc.",
  "Hon Industries Inc.",
  "Honeywell International Inc.",
  "Hormel Foods Corp.",
  "Host Marriott Corp.",
  "Household International Corp.",
  "Hovnanian Enterprises Inc.",
  "Hub Group Inc.",
  "Hubbell Inc.",
  "Hughes Supply Inc.",
  "Humana Inc.",
  "Huntington Bancshares Inc.",
  "Idacorp Inc.",
  "IDT Corporation",
  "IKON Office Solutions Inc.",
  "Illinois Tool Works Inc.",
  "IMC Global Inc.",
  "Imperial Sugar Company",
  "IMS Health Inc.",
  "Ingles Market Inc",
  "Ingram Micro Inc.",
  "Insight Enterprises Inc.",
  "Integrated Electrical Services Inc.",
  "Intel Corporation",
  "International Paper Co.",
  "Interpublic Group of Companies Inc.",
  "Interstate Bakeries Corporation",
  "International Business Machines Corp.",
  "International Flavors & Fragrances Inc.",
  "International Multifoods Corporation",
  "Intuit Inc.",
  "IT Group Inc.",
  "ITT Industries Inc.",
  "Ivax Corp.",
  "J.B. Hunt Transport Services Inc.",
  "J.C. Penny Co.",
  "J.P. Morgan Chase & Co.",
  "Jabil Circuit Inc.",
  "Jack In The Box Inc.",
  "Jacobs Engineering Group Inc.",
  "JDS Uniphase Corp.",
  "Jefferson-Pilot Co.",
  "John Hancock Financial Services Inc.",
  "Johnson & Johnson",
  "Johnson Controls Inc.",
  "Jones Apparel Group Inc.",
  "KB Home",
  "Kellogg Company",
  "Kellwood Company",
  "Kelly Services Inc.",
  "Kemet Corp.",
  "Kennametal Inc.",
  "Kerr-McGee Corporation",
  "KeyCorp",
  "KeySpan Corp.",
  "Kimball International Inc.",
  "Kimberly-Clark Corporation",
  "Kindred Healthcare Inc.",
  "KLA-Tencor Corporation",
  "K-Mart Corp.",
  "Knight-Ridder Inc.",
  "Kohl's Corp.",
  "KPMG Consulting Inc.",
  "Kroger Co.",
  "L-3 Communications Holdings Inc.",
  "Laboratory Corporation of America Holdings",
  "Lam Research Corporation",
  "LandAmerica Financial Group Inc.",
  "Lands' End Inc.",
  "Landstar System Inc.",
  "La-Z-Boy Inc.",
  "Lear Corporation",
  "Legg Mason Inc.",
  "Leggett & Platt Inc.",
  "Lehman Brothers Holdings Inc.",
  "Lennar Corporation",
  "Lennox International Inc.",
  "Level 3 Communications Inc.",
  "Levi Strauss & Co.",
  "Lexmark International Inc.",
  "Limited Inc.",
  "Lincoln National Corporation",
  "Linens 'n Things Inc.",
  "Lithia Motors Inc.",
  "Liz Claiborne Inc.",
  "Lockheed Martin Corporation",
  "Loews Corporation",
  "Longs Drug Stores Corporation",
  "Louisiana-Pacific Corporation",
  "Lowe's Companies Inc.",
  "LSI Logic Corporation",
  "The LTV Corporation",
  "The Lubrizol Corporation",
  "Lucent Technologies Inc."
];

// Generate a random integer using Math.random.
const generateRandomInt = (min: number, max: number) => {
  const minCeil = Math.ceil(min);
  const maxFloor = Math.floor(max);
  return Math.floor(Math.random() * (maxFloor - minCeil) + minCeil); //The maximum is exclusive and the minimum is inclusive.
};

/**
 * Generates a random integer using crypto.getRandomValues, which is cryptographically secure.
 * Though we use this for test values (see: generateAccountNumber), it makes CodeQL happy and is a best practice.
 */
const generateSecureRandomInt = (min: number, max: number) => {
  if (
    typeof window !== "undefined" &&
    window.crypto &&
    typeof window.crypto.getRandomValues === "function"
  ) {
    const range = max - min;
    // Create an array containing a single 32-bit unsigned integer.
    const randomArray = new Uint32Array(1);
    window.crypto.getRandomValues(randomArray);
    // Normalize the value of randomArray[0] to a range between 0 and 1.
    const randomValue = randomArray[0] / (0xffffffff + 1); // 0xFFFFFFFF is a hexadecimal representation of the largest 32-bit unsigned integer.
    return Math.floor(randomValue * (range + 1)) + min;
  } else {
    // Otherwise, if crypto is not available, fall back to Math.random.
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
};

// For testing, generate a routing number that aligns to an institution.
export const generateRoutingNumber = (): string => {
  const routingNumbers = [
    "273976369", // Veridian,
    "102001017", // Chase,
    "103000017", // Bank of America
    "123006800", // Wells Fargo
    "321171184" // Citibank
  ];
  return routingNumbers[generateRandomInt(0, routingNumbers.length)];
};

// For testing, generate a random account number.
export const generateAccountNumber = (): string => {
  const min = 10000000000;
  const max = 99999999999;

  return generateSecureRandomInt(min, max).toString();
};
