import { LiveMoovAPIClient } from "../api";
import { APIResponse } from "../request";
import {
  ApplePayListDomainsResponse,
  CreateDomains,
  CreateDomainsResponse,
  PatchDomains
} from "./applePay.model";

export interface ApplePayAPI {
  /** Lists the apple-pay-approved domains registered by the connected account */
  listDomains(
    facilitatorID: string,
    connectedAccountID: string
  ): APIResponse<ApplePayListDomainsResponse>;
  /** Registers a domain to be used by the connected account for apple pay transactions */
  createDomain(
    facilitatorID: string,
    connectedAccountID: string,
    createDomainPayload: CreateDomains
  ): APIResponse<CreateDomainsResponse>;
  /** Updates a registered apple-pay-approved domain */
  patchDomain(
    facilitatorID: string,
    connectedAccountID: string,
    patchDomainPayload: PatchDomains
  ): APIResponse<undefined>;
}

export class LiveApplePayAPI implements ApplePayAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async listDomains(
    facilitatorID: string,
    connectedAccountID: string
  ): APIResponse<ApplePayListDomainsResponse> {
    const [result, err] = await this._client.request<ApplePayListDomainsResponse>(
      `/accounts/${connectedAccountID}/apple-pay/domains`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async createDomain(
    facilitatorID: string,
    connectedAccountID: string,
    createDomainsPayload: CreateDomains
  ): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(
      `/accounts/${connectedAccountID}/apple-pay/domains`,
      {
        method: "POST",
        json: createDomainsPayload,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async patchDomain(
    facilitatorID: string,
    connectedAccountID: string,
    patchDomainsPayload: PatchDomains
  ): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(
      `/accounts/${connectedAccountID}/apple-pay/domains`,
      {
        method: "PATCH",
        json: patchDomainsPayload,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
