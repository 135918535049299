import { useContext } from "react";
import { FormGroup, SwitchButton } from "@moovfinancial/cargo";
import { Label } from "components/form/Form";
import type { AccountType } from "api/v2";
import {
  AccountSetupContext,
  defaultCapabilities
} from "pages/accounts/AccountModals/AccountSetupContext";
import styles from "./AccountTypeSelector.module.scss";

export const AccountTypeSelector = ({ compact }: { compact?: boolean }) => {
  const {
    account: { accountType = "business" },
    dispatch,
    setCapabilities
  } = useContext(AccountSetupContext);

  const options = [
    { value: "business", label: "Business" },
    { value: "individual", label: "Individual" }
  ];

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCapabilities(defaultCapabilities);
    dispatch({
      type: "account",
      value: { accountType: e.target.value as AccountType }
    });
  };

  return (
    <FormGroup>
      <Label label="Account type" />
      <SwitchButton
        options={options}
        selectedValue={accountType}
        onChange={handleChange}
        className={compact ? styles.compact : undefined}
      />
    </FormGroup>
  );
};
