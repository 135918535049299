import { LiveMoovAPIClient } from "../api";
import { APIResponse } from "../request";
import {
  CardFeaturesConfig,
  CreateConfig,
  CreateConfigResponse,
  ListConfigsResponse
} from "./cardFeaturesConfig.model";

export interface CardFeaturesConfigAPI {
  /** Lists the card feature configurations for the connected account */
  list(facilitatorID: string, connectedAccountID: string): APIResponse<ListConfigsResponse>;
  /** Retrieve an account configuration by ID, whether active or disabled */
  get(facilitatorID: string, connectedAccountID: string): APIResponse<CardFeaturesConfig>;
  /** Enable a facilitator account to link cards, and optionally set configuration options */
  create(
    facilitatorID: string,
    createConfigPayload: CreateConfig
  ): APIResponse<CreateConfigResponse>;
  /** Updates a card feature configuration */
  update(
    facilitatorID: string,
    connectedAccountID: string,
    patchConfigPayload: CardFeaturesConfig
  ): APIResponse<undefined>;
  /** Disable a card account that is currently enabled for card linking */
  disable(facilitatorID: string, connectedAccountID: string): APIResponse<undefined>;
}

export class LiveCardFeaturesConfigAPI implements CardFeaturesConfigAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async list(facilitatorID: string): APIResponse<ListConfigsResponse> {
    const [result, err] = await this._client.request<ListConfigsResponse>(
      "/ops/card-account-configs",
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async get(facilitatorID: string, connectedAccountID: string): APIResponse<CardFeaturesConfig> {
    const [result, err] = await this._client.request<CardFeaturesConfig>(
      `/ops/card-account-configs/${connectedAccountID}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async create(
    facilitatorID: string,
    createConfigPayload: CreateConfig
  ): APIResponse<CardFeaturesConfig> {
    const [result, err] = await this._client.request<CardFeaturesConfig>(
      "/ops/card-account-configs",
      {
        method: "POST",
        json: createConfigPayload,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async update(
    facilitatorID: string,
    connectedAccountID: string,
    patchConfigPayload: CardFeaturesConfig
  ): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(
      `/ops/card-account-configs/${connectedAccountID}`,
      {
        method: "PATCH",
        json: patchConfigPayload,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async disable(facilitatorID: string, connectedAccountID: string): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(
      `/ops/card-account-configs/${connectedAccountID}`,
      {
        method: "DELETE",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
