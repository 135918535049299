import { getOpenApiClient } from "../OpenApiClientFactory";

type Props = {
  planIDs: string[];
  accountID: string;
  callerAccountID: string;
};

export const fetchFeePlans = async ({
  accountID,
  callerAccountID: facilitatorID,
  planIDs
}: Props) => {
  const openApi = getOpenApiClient();
  const { data, error } = await openApi.GET("/accounts/{accountID}/fee-plans", {
    params: {
      query: { planIDs: planIDs.join(",") },
      path: { accountID: accountID ?? "" }
    },
    headers: {
      "X-Account-ID": facilitatorID
    }
  });
  if (error || !data) {
    // catastrophic problem!
    return [];
  }
  return data;
};
