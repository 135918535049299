import clsx from "clsx";
import { BUTTONLIKE_MODIFIERS, ButtonLikeModifiers, ButtonLikeStyles } from "./ButtonLikeTypes";
import styles from "./ButtonLike.module.scss";

/**
 * Generates buttonType and buttonStyle classes.
 */
export const generateButtonLikeStyleClasses = ({
  buttonType = "primary",
  buttonStyle = "fill"
}: ButtonLikeStyles) => {
  // When buttonType is primary, its buttonStyle must always be `fill`.
  if (buttonType === "primary") {
    return clsx(styles[`type-${buttonType}`], styles["style-fill"]);
  }
  // Otherwise, return the `buttonStyle` that has been set.
  return clsx(styles[`type-${buttonType}`], styles[`style-${buttonStyle}`]);
};

/**
 * Generates modifier classes.
 */
export const generateButtonLikeModifierClasses = (modifiers: ButtonLikeModifiers) => {
  return clsx(
    BUTTONLIKE_MODIFIERS.map((modifier) => modifiers[modifier] && styles[`modifier-${modifier}`])
  );
};
