import { LiveMoovAPIClient } from "../api";
import { ErrorResponse } from "../request";
import { MoovDocument, MoovDocumentWithDetails, PatchDocument } from "./files.model";

export interface FilesAPI {
  /** Lists all uploaded files for the given account */
  list(
    facilitatorID: string,
    connectedAccountID: string
  ): Promise<[MoovDocument[] | undefined, ErrorResponse | undefined]>;
  /** Lists all uploaded files for the given account, including internalOnly files */
  adminList(
    facilitatorID: string,
    connectedAccountID: string
  ): Promise<[MoovDocument[] | undefined, ErrorResponse | undefined]>;
  /** Gets a file by id */
  get(
    facilitatorID: string,
    connectedAccountID: string,
    fileID: string
  ): Promise<[MoovDocument | undefined, ErrorResponse | undefined]>;
  /** Uploads a file */
  upload(
    facilitatorID: string,
    connectedAccountID: string,
    file: FormData
  ): Promise<[MoovDocument | undefined, ErrorResponse | undefined]>;
  /** Downloads a file. Moov admin only. */
  adminDownload(
    facilitatorID: string,
    connectedAccountID: string,
    fileID: string
  ): Promise<[any | undefined, ErrorResponse | undefined]>;
  /** Updates the status of a file. Moov admin only. */
  adminUpdate(
    facilitatorID: string,
    connectedAccountID: string,
    file: PatchDocument
  ): Promise<[MoovDocument | undefined, ErrorResponse | undefined]>;
  /** Gets the details of a file. Moov admin only. */
  getFileWithDetails(
    facilitatorID: string,
    connectedAccountID: string,
    fileID: string
  ): Promise<[MoovDocumentWithDetails | undefined, ErrorResponse | undefined]>;
}

export class LiveFilesAPI implements FilesAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async list(
    facilitatorID: string,
    connectedAccountID: string
  ): Promise<[MoovDocument[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<MoovDocument[]>(
      `/accounts/${connectedAccountID}/files`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminList(
    facilitatorID: string,
    connectedAccountID: string
  ): Promise<[MoovDocument[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<MoovDocument[]>(
      `/ops/accounts/${connectedAccountID}/files`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async get(
    facilitatorID: string,
    connectedAccountID: string,
    fileID: string
  ): Promise<[MoovDocument | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<MoovDocument>(
      `/accounts/${connectedAccountID}/files/${fileID}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async upload(
    facilitatorID: string,
    connectedAccountID: string,
    file: FormData
  ): Promise<[MoovDocument | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<MoovDocument>(
      `/accounts/${connectedAccountID}/files`,
      {
        method: "POST",
        xAccountID: facilitatorID,
        body: file
      }
    );
    return [result, err];
  }

  async adminDownload(
    facilitatorID: string,
    connectedAccountID: string,
    fileID: string
  ): Promise<[any, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<any>(
      `/admin/accounts/${connectedAccountID}/files/${fileID}/download`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminUpdate(
    facilitatorID: string,
    connectedAccountID: string,
    file: PatchDocument
  ): Promise<[MoovDocument | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<MoovDocument>(
      `/admin/accounts/${connectedAccountID}/files/${file.fileID}`,
      {
        method: "PATCH",
        xAccountID: facilitatorID,
        json: file
      }
    );
    return [result, err];
  }

  async getFileWithDetails(
    facilitatorID: string,
    connectedAccountID: string,
    fileID: string
  ): Promise<[MoovDocumentWithDetails | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<MoovDocumentWithDetails>(
      `/ops/accounts/${connectedAccountID}/files/${fileID}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
