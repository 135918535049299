import React, { useCallback, useEffect, useState } from "react";

export function useMeasure(ref: React.MutableRefObject<any>) {
  const [element, attachRef] = useCallbackRef();
  const [bounds, setBounds] = useState({ height: 0 });

  useEffect(() => {
    function onResize([entry]: ResizeObserverEntry[]) {
      setBounds({
        height: entry.contentRect.height
      });
    }

    const observer = new ResizeObserver(onResize);

    if (element && element.current) {
      observer.observe(element.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [element]);

  useEffect(() => {
    attachRef(ref);
  }, [attachRef, ref]);

  return bounds;
}

function useCallbackRef(): [React.MutableRefObject<any> | null, (node: any) => void] {
  const [ref, setRef] = useState<React.MutableRefObject<any> | null>(null);
  const fn = useCallback((node: any) => {
    setRef(node);
  }, []);

  return [ref, fn];
}
