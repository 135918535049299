import React, { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useOpenApi } from "@moovfinancial/common/hooks/useOpenApi";
import { getFingerprint } from "@moovfinancial/common/utils/fingerprint";
import { Session } from "api/Session.model";
import type { LocationState } from "pages/auth/signingRedirectHelper";
import { UserContext } from "./UserContext";

interface ContextProps {
  children: React.ReactNode;
}

type SignOutOptions = {
  shouldSaveLocationBeforeSignout: boolean;
  showSessionExpiredMessageOnLogin: boolean;
};

export interface SessionContext {
  fetchSession: (refresh?: boolean) => Promise<void>;
  signOut: (options?: SignOutOptions) => void;
  session: Session | null;
}

/**
 * A single source of truth for the user's session
 *
 * This context might evolve and swallow the current UserContext, FacilitatorContext and AccountContext
 *
 */
export const SessionContext = createContext<SessionContext>({
  fetchSession: () => Promise.resolve(),
  signOut: () => {},
  session: null
});

let previousUserID: string | undefined;
let resetLocation = false;

export default function SessionContextProvider({ children }: ContextProps) {
  // it's quite obvious that this context is very coupled with the UserContext, so in the future we will merge them
  // once we extract the non-logged-in parts of `UserContext` to a new context
  const { setIsVerified, setUser, reset: resetUserContext } = useContext(UserContext);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [session, setSession] = useState<Session | null>(null);
  const { openApi } = useOpenApi();

  const refreshSession = async () =>
    await openApi.POST("/session-refresh", {
      body: {
        fingerprint: await getFingerprint()
      }
    });

  const fetchSession = async (refresh: boolean = false) => {
    const { data, error } = refresh ? await refreshSession() : await openApi.GET("/session");
    if (error || !data) {
      // This block will be hit when the user tries to access a protected route before being signed in (including "/")
      // and right before they are redirected to the signin page, so we save the current location in state so we can
      // redirect back to it after they sign in
      // NOTE: If the user goes to "/signin" directly, this state will not be set, so we'll redirect to "/" after signing in
      const state: LocationState = {
        ...(currentLocation.state as LocationState),
        previousUserID,
        redirectLocationAfterLogin: resetLocation ? "/" : currentLocation.pathname
      };
      navigate("/signin", { replace: true, state });
      // we only update the previousUserID ONCE if we're coming from a signout event
      resetLocation = false;
      previousUserID = undefined;
      resetUserContext();
      return;
    }
    // this casting fixes the fact that OpenAPI spec is wrong and says we can have a session w/o userID, expiresOn or globalExpiresOn 😅
    const session = data as Session;
    setIsVerified(true);
    setUser(session);
    setSession(session);
  };

  // Signs the user out of the Dashboard
  const signOut = async (
    { shouldSaveLocationBeforeSignout, showSessionExpiredMessageOnLogin }: SignOutOptions = {
      shouldSaveLocationBeforeSignout: false,
      showSessionExpiredMessageOnLogin: false
    }
  ) => {
    if (showSessionExpiredMessageOnLogin) {
      localStorage.moovExpired = true;
    }
    previousUserID = session?.userID;
    resetLocation = !shouldSaveLocationBeforeSignout;
    await openApi.DELETE("/session");
    setSession(null);
  };

  return (
    <SessionContext.Provider
      value={{
        fetchSession,
        signOut,
        session
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
