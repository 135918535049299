import { useContext } from "react";
import { Alert } from "@moovfinancial/cargo";
import useAccountLocking from "hooks/useAccountLocking";
import { AccountSetupContext } from "../../AccountSetupContext";
import styles from "../../AccountSetup.module.scss";

export const PrefillAlert = () => {
  const { prefilled } = useContext(AccountSetupContext);
  const { verificationLocked, underwritingLocked } = useAccountLocking();
  if (!prefilled || verificationLocked || underwritingLocked) return null;
  return (
    <Alert className={styles.headingAlert} type="info">
      We pre-populated these fields to save you some time in test mode.
    </Alert>
  );
};
