"use client";

import clsx from "clsx";
import { type ComponentProps, type ElementType, type FocusEvent, useEffect, useState } from "react";
import { ValidationMessage } from "../ValidationMessage";
import { Textarea } from "./Textarea";
import { ValidatedInput } from "./ValidatedInput";
import styles from "./CharCounterInput.module.scss";

type BaseProps<C> = {
  /**
   * The minimum valid length of the input
   */
  minLength?: number;
  /**
   * The maximum valid length of the input
   */
  maxLength: number;
  /**
   * The value of the input
   */
  value?: string;
  /**
   * The base input this component wraps. Defaults to the basic HTML `input` element
   */
  as: C;
  /**
   * A callback that is called when the validity of the input changes
   */
  onValidityChange?: (valid: boolean) => void;
  /**
   * The class name to apply to the input
   */
  className?: string;
  /**
   * onFocus event handler
   */
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  /**
   * onBlur event handler
   */
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
};

export type CharCounterWrapperProps<C extends ElementType = "input"> = BaseProps<C> &
  Omit<ComponentProps<C>, keyof BaseProps<C>>;

export type CharCounterInputProps = Omit<CharCounterWrapperProps<typeof ValidatedInput>, "as">;
export type CharCounterTextareaProps = Omit<CharCounterWrapperProps<typeof Textarea>, "as">;

/**
 * HOC that wraps both Input and Textarea components to add a character counter. Requires `as` prop
 */
export const CharCounterWrapper = <C extends ElementType = "input">({
  value = "", // need to define a default value or React complains
  minLength = 0,
  maxLength = Number.MAX_SAFE_INTEGER,
  as: Component,
  onValidityChange = () => {},
  className,
  onFocus,
  onBlur,
  ...rest
}: CharCounterWrapperProps<C>) => {
  // eslint-disable-next-line no-console
  if (minLength >= maxLength) console.error("minLength must be less than maxLength");

  const [hasFocus, setHasFocus] = useState(true);
  const [stickyInvalid, setStickyInvalid] = useState(false);

  const currentLength = value.length;

  const isValid = (() => {
    if (currentLength === 0) return true;
    if (currentLength < minLength) return false;
    if (currentLength > maxLength) return false;
    return true;
  })();

  useEffect(() => {
    onValidityChange(isValid);
  }, [isValid]);

  const errorMessage =
    currentLength < minLength
      ? `Minimum ${minLength} characters required`
      : "Maximum character limit exceeded";

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(true);
    if (rest?.onFocus) onFocus?.(event);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isValid) setStickyInvalid(true);
    if (isValid) setStickyInvalid(false);
    setHasFocus(false);
    if (rest?.onBlur) onBlur?.(event);
  };

  const shouldShowValidationErrors = !isValid && (!hasFocus || stickyInvalid);

  return (
    <div className={clsx(styles.charCounterInputWrapper, className)}>
      {/* @ts-expect-error - Same error as in FloatingLabelInput, so at least we're consistent 😂😭 */}
      <Component
        {...rest}
        value={value}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        data-testid="inputCharCounter"
      />
      <div className={styles.inputInfo}>
        {shouldShowValidationErrors && <ValidationMessage message={errorMessage} noMargins />}
        <div
          className={clsx(
            styles.charCounter,
            (rest.disabled || rest.isLocked) && styles.disabled,
            shouldShowValidationErrors && styles.error
          )}
        >
          {currentLength}/{maxLength}
        </div>
      </div>
    </div>
  );
};

/**
 * Wraps Input components that extend from ValidatedInput to add a character counter and related validation messages
 */
export const CharCounterInput = ({ ...rest }: CharCounterInputProps) => {
  return <CharCounterWrapper as={ValidatedInput} {...rest} />;
};

/**
 * Wraps Textarea components to add a character counter and related validation messages
 */
export const TextareaCharCounter = ({ ...rest }: CharCounterTextareaProps) => {
  return <CharCounterWrapper as={Textarea} {...rest} />;
};
