import { memo } from "react";
import {
  formattedSSNToString,
  stringToFormattedSSN
} from "@moovfinancial/common/utils/format/formatSSN";
import { StringMaskingInput, StringMaskingInputProps } from "../StringMaskingInput";

export interface SSNInputProps
  extends Omit<
    StringMaskingInputProps,
    "acceptedChars" | "formattedToUnformatted" | "inputFormatter" | "unformattedToFormatted"
  > {}

/**
 * Masking input that onValueChange sends the value as a string of numbers
 */
export const SSNInput = memo(function SSNInput(props: SSNInputProps) {
  return (
    <StringMaskingInput
      acceptedChars={/\d+/g}
      formattedToUnformatted={formattedSSNToString}
      inputFormatter={stringToFormattedSSN}
      inputMode="numeric"
      unformattedToFormatted={stringToFormattedSSN}
      {...props}
    />
  );
});
