import React, { useCallback } from "react";
import { FormGroup, Helper, TextInput, Textarea } from "@moovfinancial/cargo/src/components/Form";
import { Label } from "./Form";
import styles from "./Form.module.scss";

export interface InputFormGroupProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  keyName: string;
  value: string | undefined | number;
  required?: boolean;
  type?: string;
  placeholder?: string;
  helper?: string | React.ReactNode;
  error?: string | React.ReactNode | null;
  warning?: string;
  optional?: boolean;
  optionalText?: string;
  infoText?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isLocked?: boolean;
  warn?: boolean;
  noMargins?: boolean;
}

const InputFormGroup = ({
  label,
  optional,
  keyName,
  value,
  required,
  onChange,
  type = "text",
  placeholder,
  helper,
  error,
  warning,
  optionalText = "(optional)",
  infoText,
  isLocked = false,
  warn = false,
  className,
  noMargins,
  ...rest
}: InputFormGroupProps) => {
  const onLocalChange = useCallback(
    (e: any) => {
      onChange(e);
    },
    [onChange]
  );

  return (
    <FormGroup
      className={className}
      as="label"
      errorMessage={error}
      warningMessage={warning}
      noMargins={noMargins}
    >
      {label &&
        (typeof label === "string" ? (
          <Label
            as="span"
            label={label}
            optional={optional}
            optionalText={optionalText}
            infoText={infoText}
          />
        ) : (
          <Label as="span" optional={optional} optionalText={optionalText} infoText={infoText}>
            {label}
          </Label>
        ))}
      {type === "textarea" ? (
        <Textarea
          isErroring={!!error}
          isLocked={isLocked}
          isWarning={!!warn}
          name={keyName}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onLocalChange}
          {...(rest as React.InputHTMLAttributes<HTMLTextAreaElement>)}
        />
      ) : (
        <TextInput
          isErroring={!!error}
          isLocked={isLocked}
          isWarning={!!warn}
          name={keyName}
          placeholder={placeholder}
          required={required}
          type={type}
          value={value}
          onChange={onLocalChange}
          {...rest}
        />
      )}
      <div className={styles.inputStatusDetails}>{helper && <Helper>{helper}</Helper>}</div>
    </FormGroup>
  );
};

export default InputFormGroup;
