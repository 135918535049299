import {
  CreateRepresentative,
  DecryptedAccountData,
  PatchRepresentative,
  Representative
} from "../accounts.model";
import { LiveMoovAPIClient } from "../api";
import { APIResponse } from "../request";

export type RepresentativeResponse = APIResponse<Representative>;

export interface RepresentativeAPI {
  /* Gets a representative */
  get(accountID: string, representativeID: string, facilitatorID: string): RepresentativeResponse;
  /* Creates a representative */
  create(
    accountID: string,
    facilitatorID: string,
    representative: CreateRepresentative
  ): RepresentativeResponse;
  adminCreate(
    accountID: string,
    facilitatorID: string,
    representative: CreateRepresentative
  ): RepresentativeResponse;
  /* Updates a representative */
  update(
    accountID: string,
    facilitatorID: string,
    representative: PatchRepresentative
  ): RepresentativeResponse;
  /* Admin endpoint for representative updates */
  adminUpdate(
    accountID: string,
    facilitatorID: string,
    representative: PatchRepresentative
  ): RepresentativeResponse;
  /* Deletes representative */
  delete(
    accountID: string,
    representativeID: string,
    facilitatorID: string
  ): APIResponse<undefined>;
  adminDelete(
    accountID: string,
    representativeID: string,
    facilitatorID: string
  ): APIResponse<undefined>;
  /* Gets decrypted representative information */
  decrypt(
    accountID: string,
    representativeID: string,
    facilitatorID: string
  ): APIResponse<DecryptedAccountData>;
  /* Lists all representatives */
  list(accountID: string, facilitatorID: string): APIResponse<Representative[]>;
}

export class LiveRepresentativesAPI implements RepresentativeAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }
  async get(
    accountID: string,
    representativeID: string,
    facilitatorID: string
  ): RepresentativeResponse {
    const [result, err] = await this._client.request<Representative>(
      `/accounts/${accountID}/representatives/${representativeID}`,
      {
        method: "GET",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async create(
    accountID: string,
    facilitatorID: string,
    representative: CreateRepresentative
  ): RepresentativeResponse {
    const [result, err] = await this._client.request<Representative>(
      `/accounts/${accountID}/representatives`,
      {
        method: "POST",
        json: representative,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminCreate(
    accountID: string,
    facilitatorID: string,
    representative: CreateRepresentative
  ): RepresentativeResponse {
    const [result, err] = await this._client.request<Representative>(
      `/admin/accounts/${accountID}/representatives`,
      {
        method: "POST",
        json: representative,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async update(
    accountID: string,
    facilitatorID: string,
    representative: PatchRepresentative
  ): RepresentativeResponse {
    const [result, err] = await this._client.request<Representative>(
      `/accounts/${accountID}/representatives/${representative.representativeID}`,
      {
        method: "PATCH",
        json: representative,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminUpdate(
    accountID: string,
    facilitatorID: string,
    representative: PatchRepresentative
  ): RepresentativeResponse {
    const [result, err] = await this._client.request<Representative>(
      `/admin/accounts/${accountID}/representatives/${representative.representativeID}`,
      {
        method: "PATCH",
        json: representative,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async delete(
    accountID: string,
    representativeID: string,
    facilitatorID: string
  ): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(
      `/accounts/${accountID}/representatives/${representativeID}`,
      {
        method: "DELETE",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminDelete(
    accountID: string,
    representativeID: string,
    facilitatorID: string
  ): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(
      `/admin/accounts/${accountID}/representatives/${representativeID}`,
      {
        method: "DELETE",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async decrypt(
    accountID: string,
    representativeID: string,
    facilitatorID: string
  ): APIResponse<DecryptedAccountData> {
    const [result, err] = await this._client.request<DecryptedAccountData>(
      `/admin/accounts/${accountID}/representatives/${representativeID}/decrypted`,
      {
        method: "GET",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async list(accountID: string, facilitatorID: string): APIResponse<Representative[]> {
    const [result, err] = await this._client.request<Representative[]>(
      `/accounts/${accountID}/representatives`,
      {
        method: "GET",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
