import { useContext } from "react";
import useAccountLocking from "hooks/useAccountLocking";
import useAccountRequirements from "hooks/useAccountRequirements";
import { AccountInput } from "../components/AccountInput";
import { BusinessTypeSelector } from "../components/BusinessTypeSelector";
import { DescriptionOrWebsiteInput } from "../components/DescriptionOrWebsiteInput";
import { PrefillAlert } from "../components/PrefillAlert";
import { Step } from "components/dialog/StepModal";
import Toaster from "components/toaster/Toaster";
import { AccountSetupContext } from "../../AccountSetupContext";
import useAccountModalCalls from "../../useAccountModalCalls";

export const BusinessBasicDetails = () => {
  const { account, errorMessages, capabilityRequirements } = useContext(AccountSetupContext);
  const { createAccount, updateAccount, loading } = useAccountModalCalls();

  const { accountLocks } = useAccountLocking();
  const { accountRequirements } = useAccountRequirements(capabilityRequirements);

  return (
    <Step onSubmit={account.accountID ? updateAccount : createAccount} loading={loading}>
      <PrefillAlert />
      {errorMessages && <Toaster toastInput={errorMessages} duration={4000} />}
      <AccountInput
        label="Business name"
        name="legalBusinessName"
        value={account.profile?.business?.legalBusinessName}
        showDefaultWarningMessage={!!accountRequirements.profile?.business?.legalBusinessName}
        isLocked={!!accountLocks?.profile?.business?.legalBusinessName}
        data-testid="legalBusinessName"
        required
      />
      <BusinessTypeSelector
        warn={!!accountRequirements.profile?.business?.businessType}
        isLocked={!!accountLocks?.profile?.business?.businessType}
      />
      <DescriptionOrWebsiteInput />
    </Step>
  );
};
