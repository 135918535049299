import clsx from "clsx";
import Box from "images/box-illustration.svg?react";
import styles from "./Auth.module.scss";

function GradientBackground() {
  return (
    <>
      <div className={styles.gradientBackground}>
        <div className={clsx(styles.gradientShape, styles.shape1)} />
        <div className={clsx(styles.gradientShape, styles.shape2)} />
        <div className={clsx(styles.gradientShape, styles.shape3)} />
        <div className={clsx(styles.gradientShape, styles.shape4)} />
      </div>
      <div className={styles.boxIllustration}>
        <Box />
      </div>
    </>
  );
}

export default GradientBackground;
