import React, { createContext, useEffect, useState } from "react";
import { debounce } from "remeda";
import { deepEqual } from "@moovfinancial/common/utils/deepEqual";

interface ContextProps {
  children: React.ReactNode;
}

export type WindowSize = {
  width: number;
  height: number;
};

type Callback = (windowSize: WindowSize) => void;

export interface WindowResizeContext {
  resizeNotifier: {
    subscribe: (cb: Callback) => void;
    unsubscribe: (cb: Callback) => void;
  };
}

/** A context manage window.resize events and notify its subscribers efficiently */
export const WindowResizeContext = createContext<WindowResizeContext>({
  resizeNotifier: {
    subscribe: () => null,
    unsubscribe: () => null
  }
});

const DEBOUNCE_INTERVAL = 200;

const SubscriberManager = () => {
  const subscribers: Array<Callback> = [];
  let pending: ReturnType<typeof setTimeout>[] = [];
  return {
    // Notifies all subscribers with the current window size
    notify: () => {
      // cancel all pending timeouts
      pending.forEach((timeoutID) => clearTimeout(timeoutID));
      pending = [];
      const currWindowSize = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      // Increment between each subscriber call
      // E.g.
      // - for 5 subscribers and a DEBOUNCE_INTERVAL of 50ms the interval will be 9ms
      // - for 50 subscribers and a DEBOUNCE_INTERVAL of 50ms the interval will be 1ms (worst case)
      // - for 100 subscribers and a DEBOUNCE_INTERVAL of 50ms the interval will be 1ms AND each ms 2 subscribers will be called (see modulo below)
      //
      // My testing indicates that we rarely have more than 20 subscribers at any given point,
      // so we should always have a decent interval between each call.
      const increment = Math.ceil(DEBOUNCE_INTERVAL / (subscribers.length + 1));
      subscribers.forEach((cb, i) => {
        // We fire the subscribers with a small delay between each
        // so the calls spread through the whole DEBOUNCE_INTERVAL
        // to prevent overloading the main thread.
        //
        // We also keep track of the timeouts so we can cancel them when the window is resized again
        // as we don't want to fire the subscribers with the old window size
        pending.push(
          setTimeout(
            () => {
              cb(currWindowSize);
            },
            // Modulo the DEBOUNCE_INTERVAL to prevent subscribers from not being called within
            // the DEBOUNCE_INTERVAL when we have a large number of subscribers
            (i % DEBOUNCE_INTERVAL) * increment
          )
        );
      });
    },
    // Adds a subscriber
    add: (cb: Callback) => {
      subscribers.push(cb);
    },
    // Removes a subscriber
    remove: (cb: Callback) => {
      const index = subscribers.indexOf(cb);
      if (index !== -1) {
        subscribers.splice(index, 1);
      }
    }
  };
};

const subscriberManager = SubscriberManager();

const getWindowSize = (): WindowSize => ({
  width: window.innerWidth,
  height: window.innerHeight
});

export default function WindowResizeContextProvider({ children }: ContextProps) {
  const [previousWindowSize, setPreviousWindowSize] = useState<WindowSize>(getWindowSize());

  useEffect(() => {
    const debouncedNotify = debounce(
      () => {
        const currWindowSize = getWindowSize();
        if (!deepEqual(currWindowSize, previousWindowSize)) {
          setPreviousWindowSize(currWindowSize);
          subscriberManager.notify();
        }
      },
      { waitMs: DEBOUNCE_INTERVAL }
    );
    window.addEventListener("resize", () => debouncedNotify.call());
    // cleanup
    return () => window.removeEventListener("resize", () => debouncedNotify.call());
  }, []);

  return (
    <WindowResizeContext.Provider
      value={{
        resizeNotifier: {
          subscribe: (cb) => {
            subscriberManager.add(cb);
          },
          unsubscribe: (cb) => {
            subscriberManager.remove(cb);
          }
        }
      }}
    >
      {children}
    </WindowResizeContext.Provider>
  );
}
