import { useContext, useEffect, useState } from "react";
import { LegacyCard, Pricing } from "@moovfinancial/cargo";
import { fetchFeePlans } from "@moovfinancial/common/api/fetchers/fetchFeePlans";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { OnboardingInviteContext } from "contexts/OnboardingInviteContext";

type FeePlan = components["schemas"]["FeePlan"];

type PricingSectionProps = {
  planIDs: string[];
};

export const PricingSection = ({ planIDs }: PricingSectionProps) => {
  const { invite } = useContext(OnboardingInviteContext);
  const [feePlans, setFeePlans] = useState<FeePlan[]>([]);

  useEffect(() => {
    if (!invite?.partner?.accountID) return;
    // For this page, we actually want use the account ID of the partner that created the onboarding link.
    // If the invitee created an account beforehand, this call will use them as facilitator and fail.
    fetchFeePlans({
      accountID: invite.partner.accountID,
      callerAccountID: invite.partner.accountID,
      planIDs
    })
      .then(setFeePlans)
      .catch((_e) => {});
  }, [planIDs, invite?.partner?.accountID]);

  return (
    <LegacyCard>
      <LegacyCard.Header>
        <LegacyCard.Title>Pricing disclosure</LegacyCard.Title>
      </LegacyCard.Header>
      <LegacyCard.Body>
        <Pricing collapsible expandOneAtATime responsive={false} feePlans={feePlans} />
      </LegacyCard.Body>
    </LegacyCard>
  );
};
