import clsx from "clsx";
import { HTMLAttributes, createContext, useContext, useId } from "react";
import { To, useNavigate } from "react-router-dom";
import { BaseButton } from "../../Actions";
import { IconClear } from "../../Icons";
import { Icon } from "../../Icons/Icon";
import styles from "./Drawer.module.scss";

const DrawerIdContext = createContext("");

export interface DrawerProps extends HTMLAttributes<HTMLDialogElement> {
  /**
   * The route that the close button should navigate to.
   */
  closeRoute: To;
  /**
   * If closeRoute is a relative to the path instead of the route
   */
  closeRouteRelativePath?: boolean;
  /**
   * Whether the drawer should be positioned relatively. Used for demo.
   */
  _relative?: boolean;
}

/**
 * Drawer is a panel that slides out from the edge of the screen. This component is the UI for the the drawer itself and does not encapsulate the interactive functionality. It is intended to be used inside DrawerLayout, which handles the layout logic.
 */
export const Drawer = ({
  closeRoute,
  closeRouteRelativePath,
  _relative,
  className,
  children
}: DrawerProps) => {
  const drawerId = useId();
  const navigate = useNavigate();

  return (
    <DrawerIdContext.Provider value={drawerId}>
      <dialog
        className={clsx(styles.drawer, className, _relative && styles.relative)}
        role="dialog"
        open
        aria-labelledby={drawerId}
      >
        <div className={styles.drawerClose}>
          <BaseButton
            onClick={() =>
              navigate(closeRoute, { relative: closeRouteRelativePath ? "path" : "route" })
            }
            aria-label="close"
            data-testid="closeDrawer"
          >
            <Icon iconComponent={IconClear} size="S" />
          </BaseButton>
        </div>
        <div className={styles.drawerChildren}>{children}</div>
      </dialog>
    </DrawerIdContext.Provider>
  );
};

/**
 * Drawer.Header
 * The header that holds a title or other important information.
 *
 * Note: Accepts either a title or children. If children are passed, the title prop is ignored.
 */
interface DrawerHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  className?: string;
  children?: React.ReactNode;
}

const DrawerHeader = ({
  title,
  className,
  children,
  ...rest
}:
  | (DrawerHeaderProps & { children: React.ReactNode })
  | (DrawerHeaderProps & { title: string })) => {
  const drawerId = useContext(DrawerIdContext);
  return (
    <header className={clsx(styles.drawerHeader, className)} {...rest}>
      {children ? (
        children
      ) : (
        <h2 className={styles.drawerHeaderHeading} id={drawerId}>
          {title}
        </h2>
      )}
    </header>
  );
};
Drawer.Header = DrawerHeader;

/**
 * Drawer.Body
 * The body content of the drawer that contains all other children of the component.
 *
 * Note: This content scrolls on overflow.
 */
interface DrawerBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}
const DrawerBody = ({ children, className, ...rest }: DrawerBodyProps) => {
  return (
    <div className={clsx(styles.drawerBody, className)} {...rest}>
      {children}
    </div>
  );
};
Drawer.Body = DrawerBody;

/**
 * Drawer.Footer
 * The sticky footer that holds drawer actions such as submit buttons.
 *
 * Note: This content does not scroll with other drawer content.
 */
interface DrawerFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}
const DrawerFooter = ({ children, className, ...rest }: DrawerFooterProps) => {
  return (
    <footer className={clsx(styles.drawerFooter, className)} {...rest}>
      {children}
    </footer>
  );
};
Drawer.Footer = DrawerFooter;
