import clsx from "clsx";
import { ReactNode, memo, useCallback, useState } from "react";
import { Theme } from "@moovfinancial/common/types/Theme";
import { Button } from "../Actions/Button";
import styles from "./ToggleComponents.module.scss";

export interface ToggleComponentsProps {
  primaryComponent: ReactNode;
  secondaryComponent: ReactNode;
  switchToPrimaryButtonLabel: string;
  switchToSecondaryButtonLabel: string;
  theme?: Theme<typeof styles>;
}

export const ToggleComponents = memo(function ToggleComponents({
  primaryComponent: primaryInput,
  secondaryComponent: secondaryInput,
  switchToPrimaryButtonLabel,
  switchToSecondaryButtonLabel,
  theme
}: ToggleComponentsProps) {
  const [displayPrimary, setDisplayPrimary] = useState(true);

  const switchInput = useCallback(() => setDisplayPrimary((prevValue) => !prevValue), []);

  return (
    <div className={clsx(styles.container, theme?.container)}>
      {displayPrimary ? primaryInput : secondaryInput}
      <Button
        buttonStyle="fill"
        buttonType="secondary"
        className={theme?.button}
        label={displayPrimary ? switchToSecondaryButtonLabel : switchToPrimaryButtonLabel}
        onClick={switchInput}
        type="button"
      />
    </div>
  );
});
