import { CapabilityName } from "api/v2";

export interface CapabilityOption {
  name: string;
  description: string;
  apiValue: CapabilityName;
  disabled?: boolean;
}

const CAPABILITY_OPTIONS: Record<
  Exclude<CapabilityName, "production-app" | "card-issuing" | "1099">,
  CapabilityOption
> = {
  transfers: {
    name: "Transfers",
    description: "Allows an account to receive payouts or pay with a card.",
    apiValue: "transfers",
    disabled: true
  },
  wallet: {
    name: "Wallet",
    description: "Allows an account to store funds.",
    apiValue: "wallet"
  },
  "send-funds": {
    name: "Send funds",
    description: "Allows an account to send a payout to another account.",
    apiValue: "send-funds"
  },
  "collect-funds": {
    name: "Collect funds",
    description: "Allows an account to charge or debit from another account.",
    apiValue: "collect-funds"
  }
};

export const businessCapabilityOptions: CapabilityOption[] = [
  CAPABILITY_OPTIONS.transfers,
  CAPABILITY_OPTIONS.wallet,
  CAPABILITY_OPTIONS["send-funds"],
  CAPABILITY_OPTIONS["collect-funds"]
];
export const individualCapabilityOptions: CapabilityOption[] = [
  CAPABILITY_OPTIONS.transfers,
  CAPABILITY_OPTIONS.wallet,
  CAPABILITY_OPTIONS["send-funds"]
];
