import clsx from "clsx";
import { MutableRefObject } from "react";
import { Sizes, StandardSizes } from "@moovfinancial/common/styling/StandardSizes";
import { shouldShowDebug } from "@moovfinancial/common/utils/env";
import styles from "./Loading.module.scss";

interface LoadingProps {
  /**
   * Whether the loading spinner should be centered absolutely (both vertically and horizontally)
   * Defaults to `false`
   */
  centered?: boolean;
  /**
   * Optional label for accessibility, it will not be visible on the screen.
   * Defaults to "Loading..."
   */
  label?: string;
  /**
   * Additional class names to apply to the root element
   */
  className?: string;
  /**
   * The size of the spinner in standard sizes ("S", "M", etc.) or REMs.
   */
  size?: Sizes;
  /**
   * A ref to the root element
   */
  rootRef?: MutableRefObject<HTMLDivElement | null>;
  /**
   * A debug label to display in the loading spinner in local development only
   */
  debug?: string;
}

const sizes = new StandardSizes({ XXS: 0.75, XS: 1, S: 1.5, M: 2, L: 2.5, XL: 3, XXL: 3.5 }, "XS");

export const Loading = ({ centered, label, className, rootRef, size, debug }: LoadingProps) => {
  const sizeStyle = sizes.getStyle(size);
  return (
    <div
      ref={rootRef}
      className={clsx(styles.Loading, centered && styles.centered, className)}
      data-testid="Loading"
    >
      <div className={styles.spinner} style={sizeStyle} role="status">
        <span className={styles.sr}>{label || "Loading..."}</span>
      </div>
      {debug && shouldShowDebug() && (
        <>
          <div>Only visible in local development:</div>
          <p>{debug}</p>
        </>
      )}
    </div>
  );
};
