import clsx from "clsx";
import { ReactNode } from "react";
import { IconErrorOutlined } from "../Icons";
import { Icon } from "../Icons/Icon";
import styles from "./ValidationMessage.module.scss";

export interface ValidationMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The error message to display.
   */
  message?: ReactNode;
  /**
   * Whether to hide the error icon. Defaults to false.
   */
  hideIcon?: boolean;
  /**
   * Optionally, pass children to display instead of the message.
   */
  children?: ReactNode;
  /**
   * severity. Error = RED, Warning = ORANGE
   */
  severity?: "error" | "warning";
  /**
   * Remove all margins and paddings so the parent can position this element
   */
  noMargins?: boolean;
}

export const ValidationMessage = ({
  message,
  hideIcon = false,
  className,
  children,
  severity = "error",
  noMargins = false,
  ...rest
}: ValidationMessageProps) =>
  (message || children) && (
    <div
      data-testid="ValidationMessage"
      className={clsx(
        styles.ValidationMessage,
        styles[severity],
        className,
        noMargins && styles.noMargins
      )}
      {...rest}
    >
      {!hideIcon && <Icon iconComponent={IconErrorOutlined} />}
      {message ?? children}
    </div>
  );
