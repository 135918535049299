import { useContext } from "react";
import { Alert } from "@moovfinancial/cargo";
import { AccountTypeSelector } from "./components/AccountTypeSelector";
import { CapabilitiesSelector } from "./components/CapabilitiesSelector";
import { Step } from "components/dialog/StepModal";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import styles from "./CreateNewAccount.module.scss";

export const CreateNewAccount = () => {
  const { facilitatorSubmitted, mode, productionAppEnabled } = useContext(FacilitatorContext);

  return (
    <Step>
      {mode === "production" && facilitatorSubmitted && !productionAppEnabled && (
        <Alert className={styles.alert} type="info">
          While your account is under review, you won’t be able to verify accounts.
        </Alert>
      )}
      <AccountTypeSelector />
      <CapabilitiesSelector />
    </Step>
  );
};
