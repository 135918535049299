import { LiveMoovAPIClient } from "../api";
import { PaginationCursor } from "../common.model";
import { ErrorResponse } from "../request";
import {
  AbbreviatedVerification,
  AdminVerification,
  EnrichedVerificationAccount,
  FormattedVerification,
  MiddeskTask,
  OneOffKYC,
  OneOffRepVerification,
  OneOffWatchlist,
  PatchVerification,
  RepVerificationDecisionBody,
  Verification,
  VerificationFilters
} from "./verification.model";

export interface VerificationAPI {
  /** Gets verification for an account */
  get(
    facilitatorID: string,
    connectedAccountID: string
  ): Promise<[Verification | undefined, ErrorResponse | undefined]>;
  /** Lists all verifications for a facilitator. Moov admin only */
  adminList(
    filter?: VerificationFilters,
    cursor?: PaginationCursor
  ): Promise<[AbbreviatedVerification[] | undefined, ErrorResponse | undefined]>;
  /** Gets enhanced verification data for an account. Moov admin only */
  adminGet(
    accountID: string
  ): Promise<[FormattedVerification | undefined, ErrorResponse | undefined]>;
  /** Updates verification for an account. Moov admin only */
  adminUpdate(
    facilitatorID: string,
    connectedAccountID: string,
    verification: PatchVerification
  ): Promise<[Verification | undefined, ErrorResponse | undefined]>;
  /** Gets one-off representative verification */
  adminGetRepVerification(
    facilitatorID: string,
    accountID: string,
    representativeID: string
  ): Promise<[OneOffRepVerification | undefined, ErrorResponse | undefined]>;
  /** Updates internal decision on one-off representative verification */
  adminUpdateRepVerification(
    facilitatorID: string,
    accountID: string,
    representativeID: string,
    decision: RepVerificationDecisionBody
  ): Promise<[undefined, ErrorResponse | undefined]>;
  /** Processes one-off representative kyc */
  processRepresentativeKYC(
    facilitatorID: string,
    accountID: string,
    representativeID: string
  ): Promise<[OneOffKYC | undefined, ErrorResponse | undefined]>;
  /** Processes one-off representative watchlist */
  processRepresentativeWatchlist(
    facilitatorID: string,
    accountID: string,
    representativeID: string
  ): Promise<[OneOffWatchlist | undefined, ErrorResponse | undefined]>;
}

export class LiveVerificationAPI implements VerificationAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async get(
    facilitatorID: string,
    connectedAccountID: string
  ): Promise<[Verification | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<Verification>(
      `/accounts/${connectedAccountID}/verification`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminList(
    filter?: VerificationFilters,
    cursor?: PaginationCursor
  ): Promise<[AbbreviatedVerification[] | undefined, ErrorResponse | undefined]> {
    let query = filter || cursor ? {} : undefined;
    if (filter) query = { ...query, ...filter };
    if (cursor) query = { ...query, ...cursor };
    const [result, err] = await this._client.request<{
      accounts: { [key: string]: EnrichedVerificationAccount };
      verifications: Verification[];
    }>("/dashboard/admin/verifications", {
      query
    });
    if (err) return [undefined, err];
    if (result === undefined) return [result, undefined];

    // Translate from backend matches to an match enriched with account data
    const enrichedVerifications: AbbreviatedVerification[] = result.verifications.map(
      (v: Verification) => ({
        ...v,
        connectedAccount: result.accounts[v.accountID]
      })
    );
    return [enrichedVerifications, undefined];
  }

  async adminGet(
    connectedAccountID: string
  ): Promise<[FormattedVerification | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<{
      accounts: { [key: string]: EnrichedVerificationAccount };
      verification: AdminVerification;
    }>(`/dashboard/admin/accounts/${connectedAccountID}/verification`);
    if (err) return [undefined, err];
    if (result === undefined) return [result, undefined];

    const formatMiddeskSteps = (tasks: MiddeskTask[]) => {
      const middeskTasks: { [key: string]: MiddeskTask[] } = {};
      tasks.forEach((task) => {
        if (!middeskTasks[task.category]) {
          middeskTasks[task.category] = [task];
        } else {
          middeskTasks[task.category].push(task);
        }
      });
      return middeskTasks;
    };

    // Translate from backend matches to an match enriched with account data
    let enrichedVerification: FormattedVerification;

    if (result.verification.business) {
      enrichedVerification = {
        ...result.verification,
        business: {
          ...result.verification.business,
          middeskTasks: formatMiddeskSteps(result.verification.business?.middeskTasks ?? [])
        },
        connectedAccount: result.accounts[result.verification.accountID]
      };
    } else {
      const individualVerification: Omit<AdminVerification, "business"> = result.verification;

      enrichedVerification = {
        ...individualVerification,
        connectedAccount: result.accounts[result.verification.accountID]
      };
    }

    return [enrichedVerification, undefined];
  }

  async adminUpdate(
    facilitatorID: string,
    connectedAccountID: string,
    verification: PatchVerification
  ): Promise<[Verification | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<Verification>(
      `/admin/accounts/${connectedAccountID}/verification`,
      {
        method: "PATCH",
        json: verification,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminGetRepVerification(
    facilitatorID: string,
    accountID: string,
    representativeID: string
  ): Promise<[OneOffRepVerification | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<OneOffRepVerification>(
      `/admin/accounts/${accountID}/representatives/${representativeID}/manual-requests`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminUpdateRepVerification(
    facilitatorID: string,
    accountID: string,
    representativeID: string,
    decision: RepVerificationDecisionBody
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/admin/accounts/${accountID}/representatives/${representativeID}/manual-verification`,
      {
        method: "PUT",
        json: decision,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async processRepresentativeKYC(
    facilitatorID: string,
    accountID: string,
    representativeID: string
  ): Promise<[OneOffKYC | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<OneOffKYC>(
      `/admin/accounts/${accountID}/representatives/${representativeID}/kyc`,
      {
        method: "POST",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async processRepresentativeWatchlist(
    facilitatorID: string,
    accountID: string,
    representativeID: string
  ): Promise<[OneOffWatchlist | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<OneOffWatchlist>(
      `/admin/accounts/${accountID}/representatives/${representativeID}/watchlist`,
      {
        method: "POST",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
