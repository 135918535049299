"use client";

import clsx from "clsx";
import SvgrReactComponent from "vite-plugin-svgr/*.svg?react";
import { CargoElement, CargoElementProps } from "../../CargoElement";
import { CurrentStep } from "./CurrentStep";
import { Step as StepComponent } from "./Step";
import { StepProgress } from "./StepProgress";
import {
  NavigationStep,
  StepOrientation,
  Step as StepType,
  areAllStepsNavigable
} from "./step.model";
import styles from "./Stepper.module.scss";

export interface StepperProps extends CargoElementProps {
  /**
   * The orientation of the stepper. Defaults to "vertical".
   * Horizontal orientation cannot support more than one level of steps.
   */
  orientation?: StepOrientation;
  /**
   * The icon to override the active step icon. This will apply to all steps.
   * For more granular control, use the `activeIcon` prop on the `Step` component.
   */
  activeIcon?: typeof SvgrReactComponent;
  /**
   * The steps to render in the stepper.
   */
  steps: StepType[];
}

export const Stepper = ({
  className,
  orientation = "vertical",
  activeIcon,
  steps,
  ...props
}: StepperProps) => {
  const isNavigationMode = areAllStepsNavigable(steps);

  return (
    <CargoElement className={clsx(styles.stepper, styles[orientation], className)} {...props}>
      {steps.map((step, index) => {
        return (
          <>
            <StepComponent
              activeIcon={activeIcon || step.activeIcon}
              isNavigationMode={isNavigationMode}
              key={index}
              orientation={orientation}
              step={step}
            />
            {step.isActive && !isNavigationMode && (
              <StepProgress
                currentStep={index + 1}
                stepName={step.Component}
                totalSteps={steps.length}
              />
            )}
          </>
        );
      })}
      {isNavigationMode && <CurrentStep steps={steps as NavigationStep[]} />}
    </CargoElement>
  );
};
