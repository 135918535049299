import { LiveMoovAPIClient } from "./api";
import { ErrorResponse } from "./request";

export interface AvatarsAPI {
  /** Gets the user's avatar as an object url */
  getUser(
    facilitatorID: string,
    avatarID: string // Account id, user id, or representative id
  ): Promise<[Blob | undefined, ErrorResponse | undefined]>;
  /** Gets the bank's avatar as an object url */
  getBank(
    facilitatorID: string,
    avatarID: string // Bank routing number
  ): Promise<[Blob | undefined, ErrorResponse | undefined]>;
}

export class LiveAvatarsAPI implements AvatarsAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async getUser(
    facilitatorID: string,
    avatarID: string
  ): Promise<[Blob | undefined, ErrorResponse | undefined]> {
    try {
      const [response, error] = await this._client.request<Blob>(
        `/dashboard/avatars/user/${avatarID}`,
        {
          xAccountID: facilitatorID
        }
      );
      if (error) return [undefined, error];
      const avatarBlob = response;
      return [avatarBlob, undefined];
    } catch {
      return [undefined, undefined];
    }
  }

  async getBank(
    facilitatorID: string,
    avatarID: string
  ): Promise<[Blob | undefined, ErrorResponse | undefined]> {
    try {
      const [response, error] = await this._client.request<Blob>(
        `/dashboard/avatars/bank/${avatarID}`,
        {
          xAccountID: facilitatorID
        }
      );
      if (error) return [undefined, error];
      return [response, undefined];
    } catch {
      return [undefined, undefined];
    }
  }
}
