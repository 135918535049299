import FingerprintJS from "@fingerprintjs/fingerprintjs";

let visitorID: string | null = null;

export function getFingerprint(): Promise<string> {
  if (visitorID) {
    return Promise.resolve(visitorID);
  } else {
    return FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => (visitorID = result.visitorId));
  }
}
