import { components } from "../types/__generated-types__/api";

export type Industry = components["schemas"]["Industry"];
export type IndustryCode = components["schemas"]["IndustryCodes"];

export function getCodesByTitle(title: string, industries?: Industry[]): Industry | undefined {
  const arr = industries && industries.length ? industries : backupIndustryCodes;
  return arr.find((codes) => codes.title === title);
}

export function getTitleByCodes(
  industryCode: IndustryCode,
  industryCodes: Industry[] = []
): string | undefined {
  const arr = industryCodes.length ? industryCodes : backupIndustryCodes;
  const industryObject = arr.find(
    (codes) =>
      industryCode.mcc === codes.mcc &&
      industryCode.sic === codes.sic &&
      industryCode.naics === codes.naics
  );

  if (industryObject) {
    return industryObject.title;
  }
}

export function isFinancialInstitution(industryCodes?: IndustryCode): boolean {
  if (!industryCodes) return false;
  const naicsCodes = new Set([
    "5221",
    "522110",
    "522130",
    "522180",
    "5222",
    "522210",
    "522220",
    "5222291",
    "522292"
  ]);
  const hasMCC = industryCodes?.mcc === "6012";
  const sicNumber = Number(industryCodes?.sic);
  const hasSIC = sicNumber >= 6011 && sicNumber <= 6162;
  const hasNAICS = industryCodes?.naics ? naicsCodes.has(industryCodes.naics) : false;
  return !!(hasMCC || hasSIC || hasNAICS);
}

export const backupIndustryCodes: Industry[] = [
  {
    title: "AC, Refrigeration Repair",
    naics: "811412",
    sic: "7623",
    mcc: "7623"
  },
  {
    title: "Accounting, Bookkeeping",
    naics: "541211",
    sic: "8721",
    mcc: "8931"
  },
  {
    title: "Advertising Services",
    naics: "541810",
    sic: "7311",
    mcc: "7311"
  },
  {
    title: "Agricultural Cooperative",
    naics: "813910",
    sic: "8699",
    mcc: "0763"
  },
  {
    title: "Airlines, Air Carriers",
    naics: "481111",
    sic: "4512",
    mcc: "4511"
  },
  {
    title: "Airports, Flying Fields",
    naics: "488119",
    sic: "4581",
    mcc: "4582"
  },
  { title: "Ambulance Services", naics: "621910", sic: "4119", mcc: "4119" },
  {
    title: "Amusement Parks, Carnivals",
    naics: "713110",
    sic: "7996",
    mcc: "7996"
  },
  {
    title: "Antique Reproductions",
    naics: "453310",
    sic: "5932",
    mcc: "5937"
  },
  { title: "Antiques", naics: "453310", sic: "5932", mcc: "5932" },
  { title: "Aquariums", naics: "712130", sic: "8422", mcc: "7998" },
  {
    title: "Architectural, Surveying",
    naics: "541310",
    sic: "8712",
    mcc: "8911"
  },
  {
    title: "Art Dealers, Galleries",
    naics: "453920",
    sic: "5999",
    mcc: "5971"
  },
  {
    title: "Artists Supply, Crafts",
    naics: "451120",
    sic: "5092",
    mcc: "5970"
  },
  {
    title: "Auto and Home Supplies",
    naics: "441310",
    sic: "5531",
    mcc: "5531"
  },
  { title: "Auto Body Repair", naics: "811121", sic: "7532", mcc: "7531" },
  { title: "Auto Paint", naics: "811121", sic: "7532", mcc: "7535" },
  { title: "Auto Service", naics: "811111", sic: "7538", mcc: "7538" },
  {
    title: "Automated Cash Disburse",
    naics: "522320",
    sic: "6099",
    mcc: "6011"
  },
  {
    title: "Automated Fuel Dispensers",
    naics: "454310",
    sic: "5983",
    mcc: "5542"
  },
  {
    title: "Automobile Associations",
    naics: "561599",
    sic: "8699",
    mcc: "8675"
  },
  {
    title: "Automobile Dealers (New \u0026 Used)",
    naics: "441110",
    sic: "5511",
    mcc: "5511"
  },
  {
    title: "Automobile Dealers (Used Only)",
    naics: "441120",
    sic: "5521",
    mcc: "5521"
  },
  {
    title: "Automotive Parts, Accessories",
    naics: "441310",
    sic: "5013",
    mcc: "5533"
  },
  { title: "Automotive Tire", naics: "441320", sic: "5014", mcc: "5532" },
  { title: "Bail, Bond Payments", naics: "812990", sic: "9223", mcc: "9223" },
  { title: "Bakeries", naics: "311811", sic: "5461", mcc: "5462" },
  { title: "Bands, Orchestras", naics: "711130", sic: "7929", mcc: "7929" },
  { title: "Barber, Beauty", naics: "812112", sic: "7231", mcc: "7230" },
  {
    title: "Betting, Casino Gambling",
    naics: "713210",
    sic: "7999",
    mcc: "7995"
  },
  { title: "Bicycle", naics: "451110", sic: "5091", mcc: "5940" },
  {
    title: "Billiard, Pool Establishments",
    naics: "713990",
    sic: "7999",
    mcc: "7932"
  },
  { title: "Boat Dealers", naics: "441222", sic: "5551", mcc: "5551" },
  {
    title: "Boat Rentals, Leases",
    naics: "532284",
    sic: "7999",
    mcc: "4457"
  },
  { title: "Books", naics: "451211", sic: "5942", mcc: "5942" },
  {
    title: "Books, Periodicals, Newspapers",
    naics: "424920",
    sic: "5192",
    mcc: "5192"
  },
  { title: "Bowling Alleys", naics: "713950", sic: "7933", mcc: "7933" },
  { title: "Bus Lines", naics: "485113", sic: "4131", mcc: "4131" },
  {
    title: "Business, Secretarial Schools",
    naics: "611410",
    sic: "8244",
    mcc: "8244"
  },
  {
    title: "Buying, Shopping Services",
    naics: "812990",
    sic: "7299",
    mcc: "7278"
  },
  {
    title: "Cable, Satellite, Pay Television and Radio",
    naics: "515210",
    sic: "4899",
    mcc: "4899"
  },
  {
    title: "Camera, Photographic Supply",
    naics: "443142",
    sic: "5946",
    mcc: "5946"
  },
  {
    title: "Candy, Nut, Confectionery",
    naics: "445292",
    sic: "5441",
    mcc: "5441"
  },
  { title: "Car Rental Agencies", naics: "532111", sic: "7514", mcc: "7512" },
  { title: "Car Washes", naics: "811192", sic: "7542", mcc: "7542" },
  {
    title: "Carpentry Contractors",
    naics: "238350",
    sic: "1751",
    mcc: "1750"
  },
  {
    title: "Carpet, Upholstery Cleaning",
    naics: "561740",
    sic: "7217",
    mcc: "7217"
  },
  { title: "Caterers", naics: "722320", sic: "5812", mcc: "5811" },
  {
    title: "Charitable, Social Service Organizations",
    naics: "813410",
    sic: "8641",
    mcc: "8398"
  },
  { title: "Chemical Products ", naics: "424690", sic: "5169", mcc: "5169" },
  { title: "Child Care Services", naics: "624410", sic: "8351", mcc: "8351" },
  {
    title: "Childrens, Infants Wear",
    naics: "448130",
    sic: "5641",
    mcc: "5641"
  },
  {
    title: "Chiropodists, Podiatrists",
    naics: "621399",
    sic: "8049",
    mcc: "8049"
  },
  { title: "Chiropractors", naics: "621310", sic: "8041", mcc: "8041" },
  { title: "Cigars", naics: "453991", sic: "5993", mcc: "5993" },
  {
    title: "Civic, Social, Fraternal Associations",
    naics: "813410",
    sic: "8641",
    mcc: "8641"
  },
  {
    title: "Cleaning, Maintenance",
    naics: "561720",
    sic: "7349",
    mcc: "7349"
  },
  { title: "Clothing Rental", naics: "532281", sic: "7299", mcc: "7296" },
  {
    title: "Colleges, Universities",
    naics: "611310",
    sic: "8221",
    mcc: "8220"
  },
  {
    title: "Commercial Equipment ",
    naics: "423440",
    sic: "5046",
    mcc: "5046"
  },
  { title: "Commercial Footwear", naics: "424340", sic: "5139", mcc: "5139" },
  {
    title: "Commercial Photography, Art, Graphics",
    naics: "541922",
    sic: "7335",
    mcc: "7333"
  },
  {
    title: "Commuter Transport, Ferries",
    naics: "483212",
    sic: "4111",
    mcc: "4111"
  },
  { title: "Computer Networks", naics: "518210", sic: "7374", mcc: "4816" },
  {
    title: "Computer Programming",
    naics: "511210",
    sic: "7372",
    mcc: "7372"
  },
  { title: "Computer Repair", naics: "541519", sic: "7379", mcc: "7379" },
  { title: "Computer Software ", naics: "443120", sic: "5734", mcc: "5734" },
  {
    title: "Computers, Peripherals, Software",
    naics: "423430",
    sic: "5045",
    mcc: "5045"
  },
  { title: "Concrete Work", naics: "238110", sic: "1771", mcc: "1771" },
  {
    title: "Construction Materials ",
    naics: "423390",
    sic: "5039",
    mcc: "5039"
  },
  {
    title: "Consulting, Public Relations",
    naics: "541820",
    sic: "8743",
    mcc: "7392"
  },
  {
    title: "Convenience Stores, Specialty Markets",
    naics: "445299",
    sic: "5499",
    mcc: "5499"
  },
  {
    title: "Correspondence Schools",
    naics: "611310",
    sic: "8221",
    mcc: "8241"
  },
  { title: "Cosmetics", naics: "446120", sic: "5999", mcc: "5977" },
  { title: "Counseling Services", naics: "624190", sic: "8322", mcc: "7277" },
  { title: "Courier Services", naics: "492110", sic: "4215", mcc: "4215" },
  {
    title: "Court Costs, Alimony, Child Support ",
    naics: "922110",
    sic: "9211",
    mcc: "9211"
  },
  {
    title: "Credit Reporting Agencies",
    naics: "561450",
    sic: "7323",
    mcc: "7321"
  },
  { title: "Cruise Lines", naics: "483112", sic: "4481", mcc: "4411" },
  { title: "Dairy Products", naics: "445299", sic: "5451", mcc: "5451" },
  {
    title: "Dance Hall, Studios, Schools",
    naics: "611610",
    sic: "7911",
    mcc: "7911"
  },
  {
    title: "Dating, Escort Services",
    naics: "812990",
    sic: "7299",
    mcc: "7273"
  },
  {
    title: "Dentists, Orthodontists",
    naics: "621210",
    sic: "8021",
    mcc: "8021"
  },
  { title: "Department Stores", naics: "452210", sic: "5311", mcc: "5311" },
  { title: "Detective Agencies", naics: "561611", sic: "7381", mcc: "7393" },
  {
    title: "Digital Goods - Applications",
    naics: "454110",
    sic: "5961",
    mcc: "5817"
  },
  {
    title: "Digital Goods – Games",
    naics: "454110",
    sic: "5961",
    mcc: "5816"
  },
  {
    title: "Digital Goods – Large Digital Goods Merchant",
    naics: "454110",
    sic: "5961",
    mcc: "5818"
  },
  {
    title: "Digital Goods Media – Books, Movies, Music",
    naics: "454110",
    sic: "5961",
    mcc: "5815"
  },
  {
    title: "Direct Marketing - Catalog Merchant",
    naics: "454390",
    sic: "5963",
    mcc: "5964"
  },
  {
    title: "Direct Marketing - Catalog, Retail",
    naics: "454390",
    sic: "5963",
    mcc: "5965"
  },
  {
    title: "Direct Marketing - Inbound Telemarketing",
    naics: "454390",
    sic: "5963",
    mcc: "5967"
  },
  {
    title: "Direct Marketing - Insurance Services",
    naics: "454390",
    sic: "5963",
    mcc: "5960"
  },
  {
    title: "Direct Marketing - Other",
    naics: "454390",
    sic: "5963",
    mcc: "5969"
  },
  {
    title: "Direct Marketing - Outbound Telemarketing",
    naics: "454390",
    sic: "5963",
    mcc: "5966"
  },
  {
    title: "Direct Marketing - Subscription",
    naics: "454390",
    sic: "5963",
    mcc: "5968"
  },
  {
    title: "Direct Marketing - Travel",
    naics: "454390",
    sic: "5962",
    mcc: "5962"
  },
  { title: "Discount Stores", naics: "452210", sic: "5311", mcc: "5310" },
  { title: "Doctors", naics: "621112", sic: "8011", mcc: "8011" },
  { title: "Door-To-Door Sales", naics: "454390", sic: "5963", mcc: "5963" },
  {
    title: "Drapery, Window Covering, Upholstery",
    naics: "442291",
    sic: "5714",
    mcc: "5714"
  },
  { title: "Drinking Places", naics: "722410", sic: "5813", mcc: "5813" },
  {
    title: "Drug Stores, Pharmacies",
    naics: "446110",
    sic: "5912",
    mcc: "5912"
  },
  {
    title: "Drugs, Drug Proprietaries",
    naics: "424210",
    sic: "5122",
    mcc: "5122"
  },
  { title: "Dry Cleaners", naics: "812320", sic: "7216", mcc: "7216" },
  { title: "Durable Goods ", naics: "423990", sic: "5099", mcc: "5099" },
  { title: "Duty Free", naics: "453998", sic: "5999", mcc: "5309" },
  {
    title: "Eating Places, Restaurants",
    naics: "722110",
    sic: "5812",
    mcc: "5812"
  },
  {
    title: "Educational Services",
    naics: "611710",
    sic: "8299",
    mcc: "8299"
  },
  { title: "Electric Razor", naics: "443142", sic: "5731", mcc: "5997" },
  {
    title: "Electrical Contractors",
    naics: "238210",
    sic: "1731",
    mcc: "1731"
  },
  { title: "Electrical Parts ", naics: "423610", sic: "5065", mcc: "5065" },
  { title: "Electronics", naics: "443142", sic: "5731", mcc: "5732" },
  { title: "Electronics Repair", naics: "811211", sic: "7622", mcc: "7622" },
  {
    title: "Elementary, Secondary Schools",
    naics: "611110",
    sic: "8211",
    mcc: "8211"
  },
  {
    title: "Employment, Temp Agencies",
    naics: "541612",
    sic: "7361",
    mcc: "7361"
  },
  { title: "Equipment Rental", naics: "532490", sic: "7359", mcc: "7394" },
  {
    title: "Exterminating Services",
    naics: "561710",
    sic: "7342",
    mcc: "7342"
  },
  { title: "Family Clothing", naics: "448140", sic: "5651", mcc: "5651" },
  {
    title: "Fast Food Restaurants",
    naics: "722513",
    sic: "5812",
    mcc: "5814"
  },
  {
    title: "Financial Institutions",
    naics: "522110",
    sic: "6021",
    mcc: "6012"
  },
  {
    title: "Fines - Government Administrative Entities",
    naics: "922190",
    sic: "9229",
    mcc: "9222"
  },
  { title: "Fireplace", naics: "423220", sic: "5023", mcc: "5718" },
  { title: "Floor Covering", naics: "442210", sic: "5713", mcc: "5713" },
  { title: "Florists", naics: "453110", sic: "5992", mcc: "5992" },
  {
    title: "Florists Supplies, Nursery Stock, Flowers",
    naics: "424930",
    sic: "5193",
    mcc: "5193"
  },
  { title: "Footwear", naics: "448210", sic: "5661", mcc: "5661" },
  {
    title: "Freezer, Locker Meat Provisioners",
    naics: "454390",
    sic: "5421",
    mcc: "5422"
  },
  {
    title: "Freight Carriers, Trucking ",
    naics: "484110",
    sic: "4214",
    mcc: "4214"
  },
  { title: "Fuel Dealers ", naics: "454311", sic: "5983", mcc: "5983" },
  {
    title: "Funeral Services, Crematories",
    naics: "812210",
    sic: "7261",
    mcc: "7261"
  },
  { title: "Furniture", naics: "442299", sic: "5712", mcc: "5712" },
  {
    title: "Furniture Repair, Refinishing",
    naics: "811420",
    sic: "7641",
    mcc: "7641"
  },
  { title: "Furriers, Fur", naics: "314999", sic: "2371", mcc: "5681" },
  { title: "General Contractors", naics: "236115", sic: "1521", mcc: "1520" },
  {
    title: "Gift, Card, Novelty, Souvenir Shops",
    naics: "453220",
    sic: "5947",
    mcc: "5947"
  },
  {
    title: "Glass, Paint, Wallpaper",
    naics: "444120",
    sic: "5231",
    mcc: "5231"
  },
  { title: "Glassware, Crystal", naics: "442299", sic: "5719", mcc: "5950" },
  {
    title: "Government Services ",
    naics: "921190",
    sic: "9199",
    mcc: "9399"
  },
  {
    title: "Grocery Stores, Supermarkets",
    naics: "445110",
    sic: "5411",
    mcc: "5411"
  },
  { title: "Hardware", naics: "444130", sic: "5251", mcc: "5251" },
  { title: "Hardware, Equipment", naics: "423710", sic: "5072", mcc: "5072" },
  { title: "Health, Beauty Spas", naics: "812199", sic: "7299", mcc: "7298" },
  { title: "Hearing Aids ", naics: "423450", sic: "5047", mcc: "5975" },
  {
    title: "Heating, Plumbing, AC",
    naics: "238220",
    sic: "1711",
    mcc: "1711"
  },
  { title: "Hobby, Toy, Games", naics: "451120", sic: "5945", mcc: "5945" },
  {
    title: "Home Supply Warehouse",
    naics: "442299",
    sic: "5719",
    mcc: "5200"
  },
  { title: "Hospitals", naics: "622110", sic: "8062", mcc: "8062" },
  {
    title: "Hotels, Motels, Resorts",
    naics: "721110",
    sic: "7011",
    mcc: "7011"
  },
  { title: "Household Appliance", naics: "443141", sic: "5722", mcc: "5722" },
  {
    title: "Industrial Supplies ",
    naics: "423840",
    sic: "5085",
    mcc: "5085"
  },
  {
    title: "Information Retrieval Services",
    naics: "518111",
    sic: "7375",
    mcc: "7375"
  },
  {
    title: "Insurance Underwriting, Premiums",
    naics: "524210",
    sic: "6411",
    mcc: "6300"
  },
  {
    title: "Jewelry, Watches, Clocks, Silverware",
    naics: "448310",
    sic: "5944",
    mcc: "5944"
  },
  {
    title: "Landscaping Services",
    naics: "561730",
    sic: "0782",
    mcc: "0780"
  },
  { title: "Laundries", naics: "812320", sic: "7211", mcc: "7211" },
  {
    title: "Laundry, Cleaning Services",
    naics: "812320",
    sic: "7219",
    mcc: "7210"
  },
  {
    title: "Legal Services, Attorneys",
    naics: "541110",
    sic: "8111",
    mcc: "8111"
  },
  {
    title: "Luggage, Leather Goods",
    naics: "448320",
    sic: "5948",
    mcc: "5948"
  },
  {
    title: "Lumber, Building Materials",
    naics: "444110",
    sic: "5211",
    mcc: "5211"
  },
  {
    title: "Manual Cash Disburse",
    naics: "522320",
    sic: "6099",
    mcc: "6010"
  },
  { title: "Marinas", naics: "713930", sic: "4493", mcc: "4468" },
  {
    title: "Masonry, Stonework, Plaster",
    naics: "238140",
    sic: "1741",
    mcc: "1740"
  },
  { title: "Massage Parlors", naics: "812199", sic: "7299", mcc: "7297" },
  { title: "Medical Services", naics: "621999", sic: "8099", mcc: "8099" },
  {
    title: "Medical, Dental Labs",
    naics: "621511",
    sic: "8071",
    mcc: "8071"
  },
  {
    title: "Medical, Dental, Ophthalmic, Hospital Equipment",
    naics: "423450",
    sic: "5047",
    mcc: "5047"
  },
  { title: "Membership Clubs", naics: "713910", sic: "7997", mcc: "7997" },
  {
    title: "Membership Organizations",
    naics: "813990",
    sic: "8699",
    mcc: "8699"
  },
  {
    title: "Mens and Boys Clothing, Accessories",
    naics: "448110",
    sic: "5611",
    mcc: "5611"
  },
  {
    title: "Mens, Womens Clothing",
    naics: "448140",
    sic: "5651",
    mcc: "5691"
  },
  {
    title: "Metal Service Centers",
    naics: "423510",
    sic: "5051",
    mcc: "5051"
  },
  {
    title: "Miscellaneous Apparel, Accessories",
    naics: "448150",
    sic: "5699",
    mcc: "5699"
  },
  {
    title: "Miscellaneous Auto Dealers",
    naics: "441229",
    sic: "5599",
    mcc: "5599"
  },
  {
    title: "Miscellaneous Business Services",
    naics: "561499",
    sic: "7389",
    mcc: "7399"
  },
  {
    title: "Miscellaneous General Merchandise",
    naics: "452990",
    sic: "5399",
    mcc: "5399"
  },
  {
    title: "Miscellaneous General Services",
    naics: "812990",
    sic: "7299",
    mcc: "7299"
  },
  {
    title: "Miscellaneous Home Furnishing Specialty",
    naics: "442299",
    sic: "5719",
    mcc: "5719"
  },
  {
    title: "Miscellaneous Publishing, Printing",
    naics: "511199",
    sic: "2741",
    mcc: "2741"
  },
  {
    title: "Miscellaneous Recreation Services",
    naics: "713990",
    sic: "7999",
    mcc: "7999"
  },
  {
    title: "Miscellaneous Repair",
    naics: "811490",
    sic: "7699",
    mcc: "7699"
  },
  {
    title: "Miscellaneous Specialty Retail",
    naics: "453998",
    sic: "5999",
    mcc: "5999"
  },
  { title: "Mobile Home Dealers", naics: "453930", sic: "5271", mcc: "5271" },
  {
    title: "Motion Picture Theaters",
    naics: "512131",
    sic: "7832",
    mcc: "7832"
  },
  { title: "Motor Homes Dealers", naics: "453930", sic: "5271", mcc: "5592" },
  {
    title: "Motor Vehicle Supplies",
    naics: "423120",
    sic: "5013",
    mcc: "5013"
  },
  { title: "Motorcycle Dealers", naics: "441210", sic: "5561", mcc: "5561" },
  {
    title: "Musical Instruments, Pianos, Sheet Music",
    naics: "451140",
    sic: "5736",
    mcc: "5733"
  },
  {
    title: "News Dealers, Newsstands",
    naics: "451212",
    sic: "5994",
    mcc: "5994"
  },
  {
    title: "Non-FI, Money Orders",
    naics: "522390",
    sic: "6099",
    mcc: "6051"
  },
  {
    title: "Non-FI, Stored Value Card Purchase",
    naics: "522210",
    sic: "6021",
    mcc: "6540"
  },
  { title: "Nondurable Goods ", naics: "424990", sic: "5199", mcc: "5199" },
  {
    title: "Nurseries, Lawn, Garden Supply",
    naics: "444220",
    sic: "5261",
    mcc: "5261"
  },
  {
    title: "Nursing, Personal Care",
    naics: "621610",
    sic: "8082",
    mcc: "8050"
  },
  {
    title: "Office, Commercial Furniture",
    naics: "423210",
    sic: "5021",
    mcc: "5021"
  },
  {
    title: "Opticians, Eyeglasses",
    naics: "621320",
    sic: "8042",
    mcc: "8043"
  },
  {
    title: "Optometrists, Ophthalmologist",
    naics: "621320",
    sic: "8042",
    mcc: "8042"
  },
  {
    title: "Orthopedic Goods, Prosthetic Devices",
    naics: "446199",
    sic: "3842",
    mcc: "5976"
  },
  { title: "Osteopaths", naics: "621111", sic: "8031", mcc: "8031" },
  {
    title: "Package - Beer, Wine, Liquor",
    naics: "445310",
    sic: "5921",
    mcc: "5921"
  },
  { title: "Paints, Varnishes", naics: "424950", sic: "5198", mcc: "5198" },
  {
    title: "Parking Lots, Garages",
    naics: "812930",
    sic: "7521",
    mcc: "7523"
  },
  { title: "Passenger Railways", naics: "482111", sic: "4011", mcc: "4112" },
  { title: "Pawn Shops", naics: "522298", sic: "6141", mcc: "5933" },
  { title: "Pet Supplies, Food", naics: "453910", sic: "5149", mcc: "5995" },
  { title: "Petroleum Products", naics: "424720", sic: "5172", mcc: "5172" },
  { title: "Photo Developing", naics: "812921", sic: "7384", mcc: "7395" },
  {
    title: "Photographic Studios",
    naics: "541921",
    sic: "7221",
    mcc: "7221"
  },
  {
    title: "Photographic, Photocopy, Microfilm Equipment",
    naics: "423420",
    sic: "5044",
    mcc: "5044"
  },
  {
    title: "Piece Goods, Other Dry Goods",
    naics: "424310",
    sic: "5131",
    mcc: "5131"
  },
  {
    title: "Plumbing, Heating Equipment",
    naics: "423720",
    sic: "5074",
    mcc: "5074"
  },
  {
    title: "Political Organizations",
    naics: "813940",
    sic: "8651",
    mcc: "8651"
  },
  { title: "Postal Services ", naics: "491110", sic: "4311", mcc: "9402" },
  {
    title: "Precious Stones and Metals, Watches, Jewelry",
    naics: "423940",
    sic: "5094",
    mcc: "5094"
  },
  {
    title: "Professional Services",
    naics: "541990",
    sic: "8999",
    mcc: "8999"
  },
  {
    title: "Public Golf Courses ",
    naics: "713910",
    sic: "7992",
    mcc: "7992"
  },
  {
    title: "Public Warehousing, Storage ",
    naics: "493110",
    sic: "4225",
    mcc: "4225"
  },
  {
    title: "Quick Copy, Repro, Blueprint",
    naics: "561410",
    sic: "7338",
    mcc: "7338"
  },
  { title: "Railroads", naics: "482111", sic: "4011", mcc: "4011" },
  {
    title: "Real Estate Agents, Rentals",
    naics: "531110",
    sic: "6531",
    mcc: "6513"
  },
  { title: "Record Stores", naics: "451220", sic: "5735", mcc: "5735" },
  {
    title: "Recreational Vehicle Rentals",
    naics: "532120",
    sic: "7519",
    mcc: "7519"
  },
  { title: "Religious Goods", naics: "453998", sic: "5999", mcc: "5973" },
  {
    title: "Religious Organizations",
    naics: "813110",
    sic: "8661",
    mcc: "8661"
  },
  {
    title: "Roofing, Siding, Sheet Metal",
    naics: "238160",
    sic: "1761",
    mcc: "1761"
  },
  {
    title: "Secretarial Support Services",
    naics: "561110",
    sic: "8741",
    mcc: "7339"
  },
  {
    title: "Security Brokers, Dealers",
    naics: "523120",
    sic: "6211",
    mcc: "6211"
  },
  { title: "Service Stations", naics: "447110", sic: "5541", mcc: "5541" },
  {
    title: "Sewing, Needlework, Fabric, Piece Goods",
    naics: "451130",
    sic: "5949",
    mcc: "5949"
  },
  {
    title: "Shoe Repair, Hat Cleaning",
    naics: "811430",
    sic: "7251",
    mcc: "7251"
  },
  {
    title: "Small Appliance Repair",
    naics: "811219",
    sic: "7629",
    mcc: "7629"
  },
  { title: "Snowmobile Dealers", naics: "441228", sic: "5599", mcc: "5598" },
  {
    title: "Special Trade Contractors",
    naics: "238990",
    sic: "1799",
    mcc: "1799"
  },
  { title: "Specialty Cleaning", naics: "325612", sic: "2842", mcc: "2842" },
  { title: "Sporting Goods", naics: "451110", sic: "5941", mcc: "5941" },
  {
    title: "Sporting, Recreation Camps",
    naics: "721214",
    sic: "7032",
    mcc: "7032"
  },
  {
    title: "Sports Clubs, Fields",
    naics: "711211",
    sic: "7941",
    mcc: "7941"
  },
  {
    title: "Sports, Riding Apparel",
    naics: "451110",
    sic: "5091",
    mcc: "5655"
  },
  { title: "Stamps, Coins", naics: "453998", sic: "5999", mcc: "5972" },
  {
    title: "Stationary, Office Supplies, Printing, Writing Paper",
    naics: "424110",
    sic: "5111",
    mcc: "5111"
  },
  {
    title: "Stationery, Office, School Supplies",
    naics: "453210",
    sic: "5943",
    mcc: "5943"
  },
  { title: "Swimming Pools", naics: "423910", sic: "5999", mcc: "5996" },
  {
    title: "Tailors, Alterations",
    naics: "811490",
    sic: "7219",
    mcc: "5697"
  },
  { title: "Tax Payments ", naics: "921130", sic: "9311", mcc: "9311" },
  {
    title: "Tax Preparation Services",
    naics: "541213",
    sic: "7291",
    mcc: "7276"
  },
  {
    title: "Taxicabs, Limousines",
    naics: "485310",
    sic: "4121",
    mcc: "4121"
  },
  { title: "Telecommunication", naics: "517312", sic: "4899", mcc: "4814" },
  {
    title: "Telecommunication Equipment",
    naics: "443142",
    sic: "4812",
    mcc: "4812"
  },
  { title: "Telegraph", naics: "517311", sic: "4822", mcc: "4821" },
  { title: "Tent, Awning", naics: "424990", sic: "5199", mcc: "5998" },
  {
    title: "Testing Laboratories",
    naics: "541380",
    sic: "8734",
    mcc: "8734"
  },
  {
    title: "Theatrical Ticket Agencies",
    naics: "561599",
    sic: "7922",
    mcc: "7922"
  },
  { title: "Timeshares", naics: "531210", sic: "6531", mcc: "7012" },
  {
    title: "Tire Retreading, Repair",
    naics: "326212",
    sic: "7534",
    mcc: "7534"
  },
  { title: "Tolls, Bridge Fees", naics: "488490", sic: "4785", mcc: "4784" },
  {
    title: "Tourist Attractions, Exhibits",
    naics: "712110",
    sic: "8412",
    mcc: "7991"
  },
  { title: "Towing Services", naics: "488410", sic: "7549", mcc: "7549" },
  {
    title: "Trailer Parks, Campgrounds",
    naics: "721211",
    sic: "7033",
    mcc: "7033"
  },
  {
    title: "Transportation Services ",
    naics: "488999",
    sic: "4789",
    mcc: "4789"
  },
  {
    title: "Travel Agencies, Tour Operators",
    naics: "561510",
    sic: "4724",
    mcc: "4722"
  },
  { title: "Truck Stop", naics: "447190", sic: "5541", mcc: "7511" },
  {
    title: "Truck, Utility Trailer Rentals",
    naics: "532120",
    sic: "7513",
    mcc: "7513"
  },
  {
    title: "Typesetting, Plate Making",
    naics: "323122",
    sic: "2791",
    mcc: "2791"
  },
  { title: "Typewriters", naics: "423420", sic: "5044", mcc: "5978" },
  {
    title: "U.S. Federal Government Agencies or Departments",
    naics: "921190",
    sic: "9199",
    mcc: "9405"
  },
  {
    title: "Uniforms, Commercial Clothing",
    naics: "315280",
    sic: "5137",
    mcc: "5137"
  },
  {
    title: "Used or Secondhand Merchandise",
    naics: "453310",
    sic: "5932",
    mcc: "5931"
  },
  { title: "Utilities", naics: "926130", sic: "9631", mcc: "4900" },
  { title: "Variety Stores", naics: "452990", sic: "5331", mcc: "5331" },
  { title: "Veterinary Services", naics: "541940", sic: "0742", mcc: "0742" },
  {
    title: "Video Amusement Game Supplies",
    naics: "713120",
    sic: "7993",
    mcc: "7993"
  },
  { title: "Video Game Arcades", naics: "713120", sic: "7993", mcc: "7994" },
  { title: "Video Production", naics: "512110", sic: "7819", mcc: "7829" },
  { title: "Video Tape Rental", naics: "532230", sic: "7841", mcc: "7841" },
  {
    title: "Vocational, Trade Schools",
    naics: "611519",
    sic: "8249",
    mcc: "8249"
  },
  {
    title: "Watch, Jewelry Repair",
    naics: "811490",
    sic: "7631",
    mcc: "7631"
  },
  { title: "Welding Repair", naics: "811310", sic: "7692", mcc: "7692" },
  { title: "Wholesale Clubs", naics: "452210", sic: "5311", mcc: "5300" },
  { title: "Wig, Toupee", naics: "448150", sic: "5699", mcc: "5698" },
  { title: "Wires, Money Orders", naics: "522390", sic: "6099", mcc: "4829" },
  { title: "Womens Accessories", naics: "448150", sic: "5632", mcc: "5631" },
  {
    title: "Womens Ready-To-Wear",
    naics: "448120",
    sic: "5621",
    mcc: "5621"
  },
  {
    title: "Wrecking, Salvage Yards",
    naics: "423930",
    sic: "5093",
    mcc: "5935"
  }
];
