import clsx from "clsx";
import { useContext } from "react";
import { Checkbox } from "components/form/Form";
import { AccountSetupContext } from "pages/accounts/AccountModals/AccountSetupContext";
import { CapabilityOption } from "./CapabilityOptions.model";
import styles from "../CreateNewAccount.module.scss";

interface CapabilitiyOptionProps {
  capability: CapabilityOption;
  checked: boolean;
}

export const CapabilitiyOption = ({ capability, checked }: CapabilitiyOptionProps) => {
  const { capabilities, setCapabilities } = useContext(AccountSetupContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCapabilities = e.target.checked
      ? [...capabilities, capability.apiValue]
      : capabilities.filter((c) => c !== capability.apiValue);
    setCapabilities(updatedCapabilities);
  };

  return (
    <label
      className={clsx(styles.capability, capability.disabled && styles.disabled)}
      key={capability.apiValue}
    >
      <Checkbox checked={checked} disabled={capability.disabled} onChange={handleChange} />
      <span className={styles.capabilityLabel}>
        <div key={capability.name} className={styles.name}>
          {capability.name}
        </div>
        <div key={capability.description} className={styles.description}>
          {capability.description}
        </div>
      </span>
    </label>
  );
};
