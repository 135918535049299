import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { FormGroup, Icon, TextInput } from "@moovfinancial/cargo";
import { IconChevronRight } from "@moovfinancial/cargo/icons";
import { useOnClickOut } from "hooks/useOnClickOut";
import { Address, AddressSuggestion } from "api/v2/accounts.model";
import styles from "./AddressComboBox.module.scss";

interface ComboBoxProps extends React.ComponentProps<typeof TextInput> {
  data: Address[];
  onChoose: (value: Address) => void;
  value: string;
  addressObject?: Address;
  listBoxClassName?: string;
  disabled?: boolean;
  warn?: boolean;
  hideResults?: boolean;
}

export default function AddressComboBox({
  value,
  name,
  onFocus,
  onBlur,
  onChange,
  onClick,
  onChoose,
  data,
  required,
  addressObject,
  listBoxClassName,
  disabled,
  warn,
  hideResults,
  ...rest
}: ComboBoxProps) {
  const [focused, setFocused] = useState(false);
  const [dropdownFocus, setDropdownFocus] = useState(false);
  const [searchData, setSearchData] = useState(data);
  const [dirty, setDirty] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);
  const fistChildRef = useRef<HTMLLIElement>(null);
  const [clean, setClean] = useState(true);
  const [isErroring, setIsErroring] = useState(false);
  useOnClickOut(listRef, () => {
    setDropdownFocus(false);
  });

  useEffect(() => {
    setSearchData(data);
  }, [data]);

  useEffect(() => {
    if (addressObject?.addressLine1 && quickFormat(addressObject) !== value) {
      if (onChange)
        onChange({
          target: { value: quickFormat(addressObject) }
        } as ChangeEvent<HTMLInputElement>);
    }
  }, [addressObject?.addressLine1]);

  useEffect(() => {
    if (
      value.length >= 1 &&
      addressObject &&
      quickFormat(addressObject || {}) !== value &&
      !focused &&
      !dropdownFocus
    ) {
      setIsErroring(true);
    } else {
      setIsErroring(false);
    }
  }, [addressObject, dropdownFocus, focused, value]);

  const onLocalChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDirty(true);
      if (onChange) onChange(e);
    },
    [onChange]
  );

  function onFocusHandler(e: React.FocusEvent<HTMLInputElement, Element>) {
    setFocused(true);
    if (clean) setClean(false);
    e.target.focus();
    onFocus && onFocus(e);
  }

  function onBlurHandler(e: React.FocusEvent<HTMLInputElement, Element>) {
    setTimeout(() => {
      if (!dropdownFocus) setFocused(false);
      onBlur && onBlur(e);
    }, 200);
  }

  function onClickHandler(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    setFocused(true);
    onClick && onClick(e);
  }

  function onSelectHandler(value: string, address: AddressSuggestion) {
    if (address.entries && address.entries > 1) {
      setFocused(true);
      setDropdownFocus(true);
    } else {
      setFocused(false);
      if (dropdownFocus) setDropdownFocus(false);
    }
    onLocalChange({
      target: { value }
    } as React.ChangeEvent<HTMLInputElement>);
    onChoose(address);
  }

  function handleOnSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    onLocalChange(e);
    if (addressObject?.addressLine1) {
      onChoose({
        addressLine1: "",
        addressLine2: "",
        city: "",
        stateOrProvince: "",
        postalCode: "",
        country: "US"
      });
    }
  }
  function itemKeyUpHandler(
    e: React.KeyboardEvent<HTMLLIElement>,
    value: string,
    address: Address
  ) {
    e.stopPropagation();
    e.preventDefault();

    if (e.key === "ArrowDown") {
      if (fistChildRef.current) {
        (e.currentTarget.nextElementSibling as HTMLElement)?.focus();
      }
    }
    if (e.key === "ArrowUp") {
      if (fistChildRef.current) {
        (e.currentTarget.previousElementSibling as HTMLElement)?.focus();
      }
    }
    if (e.key === "Enter") {
      onSelectHandler(value, address);
    }
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "ArrowDown") {
      if (fistChildRef.current) {
        setDropdownFocus(true);
        fistChildRef.current.focus();
      }
    }
  }

  function quickFormat(item: AddressSuggestion) {
    if (item.entries && item.entries > 1) {
      return `${item.addressLine1} ${item.addressLine2} (${item.entries} entries), ${item.city}, ${item.stateOrProvince}, ${item.postalCode}`;
    } else {
      return `${item.addressLine1}${item.addressLine2 ? `, ${item.addressLine2}` : ""}, ${
        item.city
      }, ${item.stateOrProvince}, ${item.postalCode}`;
    }
  }

  const getErrorMessage = () => {
    if (isErroring && !disabled) {
      return "Address is invalid.";
    }
  };

  const getWarningMessage = () => {
    if (warn && !disabled) {
      return "Address is missing.";
    }
  };

  return (
    <FormGroup
      className={styles.comboboxWrapper}
      errorMessage={getErrorMessage()}
      warningMessage={getWarningMessage()}
    >
      <TextInput
        aria-autocomplete="list"
        aria-controls={`${name}-list`}
        aria-expanded={focused}
        aria-haspopup="listbox"
        disabled={disabled}
        isErroring={isErroring && dirty}
        isWarning={warn}
        name={name}
        required={required}
        role="combobox"
        value={value}
        onBlur={onBlurHandler}
        onChange={handleOnSearchChange}
        onClick={onClickHandler}
        onFocus={onFocusHandler}
        onKeyUp={handleKeyUp}
        {...rest}
      />
      <AnimatePresence>
        {(focused || dropdownFocus) && value.length >= 3 && !hideResults && (
          <ul
            className={clsx(styles.combobox, listBoxClassName)}
            role="listbox"
            id={`${name}-list`}
            ref={listRef}
          >
            {searchData.map((item: AddressSuggestion, index) => (
              <li
                key={index}
                role="option"
                aria-selected="false"
                className={styles.comboboxItem}
                onClick={() => onSelectHandler(quickFormat(item), item)}
                onKeyUp={(e) => itemKeyUpHandler(e, quickFormat(item), item)}
                ref={index === 0 ? fistChildRef : null}
                tabIndex={0}
              >
                {quickFormat(item)}
                {!!(item.entries && item.entries > 1) && <Icon iconComponent={IconChevronRight} />}
              </li>
            ))}
            {!hideResults && searchData.length === 0 && value.length >= 3 && (
              <li key="noMatches" className={styles.comboboxItem}>
                No matching addresses
              </li>
            )}
          </ul>
        )}
      </AnimatePresence>
      <input
        hidden
        id="hiddenAddress"
        required={required}
        onChange={() => {}}
        value={addressObject?.addressLine1}
      />
    </FormGroup>
  );
}
