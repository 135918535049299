import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Loading } from "@moovfinancial/cargo";
import { SessionContext } from "contexts/SessionContext";
import SessionReminders from "layout/SessionReminders";

export const NeedsSession = () => {
  const { fetchSession, session } = useContext(SessionContext);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const doFetchSession = async () => {
      await fetchSession();
      setIsReady(true);
    };
    if (!isReady) {
      void doFetchSession();
    }
  }, []);

  return isReady ? (
    session ? (
      <>
        <Outlet />
        {/* Always that we need session we need session reminders */}
        <SessionReminders />
      </>
    ) : (
      <Navigate to="/signin" />
    )
  ) : (
    // TODO: When React 18+ specs are more solidified, we should be able to use `use`
    // (https://react.dev/reference/react/use) // to signal to `Suspense` that we're stuff loading
    //and should show the loading spinner
    <Loading centered />
  );
};
