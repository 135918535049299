import { AnimatePresence } from "framer-motion";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Loading } from "@moovfinancial/cargo";
import SignIn from "pages/auth/SignIn";
import { PrivateRoutes } from "./PrivateRoutes";

const Message = lazy(() => import("pages/auth/Message"));
const SignUp = lazy(() => import("pages/auth/SignUp"));
const ForgotPass = lazy(() => import("pages/auth/ForgotPass"));
const Recovery = lazy(() => import("pages/auth/Recovery"));
const Register = lazy(() => import("pages/auth/Register"));
const Verify = lazy(() => import("pages/auth/Verify"));
const TwoFactor = lazy(() => import("pages/auth/TwoFactor"));
const CreateAccount = lazy(() => import("pages/auth/CreateAccount"));

const Badge = lazy(() => import("components/badge/Badge"));
const LegacyTable = lazy(() => import("components/table/Table"));
const CardLogo = lazy(
  () => import("pages/accounts/AccountDetail/overview/payment-methods/CardLogo")
);

export const AppRoutes = () => {
  return (
    <Suspense fallback={<Loading centered />}>
      <AnimatePresence mode="wait" initial={false}>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpass" element={<ForgotPass />} />
          <Route path="/recovery" element={<Recovery />} />
          <Route path="/message" element={<Message />} />
          <Route path="/verify" element={<Verify />} />
          {/* This route is linked to from a registration email. */}
          <Route path="/register" element={<Register />} />
          <Route path="/session/two-factor" element={<TwoFactor />} />
          <Route path="/create-account" element={<CreateAccount />} />
          {/* It's important that PrivateRoutes are both within the same <Routes>
          tree and after all the public routes */}
          <Route path="/*" element={<PrivateRoutes />} />
        </Routes>
      </AnimatePresence>
      {/* Do not remove, here to enforce css order */}
      {/* eslint-disable-next-line no-constant-binary-expression */}
      {false && (
        <>
          <LegacyTable></LegacyTable>
          <Badge label="" Icon={() => <></>} />
          <CardLogo brand="Visa" />
        </>
      )}
    </Suspense>
  );
};
