import clsx from "clsx";
import React, { FunctionComponent } from "react";
import styles from "./LegacyCard.module.scss";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  cardStyle?: string;
  className?: string;
  Header?: FunctionComponent<React.PropsWithChildren<unknown>>;
}

interface CardBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  hasSearch?: boolean;
  hasTable?: boolean;
  thin?: boolean;
}

interface CardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  hasButton?: boolean;
  withBottomPadding?: boolean;
}

/** @deprecated Will soon be replaced by cargo's `Card` component */
export const LegacyCard = ({ children, cardStyle, className, ...rest }: CardProps) => {
  const styleClass = cardStyle ? (styles as Record<string, string>)[cardStyle] : styles.default;

  return (
    <div className={[styleClass, className].join(" ")} {...rest}>
      {children}
    </div>
  );
};

/**
 * @deprecated Will soon be replaced by cargo's `Card` component
 */
LegacyCard.Header = function cardHeader({
  children,
  className,
  hasButton,
  withBottomPadding
}: CardHeaderProps) {
  return (
    <header
      className={clsx(styles.header, className, {
        [styles.headerHasButton]: hasButton,
        [styles.headerBottomPadding]: withBottomPadding
      })}
    >
      {children}
    </header>
  );
};

/**
 * @deprecated Will soon be replaced by cargo's `Card` component
 */
LegacyCard.Title = function cardTitle({ children, className }: CardProps) {
  return <h3 className={[styles.title, className].join(" ")}>{children}</h3>;
};

/**
 * @deprecated Will soon be replaced by cargo's `Card` component
 */
LegacyCard.Body = function carBody({
  children,
  className,
  hasSearch,
  hasTable,
  thin
}: CardBodyProps) {
  return (
    <article
      className={clsx(styles.body, className, {
        [styles.smallPadding]: thin,
        [styles.hasTable]: hasTable,
        [styles.hasSearch]: hasSearch
      })}
    >
      {children}
    </article>
  );
};

/**
 * @deprecated Will soon be replaced by cargo's `Card` component
 */
LegacyCard.Footer = function cardFooter({ children, className }: CardProps) {
  return <footer className={[styles.footer, className].join(" ")}>{children}</footer>;
};

/** @deprecated Will soon be replaced by cargo's `Card` component */
export default LegacyCard;
