import clsx from "clsx";
import styles from "./Helper.module.scss";

interface HelperProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Remove all margins and paddings so the parent can position this element.
   * This will be the default in the future
   */
  noMargins?: boolean;
}

export const Helper = ({ children, className, noMargins = false, ...rest }: HelperProps) => (
  <div className={clsx(styles.helper, noMargins && styles.noMargins, className)} {...rest}>
    {children}
  </div>
);
