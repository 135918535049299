import { useState } from "react";
import type { components } from "@moovfinancial/common/types/__generated-types__/api";
import { Category } from "./Category";
import { CategoryLinks } from "./CategoryLinks";
import { FeeRow } from "./FeeRow";
import { Subcategory } from "./Subcategory";

type FeePlan = components["schemas"]["FeePlan"];
type Fee = components["schemas"]["Fee"];
type DisplayCategory =
  | "ACH"
  | "Card acquiring"
  | "Instant payments"
  | "Transaction management fees"
  | "Platform fees"
  | "Network fees";
type DisplaySubcategory =
  | "ACH transaction fees"
  | "ACH returns"
  | "Card acquiring fees"
  | "Instant payments"
  | "Card exceptions"
  | "Card service fee"
  | "Network fees"
  | "Platform fees"
  | "Other fees";
type SubcategoryRecord = Partial<Record<DisplaySubcategory, Fee[]>>;
type CategoryRecord = Partial<Record<DisplayCategory, SubcategoryRecord>>;

/**
 * Order of appearance for display categories
 */
const categoryOrder: DisplayCategory[] = [
  "ACH",
  "Card acquiring",
  "Instant payments",
  "Transaction management fees",
  "Platform fees",
  "Network fees"
];

/**
 * Order of appearance for display subcategories
 */
const subcategoryOrder: DisplaySubcategory[] = [
  "ACH transaction fees",
  "ACH returns",
  "Card acquiring fees",
  "Card exceptions",
  "Card service fee",
  "Instant payments",
  "Network fees",
  "Platform fees",
  "Other fees"
];

/**
 * Sorts backend fees into frontend categories
 * Mapping is defined in https://docs.google.com/spreadsheets/d/1kjU6mr-nnt7oompuQBhutIfn6QbYiNE3J5x4xoCIatE/edit?pli=1&gid=1316557581#gid=1316557581
 */
const feeToDisplayCategory = (fee: Fee): DisplayCategory => {
  switch (fee.feeCategory) {
    case "ach":
      return "ACH";
    case "card-acquiring":
      return "Card acquiring";
    case "network-passthrough":
      return "Network fees";
    case "card-other":
      return "Transaction management fees";
    case "card-pull":
    case "card-push":
    case "rtp":
      return "Instant payments";
    default:
      return "Platform fees";
  }
};

/**
 * Maps display categories to matching descriptions
 * Mapping is defined in https://www.figma.com/design/cJbcP9VR5rmMzyN601Yvr2/Billing?node-id=9799-92720&node-type=frame&t=hnJuckaJZDo8J16P-0
 */
const displayCategoryToDescription = (category: DisplayCategory) => {
  switch (category) {
    case "ACH":
      return "Fees assessed on each ACH transaction that is processed or returned.";
    case "Card acquiring":
      return "Fees assessed on each card transaction processed by the card networks.";
    case "Instant payments":
      return "Fees assessed when processing transactions via instant or real-time payment networks.";
    case "Transaction management fees":
      return "Cover various services related to processing, verification, fraud prevention, and card transaction management.";
    case "Platform fees":
      return "Fees assessed on a recurring basis for the maintenance and usage of the platform.";
    case "Network fees":
      return "Fees are standardized by the card brands and assessed when processing card payments. These fees are not configurable by Moov.";
    default:
      return "";
  }
};

/**
 * Sorts backend fees into frontend subcategories
 * Mapping is defined in https://docs.google.com/spreadsheets/d/1kjU6mr-nnt7oompuQBhutIfn6QbYiNE3J5x4xoCIatE/edit?pli=1&gid=1316557581#gid=1316557581
 */
const feeToDisplaySubcategory = (fee: Fee): DisplaySubcategory => {
  switch (fee.billableEvent) {
    case "ach-volume":
      return "ACH transaction fees";
    case "ach-return-noc":
      return "ACH returns";
    case "rtp-volume":
      return "Instant payments";
    case "rtp-verification":
      return "Instant payments";
    case "push-volume":
      return "Instant payments";
    case "pull-volume":
      return "Instant payments";
    case "card-settled-volume":
      return "Card acquiring fees";
    case "card-auth-volume":
      return "Card acquiring fees"; // The Google Sheet shows a subcategory for disputes and refunds, but Figma doesn't
    case "dispute-count":
      return "Card exceptions";
    case "card-verification-count":
      return "Card service fee";
    case "card-update-count":
      return "Card service fee";
    case "platform-fee":
      return "Other fees";
    case "transaction-monitoring":
      return "Other fees";
    case "card-auth-passthrough":
      return "Network fees"; // The Google Sheet shows a subcategory for each brand, but Figma doesn't
    case "card-settled-passthrough":
      return "Network fees"; // The Google Sheet shows a subcategory for each brand, but Figma doesn't
    case "interchange":
      return "Card acquiring fees";
    case "verified-account":
      return "Platform fees";
    case "pci-fee":
      return "Platform fees";
    case "pci-non-compliance-fee":
      return "Platform fees";
    case "kyc-count":
      return "Platform fees";
    case "kyb-count":
      return "Platform fees";
    default:
      return "Other fees";
  }
};

/**
 * Helper function to sort fees within a subcategory
 */
const compareFees = (a: Fee, b: Fee) => {
  const aHasVolume = a.billableEvent?.toLowerCase().includes("volume") || false;
  const bHasVolume = b.billableEvent?.toLowerCase().includes("volume") || false;

  if (aHasVolume && !bHasVolume) return -1;
  if (!aHasVolume && bHasVolume) return 1;

  return (a.feeName || "").localeCompare(b.feeName || "");
};

interface PricingProps {
  /** Array of fee plans to display */
  feePlans?: FeePlan[];
  /** Whether to show descriptions for each category */
  showDescriptions?: boolean;
  /** Determines the number of columns displayed on larger screens */
  responsive?: boolean;
  /** Whether to show links that scroll to each category */
  showCategoryLinks?: boolean;
  /** Whether the categories are collapsible */
  collapsible?: boolean;
  /** Whether only one category should be expanded at a time */
  expandOneAtATime?: boolean;
}

/**
 * Very specialized component that displays Billing Plans pricing information
 */
export function Pricing({
  feePlans,
  showDescriptions = false,
  responsive = true,
  showCategoryLinks = false,
  collapsible = false,
  expandOneAtATime = false
}: PricingProps) {
  // Tracks expanded categories
  const [expandedCategories, setExpandedCategories] = useState<Record<DisplayCategory, boolean>>({
    ACH: true,
    "Card acquiring": expandOneAtATime ? false : true,
    "Instant payments": expandOneAtATime ? false : true,
    "Transaction management fees": expandOneAtATime ? false : true,
    "Platform fees": expandOneAtATime ? false : true,
    "Network fees": expandOneAtATime ? false : true
  });

  // Organize fees into categories and subcategories
  const categorizedFees: CategoryRecord = {};
  feePlans?.forEach((plan) => {
    plan.billableFees?.forEach((fee) => {
      const category = feeToDisplayCategory(fee);
      const subcategory = feeToDisplaySubcategory(fee);
      if (!categorizedFees[category]) categorizedFees[category] = {};
      if (!categorizedFees[category][subcategory]) categorizedFees[category][subcategory] = [];
      categorizedFees[category][subcategory].push(fee);
    });
  });
  const visibleCategoryOrder = categoryOrder.filter((category) => categorizedFees[category]);

  // Collapses or expands clicked category
  const handleCollapseButtonClick = (category: DisplayCategory) => {
    const newExpandedState = !expandedCategories[category];
    if (expandOneAtATime)
      Object.keys(expandedCategories).forEach(
        (key) => (expandedCategories[key as DisplayCategory] = false)
      );
    expandedCategories[category] = newExpandedState;
    setExpandedCategories({ ...expandedCategories });
  };

  return feePlans && feePlans.length > 0 ? (
    <>
      {showCategoryLinks && <CategoryLinks categories={visibleCategoryOrder} />}
      {visibleCategoryOrder.map((category) => (
        <Category
          key={category}
          title={category}
          description={showDescriptions ? displayCategoryToDescription(category) : ""}
          responsive={responsive}
          expanded={collapsible && expandedCategories[category]}
          collapsed={collapsible && !expandedCategories[category]}
          onCollapseButtonClick={() => handleCollapseButtonClick(category)}
        >
          {subcategoryOrder.map(
            (subcategory) =>
              categorizedFees[category]![subcategory] && (
                <Subcategory
                  key={subcategory}
                  title={Object.keys(categorizedFees[category]!).length > 1 ? subcategory : ""}
                >
                  {categorizedFees[category]![subcategory].sort(compareFees).map((fee) => (
                    <FeeRow key={fee.billableFeeID} fee={fee} />
                  ))}
                </Subcategory>
              )
          )}
        </Category>
      ))}
    </>
  ) : null;
}
