import clsx from "clsx";
import React, { MouseEvent } from "react";
import styles from "./Badge.module.scss";

export type BadgeColor =
  | "green"
  | "red"
  | "orange"
  | "gray"
  | "lightGray"
  | "blue"
  | "purple"
  | "gold"
  | "inactive"
  | "goldDynamic"
  | "grayDynamic"
  | "grayOutlined"
  | "greenDynamic"
  | "purpleDynamic"
  | "redDynamic"
  | "blueDynamic"
  | "default";

export interface BadgeProps {
  /** Label displayed within the badge */
  label: string;
  /** Icon component displayed within the badge */
  Icon?: React.ReactElement;
  /** If true, the icon will have a compact display with less padding. */
  iconCompact?: boolean;
  /** Color of the badge */
  color?: BadgeColor;
  /** Color of the badge when hovered */
  hoverColor?: BadgeColor;
  /** Callback fired when a user clicks on the badge */
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  /** If true, increases font size and badge height on mobile */
  largeMobile?: boolean;
  /** If true, applies responsive styling for icon-only badges */
  noLabelMobile?: boolean;
  /** If true, badge displays icon only */
  noLabel?: boolean;
  /** If true, badge icon will be outlined, not filled */
  noFill?: boolean;
  /** If true, badge background will be transparent */
  noBackground?: boolean;
  /** If true, badge displays label only (in all caps) on both desktop and mobile */
  allCaps?: boolean;
  /** If true, badge displays label only (in all caps) on desktop and Icon only on mobile */
  responsiveCapsOrIcon?: boolean;
  /** Optional className applied to the badge for custom styling */
  className?: string;
}

export const Badge = React.memo(function Badge({
  label,
  Icon,
  iconCompact = false,
  color = "default",
  hoverColor,
  largeMobile,
  onClick,
  noLabelMobile,
  noLabel,
  noFill,
  noBackground,
  className,
  allCaps,
  responsiveCapsOrIcon
}: BadgeProps) {
  return (
    <div
      className={clsx(
        styles.badge,
        styles[color],
        className,
        hoverColor && styles[`${hoverColor}Hover`],
        iconCompact && styles.iconCompact,
        {
          [styles.largeMobile]: largeMobile,
          [styles.clickable]: onClick,
          [styles.noLabelMobile]: noLabelMobile,
          [styles.noFill]: noFill,
          [styles.noLabel]: noLabel,
          [styles.noBackground]: noBackground,
          [styles.allCaps]: allCaps,
          [styles.responsiveCapsOrIcon]: responsiveCapsOrIcon
        }
      )}
      onClick={onClick}
      data-testid="badge"
    >
      {Icon ?? null}
      <span className={styles.label}>{label}</span>
    </div>
  );
});
