"use client";

import clsx from "clsx";
import React, { ReactNode, memo, useState } from "react";
import { SwitchButtonOption } from "./SwitchButtonOption";
import styles from "./SwitchButton.module.scss";

export type SwitchButtonOptionType = {
  id?: string;
  label: string | ReactNode;
  value: string;
  onSelect?: () => void;
  className?: string;
};

type SwitchButtonSize = "compact" | undefined;

export interface SwitchButtonProps {
  /**
   * The options this Switch will have, including an optional `onSelect` handler
   */
  options: SwitchButtonOptionType[];
  /**
   * The currently selected value. If not passed, the first option will be selected
   */
  selectedValue?: string;
  /**
   * optionally provide a handler for the `onChange` event, which will be called with the HTML event object and the seleted value
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  /**
   * Optionally change the size class
   */
  size?: SwitchButtonSize;
  /**
   * Optionally stretch the button width to 100% so the parent can control the width
   */
  stretch?: boolean;
  /**
   * Classnames to override styles
   */
  className?: string;
}

/**
 * Primary UI component for toggling between two or more choices.
 */
export const SwitchButton = memo(function InnerSwitchButton({
  options,
  selectedValue: selectedValueParam,
  onChange: onChangeParam = () => {},
  size,
  stretch = false,
  className
}: SwitchButtonProps) {
  const [selectedValue, setSelectedValue] = useState<string>(
    selectedValueParam || options[0].value
  );
  const onChange = (event: React.ChangeEvent<HTMLInputElement>, option: SwitchButtonOptionType) => {
    setSelectedValue(option.value);
    onChangeParam(event, option.value);
    option.onSelect?.();
  };

  return (
    <div
      className={clsx(
        styles.switchButtonContainer,
        stretch && styles.stretch,
        className,
        size ? styles[size] : undefined
      )}
    >
      <div role="radiogroup" className={clsx(styles.switchButtonRadios, styles.stretch)}>
        {options.map((option) => (
          <SwitchButtonOption
            option={option}
            selectedValue={selectedValue}
            onChange={onChange}
            key={`radio-button-label-${option.value}`}
          />
        ))}
      </div>
    </div>
  );
});
