import { LiveMoovAPIClient } from "../api";
import { ErrorResponse } from "../request";
import { NetworkIDData } from "./networkIDs.model";

export interface NetworkIDsAPI {
  /** Fetches the card brand submids/network ids for a Moov account */
  get(
    facilitatorID: string,
    connectedAccountID: string
  ): Promise<[NetworkIDData | undefined, ErrorResponse | undefined, Response | undefined]>;
}

export class LiveNetworkIDsAPI implements NetworkIDsAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async get(
    facilitatorID: string,
    connectedAccountID: string
  ): Promise<[NetworkIDData | undefined, ErrorResponse | undefined, Response | undefined]> {
    const [result, err, resp] = await this._client.request<NetworkIDData>(
      `/accounts/${connectedAccountID}/network-ids`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err, resp];
  }
}
