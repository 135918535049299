import { forwardRef } from "react";
import { TextInput, TextInputProps } from "./TextInput";
import styles from "./ColorInput.module.scss";

/**
 * This is a lightweight wrapper of the TextInput component that includes an additional color
 * picker input set to the same value & change event to keep the two in sync.
 */
export const ColorInput = forwardRef<HTMLInputElement, TextInputProps>(function ColorInput(
  { value, onChange, name = "color", ...rest }: TextInputProps,
  ref
) {
  return (
    <TextInput
      ref={ref}
      theme={{
        outerWrapper: styles.wrapper
      }}
      Decoration={
        <input
          type="color"
          name={name}
          value={value}
          onChange={onChange}
          className={styles.colorInput}
        />
      }
      name={name}
      value={value}
      onChange={onChange}
      maxLength={7}
      minLength={4}
      {...rest}
    />
  );
});
