import { HTMLMotionProps, motion } from "framer-motion";
import { ReactNode, useEffect, useRef } from "react";
import { useMeasure } from "hooks/useMeasure";

interface AnimateProps extends HTMLMotionProps<"div"> {
  duration?: number;
  ease?: string;
  isVisible: boolean;
}

export function AnimateHeight({
  duration,
  ease = "easeOut",
  variants = {
    open: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0, overflowY: "clip" }
  },
  isVisible,
  children,
  ...other
}: AnimateProps & { children: ReactNode }) {
  const ref = useRef<HTMLDivElement>(null);
  const bounds = useMeasure(ref);

  useEffect(() => {
    if (isVisible) {
      ref.current?.removeAttribute("inert");
    } else {
      ref.current?.setAttribute("inert", "");
    }
  }, [isVisible]);

  return (
    <motion.div
      initial={isVisible ? "open" : "collapsed"}
      animate={isVisible ? "open" : "collapsed"}
      inherit={false}
      variants={variants}
      transition={{
        ease,
        duration:
          typeof duration === "number" ? duration : getAutoHeightDuration(bounds.height) / 1000
      }}
      {...other}
    >
      <div ref={ref}>{children}</div>
    </motion.div>
  );
}

function getAutoHeightDuration(height: number) {
  if (!height) return 0;
  const constant = height / 36;
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}
