/**
 * Accepts a string of numbers and returns that string formatted as an EIN (##-#######)
 *
 * @param value The string to format
 * @returns The value formatted as an EIN (##-#######)
 */
export function stringToFormattedEIN(value: string) {
  if (!value) return value;
  const cleaned = value.replace(/\D/g, "");
  const substrings = [cleaned.slice(0, 2), cleaned.slice(2, 9)].filter((s) => s.length > 0);
  return substrings.join("-");
}

/**
 * Accepts a formatted EIN string (###-##-####) and returns a string of numbers
 *
 * @param value The formatted EIN string to convert to a string of numbers
 * @returns An EIN string with all non-numeric characters removed
 */
export function formattedEINToString(value: string) {
  return value.replace(/\D/g, "");
}
