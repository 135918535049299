import styles from "./Subcategory.module.scss";

interface SubcategoryProps {
  title: string;
  children: React.ReactNode;
}

export function Subcategory({ title, children }: SubcategoryProps) {
  return (
    <div className={styles.subcategory}>
      {!!title && <h4 className={styles.subcategoryTitle}>{title}</h4>}
      {children}
    </div>
  );
}
