import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Loading } from "@moovfinancial/cargo";
import { OnboardingContext } from "contexts/OnboardingContext";
import { AddOwner } from "./AddOwner";
import { ReviewOwners } from "./ReviewOwners";

export function OwnersStep() {
  const { isLoading, representatives } = useContext(OnboardingContext);

  const hasRepresentatives = representatives.length > 0;

  return isLoading ? (
    <Loading centered />
  ) : (
    <Routes>
      <Route index element={hasRepresentatives ? <ReviewOwners /> : <Navigate to="add" />} />
      <Route path="add" element={<AddOwner />} />
      <Route path="edit/:representativeID" element={<AddOwner />} />
    </Routes>
  );
}
