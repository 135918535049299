import { MaskingInput, MaskingInputProps } from "./MaskingInput";

export interface StringMaskingInputProps
  extends Omit<
    MaskingInputProps<string>,
    "modelValueToFormattedString" | "formattedStringToModelValue" | "acceptedChars" | "value"
  > {
  /**
   * Regex that the input must match.
   *
   * NOTE: This regex should contain the correct quantifier, probably a "+"
   *
   * @default /.+/g
   */
  acceptedChars?: RegExp;
  /**
   *  Function to parse the formatted string into the unformatted string
   */
  formattedToUnformatted: (value: string) => string;
  /**
   * function called on value change. The function should update the value in the parent component's state.
   */
  onValueChange?: (value: string, formattedValue: string) => void;
  /**
   *  Function to format the unformatted string into the formatted string
   */
  unformattedToFormatted: (value: string) => string;
  /**
   * The unformatted string value of the input
   */
  value: string;
}

/**
 * Small component that wraps `MaskingInput` and restricts the model values to strings only
 */
export const StringMaskingInput = ({
  acceptedChars = /.+/g,
  formattedToUnformatted,
  unformattedToFormatted,
  ...rest
}: StringMaskingInputProps) => (
  <MaskingInput
    acceptedChars={acceptedChars}
    formattedStringToModelValue={formattedToUnformatted}
    modelValueToFormattedString={unformattedToFormatted}
    {...rest}
  />
);
