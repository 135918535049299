import styles from "./PageFooter.module.scss";

interface PageFooterProps {
  /**
   * The element to render the footer as.
   * Helps when we need to wrap this component in a <footer> to expand upon the content.
   * Defaults to `"footer"`.
   */
  as?: "footer" | "div";
  /**
   * The tagline to display in the footer, overrides the default.
   */
  moovTagline?: string | JSX.Element;
}

export const PageFooter = ({ as: Element = "footer", moovTagline }: PageFooterProps) => {
  return (
    <Element className={styles.footer}>
      <div>
        {moovTagline || (
          <>
            Powered by{" "}
            <a href="https://moov.io" rel="noreferrer" target="_blank">
              Moov
            </a>
          </>
        )}
      </div>
      <a href="https://moov.io/legal/platform-agreement/">Terms</a>
      <a href="https://moov.io/legal/privacy-policy/">Privacy</a>
    </Element>
  );
};
