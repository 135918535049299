import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { Account as LegacyAccountType } from "api/v2";
import { getAccountPatch as baseGetAccountPatch } from "helpers/accounts";

type PatchAccount = components["schemas"]["PatchAccountRequest"];

export function getAccountPatch(account: PatchAccount): PatchAccount {
  const termsOfService = account.termsOfService;
  const patch = baseGetAccountPatch(account as LegacyAccountType);

  // Clear address if all fields are empty (country is pre-populated)
  if (patch.profile?.business?.address) {
    if (
      !patch.profile.business.address?.addressLine1 &&
      !patch.profile.business.address?.addressLine2 &&
      !patch.profile.business.address?.city &&
      !patch.profile.business.address?.stateOrProvince &&
      !patch.profile.business.address?.postalCode
    ) {
      delete patch.profile.business.address;
    }
  }

  if (termsOfService && "token" in termsOfService && termsOfService.token) {
    patch.termsOfService = {
      token: termsOfService.token
    };
  }

  return patch as PatchAccount;
}
