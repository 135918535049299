import React, { createContext } from "react";
import { LiveMoovAPIClient, MoovAPIClient } from "api/v2";

interface ContextProps {
  moov: MoovAPIClient;
  children: React.ReactNode;
}

export interface APIContextType {
  moov: MoovAPIClient;
}

export const APIContext = createContext<APIContextType>({ moov: new LiveMoovAPIClient() });

export default function APIContextProvider({ moov, children }: ContextProps) {
  return <APIContext.Provider value={{ moov }}>{children}</APIContext.Provider>;
}
