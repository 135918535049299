import { useEffect } from "react";
import styles from "./CategoryLinks.module.scss";

type DisplayCategory =
  | "ACH"
  | "Card acquiring"
  | "Instant payments"
  | "Transaction management fees"
  | "Platform fees"
  | "Network fees";

interface CategoryLinksProps {
  categories: DisplayCategory[];
}

/** A row of links that take the user to the corresponding pricing category on the page */
export function CategoryLinks({ categories }: CategoryLinksProps) {
  // Highlight the category links based on scroll position
  useEffect(() => {
    const categoryIDs = categories.map((category) => category.replaceAll(" ", "-"));
    const intersectionObservers: IntersectionObserver[] = [];
    categoryIDs.forEach((id) => {
      const categoryLink = document.querySelector<HTMLAnchorElement>(`a[href="#${id}"]`);
      const categoryElement = document.querySelector<HTMLDivElement>(`div#${id}`);
      if (categoryLink && categoryElement) {
        const intersectionObserver = new IntersectionObserver(
          (entries) => {
            categoryLink.classList.toggle(styles.active, entries[0].isIntersecting);
          },
          { threshold: 1.0 }
        );
        intersectionObserver.observe(categoryElement);
        intersectionObservers.push(intersectionObserver);
      }
    });
    // Clean up intersection observers
    return () => {
      intersectionObservers.forEach((observer) => observer.disconnect());
    };
  }, [categories]);

  return (
    <div className={styles.categoryLinks}>
      {categories.map((category) => (
        <a key={category} href={`#${category.replaceAll(" ", "-")}`}>
          {category}
        </a>
      ))}
    </div>
  );
}
