import { clsx } from "clsx";
import { forwardRef } from "react";
import { Theme } from "@moovfinancial/common/types/Theme";
import { GenericSelectClonedTriggerProps } from "./GenericSelect";
import styles from "./GenericSelectTrigger.module.scss";

interface GenericSelectTriggerProps extends GenericSelectClonedTriggerProps {
  /** Theme object to override default styling of the component */
  theme?: Theme<typeof styles>;
  /** Optional, additional class name to apply to the trigger */
  className?: string;
  /** Content displayed inside the trigger */
  children: React.ReactNode;
}

/** An input-like button that triggers the listbox of a GenericSelect. */
export const GenericSelectTrigger = forwardRef<HTMLButtonElement, GenericSelectTriggerProps>(
  function GenericSelectTrigger(
    {
      className,
      children,
      error,
      warning,
      isLocked,
      hasValue,
      theme,
      ...rest
    }: GenericSelectTriggerProps,
    ref
  ) {
    return (
      <button
        ref={ref}
        type="button"
        className={clsx(
          !!error && styles.error,
          !!error && theme?.error,
          !!warning && styles.warning,
          !!warning && theme?.warning,
          isLocked && styles.isLocked,
          isLocked && theme?.isLocked,
          hasValue && styles.hasValue,
          hasValue && theme?.hasValue,
          styles.trigger,
          theme?.trigger,
          className
        )}
        {...rest}
      >
        {children}
      </button>
    );
  }
);
