import { useContext, useState } from "react";
import useAccountLocking from "hooks/useAccountLocking";
import useAccountRequirements from "hooks/useAccountRequirements";
import { AccountInput } from "../components/AccountInput";
import { VerificationLockedBanner } from "../components/AccountLockedBanner";
import { AddressField } from "../components/AddressField";
import { DescriptionOrWebsiteInput } from "../components/DescriptionOrWebsiteInput";
import { ManualAddress } from "../components/ManualAddress";
import { PhoneField } from "../components/PhoneField";
import { Step } from "components/dialog/StepModal";
import Toaster from "components/toaster/Toaster";
import { Text } from "cleanComponents/Typography/Text";
import { AccountSetupContext } from "../../AccountSetupContext";
import useAccountModalCalls from "../../useAccountModalCalls";
import styles from "./BusinessContact.module.scss";

export const BusinessContact = () => {
  const { account, manualAddress, errorMessages, capabilityRequirements } =
    useContext(AccountSetupContext);

  const { accountLocks, verificationLocked } = useAccountLocking();
  const { accountRequirements } = useAccountRequirements(capabilityRequirements);
  const { createAccount, updateAccount, loading } = useAccountModalCalls();

  const [addressUpdated, setAddressUpdated] = useState(false);

  if (manualAddress) {
    return <ManualAddress setAddressUpdated={setAddressUpdated} />;
  }

  return (
    <Step
      className={styles.businessContact}
      onSubmit={account.accountID ? updateAccount : createAccount}
      loading={loading}
    >
      {verificationLocked && <VerificationLockedBanner />}
      {errorMessages && <Toaster toastInput={errorMessages} duration={4000} />}
      <Text textStyle="paragraph-m-regular">
        All fields, unless labeled optional, are required to create an account and enable
        capabilities. However some information can be provided later.
      </Text>
      <div className={styles.grid}>
        <PhoneField
          warn={!!accountRequirements?.profile?.business?.phone?.number}
          isLocked={!!accountLocks?.profile?.business?.phone?.number}
          dispatchType="businessPhone"
          value={account.profile?.business?.phone?.number || ""}
        />
        <AccountInput
          label="Email"
          name="email"
          type="email"
          value={account.profile?.business?.email || ""}
          disabled={!!accountLocks?.profile?.business?.email}
          isLocked={!!accountLocks?.profile?.business?.email}
          showDefaultWarningMessage={!!accountRequirements?.profile?.business?.email}
        />
        <DescriptionOrWebsiteInput />
        <AddressField
          address={account.profile?.business?.address}
          warn={!!accountRequirements?.profile?.business?.address?.addressLine1 && !addressUpdated}
          isLocked={!!accountLocks?.profile?.business?.address?.addressLine1}
        />
      </div>
    </Step>
  );
};
