"use client";

import clsx from "clsx";
import React, { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from "react";
import { BaseButton } from "../../Actions";
import { AnimateHeight } from "../../AnimationContainers";
import { IconChevronLeft, IconClear } from "../../Icons";
import styles from "./Modal.module.scss";

export interface ModalProps extends React.HTMLAttributes<HTMLDialogElement> {
  /** Controls whether the modal is open or closed. */
  isOpen: boolean;
  /** Controls whether or not the close button is visible. */
  hideClose?: boolean;
  /** Controls whether or not the height will be animated. */
  animateHeight?: boolean;
  /** The function that will be called when the user clicks the close button or outside of the modal. */
  onClose: () => void;
  /** The function that will be called when the user clicks the back button. */
  onBack?: () => void;
  /** Actions to be displayed in the top left corner of the modal. */
  Action?: FunctionComponent;
}

export const Modal = ({
  isOpen,
  hideClose,
  animateHeight = true,
  onClose,
  onBack,
  children,
  className,
  Action,
  ...rest
}: ModalProps) => {
  const [isMounting, setIsMounting] = useState(true);
  useEffect(() => {
    setIsMounting(false);
  }, []);

  useEffect(() => {
    const darkThemeMeta = document.querySelector(
      'meta[name="theme-color"][media="(prefers-color-scheme: dark)"]'
    );
    const lightThemeMeta = document.querySelector(
      'meta[name="theme-color"][media="(prefers-color-scheme: light)"]'
    );

    const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 959px)").matches;

    if (isOpen) {
      // When the modal opens, update theme color to match either the modal backdrop or the modal background, depending on viewport size
      if (darkThemeMeta) {
        if (isMobile()) {
          darkThemeMeta.setAttribute("content", "#252525");
        } else {
          darkThemeMeta.setAttribute("content", "#0b0b0b");
        }
      }
      if (lightThemeMeta) {
        if (isMobile()) {
          lightThemeMeta.setAttribute("content", "#ffffff");
        } else {
          lightThemeMeta.setAttribute("content", "#cacaca");
        }
      }
    }
    return () => {
      if (darkThemeMeta) darkThemeMeta.setAttribute("content", "#0e0e0e");
      if (lightThemeMeta) lightThemeMeta.setAttribute("content", "#fcfcfc");
    };
  }, [isOpen]);

  const dialogRef = useRef<HTMLDialogElement>(null);
  useLayoutEffect(() => {
    if (isMounting) return; // Prevents InvalidStateError in Safari
    isOpen ? dialogRef.current?.showModal() : dialogRef.current?.close();
  }, [isOpen, isMounting]);

  const handleBackdropClick = (event: React.MouseEvent<HTMLDialogElement>) => {
    if (event.target === dialogRef.current) {
      onClose();
    }
  };

  // Allow us to optionally override the default behavior of closing the modal when the escape key is pressed.
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === "Escape") {
      onClose();
      event.preventDefault();
    }
  };

  if (!isOpen) return null;

  return (
    <dialog
      className={clsx(styles.modal, className)}
      role="dialog"
      onClick={handleBackdropClick}
      onClose={onClose}
      ref={dialogRef}
      onKeyDown={handleKeyDown}
      data-testid="modalParent"
      {...rest}
    >
      <div className={styles.controlButtons}>
        {(Action || onBack) && (
          <div className={styles.leftSideActions}>
            {onBack && (
              <BaseButton
                type="button"
                aria-label="back"
                className={styles.backButton}
                data-testid="backModal"
                onClick={onBack}
              >
                <IconChevronLeft />
              </BaseButton>
            )}
            {Action && (
              <div>
                <Action />
              </div>
            )}
          </div>
        )}
        {!hideClose && (
          <BaseButton
            type="button"
            aria-label="close"
            className={styles.closeButton}
            data-testid="closeModal"
            onClick={onClose}
          >
            <IconClear />
          </BaseButton>
        )}
      </div>
      {animateHeight ? (
        <AnimateHeight className={styles.contentWrapper}>{children}</AnimateHeight>
      ) : (
        <div className={styles.contentWrapper}>{children}</div>
      )}
    </dialog>
  );
};

/**
 * Modal.Header
 * The header that holds a title or other important information.
 *
 * Note: Accepts either a title or children. If children are passed, the title prop is ignored.
 */
interface ModalHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  className?: string;
  children?: React.ReactNode;
}
const ModalHeader = ({
  title,
  className,
  children,
  ...rest
}: (ModalHeaderProps & { children: React.ReactNode }) | (ModalHeaderProps & { title: string })) => {
  return (
    <header className={clsx(styles.header, className)} {...rest}>
      {children ? children : <h2>{title}</h2>}
    </header>
  );
};
Modal.Header = ModalHeader;

/**
 * Modal.Body
 * The body content of the modal that should contain all other children of the component.
 *
 * Note: This content scrolls.
 */
interface ModalBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}
const ModalBody = ({ children, className, ...rest }: ModalBodyProps) => {
  return (
    <div className={clsx(styles.body, className)} {...rest}>
      {children}
    </div>
  );
};
Modal.Body = ModalBody;

/**
 * Modal.Footer
 * The sticky footer that holds drawer actions such as submit buttons.
 *
 * Note: This content does not scroll with other modal content.
 */
interface ModalFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}
const ModalFooter = ({ children, className, ...rest }: ModalFooterProps) => {
  return (
    <footer className={clsx(styles.footer, className)} {...rest}>
      {children}
    </footer>
  );
};
Modal.Footer = ModalFooter;
