export function loadFromLocalStorage<T>(key: string, defaultValue: T, expectedType?: string): T {
  let item = null;
  try {
    item = JSON.parse(localStorage.getItem(key) as string) as T;
  } catch {
    // OK to ignore exceptions here
  }
  if (item === null || (expectedType && typeof item !== expectedType)) {
    item = defaultValue;
  }
  return item;
}

export function saveToLocalStorage<T>(key: string, item: T) {
  localStorage.setItem(key, JSON.stringify(item));
}
