import clsx from "clsx";
import { motion } from "framer-motion";
import styles from "./PageSheet.module.scss";

function PageSheet(props: React.PropsWithChildren<{ hide?: boolean }>) {
  const visibilityStyle = props.hide ? styles.hiddenMobile : "";

  return (
    <div className={clsx([styles.default, visibilityStyle])}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {props.children}
      </motion.div>
    </div>
  );
}

export default PageSheet;
