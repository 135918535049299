import { useEffect } from "react";
import { useRegisterSW } from "virtual:pwa-register/react";

function useServiceWorker() {
  // replaced dynamically
  const buildDate = "__DATE__";
  // replaced dyanmicaly
  const reloadSW = "__RELOAD_SW__";

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    immediate: true,
    onRegisteredSW(swScriptUrl, registration) {
      // @ts-expect-error TypeScript does not understand that "RELOAD SW" is replaced dynamically by Vite PWA
      if (reloadSW === "true") {
        registration &&
          setInterval(() => {
            registration.update();
          }, 200000);
      }
    }
  });

  useEffect(() => {
    if (needRefresh) updateServiceWorker(true);
  }, [needRefresh]);
}

export default useServiceWorker;
