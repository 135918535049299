import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSuper from "hooks/useSuper";
import { Capability, CapabilityName } from "api/v2";
import { APIContext } from "./APIContext";
import { AccountContext } from "./AccountContext";
import { FacilitatorContext } from "./FacilitatorContext";

interface ContextProps {
  children: React.ReactNode;
}

interface CapabilitiesContextType {
  capabilities: Capability[];
  refreshCapabilities: () => void;
  hasCapability: (capabilities: CapabilityName) => boolean;
}

export const CapabilitiesContext = createContext<CapabilitiesContextType>({
  capabilities: [],
  refreshCapabilities: () => {},
  hasCapability: () => false
});

/** Holds the capabilities of the account in AccountContext. Single source of truth. */
export default function CapabilitiesContextProvider({ children }: ContextProps) {
  const { facilitatorID } = useContext(FacilitatorContext);
  const { account } = useContext(AccountContext);
  const { moov } = useContext(APIContext);
  const isSuper = useSuper();
  const [capabilities, setCapabilities] = useState<Capability[]>([]);

  const refreshCapabilities = useCallback(() => {
    if (account && facilitatorID && (!account.disconnectedOn || isSuper)) {
      moov.capabilities
        .list(facilitatorID, account.accountID)
        .then(([results]) => {
          if (results) {
            setCapabilities(results);
          }
        })
        .catch((_err: any) => {
          toast("There was an error loading capabilities");
          setCapabilities([]);
        });
    } else if (!account) {
      setCapabilities([]);
    }
  }, [account, facilitatorID]);

  useEffect(() => refreshCapabilities(), [account, facilitatorID]);

  const hasCapability = (capabilityToCheck: CapabilityName) => {
    return !!capabilities.find(
      (capability) => capability.capability === capabilityToCheck && capability.status === "enabled"
    );
  };

  return (
    <CapabilitiesContext.Provider
      value={{
        capabilities,
        refreshCapabilities,
        hasCapability
      }}
    >
      {children}
    </CapabilitiesContext.Provider>
  );
}
