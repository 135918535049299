import {
  capitalize,
  dotSeparatedStringToSentence,
  sentenceToCamelCaseWord
} from "../utils/stringManipulation";

/**
 * According to some of our existing code before this commit, some errors might have nested
 * error objects in them, so accounting for that here.
 */
export type RecursiveErrorObject = { [key: string]: string | RecursiveErrorObject };

export type AnyResponseError =
  | RecursiveErrorObject
  | string
  | { error: RecursiveErrorObject }
  | { error: string[] }
  | string[];

/**
 * The request wasn't able to reach the server. This can happen when the app is
 * offline or there are other downstream connection issues.
 */
export type NetworkError = {
  type: "network";
  error: TypeError | DOMException;
};

/**
 * The request reached the server, but the server returned a status code outside
 * of the range [200, 400).
 */
export type HTTPError = {
  type: "http";
  resp: Response;
  // old 422 errors where returned directly in the body as a dict and 409 errors as a string
  body?: AnyResponseError;
};

/**
 *  In some endpoints, we might be getting errors directly as strings in the body.
 *
 *  To be clear: I did not confirm that's the case, this is more defensive programming than anything else
 *
 */
export type LegacyHTTPErrorRawString = HTTPError & {
  body: string;
};

/**
 *  In old / non-compliant-with-new-patterns endpoints, we are getting errors as an object directly in the body
 */
export type LegacyHTTPErrorRawObject = HTTPError & {
  body: RecursiveErrorObject;
};

/**
 * According to our FE code, we have some errors that are returned
 * as strings wrapped in an `error` object
 *
 * e.g. https://github.com/moovfinancial/frontend/blob/main/dashboard/src/pages/documents/DocumentPreviewModal/DocumentDecision.tsx#L105
 */
export type HTTPErrorWrappedString = HTTPError & {
  body: { error: string };
};

/**
 * Errors that are returned as RecursiveError objects in the `error` prop
 */
export type HTTPErrorWrappedObject = HTTPError & {
  body: { error: RecursiveErrorObject };
};

/**
 * Just in case we also deal with responses that have arrays of error messages
 */
export type LegacyHTTPRawErrorArray = HTTPError & {
  body: string[];
};

export type LegacyHTTPWrappedErrorArray = HTTPError & {
  body: { error: string[] };
};

/**
 * The request reached the server, the server returned a successful status code,
 * but an error occurred while parsing the JSON or text response body.
 */
export type PostProcessingError = {
  type: "post";
  error: Error;
  resp: Response;
};

/** One of three different types of errors that can occur when using the Fetch API. */
export type ErrorResponse = NetworkError | HTTPError | PostProcessingError;

/** Helper generic to easily create API endpoint return types */
export type APIResponse<T> = Promise<
  | [T | undefined, ErrorResponse | undefined]
  | [T | undefined, ErrorResponse | undefined, Response | undefined]
>;

export const isHTTPError = (response?: unknown): response is HTTPError => {
  return (
    !!response &&
    !!(response as HTTPError).type &&
    !!(response as HTTPError).resp?.status &&
    (response as HTTPError).type === "http"
  );
};

const isLegacyHTTPErrorRawString = (
  response: ErrorResponse
): response is LegacyHTTPErrorRawString =>
  isHTTPError(response) && !!response.body && typeof response.body === "string";

export const isLegacyHTTPErrorRawObject = (
  error: ErrorResponse
): error is LegacyHTTPErrorRawObject =>
  isHTTPError(error) &&
  !!error.body &&
  typeof error.body === "object" &&
  !Array.isArray(error.body) &&
  !Array.isArray(error.body?.error);

export const isHTTPErrorWrappedString = (
  response: ErrorResponse
): response is HTTPErrorWrappedString => {
  return (
    isHTTPError(response) &&
    typeof response.body === "object" &&
    "error" in response.body &&
    typeof response.body.error === "string"
  );
};

export const isHTTPErrorWrappedObject = (
  response: ErrorResponse
): response is HTTPErrorWrappedObject => {
  return (
    isHTTPError(response) &&
    typeof response.body === "object" &&
    "error" in response.body &&
    typeof response.body.error === "object" &&
    !Array.isArray(response.body.error)
  );
};

export const isLegacyHTTPRawErrorArray = (
  response: ErrorResponse
): response is LegacyHTTPRawErrorArray =>
  isHTTPError(response) &&
  !!response.body &&
  typeof response.body === "object" &&
  Array.isArray(response.body);

export const isLegacyHTTPWrappedErrorArray = (
  response: ErrorResponse
): response is LegacyHTTPWrappedErrorArray =>
  isHTTPError(response) &&
  typeof response.body === "object" &&
  "error" in response.body &&
  Array.isArray(response.body.error);

/** Determines if and asserts the error is a NetworkError. */
export function isNetworkError(err?: unknown): err is NetworkError {
  return !!err && (err as NetworkError).type === "network";
}

/** Determines if and asserts the error is a PostProcessingError. */
export function isPostProcessingError(err?: unknown): err is PostProcessingError {
  return !!err && (err as PostProcessingError).type === "post";
}

/**
 * Utility function to transform an array of error messages into a RecursiveErrorObject
 *
 * It will try to split the error message by ":" and use the left part as the key and the right part as the value
 *
 * If it can't split the error message by ":", it will merge all the error messages into a single key called "error"
 */
export const errorMessageArrayToObject = (errors: string[]): RecursiveErrorObject => {
  return errors.reduce<Record<string, string>>((acc, error) => {
    const [field, errorMessage] = error.split(":").map((str) => str.trim());
    if (errorMessage) {
      // Just in case the left side is a sentence, we'll transform it to a camelCase word
      acc[sentenceToCamelCaseWord(field)] = errorMessage;
    } else {
      // if there's no ":" in the error message, we'll add it to the "error" key
      const errorMessage = (acc.error || "").length ? `${acc.error}, ${error}` : error;
      acc.error = errorMessage;
    }
    return acc;
  }, {});
};

/**
 * Function that standardizes AnyResponseError to string
 * @deprecated -- should not be used, this is just a defensive programming crutch to deal with non-typed openAPI errors
 */
export const anyResponseErrorToString = (error: AnyResponseError): string => {
  if (typeof error === "string") return error;
  if (typeof error === "object" && error) {
    if ("error" in error) {
      if (Array.isArray(error.error)) {
        return errorArrayToString(error.error);
      } else if (typeof error.error === "string") {
        return error.error;
      } else {
        return errorObjectToString(error.error);
      }
    } else {
      if (Array.isArray(error)) {
        return errorArrayToString(error);
      } else {
        return errorObjectToString(error);
      }
    }
  }
  return "Unknown error";
};

/**
 *
 * Function that standardizes any kind of ErrorResponse to RecursiveErrorObject
 *
 */
export const getErrorsAsObject = (response: HTTPError): RecursiveErrorObject => {
  // 🔴 BE CAREFUL: ORDER MATTERS -- MORE SPECIFIC CHECKS SHOULD BE HIGHER UP THE CHAIN
  if (isHTTPErrorWrappedObject(response)) return response.body.error;
  if (isHTTPErrorWrappedString(response)) return errorStringtoErrorObject(response.body.error);
  if (isLegacyHTTPErrorRawObject(response)) return response.body;
  if (isLegacyHTTPErrorRawString(response)) return errorStringtoErrorObject(response.body);
  if (isLegacyHTTPWrappedErrorArray(response))
    return errorMessageArrayToObject(response.body.error);
  if (isLegacyHTTPRawErrorArray(response)) return errorMessageArrayToObject(response.body);
  return {};
};

/**
 * Recursive function to get any kind of errors inside any kind of ErrorResponse as an array of strings
 *
 * E.g. { one: { two: "error message"}, other: "message", error: "default message" }
 *
 * will produce:
 *
 * ["one.two: error message", "other: message", "default message"]
 *
 * The function will ignore the default `error` key in the object and, if found, will only return the value, skipping the key:
 *
 * { error: "Error Message"} -> ["Error Message"];
 *
 */
export const errorObjectToArray = (
  errors: RecursiveErrorObject, // Possibly recursive error object we're traversing
  fieldPath: string = "" // "level1.level2"
) => {
  const res: string[] = [];
  Object.entries(errors).map(([k, v]) => {
    // for the default "error" error, skip the field name part
    const currField = k === "error" ? "" : k;
    const path = `${fieldPath}.${currField}`;
    // remove leading and trailing dots
    const trimmedPath = path.replace(/^\.|\.$/g, "");
    const sentencePath = dotSeparatedStringToSentence(trimmedPath);
    if (typeof v === "string") {
      const message = `${sentencePath.length ? `${sentencePath}: ` : ""}${cleanErrorMessage(v)}`;
      res.push(message);
    } else {
      res.push(...errorObjectToArray(v, trimmedPath));
    }
  });
  return res;
};

export const cleanErrorMessage = (errorMessage: string) =>
  `${capitalize(errorMessage.trim().replace(/\.$/, ""))}.`;

const normalizeErrorMessage = (error: string) => {
  const [errorName, errorMessage] = error.split(":");
  return errorMessage
    ? `${dotSeparatedStringToSentence(errorName).trim()}: ${cleanErrorMessage(errorMessage)}`
    : cleanErrorMessage(errorName);
};

export const normalizeErrorArray = (errorArray: string[]) => errorArray.map(normalizeErrorMessage);

/**
 * Transforms an array of error message strings into a single string
 */
export const errorArrayToString = (errorArray: string[]) =>
  normalizeErrorArray(errorArray).join(" ").trim();

/**
 * Composes the 2 previous functions to go directly from errorObject -> string
 */
export const errorObjectToString = (errorObject: RecursiveErrorObject) =>
  errorArrayToString(errorObjectToArray(errorObject));

/**
 * Unlikely to be needed, but it's here for completeness
 * Transforms a string into an error object
 */
export const errorStringtoErrorObject = (errorString: string) => {
  const [name, message] = errorString.split(":");
  const errorMessage = message ? message.trim() : name;
  const errorName = message ? name : "error";
  return { [errorName]: cleanErrorMessage(errorMessage) };
};

/**
 * Extract the errors from any ErrorResponse into an array of strings
 */
export const getErrorsAsArray = (response: HTTPError): string[] => {
  // if the response already has an array, use it as is w/o unnecessary processing
  if (isLegacyHTTPRawErrorArray(response)) return normalizeErrorArray(response.body);
  if (isLegacyHTTPWrappedErrorArray(response)) return normalizeErrorArray(response.body.error);
  const errors = getErrorsAsObject(response);
  return errorObjectToArray(errors);
};

/**
 * Extract the error(s) from any ErrorResponse in a single string
 */
export const getErrorsAsString = (response: HTTPError): string => {
  const errorArray = getErrorsAsArray(response);
  return errorArrayToString(errorArray);
};
