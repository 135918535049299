import { LiveMoovAPIClient } from "./api";
import { PaginationCursor } from "./common.model";
import {
  AdminBulkDisputeUpdate,
  AdminBulkDisputeUpdateResponse,
  AdminDisputeEnriched,
  AdminDisputeEvidence,
  AdminDisputeFilter,
  AdminDisputeUpdate,
  Dispute,
  DisputeEnriched,
  DisputeEvidence,
  DisputeEvidenceText,
  DisputeEvidenceUpdate,
  DisputeEvidenceUpdateMetadata,
  PartialAdminDisputeFilter,
  PartialDisputeFilter
} from "./disputes.model";
import { APIResponse, ErrorResponse } from "./request";

export interface DisputesAPI {
  /** Accepts a dispute */
  acceptDispute(
    facilitatorID: string,
    disputeID: string
  ): Promise<[DisputeEnriched | undefined, ErrorResponse | undefined]>;

  /** Lists the disputes for a facilitator */
  list(
    facilitatorID: string,
    filter?: PartialDisputeFilter,
    cursor?: PaginationCursor
  ): Promise<[DisputeEnriched[] | undefined, ErrorResponse | undefined]>;

  /** Gets a dispute by id */
  get(
    facilitatorID: string,
    disputeID: string
  ): Promise<[Dispute | undefined, ErrorResponse | undefined]>;

  /** Lists all evidence attached to the dispute */
  listEvidence(
    facilitatorID: string,
    disputeID: string
  ): Promise<[DisputeEvidence[] | undefined, ErrorResponse | undefined]>;

  /** Deletes dispute evidence by ID */
  deleteEvidence(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string
  ): Promise<[undefined, ErrorResponse | undefined]>;

  /** Allows a user to download a dispute evidence file */
  downloadEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string
  ): Promise<[File | undefined, ErrorResponse | undefined]>;

  /** Retrieves the evidence file with the given id */
  getEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string
  ): Promise<[File | undefined, ErrorResponse | undefined]>;

  /** Submits evidence for a dispute */
  submitEvidence(
    facilitatorID: string,
    disputeID: string
  ): Promise<[Dispute | undefined, ErrorResponse | undefined]>;

  /** Updates dispute file evidence by ID */
  updateEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string,
    evidenceUpdate: DisputeEvidenceUpdate
  ): Promise<[DisputeEvidenceUpdateMetadata | undefined, ErrorResponse | undefined]>;

  /** Updates dispute text evidence by ID */
  updateEvidenceText(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string,
    evidenceUpdate: DisputeEvidenceUpdate
  ): Promise<[DisputeEvidenceUpdateMetadata | undefined, ErrorResponse | undefined]>;

  /** Uploads a document file as evidence for the dispute */
  uploadEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    file: FormData
  ): Promise<[undefined, ErrorResponse | undefined]>;

  /** Uploads text as evidence for a dispute. */
  uploadEvidenceText(
    facilitatorID: string,
    disputeID: string,
    textEvidence: DisputeEvidenceText
  ): Promise<[undefined, ErrorResponse | undefined]>;

  /** Uploads an evidence file for the dispute */
  uploadEvidence(
    facilitatorID: string,
    disputeID: string,
    file: FormData
  ): Promise<[undefined, ErrorResponse | undefined]>;

  /** Updates multiple disputes at once. Moov-admins only. */
  adminBulkUpdate(
    bulkDisputeUpdate: AdminBulkDisputeUpdate
  ): APIResponse<AdminBulkDisputeUpdateResponse>;

  /** Deletes dispute evidence by ID. Moov-admins only. */
  adminDeleteEvidence(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string
  ): Promise<[undefined, ErrorResponse | undefined]>;

  /** Enables downloading a dispute evidence file. Moov-admins only. */
  adminDownloadEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string
  ): Promise<[File | undefined, ErrorResponse | undefined]>;

  /** Lists all disputes on the Moov platform. Moov-admins only. */
  adminList(
    facilitatorID: string,
    filter?: PartialAdminDisputeFilter,
    cursor?: PaginationCursor
  ): Promise<[AdminDisputeEnriched[] | undefined, ErrorResponse | undefined]>;

  /** Lists evidence for a given dispute. Moov-admins only. */
  adminListEvidence(
    facilitatorID: string,
    disputeID: string,
    filter?: PartialAdminDisputeFilter,
    cursor?: PaginationCursor
  ): Promise<[AdminDisputeEvidence[] | undefined, ErrorResponse | undefined]>;

  /** Updates dispute status, amount, or case number. Moov-admins only. */
  adminUpdate(disputeID: string, disputeUpdate: AdminDisputeUpdate): APIResponse<undefined>;

  /** Updates dispute text evidence by ID. Moov-admins only. */
  adminUpdateEvidenceText(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string,
    evidenceUpdate: DisputeEvidenceUpdate
  ): Promise<[DisputeEvidenceUpdateMetadata | undefined, ErrorResponse | undefined]>;

  /** Add an evidence file to a dispute. Moov-admins only. */
  adminUploadEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    file: FormData
  ): Promise<[undefined, ErrorResponse | undefined]>;

  /** Uploads text evidence for a dispute. Moov-admins only. */
  adminUploadEvidenceText(
    facilitatorID: string,
    disputeID: string,
    textEvidence: DisputeEvidenceText
  ): Promise<[undefined, ErrorResponse | undefined]>;
}

export class LiveDisputesAPI implements DisputesAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async acceptDispute(
    facilitatorID: string,
    disputeID: string
  ): Promise<[DisputeEnriched | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<DisputeEnriched>(
      `/disputes/${disputeID}/accept`,
      { method: "POST", xAccountID: facilitatorID }
    );
    return [result, err];
  }

  async list(
    facilitatorID: string,
    filter?: PartialDisputeFilter,
    cursor?: PaginationCursor
  ): Promise<[DisputeEnriched[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<DisputeEnriched[]>("/dashboard/disputes", {
      query: { ...filter, ...cursor },
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async get(
    facilitatorID: string,
    disputeID: string
  ): Promise<[Dispute | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<Dispute>(`/disputes/${disputeID}`, {
      xAccountID: facilitatorID
    });
    return [result, err];
  }

  async listEvidence(
    facilitatorID: string,
    disputeID: string
  ): Promise<[DisputeEvidence[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<DisputeEvidence[]>(
      `/disputes/${disputeID}/evidence`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async deleteEvidence(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/disputes/${disputeID}/evidence/${evidenceID}`,
      {
        method: "DELETE",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async getEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string
  ): Promise<[File | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<File>(
      `/ops/disputes/${disputeID}/evidence/${evidenceID}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async downloadEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string
  ): Promise<[File | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<File>(
      `/disputes/${disputeID}/evidence/${evidenceID}/data`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async submitEvidence(
    facilitatorID: string,
    disputeID: string
  ): Promise<[Dispute | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/disputes/${disputeID}/evidence/submit`,
      {
        method: "POST",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async updateEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string,
    evidenceUpdate: DisputeEvidenceUpdate
  ): Promise<[DisputeEvidenceUpdateMetadata | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<DisputeEvidenceUpdateMetadata>(
      `/disputes/${disputeID}/evidence/${evidenceID}`,
      {
        json: evidenceUpdate,
        method: "PATCH",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async updateEvidenceText(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string,
    evidenceUpdate: DisputeEvidenceUpdate
  ): Promise<[DisputeEvidenceUpdateMetadata | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<DisputeEvidenceUpdateMetadata>(
      `/disputes/${disputeID}/evidence/${evidenceID}`,
      {
        json: evidenceUpdate,
        method: "PATCH",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async uploadEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    file: FormData
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/disputes/${disputeID}/evidence-file`,
      {
        method: "POST",
        body: file,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async uploadEvidenceText(
    facilitatorID: string,
    disputeID: string,
    textEvidence: DisputeEvidenceText
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/disputes/${disputeID}/evidence-text`,
      {
        method: "POST",
        json: textEvidence,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async uploadEvidence(
    facilitatorID: string,
    disputeID: string,
    files: FormData
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/admin/disputes/${disputeID}/evidence`,
      {
        method: "POST",
        body: files,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminBulkUpdate(
    bulkDisputeUpdate: AdminBulkDisputeUpdate
  ): APIResponse<AdminBulkDisputeUpdateResponse> {
    const [result, err] = await this._client.request<AdminBulkDisputeUpdateResponse>(
      "/ops/disputes/bulk/statuses",
      {
        method: "PATCH",
        json: bulkDisputeUpdate
      }
    );
    return [result, err];
  }

  async adminDeleteEvidence(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/ops/disputes/${disputeID}/evidence/${evidenceID}`,
      {
        method: "DELETE",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminDownloadEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string
  ): Promise<[File | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<File>(
      `/ops/disputes/${disputeID}/evidence/${evidenceID}/data`,
      {
        xAccountID: facilitatorID
      }
    );

    return [result, err];
  }

  async adminList(
    facilitatorID: string,
    filter?: AdminDisputeFilter,
    cursor?: PaginationCursor
  ): Promise<[AdminDisputeEnriched[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<AdminDisputeEnriched[]>(
      "/dashboard/admin/disputes",
      {
        xAccountID: facilitatorID,
        query: { ...filter, ...cursor }
      }
    );
    return [result, err];
  }

  async adminListEvidence(
    facilitatorID: string,
    disputeID: string,
    filter?: AdminDisputeFilter,
    cursor?: PaginationCursor
  ): Promise<[AdminDisputeEvidence[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<AdminDisputeEvidence[]>(
      `/admin/disputes/${disputeID}/evidence`,
      {
        query: { ...filter, ...cursor },
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminUpdate(disputeID: string, disputeUpdate: AdminDisputeUpdate): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(`/admin/disputes/${disputeID}`, {
      method: "PATCH",
      json: disputeUpdate
    });
    return [result, err];
  }

  async adminUpdateEvidenceText(
    facilitatorID: string,
    disputeID: string,
    evidenceID: string,
    evidenceUpdate: DisputeEvidenceUpdate
  ): Promise<[DisputeEvidenceUpdateMetadata | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<DisputeEvidenceUpdateMetadata>(
      `/admin/disputes/${disputeID}/evidence/${evidenceID}`,
      {
        json: evidenceUpdate,
        method: "PATCH",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminUploadEvidenceFile(
    facilitatorID: string,
    disputeID: string,
    file: FormData
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/admin/disputes/${disputeID}/evidence-file`,
      {
        method: "POST",
        body: file,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async adminUploadEvidenceText(
    facilitatorID: string,
    disputeID: string,
    textEvidence: DisputeEvidenceText
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/ops/disputes/${disputeID}/evidence-text`,
      {
        method: "POST",
        json: textEvidence,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
