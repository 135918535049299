import { type Account, type BusinessType } from "./v2/accounts.model";

export interface CreateFacilitatorAccounts {
  userID: string;
  email: string;
  firstName: string;
  lastName: string;
  legalBusinessName: string;
  businessType: BusinessType;
  description: string;
  termsOfServiceToken: string;
}

export interface FacilitatorAccounts {
  production: Account;
  sandbox: Account;
}

export enum OnboardingStateValue {
  Created = "created",
  SubmittedForReview = "submitted-for-review",
  InReview = "in-review",
  Reviewed = "reviewed",
  Unknown = "unknown"
}

export interface OnboardingState {
  state: OnboardingStateValue;
}

export interface SalesMessage {
  accountID: string;
  testAccountID: string;
  userID: string;
  displayName: string;
  message: string;
  firstName: string;
  lastName: string;
  email: string;
}
