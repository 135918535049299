import React, { createContext, useState } from "react";

interface ContextProps {
  children: React.ReactNode;
}

interface NavContextType {
  open: boolean;
  setOpen: (open: boolean) => void;
  close: () => void;
  shouldRenderNavMenu: boolean;
  setShouldRenderNavMenu: (shouldRenderNavMenu: boolean) => void;
}

export const NavContext = createContext<NavContextType>({
  open: false,
  setOpen: () => {},
  close: () => {},
  shouldRenderNavMenu: true,
  setShouldRenderNavMenu: () => {}
});

export default function NavContextProvider({ children }: ContextProps) {
  const [open, setOpen] = useState(false);
  const [shouldRenderNavMenu, setShouldRenderNavMenu] = useState(true);

  const close = () => setOpen(false);

  return (
    <NavContext.Provider
      value={{
        open,
        setOpen,
        close,
        shouldRenderNavMenu,
        setShouldRenderNavMenu
      }}
    >
      {children}
    </NavContext.Provider>
  );
}
