import { type RefObject, useEffect } from "react";

export function useOnClickOut<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T> | null,
  handler: (e: MouseEvent | TouchEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref?.current || (event.target instanceof Node && ref.current.contains(event.target))) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mouseup", listener);
    document.addEventListener("touchend", listener);
    return () => {
      document.removeEventListener("mouseup", listener);
      document.removeEventListener("touchend", listener);
    };
  }, [ref, handler]);
}
