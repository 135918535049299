import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { FormGroup, Helper } from "@moovfinancial/cargo";
import ComboBox from "./ComboBox";
import styles from "./FloatingLabelInput.module.scss";

interface FloatingLabelComboBoxProps extends React.ComponentProps<typeof ComboBox> {
  helper?: string | React.ReactNode;
  label?: string;
  noMargins?: boolean;
}

export default function FloatingLabelComboBox({
  label,
  value,
  onChange,
  type = "text",
  placeholder,
  helper,
  error,
  noMargins = false,
  onBlur,
  onFocus,
  ...rest
}: FloatingLabelComboBoxProps) {
  const [floating, setFloating] = useState(!!value);

  useEffect(() => {
    if (value && !floating) {
      setFloating(true);
    }
  }, [value]);

  function handleLabelState(e: React.FocusEvent<HTMLInputElement>, action: "focus" | "blur") {
    if (action === "focus") {
      if (!floating) setFloating(true);
      onFocus && onFocus(e);
    } else if (action === "blur") {
      if (!e.target.value) setFloating(false);
      onBlur && onBlur(e);
    }
  }

  return (
    <FormGroup className={styles.inputContainer} noMargins={noMargins} errorMessage={error}>
      <label className={clsx(styles.label, { [styles.floating]: floating })}>
        {label || placeholder}
      </label>
      <ComboBox
        type={type}
        onFocus={(e) => handleLabelState(e, "focus")}
        onBlur={(e) => handleLabelState(e, "blur")}
        onChange={onChange}
        value={value}
        {...rest}
      />
      {helper && <Helper>{helper}</Helper>}
    </FormGroup>
  );
}
