import { FileForUpload } from "pages/production-access-form/ProductionAccessFormReducer";

export type FilePurpose =
  | "identity_verification"
  | "business_verification"
  | "merchant_underwriting"
  | "representative_verification"
  | "individual_verification"
  | "account_requirement";

export const FilePurposeMap: Record<FilePurpose, string> = {
  identity_verification: "Identity verification",
  business_verification: "Business verification",
  merchant_underwriting: "Merchant underwriting",
  representative_verification: "Representative verification",
  individual_verification: "Individual verification",
  account_requirement: "Account requirement"
};

export const isFilePurpose = (value: string | FilePurpose): value is FilePurpose => {
  if (Object.keys(FilePurposeMap).includes(value)) return true;
  return false;
};

export const isFileStatus = (value: string | FileStatus): value is FileStatus => {
  return value === "approved" || value === "rejected" || value === "pending";
};

export type FileStatus = "approved" | "rejected" | "pending";

export type ParsedDocumentMetadata = {
  representative_id?: string;
  comment?: string;
  error_code?: DocumentErrorCode;
  requirement_id?: string;
};

export type MoovDocument = {
  accountID: string;
  createdOn: string;
  fileID: string;
  fileName: string;
  filePurpose: FilePurpose;
  fileSizeBytes: number;
  fileStatus: FileStatus;
  updatedOn: string;
  metadata: string;
  decisionReason?: string;
  internalOnly?: boolean;
};

export interface MoovDocumentWithDetails extends MoovDocument {
  bankStatement?: {
    bankStatementID: string;
    clientAddress: string;
    clientName: string;
    createdOn: string;
    endingBalance?: number;
    fileID: string;
    highlights: {
      addressMatch: boolean;
      averageDailyBalance?: number;
      bankStatementHighlightID: string;
      bankStatementID: string;
      createdOn: string;
      isRecentThreeMonths: boolean;
      lastFourAccountNumberMatch: boolean;
      nameMatch: boolean;
    };
    lastFourAccountNumber: string;
    startingBalance?: number;
    statementDate: string;
    statementEndDate: string;
    statementStartDate: string;
    transactions: {
      amount?: number;
      bankStatementID: string;
      createdOn: string;
      date: string;
      transactionID: string;
      transactionType: string;
    }[];
  };
  identityDocument?: {
    givenNames: string;
    familyName: string;
    address: string;
    expDate: string;
    nameMatch: boolean;
    dobMatch: boolean;
    addressMatch: boolean;
    isExpired: boolean;
    imageManipulation: boolean;
    isIdentityDocument: boolean;
    onlineDuplicate: boolean;
    suspiciousWords: boolean;
  };
}

export interface PatchDocument {
  fileID: string;
  fileStatus: string;
  metadata?: string;
  decisionReason?: string;
}

export const DocumentErrorCodeMap = {
  "invalid-value": "Invalid value",
  "failed-automatic-verification": "Failed automatic verification",
  "failed-other": "Failed other",
  "invalid-address": "Invalid address",
  "address-restricted": "Address restricted",
  "tax-id-mismatch": "Tax ID mismatch",
  "document-id-mismatch": "Document ID mismatch",
  "document-date-of-birth-mismatch": "Document date of birth mismatch",
  "document-name-mismatch": "Document name mismatch",
  "document-address.mismatch": "Document address mismatch",
  "document-number-mismatch": "Document number mismatch",
  "document-incomplete": "Document incomplete",
  "document-failed-risk": "Document failed risk",
  "document-illegible": "Document illegible",
  "document-unsupported": "Document unsupported",
  "document-not-uploaded": "Document not uploaded",
  "document-corrupt": "Document corrupt",
  "document-expired": "Document expired"
};

export type DocumentErrorCode = keyof typeof DocumentErrorCodeMap;

export const isFileError = (value: string | DocumentErrorCode): value is DocumentErrorCode => {
  return Object.keys(DocumentErrorCodeMap).includes(value);
};

// Type guard to ensure the file is a native File object that can be uploaded.
// This function is used to differentiate between File objects that are able to be uploaded
// and documents that have already been processed (which would have a fileID property).
export const isNativeFile = (value?: FileForUpload): value is File => {
  return !value?.fileID;
};
