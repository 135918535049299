import { LiveMoovAPIClient } from "./api";
import {
  FullIssuedCard,
  IssuedCard,
  IssuedCardAuthorization,
  IssuedCardListFilter,
  IssuedCardTransaction,
  IssuingAuthorizationListFilter,
  IssuingTransactionsListFilter,
  RequestCard,
  UpdateIssuedCard
} from "./issuing.model";
import { ErrorResponse } from "./request";

export interface IssuingAPI {
  /** Lists the issued cards for an account. */
  list(
    facilitatorID: string,
    accountID: string,
    filter?: IssuedCardListFilter
  ): Promise<[IssuedCard[] | undefined, ErrorResponse | undefined]>;

  /** Requests a new issued card */
  request(
    facilitatorID: string,
    accountID: string,
    requestCard: RequestCard
  ): Promise<[IssuedCard | undefined, ErrorResponse | undefined]>;

  /** Gets details of the issued card. */
  get(
    facilitatorID: string,
    accountID: string,
    cardID: string
  ): Promise<[IssuedCard | undefined, ErrorResponse | undefined]>;

  /** Gets the full details of the issued card. */
  getFullDetails(
    facilitatorID: string,
    accountID: string,
    cardID: string
  ): Promise<[FullIssuedCard | undefined, ErrorResponse | undefined]>;

  /** Updates details of the issued card. */
  update(
    facilitatorID: string,
    accountID: string,
    cardID: string,
    updateCard: UpdateIssuedCard
  ): Promise<[undefined, ErrorResponse | undefined]>;

  /** Lists all authorizations involving issued cards. */
  listAuthorizations(
    facilitatorID: string,
    accountID: string,
    filter?: IssuingAuthorizationListFilter
  ): Promise<[IssuedCardAuthorization[] | undefined, ErrorResponse | undefined]>;

  /** Lists all transactions involving issued cards. */
  listTransactions(
    facilitatorID: string,
    accountID: string,
    filter?: IssuingTransactionsListFilter
  ): Promise<[IssuedCardTransaction[] | undefined, ErrorResponse | undefined]>;
}

export class LiveIssuingAPI implements IssuingAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async list(
    facilitatorID: string,
    accountID: string,
    filter?: IssuedCardListFilter
  ): Promise<[IssuedCard[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<IssuedCard[]>(
      `/issuing/${accountID}/issued-cards`,
      {
        query: filter,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async request(
    facilitatorID: string,
    accountID: string,
    requestCard: RequestCard
  ): Promise<[IssuedCard | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<IssuedCard>(
      `/issuing/${accountID}/issued-cards`,
      {
        method: "POST",
        json: requestCard,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async get(
    facilitatorID: string,
    accountID: string,
    cardID: string
  ): Promise<[IssuedCard | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<IssuedCard>(
      `/issuing/${accountID}/issued-cards/${cardID}`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async getFullDetails(
    facilitatorID: string,
    accountID: string,
    cardID: string
  ): Promise<[FullIssuedCard | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<FullIssuedCard>(
      `/issuing/${accountID}/issued-cards/${cardID}/details`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async update(
    facilitatorID: string,
    accountID: string,
    cardID: string,
    updateCard: UpdateIssuedCard
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/issuing/${accountID}/issued-cards/${cardID}`,
      {
        method: "PATCH",
        json: updateCard,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async listAuthorizations(
    facilitatorID: string,
    accountID: string,
    filter?: IssuingAuthorizationListFilter
  ): Promise<[IssuedCardAuthorization[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<IssuedCardAuthorization[]>(
      `/issuing/${accountID}/authorizations`,
      {
        query: filter,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async listTransactions(
    facilitatorID: string,
    accountID: string,
    filter?: IssuingTransactionsListFilter
  ): Promise<[IssuedCardTransaction[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<IssuedCardTransaction[]>(
      `/issuing/${accountID}/card-transactions`,
      {
        query: filter,
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
