import clsx from "clsx";
import { HTMLAttributes, ReactNode, useEffect, useReducer, useState } from "react";
import { AnimateHeight } from "components/animate-height/AnimateHeight";
import BlockedIcon from "components/icons/blocked.svg?react";
import CheckIcon from "components/icons/checkmark_circle_outlined.svg?react";
import ErrorIcon from "components/icons/error_outlined.svg?react";
import InfoIcon from "components/icons/info_outlined.svg?react";
import styles from "./Toaster.module.scss";

export type ToastStatusType = "success" | "warn" | "error" | "info";

const icons = {
  success: <CheckIcon />,
  warn: <ErrorIcon />,
  error: <BlockedIcon />,
  info: <InfoIcon />
};

export interface ToastInput {
  message: ReactNode;
  status: ToastStatusType;
  duration?: number | "persist";
}

export interface ToastEntry extends ToastInput {
  key: string;
}

interface ToastAction {
  type: "toast" | "kill" | "solo";
  value: ToastEntry;
}

const variants = {
  open: {
    opacity: 1,
    height: "auto"
  },
  collapsed: { opacity: 0, height: 0 }
};

const ToastComponent = ({ message, status, duration = 5000 }: ToastInput) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      window.setTimeout(() => {
        setShow(true);
        if (duration !== "persist") {
          window.setTimeout(() => {
            setShow(false);
          }, duration);
        }
      }, 100);
    }
  }, [message]);

  return (
    <AnimateHeight variants={variants} isVisible={show}>
      <div className={clsx(styles.toastMessage, styles[status])}>
        {status && icons[status]} {message ? message : ""}
      </div>
    </AnimateHeight>
  );
};

const kill = (state: ToastEntry[], action: ToastAction) => {
  const index = state.findIndex((entry) => entry.key === action.value.key);
  const arr = [...state];
  arr.splice(index, 1);
  return arr;
};

const reducer = (state: ToastEntry[], action: ToastAction) => {
  switch (action.type) {
    case "toast":
      if (state.find((entry) => entry.key === action.value.key)) return state;
      return [...state, action.value];
    case "kill":
      return kill(state, action);
    case "solo":
      return [action.value];
  }
};
const initialState: ToastEntry[] = [];

function Toaster({
  toastInput,
  duration,
  ...rest
}: {
  toastInput: ToastEntry[] | ToastInput;
  duration?: number | "persist";
} & HTMLAttributes<HTMLDivElement>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (Array.isArray(toastInput)) {
      const toastEntries = toastInput;
      const existingEntries = [...state];
      // Kill existing toasts that aren't in the new list
      existingEntries
        .filter((toast) => !toastEntries.find((entry) => entry.key === toast.key))
        .forEach((toast) => dispatch({ type: "kill", value: toast }));
      // Add toasts that aren't in the existing list
      toastEntries
        .filter((toast) => !existingEntries.find((entry) => entry.key === toast.key))
        .forEach((toast) => dispatch({ type: "toast", value: toast }));
    } else if (toastInput?.message) {
      const toastEntry: ToastEntry = {
        message: toastInput.message,
        status: toastInput.status || "success",
        duration: toastInput.duration,
        key: "solo-toast"
      };
      dispatch({
        type: "solo",
        value: toastEntry
      });
    }
  }, [toastInput]);

  return (
    <div className="toastContainer" {...rest}>
      {state.map((toastEntry) => {
        return (
          <ToastComponent
            key={toastEntry.key}
            message={toastEntry.message}
            status={toastEntry.status}
            duration={toastEntry.duration || duration}
          />
        );
      })}
    </div>
  );
}

export default Toaster;
