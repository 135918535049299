import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { BirthDate } from "@moovfinancial/cargo/src/components/BusinessLogicCoupled/Form/Input/MaskingInputs/BirthDateMaskingInput";
import { stringToFormattedSSN } from "@moovfinancial/common/utils/format/formatSSN";
import { DecryptedAccountData, DecryptedRepresentativeData, GovernmentID, TaxID } from "api/v2";
import { APIContext } from "contexts/APIContext";

interface UseDecryptValues {
  decryptedTaxID: { object: TaxID; value: string } | undefined;
  decryptedGovernmentID: { object: GovernmentID; value: string } | undefined;
  decryptedBirthday: { object: BirthDate; value: string } | undefined;
  decryptAccount: (accountID: string, facilitatorID: string) => void;
  decryptRepresentative: (
    accountID: string,
    representativeID: string,
    facilitatorID: string
  ) => void;
  error: boolean;
  loading: boolean;
}

export const useDecryptValues = (): UseDecryptValues => {
  const { moov } = useContext(APIContext);

  const [decryptedTaxID, setDecryptedTaxID] = useState<
    { object: TaxID; value: string } | undefined
  >();
  const [decryptedGovernmentID, setDecryptedGovernmentID] = useState<
    { object: GovernmentID; value: string } | undefined
  >();
  const [decryptedBirthday, setDecryptedBirthday] = useState<
    { object: BirthDate; value: string } | undefined
  >();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const applyDecryptionResults = (result?: DecryptedRepresentativeData | DecryptedAccountData) => {
    setDecryptedGovernmentID(
      result?.governmentID
        ? {
            object: result.governmentID,
            value:
              stringToFormattedSSN(
                result.governmentID.ssn?.full ||
                  result.governmentID.ssn?.lastFour ||
                  result.governmentID.itin?.full ||
                  result.governmentID.itin?.lastFour ||
                  ""
              ) || ""
          }
        : undefined
    );
    setDecryptedBirthday(
      result?.birthDate
        ? {
            object: result.birthDate,
            value: `${result.birthDate.month}/${result.birthDate.day}/${result.birthDate.year}`
          }
        : undefined
    );

    if (result && "taxID" in result) {
      setDecryptedTaxID(
        result?.taxID
          ? {
              object: result.taxID,
              value: result.taxID.ein?.number || ""
            }
          : undefined
      );
    }
  };

  const decryptAccount = (accountID: string, facilitatorID: string) => {
    setLoading(true);
    void moov.accounts
      .decrypt(accountID, facilitatorID)
      .then(([result, error]) => {
        if (error) {
          toast("Decrypting account value failed");
          setError(true);
        }
        applyDecryptionResults(result);
        setError(false);
      })
      .then(() => setLoading(false));
  };

  const decryptRepresentative = (
    accountID: string,
    representativeID: string,
    facilitatorID: string
  ) => {
    setLoading(true);
    void moov.accounts.representatives
      .decrypt(accountID, representativeID, facilitatorID)
      .then(([result, error]) => {
        if (error) {
          toast("Decrypting representative value failed");
          setError(true);
        }
        applyDecryptionResults(result);
        setError(false);
      })
      .finally(() => setLoading(false));
  };

  return {
    decryptedTaxID,
    decryptedGovernmentID,
    decryptedBirthday,
    decryptAccount,
    decryptRepresentative,
    error,
    loading
  };
};
