"use client";

import clsx from "clsx";
import {
  type ComponentProps,
  type ElementType,
  type FocusEvent,
  type ReactNode,
  useEffect,
  useState
} from "react";
import { useId } from "@moovfinancial/common/hooks/useGetId";
import { Textarea } from "./Textarea";
import { ValidatedInput } from "./ValidatedInput";
import styles from "./FloatingLabelInput.module.scss";

type BaseProps<C> = {
  /**
   * The component to use as the base input. Defaults to the basic HTML `input` or the `Textarea` element
   */
  as: C;
  /**
   * Whether or not to force the floating styles. Defaults to `false`.
   * If `true`, the field will always have floating styles.
   *
   * Helpful when changing input values programmatically (no user focus) or working with falsy values like 0.
   */
  forceFloating?: boolean;
  /**
   * name of the field
   */
  name?: string;
  /**
   * id of the field
   */
  id?: string;
  /**
   * Classname
   */
  className?: string;
  /**
   * Label
   */
  label: string | ReactNode;
  /**
   * onFocus event handler
   */
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  /**
   * onBlur event handler
   */
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
};

/**
 * This is the key: we extend the BaseProps above with the props extracted from the passed-in component.
 * We need to omit the props that are already defined in BaseProps so they are properly typed in the component,
 * otherwise TS cannot infer their type as they might be overridden by the component's props
 */
export type FloatingLabelWrapperProps<C extends ElementType = "input"> = BaseProps<C> &
  Omit<ComponentProps<C>, keyof BaseProps<C>>;

export type FloatingLabelInputProps = Omit<FloatingLabelWrapperProps<typeof ValidatedInput>, "as">;
export type FloatingLabelTextareaProps = Omit<FloatingLabelWrapperProps<typeof Textarea>, "as">;

/**
 * Generic wrapper for both Inputs and Textareas to provide a floating label effect. REQUIRES `as` prop
 */
export const FloatingLabelWrapper = <C extends ElementType = "input">({
  className,
  forceFloating = false,
  label,
  onBlur,
  onFocus,
  value,
  name,
  id: propId,
  as: BaseComponent,
  ...rest
}: FloatingLabelWrapperProps<C>) => {
  const [floating, setFloating] = useState(!!value);
  const [focused, setFocused] = useState(false);
  // Passing the name to getId ensures that the id generates is always the same, so snapshots will work
  const id = useId(propId, name);

  useEffect(() => {
    if (value) {
      setFloating(true);
    } else if (!value && !focused && floating) {
      setFloating(false);
    }
  }, [floating, focused, value]);

  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    if (!floating) {
      setFocused(true);
      setFloating(true);
    }
    onFocus?.(e);
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (value == null || value === "") setFloating(false);
    onBlur?.(e);
  }

  function handleAutofill(e: React.AnimationEvent<HTMLInputElement>) {
    if (e.animationName === "onAutoFillStart") {
      if (!floating) {
        setFloating(true);
      }
    }
  }

  const floatingStyles = forceFloating || floating;

  return (
    <div className={styles.FloatingLabelInput}>
      <label className={clsx(styles.floatingLabel, floatingStyles && styles.floating)} htmlFor={id}>
        {label}
      </label>
      {/* Type '{ id: string; name: string | undefined; onAnimationStart: (e: AnimationEvent<ElementType<C>>) => void; onBlur: (e: FocusEvent<ElementType<C>, Element>) => void; onFocus: (e: FocusEvent<...>) => void; ref: ForwardedRef<...> | undefined; theme: { ...; }; value: string | ... 2 more ... | undefined; } & Omit<...>' is not assignable to type 'LibraryManagedAttributes<C, any>' */}
      {/* @ts-expect-error was not able to figure out how to remove this last type error 👆 */}
      <BaseComponent
        id={id}
        name={name}
        onAnimationStart={handleAutofill}
        onBlur={handleBlur}
        onFocus={handleFocus}
        // In case the underlying input has a default placeholder, we hide it when we're NOT floating
        // i.e. when the input is empty and not being edited
        theme={{ inputElement: clsx(className, !floatingStyles && styles.hidden) }}
        value={value}
        {...rest}
      />
    </div>
  );
};

export const FloatingLabelInput = ({ ...rest }: FloatingLabelInputProps) => {
  return <FloatingLabelWrapper as={ValidatedInput} {...rest} />;
};

export const FloatingLabelTextarea = ({ ...rest }: FloatingLabelTextareaProps) => {
  return <FloatingLabelWrapper as={Textarea} {...rest} />;
};
