import clsx from "clsx";
import React, { HTMLAttributes, useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Button, Icon } from "@moovfinancial/cargo";
import { IconChevronDown, IconChevronUp, IconClear } from "../../../../icons";
import styles from "./BAM.module.scss";

export interface BAMProps extends HTMLAttributes<HTMLDivElement> {
  onClose?: (prop: boolean) => void;
  onNext?: () => void;
  hasNext?: boolean;
  onPrevious?: () => void;
  hasPrevious?: boolean;
  children: [
    React.ReactElement<HeaderProps>,
    React.ReactElement<{ children: React.ReactElement[] }>,
    React.ReactElement<FooterProps>?
  ];
}

interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
}

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  sticky?: boolean;
}

export interface BAMControlProps extends Omit<BAMProps, "children"> {
  onClose: () => void;
}

/**
 * The BAM is a special-purpose dialog we use to display large amounts of
 * information. It's modal in the sense that it covers up any content underneath it,
 * but it's technically non-modal because navigation is allowed when it's open.
 */
export const BAM = ({ children, ...props }: BAMProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.show();
    }
  }, [dialogRef]);

  const handleClose = useCallback(() => {
    if (dialogRef.current?.open) {
      dialogRef.current.close();
      props.onClose && props.onClose(false);
    }
  }, [dialogRef, props.onClose]);

  const [header, body, footer] = children;

  return (
    <>
      {createPortal(
        <dialog ref={dialogRef} className={styles.big}>
          <header className={styles.header}>
            <div className={styles.dialogActions}>{header.props.children}</div>
            <h1 className={styles.headerTitle}>{header.props.title}</h1>
            <BAMControls {...props} onClose={handleClose} />
          </header>
          <section className={styles.contentWrapper}>
            {body}
            {footer && (
              <footer className={clsx(styles.footer, footer.props.sticky && styles.sticky)}>
                {footer}
              </footer>
            )}
          </section>
        </dialog>,
        document.body
      )}
    </>
  );
};

const BAMControls = ({ onClose, onNext, hasNext, onPrevious, hasPrevious }: BAMControlProps) => {
  return (
    <div className={styles.controlsWrapper}>
      {onNext && onPrevious && (
        <div className={styles.nextPrevControls}>
          {/* Previous */}
          <Button
            buttonStyle="fill"
            buttonType="secondary"
            buttonSize="S"
            disabled={!hasPrevious}
            onClick={onPrevious}
            type="button"
          >
            <Icon iconComponent={IconChevronUp} size="XS" />
          </Button>
          {/* Next */}
          <Button
            buttonStyle="fill"
            buttonType="secondary"
            buttonSize="S"
            disabled={!hasNext}
            onClick={onNext}
            type="button"
          >
            <Icon iconComponent={IconChevronDown} size="XS" />
          </Button>
        </div>
      )}
      {/* Close */}
      <Button
        buttonStyle="text"
        buttonType="secondary"
        buttonSize="S"
        data-testid="BAMClose"
        onClick={onClose}
        type="button"
      >
        <Icon className={styles.closeIcon} iconComponent={IconClear} />
      </Button>
    </div>
  );
};

BAM.Header = function BAMHeader({ children }: HeaderProps): JSX.Element {
  return <>{children}</>;
};

BAM.Body = function BAMBody({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <>{children}</>;
};

BAM.Footer = function BAMFooter({ children }: FooterProps) {
  return <>{children}</>;
};
