import { FocusEvent, memo, useCallback, useMemo } from "react";
import {
  EMPTY_STATE,
  dateToFormattedString,
  formatDateInput,
  formattedDateStringToDate,
  stringToFormattedDateString
} from "@moovfinancial/common/utils/format/formatDate";
import { MaskingInput, MaskingInputProps } from "./MaskingInput";

const noop = (value: string) => value;

export interface DateMaskingInputProps
  extends Omit<
    MaskingInputProps<string>,
    | "acceptedChars"
    | "formattedStringToModelValue"
    | "inputFormatter"
    | "modelValueToFormattedString"
    | "onValueChange"
    | "value"
  > {
  /**
   * Regex that the input must match.
   *
   * NOTE: This regex should contain the correct quantifier, probably a "+"
   *
   * @default /[\d/]+/g
   */
  acceptedChars?: RegExp;
  /**
   * function called on value change. The function should update the value in the parent component's state.
   */
  onValueChange?: (value: Date | undefined, formattedValue: string) => void;
  /**
   * The Date or formatted date string value of the input
   */
  value: Date | string;
}

/**
 * Small HOC that wraps `MaskingInput` and restricts the model values to strings only
 */
export const DateMaskingInput = memo(function DateMaskingInput({
  acceptedChars = /[\d/MDY]+/g,
  onValueChange: onValueChangeProp,
  value,
  onFocus: propOnFocus,
  ...rest
}: DateMaskingInputProps) {
  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (e.target.value === EMPTY_STATE) {
        e.target.value = "";
      }
      if (propOnFocus) {
        propOnFocus(e);
      }
    },
    [propOnFocus]
  );

  const handleBeforeInput = useCallback((e: React.CompositionEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;

    // If the user enters a non-slash character, but the cursor is at the slash characters, move the cursor forward
    if (e.data !== "/" && (input.selectionStart === 2 || input.selectionStart === 5)) {
      input.selectionStart += 1;
      input.selectionEnd = input.selectionEnd ? input.selectionEnd + 1 : 1;
    }

    // If the user enters a slash character, but the cursor is not yet at the slash position, assume
    // the user was trying to enter a single digit month or day and add a leading zero and move the cursor
    if (e.data === "/" && input.selectionStart && input.selectionStart < 2) {
      input.value = `0${input.value.slice(0, 1)}${input.value.slice(1)}`;
      input.selectionStart = 3;
      input.selectionEnd = 3;
    } else if (
      e.data === "/" &&
      input.selectionStart &&
      input.selectionStart > 2 &&
      input.selectionStart < 5
    ) {
      input.value = `${input.value.slice(0, 3)}0${input.value.slice(3)}`;
      input.selectionStart = 6;
      input.selectionEnd = 6;
    }
  }, []);

  const onValueChange = useCallback(
    (_: string, formattedValue: string) => {
      const date = formattedDateStringToDate(formattedValue);

      // if we had to adjust the date to make it valid, update the formatted string
      const formatted = date ? dateToFormattedString(date) : formattedValue;
      return onValueChangeProp?.(date, formatted);
    },
    [onValueChangeProp]
  );

  const valueString = useMemo(
    () => (value instanceof Date ? dateToFormattedString(value) : value),
    [value]
  );

  return (
    <MaskingInput
      {...rest}
      acceptedChars={acceptedChars}
      formattedStringToModelValue={noop}
      guided
      inputFormatter={formatDateInput}
      modelValueToFormattedString={stringToFormattedDateString}
      onBeforeInput={handleBeforeInput}
      onFocus={handleOnFocus}
      onValueChange={onValueChange}
      placeholder={EMPTY_STATE}
      value={valueString}
    />
  );
});
