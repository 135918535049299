import { useEffect, useState } from "react";

export const useResizeObserver = (ref: React.RefObject<HTMLElement>, duration: number = 400) => {
  const [size, setSize] = useState<DOMRect | undefined>(ref.current?.getBoundingClientRect());
  const [isAnimating, setIsAnimating] = useState(true);

  let timer: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (size?.height) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        setIsAnimating(false);
      }, duration);
    }
  }, [size]);

  useEffect(() => {
    const observerCallback = (entries: ResizeObserverEntry[]) => {
      if (entries[0] && entries[0].target) {
        setIsAnimating(true);
        setSize(entries[0].target.getBoundingClientRect());
      }
    };

    const observer = new ResizeObserver(observerCallback);

    if (ref.current !== null) {
      observer.observe(ref.current);
    }

    return () => observer && observer.disconnect();
  }, [ref]);

  return { size, isAnimating };
};
