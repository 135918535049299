import {
  AnalyticsParams,
  AnalyticsParamsWithCount,
  DateRange,
  ProfileCreated,
  SpecificTransfer,
  TransferStatusResponse,
  TransferSumResponse
} from "./analytics.model";
import { LiveMoovAPIClient } from "./api";
import { APIResponse } from "./request";

export interface AnalyticsAPI {
  /** Returns the sum of all transfers, filtered by the given params */
  getTransfersSum(
    facilitatorID: string,
    analyticsParams: AnalyticsParams
  ): APIResponse<TransferSumResponse[]>;
  /** Returns transfer statuses */
  getTransferStatuses(
    facilitatorID: string,
    analyticsParams: AnalyticsParams
  ): APIResponse<TransferStatusResponse[]>;
  /** Returns the largest transfer within the given params */
  getLargestTransfers(
    facilitatorID: string,
    analyticsParams: AnalyticsParamsWithCount
  ): APIResponse<SpecificTransfer[]>;
  /** Returns the smallest transfer within the given params */
  getSmallestTransfers(
    facilitatorID: string,
    analyticsParams: AnalyticsParamsWithCount
  ): APIResponse<SpecificTransfer[]>;
  /** Returns profiles created within the given params */
  getProfilesCreated(
    facilitatorID: string,
    analyticsParams: AnalyticsParams
  ): APIResponse<ProfileCreated[]>;
}

interface ApiMetrics {
  start: string;
  stop: string;
  amount?: number;
}

function transformValues(metrics: ApiMetrics[]): DateRange[] {
  for (const m of metrics) {
    Object.assign(m, {
      start: new Date(m.start),
      stop: new Date(m.stop),
      amount: m.amount ? m.amount / 100 : undefined
    });
  }
  return metrics as unknown as DateRange[];
}

export class LiveAnalyticsAPI implements AnalyticsAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async getTransfersSum(
    facilitatorID: string,
    analyticsParams: AnalyticsParams
  ): APIResponse<TransferSumResponse[]> {
    const [result, err] = await this._client.request<ApiMetrics[]>("/analytics/transfers/sum", {
      method: "POST",
      json: analyticsParams,
      xAccountID: facilitatorID
    });
    const transformed = result ? (transformValues(result) as TransferSumResponse[]) : undefined;
    return [transformed, err];
  }

  async getTransferStatuses(
    facilitatorID: string,
    analyticsParams: AnalyticsParams
  ): APIResponse<TransferStatusResponse[]> {
    const [result, err] = await this._client.request<ApiMetrics[]>(
      "/analytics/transfers/statuses",
      {
        method: "POST",
        json: analyticsParams,
        xAccountID: facilitatorID
      }
    );
    const transformed = result ? (transformValues(result) as TransferStatusResponse[]) : undefined;
    return [transformed, err];
  }

  async getLargestTransfers(
    facilitatorID: string,
    analyticsParams: AnalyticsParamsWithCount
  ): APIResponse<SpecificTransfer[]> {
    const [result, err] = await this._client.request<ApiMetrics[]>("/analytics/transfers/largest", {
      method: "POST",
      json: analyticsParams,
      xAccountID: facilitatorID
    });
    const transformed = result ? (transformValues(result) as SpecificTransfer[]) : undefined;
    return [transformed, err];
  }

  async getSmallestTransfers(
    facilitatorID: string,
    analyticsParams: AnalyticsParamsWithCount
  ): APIResponse<SpecificTransfer[]> {
    const [result, err] = await this._client.request<ApiMetrics[]>(
      "/analytics/transfers/smallest",
      {
        method: "POST",
        json: analyticsParams,
        xAccountID: facilitatorID
      }
    );
    const transformed = result ? (transformValues(result) as SpecificTransfer[]) : undefined;
    return [transformed, err];
  }

  async getProfilesCreated(
    facilitatorID: string,
    analyticsParams: AnalyticsParams
  ): APIResponse<ProfileCreated[]> {
    const [result, err] = await this._client.request<ApiMetrics[]>(
      "/analytics/accounts/profiles-created",
      {
        method: "POST",
        json: analyticsParams,
        xAccountID: facilitatorID
      }
    );
    const transformed = result ? (transformValues(result) as ProfileCreated[]) : undefined;
    return [transformed, err];
  }
}
