import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Loading } from "@moovfinancial/cargo";
import { OnboardingContext } from "contexts/OnboardingContext";
import { BankAccountForm } from "./BankAccountForm";
import { ReviewBankAccounts } from "./ReviewBankAccounts";

export function BankAccountStep() {
  const { bankAccounts, isLoading } = useContext(OnboardingContext);

  const hasBankAccounts = !!bankAccounts.length;

  return isLoading ? (
    <Loading />
  ) : (
    <Routes>
      <Route
        index
        element={
          hasBankAccounts ? (
            <ReviewBankAccounts bankAccounts={bankAccounts} />
          ) : (
            <Navigate to="add" />
          )
        }
      />
      <Route path="add" element={<BankAccountForm />} />
    </Routes>
  );
}
