import styles from "./StepProgress.module.scss";

interface StepProgressProps {
  currentStep: number;
  stepName: string | React.ReactNode;
  subStepName?: string;
  totalSteps: number;
}

export const StepProgress = ({
  currentStep,
  stepName,
  subStepName,
  totalSteps
}: StepProgressProps) => {
  return (
    <nav className={styles.stepProgress}>
      <progress value={currentStep} max={totalSteps} className={styles.progress} />
      <div className={styles.pages}>
        Step {currentStep} of {totalSteps}
      </div>
      <div className={styles.stepName}>
        {stepName}
        {subStepName && ` – ${subStepName}`}
      </div>
    </nav>
  );
};
