import { useEffect, useMemo, useState } from "react";
import { FlagType, getFlags } from "helpers/featureFlags";

function isFlagEnabled(flagName: string, storedFlags: FlagType[]) {
  return !!storedFlags.find((storedFlag) => storedFlag.flag === flagName && storedFlag.enabled);
}

function isMockDataEnabled(flagName: string, storedFlags: FlagType[]) {
  return !!storedFlags.find((storedFlag) => storedFlag.flag === flagName && storedFlag.mock);
}

function getFlagsStatus(flagNames: string[], storedFlags: FlagType[]) {
  let newEnabled = true;
  let newMock = true;

  if (!flagNames.length || !storedFlags.length) {
    newEnabled = false;
    newMock = false;
  } else {
    flagNames.forEach((flagName) => {
      const isThisFlagEnabled = isFlagEnabled(flagName, storedFlags);
      const isMockEnabled = isMockDataEnabled(flagName, storedFlags);
      newEnabled = isThisFlagEnabled ? newEnabled : false;
      newMock = isMockEnabled ? newMock : false;
    });
  }

  return [newEnabled, newMock];
}

function useFlags(flagNames: string[]): [boolean, boolean, FlagType[]] {
  const storedFlags = useMemo(() => getFlags(), [flagNames]);
  const [initialEnabled, initialMock] = getFlagsStatus(flagNames, storedFlags);
  const [enabled, setEnabled] = useState<boolean>(initialEnabled);
  const [mock, setMock] = useState(initialMock);

  useEffect(() => {
    const [newEnabled, newMock] = getFlagsStatus(flagNames, storedFlags);
    if (enabled !== newEnabled) setEnabled(newEnabled);
    if (mock !== newMock) setMock(newMock);
  }, [flagNames]);

  return [enabled, mock, storedFlags];
}

export default useFlags;
