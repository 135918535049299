import { memo } from "react";
import { BaseButton } from "../BaseButton";
import { ButtonLike, ButtonLikeProps } from "../ButtonLike";

export interface ButtonProps extends Omit<ButtonLikeProps<typeof BaseButton>, "as"> {}

/**
 * Button is a component that is the standard for `<button>` elements.
 *
 * Meant to be used for click events and other on-page actions.
 */
export const Button = memo(function Button({ children, ...rest }: ButtonProps) {
  return (
    <ButtonLike as={BaseButton} data-testid="button" {...rest}>
      {children}
    </ButtonLike>
  );
});
