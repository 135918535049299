import { useContext, useEffect, useState } from "react";
import { WindowResizeContext, WindowSize } from "contexts/WindowResizeContext";

export type Device = "mobile" | "desktop" | "largeDesktop";

type BreakpointRanges = Record<Device, [number, number]>;

/*  Derived from the no-longer used CSS Variables:
 *
 *  --dt-mobile-breakpoint-max: 959px;
 *  --dt-desktop-breakpoint-min: 960px;
 *  --dt-desktop-breakpoint-max: 1279px;
 *  --dt-large-desktop-breakpoint-min: 1280px;
 */

const breakpointMap: BreakpointRanges = {
  mobile: [0, 959],
  desktop: [960, 1279],
  largeDesktop: [1280, Number.MAX_VALUE]
};

function isViewportWidthInsideBreakpoint(windowSize: WindowSize, breakpoint: Device | number) {
  if (typeof breakpoint === "number") {
    return windowSize.width <= breakpoint;
  }
  const [min, max] = breakpointMap[breakpoint];
  return min <= windowSize.width && windowSize.width <= max;
}

export function useBreakpoint(breakpoint: Device | number) {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const { resizeNotifier } = useContext(WindowResizeContext);

  useEffect(() => {
    resizeNotifier.subscribe(setWindowSize);
    return () => resizeNotifier.unsubscribe(setWindowSize);
  }, []);

  return isViewportWidthInsideBreakpoint(windowSize, breakpoint);
}
