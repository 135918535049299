import clsx from "clsx";
import { HTMLAttributes, useContext } from "react";
import { FormGroup, TextInput } from "@moovfinancial/cargo";
import useInputMask from "@moovfinancial/common/hooks/useInputMask";
import { formatPhoneForDB } from "@moovfinancial/common/utils/format/formatPhone";
import { Label } from "components/form/Form";
import { AccountSetupContext } from "../../AccountSetupContext";
import styles from "../../AccountSetup.module.scss";

interface PhoneFieldProps extends HTMLAttributes<HTMLInputElement> {
  warn?: boolean;
  isLocked?: boolean;
  required?: boolean;
  errored?: boolean;
  value?: string;
  dispatchType: "businessPhone" | "individualPhone";
}

export const PhoneField = ({
  warn,
  isLocked,
  errored,
  required,
  dispatchType,
  className,
  value: valueProp = "",
  ...rest
}: PhoneFieldProps) => {
  const { account, dispatch, resetInput, validate, setInvalidInputs, invalidInputs, isErrored } =
    useContext(AccountSetupContext);

  const { value, onInput, onBeforeInput } = useInputMask({
    format: formatPhoneForDB as (s: string) => string,
    value: valueProp || "",
    onFormatComplete: (formattedValue) =>
      dispatch({
        type: dispatchType,
        value: { number: formattedValue }
      })
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetInput(e.target.name);
    onInput(e);
  };

  const getWarningMessage = () => {
    if (warn) {
      return "Phone number is missing.";
    }
  };

  const requirementsNotMet =
    !account.profile?.individual?.email &&
    !account.profile?.individual?.phone?.number &&
    isErrored("phone");
  const invalidOrErrored = invalidInputs.includes("phone") || errored;
  const isErroring = requirementsNotMet || invalidOrErrored;

  const getErrorMessage = () => {
    if (requirementsNotMet) {
      return "Phone or email is required.";
    }
    if (invalidOrErrored) {
      return "Phone number is invalid.";
    }
  };

  const validatePhone = (input: HTMLInputElement) => {
    if (input.value.length !== 0 && input.value.length < 12) {
      setInvalidInputs((prev) => [...prev, "phone"]);
    } else {
      validate(input);
    }
  };

  return (
    <FormGroup
      className={clsx(styles.formGroup, className)}
      warningMessage={getWarningMessage()}
      errorMessage={getErrorMessage()}
    >
      <Label label="Phone" />
      <TextInput
        disabled={isLocked}
        inputMode="numeric"
        isErroring={isErroring}
        isLocked={isLocked}
        isWarning={warn || invalidInputs.includes("phone")}
        name="phone"
        required={required}
        title={
          account.accountType === "individual" ? "An email or phone number is required." : undefined
        }
        type="tel"
        value={value}
        onBeforeInput={onBeforeInput}
        onBlur={(e) => validatePhone(e.target)}
        onChange={handleChange}
        {...rest}
      />
    </FormGroup>
  );
};
