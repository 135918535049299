import clsx from "clsx";
import { HTMLAttributes } from "react";
import styles from "./Card.module.scss";

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  noPadding?: boolean;
}

export const Card = ({ className, children, noPadding, ...rest }: CardProps) => {
  return (
    <div className={clsx(styles.card, noPadding && styles.noPadding, className)} {...rest}>
      {children}
    </div>
  );
};
