import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormGroup } from "@moovfinancial/cargo";
import { useOnClickOut } from "hooks/useOnClickOut";
import IconInput from "./IconInput";
import styles from "./ComboBox.module.scss";

type ListItem = {
  value: string;
  label: string;
};

interface ComboBoxProps extends React.ComponentProps<typeof IconInput> {
  data: ListItem[];
  format?: "label" | "value" | "both";
  listBoxClassName?: string;
  warn?: boolean;
  isLocked?: boolean;
}

export default function ComboBox({
  value,
  name,
  onFocus,
  onBlur,
  onChange,
  onClick,
  data,
  format = "label",
  listBoxClassName,
  warn = false,
  isLocked = false,
  ...props
}: ComboBoxProps) {
  const [focused, setFocused] = useState(false);
  const [dropdownFocus, setDropdownFocus] = useState(false);
  const [searchData, setSearchData] = useState(data);
  const [dirty, setDirty] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);
  const fistChildRef = useRef<HTMLLIElement>(null);

  const onLocalChange = useCallback(
    (e: any) => {
      setDirty(true);
      if (onChange) onChange(e);
    },
    [onChange]
  );

  useOnClickOut(listRef, () => {
    setDropdownFocus(false);
  });

  useEffect(() => {
    if (value) {
      const search = data.filter((item) =>
        `${item.value.toLowerCase()} ${item.label.toLowerCase()}`.includes(
          value.toString().toLowerCase()
        )
      );
      setSearchData(search);
    } else {
      setSearchData(data);
    }
  }, [value, data]);

  function onFocusHandler(e: React.FocusEvent<HTMLInputElement, Element>) {
    setFocused(true);
    e.target.focus();
    onFocus && onFocus(e);
  }

  function onBlurHandler(e: React.FocusEvent<HTMLInputElement, Element>) {
    setTimeout(() => {
      if (!dropdownFocus) setFocused(false);
      onBlur && onBlur(e);
    }, 200);
  }

  function onClickHandler(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    setFocused(true);
    onClick && onClick(e);
  }

  function onIconClickHandler() {
    if (focused) {
      onBlurHandler({} as React.FocusEvent<HTMLInputElement, Element>);
    } else {
      onClickHandler({} as React.MouseEvent<HTMLInputElement, MouseEvent>);
    }
  }

  function onSelectHandler(value: string) {
    setFocused(false);
    if (dropdownFocus) setDropdownFocus(false);
    onLocalChange({
      target: { value }
    } as React.ChangeEvent<HTMLInputElement>);
  }

  function itemKeyUpHandler(e: React.KeyboardEvent<HTMLLIElement>, value: string) {
    e.stopPropagation();
    e.preventDefault();
    if (e.key === "ArrowDown") {
      if (fistChildRef.current) {
        // @ts-expect-error TODO
        e.target.nextElementSibling?.focus();
      }
    }
    if (e.key === "ArrowUp") {
      if (fistChildRef.current) {
        // @ts-expect-error TODO
        e.target.previousElementSibling?.focus();
      }
    }
    if (e.key === "Enter") {
      onSelectHandler(value);
    }
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "ArrowDown") {
      if (fistChildRef.current) {
        setDropdownFocus(true);
        fistChildRef.current.focus();
      }
    }
  }

  return (
    <FormGroup className={styles.comboboxWrapper} noMargins={true}>
      <IconInput
        aria-autocomplete="list"
        aria-controls={`${name}-list`}
        aria-expanded={focused}
        aria-haspopup="listbox"
        isLocked={isLocked}
        isWarning={warn && !dirty}
        name={name}
        role="combobox"
        value={value}
        onBlur={onBlurHandler}
        onChange={onLocalChange}
        onClick={onClickHandler}
        onFocus={onFocusHandler}
        onIconClick={onIconClickHandler}
        onKeyUp={handleKeyUp}
        {...props}
      />
      <AnimatePresence>
        {(focused || dropdownFocus) && (
          <ul
            className={clsx(styles.combobox, listBoxClassName)}
            role="listbox"
            id={`${name}-list`}
            ref={listRef}
          >
            {searchData.map((item, index) => (
              <li
                key={item.value}
                role="option"
                aria-selected="false"
                className={styles.comboboxItem}
                onClick={() => onSelectHandler(item.value)}
                onKeyUp={(e) => itemKeyUpHandler(e, item.value)}
                ref={index === 0 ? fistChildRef : null}
                tabIndex={0}
              >
                {format === "label"
                  ? item.label
                  : format === "value"
                    ? item.value
                    : `${item.value} - ${item.label}`}
              </li>
            ))}
            {searchData.length === 0 && <li className={styles.comboboxItem}>No matching codes</li>}
          </ul>
        )}
      </AnimatePresence>
    </FormGroup>
  );
}
