import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import { FormGroup, Icon } from "@moovfinancial/cargo";
import { IconOpenInNew } from "@moovfinancial/cargo/icons";
import { Label } from "components/form/Form";
import { Text } from "cleanComponents/Typography/Text";
import { AccountSetupContext } from "../../../AccountSetupContext";
import { CapabilitiyOption } from "./CapabilityOption";
import {
  CapabilityOption,
  businessCapabilityOptions,
  individualCapabilityOptions
} from "./CapabilityOptions.model";
import styles from "../CreateNewAccount.module.scss";

export const CapabilitiesSelector = () => {
  const {
    account: { accountType = "business" },
    capabilities
  } = useContext(AccountSetupContext);
  const [availableCapabilities, setAvailableCapabilities] = useState<CapabilityOption[]>(
    individualCapabilityOptions
  );
  useEffect(() => {
    setAvailableCapabilities(
      accountType === "individual" ? individualCapabilityOptions : businessCapabilityOptions
    );
  }, [accountType]);

  return (
    <FormGroup className={styles.capabilitySelector}>
      <Label label="Capabilities" as="legend" />
      <Text textStyle="paragraph-s-regular">
        These determine what an account can do and what questions we ask.{" "}
        <a
          href="https://docs.moov.io/guides/accounts/capabilities/"
          target="_blank"
          rel="noreferrer"
        >
          Learn more <Icon iconComponent={IconOpenInNew} />
        </a>
      </Text>
      <div key={accountType} className={clsx(styles.capabilityOptions, styles[accountType])}>
        {availableCapabilities.map((capability) => {
          return (
            <CapabilitiyOption
              key={capability.apiValue}
              capability={capability}
              checked={capabilities.includes(capability.apiValue)}
            />
          );
        })}
      </div>
    </FormGroup>
  );
};
