/* eslint-disable */
import { clone } from "remeda";
import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import { assertNever } from "@moovfinancial/common/utils/assertNever";
import deepMerge from "@moovfinancial/common/utils/deepMerge";
import { setSparsePath } from "@moovfinancial/common/utils/setSparsePath";
import { AccountUnderwriting } from "api/v2";
import {
  Account,
  type Address,
  type Business,
  type DateObject,
  type Individual,
  type Name,
  type Phone
} from "api/v2/accounts.model";
import type { SandboxDataObject } from "pages/auth/createFirstAccount";

export interface MoovFile extends File {
  fileID?: string;
}
export interface FileState {
  file: MoovFile;
  status: "pending" | "success" | "error";
  errorMessage?: string;
}

export type StateType = {
  title: string;
  underwriting: AccountUnderwriting;
  account: DeepPartial<Account>;
  prefilled: boolean;
  manualAddress: boolean;
  supportManualAddress: boolean;
  files: FileState[];
};

type PrefillAction = { type: "prefill"; value: SandboxDataObject };
type AddFileAction = { type: "addFile"; value: File };
export type Action =
  | { type: "account"; value: DeepPartial<Account> }
  | { type: "individual"; value: DeepPartial<Individual> }
  | { type: "business"; value: DeepPartial<Business> }
  | { type: "individualName"; value: DeepPartial<Name> }
  | { type: "individualPhone"; value: DeepPartial<Phone> }
  | { type: "individualAddress"; value: DeepPartial<Address> }
  | { type: "individualBirthdate"; value: DeepPartial<DateObject> }
  | { type: "businessAddress"; value: DeepPartial<Address> }
  | { type: "businessPhone"; value: DeepPartial<Phone> }
  | { type: "businessCodes"; value: DeepPartial<Business["industryCodes"]> }
  | { type: "businessTaxID"; value: string }
  | PrefillAction
  | { type: "manualAddress"; value: boolean }
  | { type: "underwriting"; value: AccountUnderwriting }
  | AddFileAction
  | { type: "uploadFiles"; value: FileState[] }
  | { type: "deleteFile"; value: string };

export type ActionType = Action["type"];

const shouldPreserveTargetValue = (val: any, key: string) => {
  if (typeof val === "string" && val === "") return false;
  if (typeof val === "number" && val === 0) return false;
  if (key === "countryCode" && val === "1") return false;
  return true;
};

const calculateFiles = (files: FileState[], action: AddFileAction) => {
  const newFiles = files.filter((file) => file.status !== "error");
  newFiles.push({ file: action.value, status: "pending" });
  return newFiles;
};

const calculateDeletedFiles = (files: FileState[], action: Action) => {
  const fileIndex = files.findIndex((file) => {
    return file.file.name === action.value;
  });
  if (fileIndex !== undefined && fileIndex > -1) {
    files.splice(fileIndex, 1);
  }
  return files;
};

const calculateNewAccount = (stateAccount: DeepPartial<Account>, action: PrefillAction) => {
  let newAccount = stateAccount;
  newAccount = deepMerge(newAccount, action.value.individual as Account, shouldPreserveTargetValue);
  newAccount = deepMerge(newAccount, action.value.business as Account, shouldPreserveTargetValue);

  return newAccount;
};

export const reducer = (state: StateType, action: Action): StateType => {
  // this works as long as we don't add dates or complex types into our state.
  const newState: StateType = clone(state);
  const files = [...state.files];

  switch (action.type) {
    case "account":
      newState.account = deepMerge(state.account, action.value);
      return newState;
    case "individual":
      return setSparsePath(
        newState,
        ["account", "profile", "individual"] as const,
        deepMerge(state.account?.profile?.individual, action.value)
      );
    case "business":
      return setSparsePath(newState, ["account", "profile", "business"] as const, {
        ...state.account?.profile?.business,
        ...action.value
      });
    case "individualName":
      return setSparsePath(newState, ["account", "profile", "individual", "name"] as const, {
        ...state.account?.profile?.individual?.name,
        ...action.value
      });
    case "individualPhone":
      return setSparsePath(newState, ["account", "profile", "individual", "phone"] as const, {
        ...state.account?.profile?.individual?.phone,
        ...action.value,
        countryCode: "1"
      });
    case "individualAddress":
      return setSparsePath(newState, ["account", "profile", "individual", "address"] as const, {
        ...state.account?.profile?.individual?.address,
        ...action.value,
        country: "US"
      });
    case "individualBirthdate":
      return setSparsePath(newState, ["account", "profile", "individual", "birthDate"] as const, {
        ...state.account?.profile?.individual?.birthDate,
        ...action.value
      });
    case "businessAddress":
      return setSparsePath(newState, ["account", "profile", "business", "address"] as const, {
        ...state.account?.profile?.business?.address,
        ...action.value,
        country: "US"
      });
    case "businessPhone":
      return setSparsePath(newState, ["account", "profile", "business", "phone"] as const, {
        ...state.account?.profile?.business?.phone,
        ...action.value,
        countryCode: "1"
      });
    case "businessCodes":
      return setSparsePath(newState, ["account", "profile", "business", "industryCodes"] as const, {
        ...state.account?.profile?.business?.industryCodes,
        ...action.value
      });
    case "businessTaxID":
      return setSparsePath(
        newState,
        ["account", "profile", "business", "taxID", "ein", "number"] as const,
        action.value
      );
    case "prefill":
      action.value.business = setSparsePath(
        action.value.business,
        ["profile", "business", "email"] as const,
        action.value?.individual?.profile?.individual?.email ?? ""
      );
      return {
        ...state,
        account: calculateNewAccount(state.account, action),
        underwriting: action.value.underwriting,
        prefilled: true
      };
    case "manualAddress":
      newState.manualAddress = action.value;
      return newState;
    case "underwriting":
      return setSparsePath(newState, ["underwriting"] as const, {
        ...state.underwriting,
        ...action.value
      });
    case "addFile":
      return {
        ...newState,
        files: calculateFiles(files, action)
      };
    case "uploadFiles":
      return { ...newState, files: action.value };
    case "deleteFile":
      return { ...newState, files: calculateDeletedFiles(files, action) };
    default:
      assertNever(action);
  }
  return state;
};

export const initialState: StateType = {
  title: "",
  underwriting: {
    averageTransactionSize: undefined,
    maxTransactionSize: undefined,
    averageMonthlyTransactionVolume: undefined
  },
  account: {
    accountType: "business"
  },
  prefilled: false,
  manualAddress: false,
  supportManualAddress: false,
  files: []
};
