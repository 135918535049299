import clsx from "clsx";
import styles from "./DotNav.module.scss";

interface DotNavProps {
  dots: number;
  active: number;
  onClick?: (number: number) => void;
  className?: string;
}

export default function DotNav({ dots, active, onClick, className }: DotNavProps) {
  return (
    <div className={clsx(styles.dotNavContainer, className)}>
      {[...Array(dots)].map((_, i) => {
        return (
          <div
            key={i}
            className={clsx(styles.dot, i === active && styles.active)}
            onClick={onClick ? () => onClick(i) : undefined}
          ></div>
        );
      })}
    </div>
  );
}
