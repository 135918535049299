import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, FormGroup, Modal, Select } from "@moovfinancial/cargo";
import useValidatedFields from "@moovfinancial/cargo/src/hooks/useValidatedFields";
import { getFirstAndLastName } from "@moovfinancial/common/utils/accounts";
import { REGEX } from "@moovfinancial/common/utils/regex";
import useRbac from "hooks/useRbac";
import useSuper from "hooks/useSuper";
import { DocumentUpload, FileForUploadState } from "components/form/DocumentUpload";
import { Checkbox, Label } from "components/form/Form";
import InputFormGroup from "components/form/InputFormGroup";
import Toaster, { ToastInput } from "components/toaster/Toaster";
import { Action, Resource } from "api/Role.model";
import { FilePurpose, FilePurposeMap, Name, Representative, isFilePurpose } from "api/v2";
import { Text } from "cleanComponents/Typography/Text";
import { APIContext } from "contexts/APIContext";
import { AccountContext } from "contexts/AccountContext";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { handleFileDownloadErrors } from "helpers/errorMessages";
import styles from "./DocumentUploadModal.module.scss";

export const FileVerificationType: FileVerificationType[] = ["tin", "verification"];

export type FileVerificationType = "tin" | "verification";

export const isFileVerificationType = (
  value: string | FileVerificationType
): value is FileVerificationType => {
  return value === "verification" || value === "tin";
};

interface DocumenUploadModalProps {
  onClose: () => void;
  isOpen: boolean;
  purpose?: FilePurpose;
  verificationType?: FileVerificationType;
  representativeId?: string;
  internalOnly?: boolean;
}

export const DocumentUploadModal = ({
  onClose,
  isOpen,
  purpose: purposeProp,
  verificationType,
  representativeId: representativeIdProp
}: DocumenUploadModalProps) => {
  const { moov } = useContext(APIContext);
  const { account, refreshAccount } = useContext(AccountContext);
  const { facilitatorID } = useContext(FacilitatorContext);
  const isSuper = useSuper();
  const canWriteFiles = useRbac(Action.Write, Resource.Files);

  const initFields = {
    comments: "Comment must be between 10 and 200 characters."
  };

  const { fields } = useValidatedFields<"comments">(initFields);

  const [file, setFile] = useState<FileForUploadState | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [purpose, setPurpose] = useState<FilePurpose | "">(purposeProp ?? "");
  const [representativeID, setRepresentativeID] = useState<string>(representativeIdProp ?? "");
  const [comment, setComment] = useState<string>("");
  const [representative, setRepresentative] = useState<Representative | undefined>();
  const [isInternalOnly, setIsInternalOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const submitBtn = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (account?.profile.business?.representatives) {
      const currentRepresentative = account?.profile.business.representatives.find(
        (rep) => rep.representativeID === representativeID
      );
      setRepresentative(currentRepresentative);
    }
  }, [representativeIdProp, account]);

  useEffect(() => {
    if (file) {
      setError(undefined);
    }
  }, [file]);

  useEffect(() => {
    setPurpose(purposeProp ?? "");
  }, [purposeProp]);

  const shouldOverrideAndServeDefault = () => {
    if (
      purposeProp === "representative_verification" &&
      !account?.profile.business?.representatives?.length
    ) {
      return true;
    }
    if (purposeProp === "business_verification" && !account?.profile.business) {
      return true;
    }
    if (purposeProp === "individual_verification" && !account?.profile.individual) {
      return true;
    }
    return false;
  };

  const getExplainer = () => {
    if (
      !purpose ||
      purpose === "account_requirement" ||
      purpose === "merchant_underwriting" ||
      purpose === "identity_verification" ||
      shouldOverrideAndServeDefault()
    ) {
      return null;
    }
    return (
      <div>
        {purpose === "business_verification" && <BusinessExplainer />}
        {verificationType === "verification" && (
          <VerificationExplainer name={account?.profile.individual?.name ?? representative?.name} />
        )}
        {verificationType === "tin" && (
          <TinExplainer name={account?.profile.individual?.name ?? representative?.name} />
        )}
      </div>
    );
  };

  const addFile = (file: FileForUploadState["file"]) => {
    setFile({ file, status: "pending" });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (file?.status !== "pending") return setError("Please select a file");
    setIsLoading(true);

    const facilitatorOrConnectedAccountID =
      !account || !account?.accountID ? facilitatorID : account?.accountID;
    const fileFormData = new FormData();

    fileFormData.append("file", file.file);
    fileFormData.append("filePurpose", purpose);

    if (representativeID || comment) {
      const metadata: { [key: string]: string | boolean } = {};
      if (representativeID) metadata.representative_id = representativeID;
      if (comment) metadata.comment = comment;
      if (isInternalOnly && isSuper) fileFormData.append("internalOnly", "true");

      fileFormData.append("metadata", JSON.stringify(metadata));
    }

    moov.accounts.files
      .upload(facilitatorID, facilitatorOrConnectedAccountID, fileFormData)
      .then(([_result, error]) => {
        if (error) {
          setFile(undefined);
          setError(handleFileDownloadErrors(error));
        } else {
          onClose();
          refreshAccount();
          toast("File uploaded!");
        }
      })
      .finally(() => setIsLoading(false));
  };

  const shouldShowPurposeSelector = !purposeProp || shouldOverrideAndServeDefault();
  const shouldShowRepresentativeSelector =
    !representative &&
    purpose === "representative_verification" &&
    account?.profile.business?.representatives?.length;

  const handlePurposeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (isFilePurpose(e.target.value)) {
      setPurpose(e.target.value);
    }
    if (submitBtn.current !== null) {
      submitBtn.current.focus();
    }
  };

  const validateComment = (value: string) => {
    fields.setDirty("comments");
    if (value.length < 10 || value.length > 200) {
      fields.setInvalid("comments");
    }
  };

  const handleCommentChange = (value: string) => {
    fields.setValid("comments");
    setComment(value);
  };

  const toastInput: ToastInput = useMemo(() => {
    return { status: "error", message: error };
  }, [error]);

  if (!canWriteFiles) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Upload file" />
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {error && <Toaster data-testid="fileError" toastInput={toastInput} />}
          {purposeProp && getExplainer()}
          <DocumentUpload
            label="Source"
            addFile={addFile}
            onDelete={() => setFile(undefined)}
            selectedFiles={file ? [file] : []}
            maxDocs={1}
          />
          {shouldShowPurposeSelector && (
            <FormGroup>
              <Label label="Purpose" htmlFor="purpose" />
              <Select
                id="purpose"
                value={purpose}
                onChange={handlePurposeChange}
                required
                autoComplete="off"
                placeholder="--"
              >
                {account?.accountType === "business" ? (
                  <BusinessOptions hasReps={!!account?.profile.business?.representatives?.length} />
                ) : null}
                {account?.accountType === "individual" ? <IndividualOptions /> : null}
                <option value="account_requirement">{FilePurposeMap.account_requirement}</option>
              </Select>
            </FormGroup>
          )}
          {shouldShowRepresentativeSelector && (
            <FormGroup>
              <Label label="Who is this document for?" htmlFor="representative" />
              <Select
                id="representative"
                value={representativeID}
                placeholder="--"
                onChange={(e) => setRepresentativeID(e.target.value)}
                required
              >
                <RepresentativeOptions
                  representatives={account?.profile.business?.representatives}
                />
              </Select>
            </FormGroup>
          )}
          <InputFormGroup
            className={styles.comment}
            label="Comments"
            onBlur={(e) => validateComment(e.target.value)}
            helper={`${comment.length}/200`}
            pattern={REGEX.NO_ILLEGAL_CHARACTERS}
            minLength={10}
            maxLength={200}
            type="textarea"
            keyName="comment"
            onChange={(e) => handleCommentChange(e.target.value)}
            value={comment}
            error={fields.isInvalid("comments") ? fields.getMessage("comments") : ""}
          />
          {isSuper && (
            <Checkbox
              label="Internal to Moov"
              checked={isInternalOnly}
              onChange={(e) => setIsInternalOnly(e.target.checked)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button buttonType="secondary" buttonStyle="fill" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={styles.submit}
            disabled={!canWriteFiles || fields.isInvalid("comments")}
            buttonStyle="fill"
            buttonType="primary"
            type="submit"
            isLoading={isLoading}
            ref={submitBtn}
            data-testid="uploadBtn"
          >
            Upload
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const VerificationExplainer = ({ name }: { name?: Name }) => (
  <>
    <Text textStyle="paragraph-m-regular">
      Upload one of the following documents to verify {getFirstAndLastName(name) ?? "account"}:
    </Text>
    <ul className={styles.list}>
      <li>Valid government photo ID</li>
      <li>Utility bill or bank statement from the last 60 days</li>
      <li>Residential lease or mortgage statement</li>
    </ul>
  </>
);

const BusinessExplainer = () => (
  <>
    <Text textStyle="paragraph-m-regular">
      Upload a government issued document containing business name, address and TIN to verify this
      account. Acceptable documents include:
    </Text>
    <ul className={styles.list}>
      <li>Articles of incorporation</li>
      <li>Certificate of formation</li>
      <li>Business license or registration</li>
      <li>SS-4 or 147C</li>
      <li>Proof of 501(c)(3) or tax exempt status</li>
    </ul>
  </>
);

const TinExplainer = ({ name }: { name?: Name }) => (
  <>
    <Text textStyle="paragraph-m-regular">
      Upload one of the following government issued documents to verify{" "}
      {getFirstAndLastName(name) ?? "account"}:
    </Text>
    <ul className={styles.list}>
      <li>Tax return</li>
      <li>W2 form</li>
      <li>SSN/ITIN card</li>
    </ul>
  </>
);

const IndividualOptions = () => (
  <option value="individual_verification">{FilePurposeMap.individual_verification}</option>
);

const BusinessOptions = ({ hasReps }: { hasReps: boolean }) => (
  <>
    <option value="business_verification">{FilePurposeMap.business_verification}</option>
    {hasReps && (
      <option value="representative_verification">
        {FilePurposeMap.representative_verification}
      </option>
    )}
    <option value="merchant_underwriting">{FilePurposeMap.merchant_underwriting}</option>
  </>
);

const RepresentativeOptions = ({ representatives }: { representatives?: Representative[] }) => (
  <>
    {representatives?.map((rep) => (
      <option key={rep.representativeID} value={rep.representativeID}>
        {getFirstAndLastName(rep.name)}
      </option>
    ))}
  </>
);
