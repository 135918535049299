import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useLinkClickHandler } from "react-router-dom";
import { Button, Icon } from "@moovfinancial/cargo";
import { IconDone } from "@moovfinancial/cargo/icons";
import { OnboardingInviteContext } from "contexts/OnboardingInviteContext";
import styles from "./FinishStep.module.scss";

export const FinishStep = () => {
  const { invite } = useContext(OnboardingInviteContext);
  const handleContinueClick = useLinkClickHandler<HTMLButtonElement>(invite?.returnURL ?? "/");

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Completed</title>
      </Helmet>
      <div className={styles.centered}>
        <div className={styles.iconContainer}>
          <div className={styles.circleGradient} />
          <div className={styles.innerCircle} />
          <Icon className={styles.doneIcon} iconComponent={IconDone} size="S" />
        </div>
        <h2 className={styles.heading}>Onboarding completed!</h2>
        <p className={styles.infoText}>
          Your information has been submitted for review. We will let you know if we need anything
          else.
        </p>
        <Button
          className={styles.button}
          buttonStyle="fill"
          buttonType="secondary"
          onClick={handleContinueClick}
        >
          {invite?.returnURL
            ? `Back to ${invite.partner?.displayName}`
            : "Continue to Moov Dashboard"}
        </Button>
        <a className={styles.link} href="#">
          Download a copy of your agreement (Coming soon!)
        </a>
      </div>
    </div>
  );
};
