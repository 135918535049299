import { useContext, useEffect, useState } from "react";
import { clone } from "remeda";
import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import { type SparsePath, setSparsePath } from "@moovfinancial/common/utils/setSparsePath";
import { Action, MoovAdminResource, Resource } from "api/Role.model";
import {
  Account,
  AccountUnderwritingStatus,
  BooleanAccountMap,
  BooleanAccountUnderwritingMap,
  Capability
} from "api/v2";
import { APIContext } from "contexts/APIContext";
import { AccountContext } from "contexts/AccountContext";
import { CapabilitiesContext } from "contexts/CapabilitiesContext";
import { UserContext } from "contexts/UserContext";
import { requirementToAccountFieldMap } from "helpers/capabilities";
import { useAuthenticatedAdminRoute } from "./useAuthenticatedAdminRoute";

function useAccountLocking(
  accountFromProps?: DeepPartial<Account> | null,
  facilitatorID?: string,
  capabilitiesFromProps?: Capability[]
): {
  accountLocks: BooleanAccountMap;
  underwritingLocks: BooleanAccountUnderwritingMap;
  verificationLocked: boolean;
  underwritingLocked: boolean;
} {
  const { userCan } = useContext(UserContext);
  const { account: accountFromContext, accountStatus } = useContext(AccountContext);
  const { capabilities: capabilitesFromContext } = useContext(CapabilitiesContext);
  const { moov } = useContext(APIContext);
  const canEditLockedAccount = userCan(Action.Write, MoovAdminResource.adminAccountPatch);
  const isReadOnly = !userCan(Action.Write, Resource.Accounts) && !canEditLockedAccount;
  const isAdminRoute = useAuthenticatedAdminRoute();
  const [underwritingStatus, setUnderwritingStatus] = useState<AccountUnderwritingStatus>(
    accountStatus?.underwriting ?? "notRequested"
  );

  const account = accountFromProps || accountFromContext;
  const capabilities = capabilitiesFromProps || capabilitesFromContext;

  useEffect(() => {
    if (account?.accountID && facilitatorID) {
      void moov.underwriting.get(account?.accountID, facilitatorID).then(([result]) => {
        setUnderwritingStatus(result?.status || "notRequested");
      });
    }
  }, [facilitatorID, accountFromProps, capabilitiesFromProps]);

  const underwritingLocked =
    underwritingStatus === "approved" ||
    underwritingStatus === "rejected" ||
    underwritingStatus === "pendingReview" ||
    isReadOnly;

  const verificationStatus = account?.verification?.verificationStatus;

  const verificationLocked =
    canEditLockedAccount && isAdminRoute
      ? false
      : verificationStatus === "verified" ||
        verificationStatus === "failed" ||
        verificationStatus === "review" ||
        verificationStatus === "resubmit" ||
        isReadOnly;

  if (account === null || (!verificationLocked && !underwritingLocked)) {
    return {
      accountLocks: {},
      underwritingLocks: {},
      verificationLocked: false,
      underwritingLocked: false
    };
  }

  let accountLocks = {} as BooleanAccountMap;
  let underwritingLocks = {} as BooleanAccountUnderwritingMap;

  if (verificationLocked) accountLocks = defaultAccountLocks(account, isReadOnly);
  if (underwritingLocked) underwritingLocks = defaultUnderwritingAccountLocks;
  if ((verificationLocked || underwritingLocked) && !isReadOnly) {
    let locks = unlockErroredFields(accountLocks, underwritingLocks, capabilities);
    locks = unlockCurrentlyDueFields(
      locks.newAccountLocks,
      locks.newUnderwritingLocks,
      capabilities
    );
    accountLocks = locks.newAccountLocks;
    underwritingLocks = locks.newUnderwritingLocks;
  }

  return {
    accountLocks,
    underwritingLocks,
    verificationLocked,
    underwritingLocked
  };
}

function unlockErroredFields(
  accountLocks: BooleanAccountMap,
  underwritingLocks: BooleanAccountUnderwritingMap,
  capabilities: Capability[]
): {
  newAccountLocks: BooleanAccountMap;
  newUnderwritingLocks: BooleanAccountUnderwritingMap;
} {
  let newAccountLocks = clone(accountLocks);
  let newUnderwritingLocks = clone(underwritingLocks);
  const accountErrors: string[] = [];
  capabilities.forEach((capability) => {
    capability.requirements.errors?.forEach((error) => {
      const errorPath = requirementToAccountFieldMap[error.requirement];
      if (errorPath) accountErrors.push(errorPath);
    });
  });
  accountErrors.forEach((errorPath) => {
    if (
      errorPath === "underwriting.averageMonthlyTransactionVolume" ||
      errorPath === "underwriting.maxTransactionSize" ||
      errorPath === "underwriting.averageTransactionSize"
    ) {
      newUnderwritingLocks = setSparsePath(
        newUnderwritingLocks,
        errorPath.split(".") as SparsePath<BooleanAccountUnderwritingMap>,
        false
      );
    } else {
      newAccountLocks = setSparsePath(
        newAccountLocks,
        errorPath.split(".") as SparsePath<BooleanAccountMap>,
        false
      );
    }
  });
  return { newAccountLocks, newUnderwritingLocks };
}

function unlockCurrentlyDueFields(
  accountLocks: BooleanAccountMap,
  underwritingLocks: BooleanAccountUnderwritingMap,
  capabilities: Capability[]
): {
  newAccountLocks: BooleanAccountMap;
  newUnderwritingLocks: BooleanAccountUnderwritingMap;
} {
  let newAccountLocks = clone(accountLocks);
  let newUnderwritingLocks = clone(underwritingLocks);
  const accountErrors: string[] = [];
  capabilities.forEach((capability) => {
    capability.requirements.currentlyDue?.forEach((due) => {
      const errorPath = requirementToAccountFieldMap[due];
      if (errorPath) accountErrors.push(errorPath);
    });
  });
  accountErrors.forEach((errorPath) => {
    if (
      errorPath === "underwriting.averageMonthlyTransactionVolume" ||
      errorPath === "underwriting.maxTransactionSize" ||
      errorPath === "underwriting.averageTransactionSize"
    ) {
      newUnderwritingLocks = setSparsePath(
        newUnderwritingLocks,
        errorPath.split(".") as SparsePath<BooleanAccountUnderwritingMap>,
        false
      );
    } else {
      newAccountLocks = setSparsePath(
        newAccountLocks,
        errorPath.split(".") as SparsePath<BooleanAccountMap>,
        false
      );
    }
  });
  return { newAccountLocks, newUnderwritingLocks };
}

const defaultUnderwritingAccountLocks: BooleanAccountUnderwritingMap = {
  maxTransactionSize: true,
  averageMonthlyTransactionVolume: true,
  averageTransactionSize: true
};

const defaultAccountLocks = (
  account: DeepPartial<Account>,
  isReadOnly: boolean
): BooleanAccountMap => {
  if (isReadOnly) {
    return {
      accountID: true,
      accountType: true,
      displayName: true,
      profile: {
        individual: {
          name: {
            firstName: true,
            middleName: true,
            lastName: true,
            suffix: true
          },
          phone: {
            number: true
          },
          email: true,
          address: {
            addressLine1: true,
            addressLine2: true,
            city: true,
            stateOrProvince: true,
            postalCode: true,
            country: true
          },
          birthDate: {
            day: true,
            month: true,
            year: true
          },
          governmentID: {
            ssn: {
              lastFour: true,
              full: true
            },
            itin: {
              lastFour: true,
              full: true
            }
          }
        },
        business: {
          legalBusinessName: true,
          doingBusinessAs: true,
          website: true,
          description: true,
          businessType: true,
          taxID: {
            ein: {
              number: true
            }
          },
          address: {
            addressLine1: true,
            addressLine2: true,
            city: true,
            stateOrProvince: true,
            postalCode: true,
            country: true
          },
          phone: {
            number: true
          },
          email: true,
          representatives: [],
          industryCodes: {
            naics: true,
            sic: true,
            mcc: true
          }
        }
      },
      metadata: {},
      termsOfService: {
        acceptedDate: true,
        acceptedIP: true,
        token: true
      },
      verification: {
        verificationStatus: true
      },
      foreignID: true
    };
  }
  return {
    accountID: !!account.accountID,
    accountType: !!account.accountType,
    displayName: !!account.displayName,
    profile: {
      individual: {
        name: {
          firstName: !!account.profile?.individual?.name,
          middleName: !!account.profile?.individual?.name,
          lastName: !!account.profile?.individual?.name,
          suffix: !!account.profile?.individual?.name
        },
        phone: {
          number: !!account.profile?.individual?.phone
        },
        email: !!account.profile?.individual?.email,
        address: {
          addressLine1: !!account.profile?.individual?.address,
          addressLine2: !!account.profile?.individual?.address,
          city: !!account.profile?.individual?.address,
          stateOrProvince: !!account.profile?.individual?.address,
          postalCode: !!account.profile?.individual?.address,
          country: !!account.profile?.individual?.address
        },
        birthDate: {
          day: !!account.profile?.individual?.birthDateProvided,
          month: !!account.profile?.individual?.birthDateProvided,
          year: !!account.profile?.individual?.birthDateProvided
        },
        governmentID: {
          ssn: {
            lastFour: !!account.profile?.individual?.governmentIDProvided,
            full: !!account.profile?.individual?.governmentIDProvided
          },
          itin: {
            lastFour: !!account.profile?.individual?.governmentIDProvided,
            full: !!account.profile?.individual?.governmentIDProvided
          }
        }
      },
      business: {
        legalBusinessName: !!account.profile?.business?.legalBusinessName,
        doingBusinessAs: !!account.profile?.business?.doingBusinessAs,
        website: !!account.profile?.business?.website,
        description: !!account.profile?.business?.description,
        businessType: !!account.profile?.business?.businessType,
        taxID: {
          ein: {
            number: !!account.profile?.business?.taxIDProvided
          }
        },
        address: {
          addressLine1: !!account.profile?.business?.address,
          addressLine2: !!account.profile?.business?.address,
          city: !!account.profile?.business?.address,
          stateOrProvince: !!account.profile?.business?.address,
          postalCode: !!account.profile?.business?.address,
          country: !!account.profile?.business?.address
        },
        phone: {
          number: !!account.profile?.business?.phone
        },
        email: !!account.profile?.business?.email,
        representatives: [],
        industryCodes: {
          naics:
            (!!account.profile?.business?.industryCodes?.naics &&
              account.profile?.business?.industryCodes?.naics !== "0000") ||
            (!!account.profile?.business?.industryCodes?.mcc &&
              account.profile?.business?.industryCodes?.mcc !== "0000") ||
            (!!account.profile?.business?.industryCodes?.sic &&
              account.profile?.business?.industryCodes?.sic !== "0000")
        }
      }
    },
    metadata: {},
    termsOfService: {
      token: false,
      acceptedDate: false,
      acceptedIP: false
    },
    verification: {
      verificationStatus: false
    },
    foreignID: false
  };
};

export default useAccountLocking;
