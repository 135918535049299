import { MaskingInput, MaskingInputProps } from "./MaskingInput";

export interface NumberMaskingInputProps
  extends Omit<
    MaskingInputProps<number>,
    "modelValueToFormattedString" | "formattedStringToModelValue" | "acceptedChars"
  > {
  /**
   * Regex that the input must match.
   *
   * NOTE: This regex should contain the correct quantifier, probably a "+"
   *
   * @default /[\d.]+/g
   */
  acceptedChars?: RegExp;
  /**
   *  Function to parse the formatted string into the number
   */
  formattedStringToNumber: (value: string) => number;
  /**
   *  Function to format the number into the formatted string
   */
  numberToFormattedString: (value: number) => string;
}

/**
 * Small HOC that wraps `MaskingInput` and restricts the model values to numbers only
 */
export const NumberMaskingInput = ({
  acceptedChars = /[\d.]+/g,
  formattedStringToNumber,
  numberToFormattedString,
  ...rest
}: NumberMaskingInputProps) => (
  <MaskingInput
    acceptedChars={acceptedChars}
    formattedStringToModelValue={formattedStringToNumber}
    inputMode="numeric"
    modelValueToFormattedString={numberToFormattedString}
    {...rest}
  />
);
