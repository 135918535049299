/**
 * Helper function to assert that a value is never.
 * This is useful for exhaustiveness checking in discriminated unions.
 *
 * As when you want to check that a switch statement is exhaustive, you can use this function in the default case.
 *
 * @param x - The value that should never be reached
 * @returns - This function never returns, it throws an error
 */
export const assertNever = (x: never): never => {
  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(x)}`);
};
