import { type FloatingLabelInputProps, FloatingLabelWrapper } from "./FloatingLabelInput";
import { PasswordInput } from "./PasswordInput";

export interface FloatingLabelPasswordInputProps
  extends Omit<FloatingLabelInputProps, "disabled" | "isLocked"> {
  /**
   * The label for the input
   */
  label: string;
}

export const FloatingLabelPasswordInput = ({ label, ...rest }: FloatingLabelPasswordInputProps) => {
  return <FloatingLabelWrapper label={label} as={PasswordInput} {...rest} />;
};
