import { useContext } from "react";
import { Button , Modal } from "@moovfinancial/cargo";
import { StepContext } from "components/dialog/StepModal";
import { Text } from "cleanComponents/Typography/Text";
import { CapabilitiesWarning } from "./CapabilitiesWarning";

export const IndividualSuccess = () => {
  const { close } = useContext(StepContext);

  return (
    <>
      <Modal.Body>
        <Text textStyle="paragraph-m-regular">
          Your account is created and the transfers capability is enabled. Any other requested
          capabilities will be enabled when the account is verified.
        </Text>
        <CapabilitiesWarning />
      </Modal.Body>
      <Modal.Footer>
        <Button buttonType="primary" fullWidth onClick={close}>
          Done
        </Button>
      </Modal.Footer>
    </>
  );
};
