import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { CargoElement, CargoElementProps } from "@moovfinancial/cargo";
import styles, { ClassNames } from "./Typography.module.scss";

interface HeadingType extends React.HTMLAttributes<HTMLHeadingElement> {
  textStyle: ClassNames;
  as?: "h1" | "h2" | "h3" | "h4" | "h5";
}

interface TextType extends React.HTMLAttributes<HTMLSpanElement> {
  textStyle: ClassNames;
  as?: "span" | "p";
}

export function Heading({ children, className, textStyle, as = "h1", ...rest }: HeadingType) {
  const Heading = as;
  return (
    <Heading className={clsx(className, styles[textStyle])} {...rest}>
      {children}
    </Heading>
  );
}

export function Text({ children, className, textStyle, as = "p", ...rest }: TextType) {
  const El = as;
  return (
    <El className={clsx(className, styles[textStyle])} {...rest}>
      {children}
    </El>
  );
}

export const LargeTitle: FunctionComponent<React.PropsWithChildren<CargoElementProps>> = ({
  children,
  className,
  ...rest
}) => (
  <CargoElement className={[styles.largeTitle, className].join(" ")} {...rest}>
    {children}
  </CargoElement>
);

export const Title: FunctionComponent<React.PropsWithChildren<CargoElementProps>> = ({
  children,
  className,
  ...rest
}) => (
  <CargoElement className={[styles.title, className].join(" ")} {...rest}>
    {children}
  </CargoElement>
);

export const Caption: FunctionComponent<React.PropsWithChildren<CargoElementProps>> = ({
  children,
  className,
  ...rest
}) => (
  <CargoElement className={[styles.caption, className].join(" ")} {...rest}>
    {children}
  </CargoElement>
);

export const Headline: FunctionComponent<React.PropsWithChildren<CargoElementProps>> = ({
  children,
  className,
  ...rest
}) => (
  <CargoElement className={[styles.headline, className].join(" ")} {...rest}>
    {children}
  </CargoElement>
);

export const Muted: FunctionComponent<React.PropsWithChildren<CargoElementProps>> = ({
  children,
  className,
  ...rest
}) => (
  <CargoElement className={[styles.muted, className].join(" ")} {...rest}>
    {children}
  </CargoElement>
);

export const ListHeading: FunctionComponent<React.PropsWithChildren<CargoElementProps>> = ({
  children,
  className,
  ...rest
}) => (
  <CargoElement className={[styles.listHeading, className].join(" ")} {...rest}>
    {children}
  </CargoElement>
);
