type ButtonLikeModifier = "fullWidth" | "fullWidthOnMobile" | "isLoading";
export const BUTTONLIKE_MODIFIERS = ["fullWidth", "fullWidthOnMobile", "isLoading"] as const;
export interface ButtonLikeModifiers extends Partial<Record<ButtonLikeModifier, boolean>> {
  /**
   * Should the button be 100% of the parent's width for all breakpoints? Defaults to `false`.
   *
   * Note: Passing this prop will prevent `gap` from having any effect.
   */
  fullWidth?: boolean;
  /**
   * Should the button be 100% of the parent's width on mobile breakpoints? Defaults to `false`.
   *
   * Note: Passing this prop will prevent `gap` from having any effect on mobile.
   */
  fullWidthOnMobile?: boolean;
  /**
   * The button will show a spinner instead of children or label. It will automatically be disabled and non-focusable when this is true. Defaults to `false`.
   */
  isLoading?: boolean;
}

/**
 * The type of button. Defaults to "primary".
 *
 * Note: `primary` will force buttonStyle to be `fill`.
 */
export type ButtonLikeType = "primary" | "secondary" | "destructive";

export type ButtonLikeStyles = {
  buttonType?: ButtonLikeType;
  /**
   * The style of the button. Defaults to "fill".
   *
   * Note: `text` is only available for "secondary" and "destructive" buttonTypes and will be ignored when buttonType is "primary".
   */
  buttonStyle?: "fill" | "text";
};

/**
 * Props that size buttons.
 */
export type ButtonSizes = "XS" | "S" | "M" | "L";
export type ButtonLikeSizing = {
  /**
   * The size of the button. Defaults to "M".
   *
   * Note: Not to be confused with StandardSizes in other parts of Cargo.
   */
  buttonSize?: ButtonSizes;
  /**
   * Applies a gap between inner elements in rem units.
   *
   * Note: This prop will have no effect if `fullWitdh` is true.
   *
   * @defaults to 0rem
   */
  gap?: number;
};
