import { AnimatePresence } from "framer-motion";
import { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes } from "react-router-dom";
import { Loading } from "@moovfinancial/cargo";
import useRouteBasedAccounts from "hooks/useRouteBasedAccounts";
import useTracking from "hooks/useTracking";
import useUserAccountHistorySynchronizer from "hooks/useUserAccountHistorySynchronizer";
import NeedsActiveAcct from "components/route/NeedsActiveAcct";
import NavContextProvider from "contexts/NavContext";
import NotificationsContextProvider from "contexts/NotificationsContext";
import { ErrorPage } from "./ErrorPage";

const PrimaryNavigation = lazy(() => import("layout/PrimaryNavigation"));
const TopBar = lazy(() => import("layout/TopBar"));
const WalletRoutes = lazy(() => import("pages/wallet/WalletRoutes"));
const Home = lazy(() => import("pages/home/Home"));
const Profile = lazy(() => import("pages/profile/Profile"));
const Accounts = lazy(() => import("pages/accounts/Accounts"));
const Developers = lazy(() => import("pages/developers/Developers"));
const Settings = lazy(() => import("pages/settings/Settings"));
const FourOhFour = lazy(() => import("pages/404"));
const Transfers = lazy(() => import("pages/transfers/Transfers"));
const Invites = lazy(() => import("layout/Invites"));
const Admin = lazy(() => import("pages/admin/Admin"));

export const DashboardRoutes = () => {
  useTracking("G-9BEP48RTE1");
  useUserAccountHistorySynchronizer();
  useRouteBasedAccounts();

  return (
    <NotificationsContextProvider>
      <NavContextProvider>
        <TopBar />
        <div className="split">
          <PrimaryNavigation />
          <div className="layout">
            <ErrorBoundary FallbackComponent={ErrorPage}>
              <Suspense fallback={<Loading centered />}>
                <AnimatePresence mode="wait" initial={false}>
                  <Routes>
                    <Route
                      index
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <Home />
                        </ErrorBoundary>
                      }
                    />
                    <Route path="/*" element={<NeedsActiveAcct />}>
                      <Route path="wallet/*" element={<WalletRoutes />} />
                      <Route path="accounts/*" element={<Accounts />} />
                      <Route path="developers/*" element={<Developers />} />
                      <Route path="transfers/*" element={<Transfers />} />
                      <Route path="settings/*" element={<Settings />} />
                      <Route path="profile" element={<Profile />} />
                      <Route path="*" element={<FourOhFour />} />
                    </Route>
                    <Route path="/*" element={<NeedsActiveAcct superUser />}>
                      <Route path="admin/*" element={<Admin />} />
                    </Route>
                  </Routes>
                </AnimatePresence>
              </Suspense>
            </ErrorBoundary>
          </div>
        </div>
        <Invites />
      </NavContextProvider>
    </NotificationsContextProvider>
  );
};
