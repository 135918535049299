import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Loading } from "@moovfinancial/cargo";
import { OnboardingContext } from "contexts/OnboardingContext";
import { Fulfillment } from "./Fulfillment";
import { ProcessingStatements } from "./ProcessingStatements";
import { Transactions } from "./Transactions";

export function ExpectedActivityStep() {
  const { isLoading } = useContext(OnboardingContext);
  return isLoading ? (
    <Loading />
  ) : (
    <Routes>
      <Route index element={<Navigate to="transactions" />} />
      <Route path="transactions/*" element={<Transactions />} />
      <Route path="fulfillment/*" element={<Fulfillment />} />
      <Route path="processing-statements/*" element={<ProcessingStatements />} />
    </Routes>
  );
}
