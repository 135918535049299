import { useContext } from "react";
import { Step } from "components/dialog/StepModal";
import { DocumentUpload } from "components/form/DocumentUpload";
import Toaster from "components/toaster/Toaster";
import { AccountSetupContext } from "../../AccountSetupContext";
import { MoovFile } from "../../AccountSetupReducer";
import useAccountModalCalls from "../../useAccountModalCalls";
import styles from "./StatementUpload.module.scss";

export const StatementUpload = () => {
  const { dispatch, files, errorMessages } = useContext(AccountSetupContext);
  const { uploadStatements, loading } = useAccountModalCalls();

  const addFile = (file: MoovFile) => {
    dispatch({
      type: "addFile",
      value: file
    });
  };

  const deleteFile = (name: string) => {
    dispatch({
      type: "deleteFile",
      value: name
    });
  };

  return (
    <Step onSubmit={uploadStatements} loading={loading}>
      {errorMessages && <Toaster toastInput={errorMessages} />}
      <div className={styles.statements}>
        <p>
          Please provide processing statements from the past three months. If the business is
          seasonal, provide processing statements for the three busiest months.
        </p>
        <DocumentUpload addFile={addFile} onDelete={deleteFile} selectedFiles={files} />
      </div>
    </Step>
  );
};
