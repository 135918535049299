import { ReactNode } from "react";
import { NavLinkProps } from "react-router-dom";
import SvgrReactComponent from "vite-plugin-svgr/*.svg?react";

export type StepOrientation = "horizontal" | "vertical";

export type StaticStep = {
  activeIcon?: typeof SvgrReactComponent;
  Component: ReactNode;
  children?: Step[];
  isActive?: boolean;
  isComplete?: boolean;
};

export type NavigationStep = Omit<StaticStep, "children"> &
  Omit<NavLinkProps, "children"> & {
    children?: NavigationStep[];
  };

export type Step = NavigationStep | StaticStep;

// Type guard for a single step.
export const isStepNavigable = (step: Step): step is NavigationStep => {
  return (step as NavigationStep).to !== undefined;
};

// Type guard for an array of steps.
export const areAllStepsNavigable = (steps: Step[]): boolean => {
  return steps.every((step) => {
    const currentStepNavigable = isStepNavigable(step);
    const childrenNavigable = step.children ? areAllStepsNavigable(step.children) : true;
    return currentStepNavigable && childrenNavigable;
  });
};
