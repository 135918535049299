import {
  CreateFacilitatorAccounts,
  FacilitatorAccounts,
  OnboardingState,
  OnboardingStateValue,
  SalesMessage
} from "./Dashboard.model";
import { http } from "./http";

export function createAccounts(create: CreateFacilitatorAccounts): Promise<FacilitatorAccounts> {
  return http("/dashboard/accounts", {
    method: "POST",
    json: create
  });
}

export function getOnboardingState(facilitatorAccountID: string): Promise<OnboardingState> {
  return http(`/dashboard/accounts/${facilitatorAccountID}/onboarding-state`, {
    xAccountID: facilitatorAccountID
  });
}

export function updateOnboardingState(
  facilitatorAccountID: string,
  state: OnboardingStateValue
): Promise<OnboardingState> {
  return http(`/dashboard/accounts/${facilitatorAccountID}/onboarding-state`, {
    method: "PUT",
    json: { state },
    xAccountID: facilitatorAccountID
  });
}

export function createSalesMessage(
  salesMessage: SalesMessage,
  facilitatorAccountID: string
): Promise<void> {
  return http("/dashboard/support/sales-messages", {
    method: "POST",
    xAccountID: facilitatorAccountID,
    json: salesMessage
  });
}
