export function unformattedStringToFormattedPhoneString(value: string) {
  const cleaned = value.replace(/\D/g, "");
  let phoneNumber = "";
  // progressively match the phone number
  const matches = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})/);
  if (!matches) return value;
  const [_, areaCode, middle, last] = matches;
  if (areaCode) {
    phoneNumber += `(${areaCode})`;
  }
  if (middle) {
    phoneNumber += ` ${middle}`;
  }
  if (last) {
    phoneNumber += `-${last}`;
  }
  return phoneNumber;
}

export function formattedPhoneStringToUnformattedString(value: string) {
  const cleaned = value.replace(/\D/g, "");
  if (cleaned.length === 0) return "";
  return parseInt(cleaned, 10).toString();
}

/** Legacy fn, kept it to prevent having to change a bunch of calls */
export default function formatPhone(value?: string) {
  if (value) {
    return unformattedStringToFormattedPhoneString(value);
  }
  return value;
}

/** Legacy function, kept it to prevent having to change a bunch of calls */
export function formatPhoneForDB(value?: string) {
  if (!value) return value;
  return formatPhoneModel(value);
}

/** Legacy function, kept it to prevent having to change a bunch of calls */
export function formatPhoneModel(value: string) {
  const cleaned = value.replace(/\D/g, "");
  const substrings = [cleaned.slice(0, 3), cleaned.slice(3, 6), cleaned.slice(6, 10)].filter(
    (s) => s.length > 0
  );
  return substrings.join("-");
}
