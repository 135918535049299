import { HttpResponse, http } from "msw";
import { mockWalletAdjustmentsList } from "tests/mocks/data/adjustments.mock";

export const listWalletAdjustmentsHandler = [
  http.get("/api/ops/adjustments", () => {
    return HttpResponse.json(mockWalletAdjustmentsList(), { status: 200 });
  })
];

// Will be adding more adjustment handlers soon, so I added this as an easy way to export them all
export const adjustmentHandlers = [...listWalletAdjustmentsHandler];
