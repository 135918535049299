import clsx from "clsx";
import { ReactElement } from "react";
import styles from "./DetailItem.module.scss";

interface DetailItemProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  value?: string | number | ReactElement;
  alwaysShow?: boolean;
  alternateColor?: boolean;
}

export default function DetailItem(
  { label, value, children, alwaysShow, alternateColor }: DetailItemProps,
  key: string
) {
  if (value || alwaysShow || children) {
    return (
      <>
        <dt key={`${key}-label`}>{label}</dt>
        <dd key={`${key}-value`} className={clsx({ [styles.notProvided]: alternateColor })}>
          {children || value}
        </dd>
      </>
    );
  } else {
    return <></>;
  }
}
