import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "contexts/UserContext";

/** Stores and retrieves the active user account id in router history as the user navigates through the Dashboard.
 * Allows Moov Admins to switch back and forth from Admin View to Facilitator View. Allows browser back and forward buttons to behave as expected. */
export default function useUserAccountHistorySynchronizer() {
  const { setActiveUserAccountIDWithSuper, activeUserAccountID, userAccounts } =
    useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userAccounts?.length) return;
    const locationState = (location.state as any) || {};
    const shouldStoreUserAccountID = !locationState.activeUserAccountID;
    const shouldRetrieveUserAccountID = activeUserAccountID !== locationState.activeUserAccountID;
    if (shouldStoreUserAccountID) {
      let userAccountIDtoStore = activeUserAccountID;
      if (location.pathname.includes("/admin")) userAccountIDtoStore = "moov-admin";
      navigate(location, {
        replace: true,
        state: { ...locationState, activeUserAccountID: userAccountIDtoStore }
      });
    } else if (shouldRetrieveUserAccountID) {
      setActiveUserAccountIDWithSuper(locationState.activeUserAccountID);
    }
  }, [userAccounts, location, activeUserAccountID]);
}
