import { Name, Representative } from "../types/accounts";

export const getFirstAndLastName = (name?: Name) => {
  if (!name) return;
  return `${name.firstName} ${name.lastName}`;
};

export const getMatchingRepresentative = (id?: string, representatives?: Representative[]) => {
  if (!representatives || !id) return;
  return representatives.find((rep) => rep.representativeID === id);
};
