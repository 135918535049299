import {
  getOpenApiClient,
  getOpenApiClients,
  getOpenApiReactClient
} from "../api/OpenApiClientFactory";

/**
 *
 * @returns Both "classic" and React clients as { openApi, $api }
 *
 * Usage:
 *
 * const { openApi, $api } = useOpenApi();
 *
 */
export const useOpenApi = () => {
  return getOpenApiClients();
};

/**
 *
 * @returns React OpenAPI client
 *
 * Usage:
 *
 * const $api = useOpenApiReact();
 *
 */
export const useOpenApiReact = () => {
  return getOpenApiReactClient();
};

/**
 *
 * @returns Classic OpenAPI client
 *
 * Usage:
 *
 * const openApi = useClassicOpenApi();
 *
 */
export const useClassicOpenApi = () => {
  return getOpenApiClient();
};
