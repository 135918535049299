import clsx from "clsx";
import styles from "./Container.module.scss";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  fullWidthMobile?: boolean;
  fullWidth?: boolean;
}

export default function Container({
  children,
  className,
  fullWidthMobile = false,
  fullWidth,
  ...rest
}: ContainerProps) {
  return (
    <div
      className={clsx(
        styles.container,
        className,
        fullWidth && styles.fullWidth,
        fullWidthMobile && styles.fullWidthMobile
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
