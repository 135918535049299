import { FormEvent, FormEventHandler, useContext, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, FloatingLabelInput, FormGroup, Icon, Select } from "@moovfinancial/cargo";
import { IconLightOn } from "@moovfinancial/cargo/icons";
import useValidatedFields from "@moovfinancial/cargo/src/hooks/useValidatedFields";
import { isFinancialInstitution } from "@moovfinancial/common/utils/Industry";
import {
  BankAccount,
  BankAccountCreateRequest,
  OnboardingContext
} from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { FooterButtons } from "../FooterButtons";
import { OnboardingErrors, parseErrors } from "../helpers/errors";
import { ACHVerificationSentModal } from "./ACHVerificationSentModal";
import { RoutingNumberInputHelper } from "./RoutingNumberInputHelper";
import { VerificationModal } from "./VerificationModal";
import styles from "./BankAccountForm.module.scss";

type ValidatedFields = keyof BankAccountCreateRequest;

const bankAccountIsComplete = (
  bankAccount: Partial<BankAccountCreateRequest>
): bankAccount is BankAccountCreateRequest => {
  return (
    !!bankAccount &&
    bankAccount.accountNumber != null &&
    bankAccount.bankAccountType != null &&
    bankAccount.holderName != null &&
    bankAccount.holderType != null &&
    bankAccount.routingNumber != null
  );
};

export function BankAccountForm() {
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  const { account, bankAccounts, createBankAccount, createMicroDeposits } =
    useContext(OnboardingContext);
  const { getPreviousStepUrl } = useContext(OnboardingStepsContext);
  const [formState, setFormState] = useState<Partial<BankAccountCreateRequest>>({
    bankAccountType: "checking",
    holderType: account.accountType,
    holderName: account.displayName
  });
  const [errors, setErrors] = useState<OnboardingErrors<BankAccountCreateRequest>>({});
  const [bankAccountToVerify, setBankAccountToVerify] = useState<BankAccount>();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyModalVisible, setIsVerifyModalVisible] = useState(false);
  const [isACHVerificationSentModalOpen, setIsACHVerificationSentModalOpen] = useState(false);
  const { fields } = useValidatedFields<ValidatedFields>({
    accountNumber: "Please enter a valid account number",
    bankAccountType: "Please select an account type",
    holderName: "Please enter the account holder name",
    holderType: "Please select an account holder type",
    routingNumber: "Please enter a valid routing number"
  });
  const hasBankAccounts = !!bankAccounts.length;

  const isValidatedField = (fieldName: string): fieldName is ValidatedFields => {
    return !!fields.getField(fieldName as ValidatedFields);
  };

  const validateInput = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const fieldName = event.currentTarget.name as ValidatedFields;
    fields.validate(fieldName, event.currentTarget);
  };

  const handleInput = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const fieldName = event.currentTarget.name;
    const fieldValue = event.currentTarget.value;

    // Validate input on each keystroke if the field is already invalid
    if (isValidatedField(fieldName) && fields.isInvalid(fieldName)) {
      validateInput(event);
    }

    setFormState((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
  };

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    if (!bankAccountIsComplete(formState) || fields.isInvalid()) return;

    setIsLoading(true);
    const { data, error } = await createBankAccount(formState);

    if (error) {
      // @ts-expect-error - TODO: openApiSpecIsWrong - I'm pretty sure error is an object, not a string 😫
      setErrors(parseErrors<BankAccountCreateRequest>(error));
      toast("Unable to add bank account. Please try again.");
      setIsLoading(false);
      return;
    }

    if (data && data.bankAccountID) {
      // initiate micro-deposit verification via ACH
      await sendMicroDeposits(data);
    }

    setIsLoading(false);
  };

  const sendMicroDeposits = async (bankAccount: BankAccount) => {
    if (!bankAccount.bankAccountID) return;

    const { data: verificationData, error: verificationError } = await createMicroDeposits(
      bankAccount.bankAccountID
    );

    if (verificationData) {
      setBankAccountToVerify(bankAccount);
      if (verificationData.verificationMethod === "instant") {
        setIsVerifyModalVisible(true);
      } else {
        setIsACHVerificationSentModalOpen(true);
      }
    }
    if (verificationError) {
      toast("Unable to send micro-deposits. Please try again.");
    }
  };

  const handleModalClose = () => {
    navigate("./");
  };

  return (
    <>
      <div className={styles.content}>
        <Helmet>
          <title>Link bank account</title>
        </Helmet>
        <h2 className={styles.header}>Bank account</h2>
        <p className={styles.pageInfoText}>
          Provide your bank details to add this payment method to your account. We’ll send a small
          deposit, which will show up instantly, if eligible, or take up to 1-2 days to appear.
          You’ll enter the code in the next step.
        </p>
        <form onSubmit={handleSubmit} ref={formRef}>
          <FormGroup noGap={false} className={styles.twoColumns}>
            <Select
              error={
                fields.isInvalid("bankAccountType")
                  ? fields.getMessage("bankAccountType")
                  : errors.bankAccountType
              }
              floatingLabelStyle
              label="Account type"
              name="bankAccountType"
              onBlur={validateInput}
              onChange={handleInput}
              placeholder="--"
              required
              value={formState.bankAccountType || ""}
            >
              <hr />
              <option value="checking">Checking</option>
              <option value="savings">Savings</option>
              <option value="loan">Loan</option>
              {account.profile?.business?.industryCodes &&
                isFinancialInstitution({
                  naics: account.profile.business.industryCodes.naics || "",
                  sic: account.profile.business.industryCodes.sic || "",
                  mcc: account.profile.business.industryCodes.mcc || ""
                }) && <option value="general-ledger">General ledger</option>}
            </Select>
            <Select
              error={
                fields.isInvalid("holderType") ? fields.getMessage("holderType") : errors.holderType
              }
              floatingLabelStyle
              label="Account holder type"
              name="holderType"
              onBlur={validateInput}
              onChange={handleInput}
              placeholder="--"
              required
              value={formState.holderType || ""}
            >
              <hr />
              <option value="individual">Individual</option>
              <option value="business">Business</option>
            </Select>
          </FormGroup>
          <FormGroup noGap={false}>
            <FloatingLabelInput
              error={
                fields.isInvalid("holderName") ? fields.getMessage("holderName") : errors.holderName
              }
              label="Account holder name"
              name="holderName"
              onBlur={validateInput}
              onChange={handleInput}
              required
              value={formState.holderName || ""}
            />
          </FormGroup>
          <FormGroup noGap={false} className={styles.twoColumns}>
            <FloatingLabelInput
              error={
                fields.isInvalid("routingNumber")
                  ? fields.getMessage("routingNumber")
                  : errors.routingNumber
              }
              label="Routing number"
              name="routingNumber"
              onBlur={validateInput}
              onChange={handleInput}
              required
              value={formState.routingNumber || ""}
              inputMode="numeric"
              maxLength={9}
              pattern="[0-9]{9}"
              helper={
                fields.isValid("routingNumber") && formState.routingNumber ? (
                  <RoutingNumberInputHelper routingNumber={formState.routingNumber} />
                ) : null
              }
            />
            <FloatingLabelInput
              error={
                fields.isInvalid("accountNumber")
                  ? fields.getMessage("accountNumber")
                  : errors.accountNumber
              }
              label="Account number"
              name="accountNumber"
              onBlur={validateInput}
              onChange={handleInput}
              required
              value={formState.accountNumber || ""}
            />
          </FormGroup>
          <div className={styles.row}>
            <p className={styles.fieldInfoText}>
              By clicking Continue, you authorize Moov to debit the bank account specified above for
              any amount owed for charges arising from your use of Moov&apos;s services, pursuant to
              Moov&apos;s website and terms, until this authorization is revoked. You may amend or
              cancel this authorization at any time by providing notice to Moov with 30 (thirty)
              days notice.
            </p>
          </div>
          <FooterButtons
            onBack={() => (hasBankAccounts ? navigate("..") : navigate(getPreviousStepUrl()))}
            onContinue={handleSubmit}
            isLoading={isLoading}
            continueLabel="Continue"
          />
        </form>
        {isACHVerificationSentModalOpen && bankAccountToVerify && (
          <ACHVerificationSentModal handleBackClick={handleModalClose} />
        )}
        {isVerifyModalVisible && bankAccountToVerify && (
          <VerificationModal bankAccount={bankAccountToVerify} handleBackClick={handleModalClose} />
        )}
      </div>
      <aside className={styles.aside}>
        <Card className={styles.infoCard}>
          <div className={styles.infoCardIcon}>
            <Icon iconComponent={IconLightOn} size="S" />
          </div>
          <div>
            <div className={styles.infoCardHeader}>What is this account used for?</div>
            <div className={styles.infoCardBody}>
              This bank account will be used for daily settlements of your wallet balance (automatic
              sweeps).
            </div>
          </div>
        </Card>
      </aside>
    </>
  );
}
