import { memo } from "react";
import {
  dollarsToFormattedString,
  formattedStringToDollars
} from "@moovfinancial/common/utils/format/formatCurrency";
import { CurrencyAmountInput, CurrencyAmountInputProps } from "./CurrencyAmountInput";

export interface DollarCurrencyAmountInputProps
  extends Omit<CurrencyAmountInputProps, "numberToFormattedString" | "formattedStringToNumber"> {
  /**
   * Value will be in DOLLARS. E.g. displayed formatted text of $1.34 will be the float 1.34
   */
  value: number;
}

/**
 * Masking currency input that onValueChange sends the value in DOLLARS
 */
export const DollarCurrencyAmountInput = memo(function InnerDollarCurrencyAmountInput({
  ...props
}: DollarCurrencyAmountInputProps) {
  return (
    <CurrencyAmountInput
      {...props}
      numberToFormattedString={dollarsToFormattedString}
      formattedStringToNumber={formattedStringToDollars}
    />
  );
});
