import clsx from "clsx";
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { Optional } from "@moovfinancial/common/types/Optional";
import { Theme } from "@moovfinancial/common/types/Theme";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { ToggleComponents } from "../../../../Foundations/ToggleComponents";
import { type FloatingLabelInputProps, FloatingLabelWrapper } from "../../FloatingLabelInput";
import { SSNInput, type SSNInputProps } from "./SSNInput";
import styles from "./GovernmentIDInput.module.scss";

export type GovernmentID = Exclude<components["schemas"]["GovernmentID"], null | undefined>;

interface GovernmentIDInputBaseProps {
  onChange?: (id: GovernmentID) => void;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  theme?: Theme<typeof styles>;
  value: GovernmentID | undefined;
}

type GovernmentIDInputWithFloatingLabelsProps = Optional<
  Omit<FloatingLabelInputProps, "as" | "onChange" | "value">,
  "label"
> &
  GovernmentIDInputBaseProps & {
    hasFloatingLabels?: true;
  };

type GovernmentIDInputWithoutFloatingLabelsProps = Omit<SSNInputProps, "onChange" | "value"> &
  GovernmentIDInputBaseProps & { hasFloatingLabels: false };

export type GovernmentIDInputProps =
  | GovernmentIDInputWithFloatingLabelsProps
  | GovernmentIDInputWithoutFloatingLabelsProps;

const initialValue: GovernmentID = {
  itin: { full: "" },
  ssn: { full: "" }
};

function GovernmentIDInputInner(props: GovernmentIDInputWithFloatingLabelsProps): JSX.Element;
function GovernmentIDInputInner(props: GovernmentIDInputWithoutFloatingLabelsProps): JSX.Element;
function GovernmentIDInputInner(props: GovernmentIDInputProps): JSX.Element;
function GovernmentIDInputInner({
  hasFloatingLabels = true,
  label,
  onChange,
  onInputChange,
  theme,
  value,
  ...rest
}: GovernmentIDInputProps) {
  const [governmentID, setGovernmentID] = useState(value ?? initialValue);
  const previousGovernmentID = useRef(value ?? initialValue);

  useEffect(() => {
    if (
      value?.itin?.full !== previousGovernmentID.current.itin?.full ||
      value?.ssn?.full !== previousGovernmentID.current.ssn?.full
    ) {
      previousGovernmentID.current = { ...value };
      setGovernmentID(value ?? initialValue);
    }
  }, [value]);

  useEffect(() => {
    if (
      governmentID.itin?.full !== previousGovernmentID.current.itin?.full ||
      governmentID.ssn?.full !== previousGovernmentID.current.ssn?.full
    ) {
      previousGovernmentID.current = { ...governmentID };
      onChange?.(governmentID);
    }
  }, [governmentID, onChange]);

  const handleSSNChange = useCallback(
    (ssn: string) => {
      previousGovernmentID.current = { ...governmentID };
      setGovernmentID((prev) => ({
        ...prev,
        itin: undefined,
        ssn: { ...prev.ssn, full: ssn }
      }));
    },
    [governmentID]
  );

  const handleITINChange = useCallback(
    (itin: string) => {
      previousGovernmentID.current = { ...governmentID };
      setGovernmentID((prev) => ({
        ...prev,
        itin: { ...prev.itin, full: itin },
        ssn: undefined
      }));
    },
    [governmentID]
  );

  const ITINComponent = useMemo(
    () =>
      hasFloatingLabels ? (
        <FloatingLabelWrapper
          as={SSNInput}
          label={label ?? "ITIN"}
          onChange={onInputChange}
          onValueChange={handleITINChange}
          value={governmentID.itin?.full ?? ""}
          {...rest}
        />
      ) : (
        <SSNInput
          label={label ?? "ITIN"}
          onChange={onInputChange}
          onValueChange={handleITINChange}
          value={governmentID.itin?.full ?? ""}
          {...rest}
        />
      ),
    [governmentID.itin?.full, handleITINChange, hasFloatingLabels, label, onInputChange, rest]
  );

  const SSNComponent = useMemo(
    () =>
      hasFloatingLabels ? (
        <FloatingLabelWrapper
          as={SSNInput}
          label={label ?? "SSN"}
          onChange={onInputChange}
          onValueChange={handleSSNChange}
          value={governmentID.ssn?.full ?? ""}
          {...rest}
        />
      ) : (
        <SSNInput
          label={label ?? "SSN"}
          onChange={onInputChange}
          onValueChange={handleSSNChange}
          value={governmentID.ssn?.full ?? ""}
          {...rest}
        />
      ),
    [governmentID.ssn?.full, handleSSNChange, hasFloatingLabels, label, onInputChange, rest]
  );

  return (
    <ToggleComponents
      primaryComponent={SSNComponent}
      secondaryComponent={ITINComponent}
      switchToPrimaryButtonLabel="Use SSN"
      switchToSecondaryButtonLabel="Use ITIN"
      theme={{
        button: hasFloatingLabels
          ? clsx(styles.buttonForFloatingLabels, theme?.buttonForFloatingLabels)
          : undefined,
        container: clsx(styles.container, theme?.container)
      }}
    />
  );
}

export const GovernmentIDInput = memo(GovernmentIDInputInner);
