import clsx from "clsx";
import { HTMLAttributes, createContext, useContext, useEffect, useRef, useState } from "react";
import { Button, Modal } from "@moovfinancial/cargo";
import DotNav from "components/dot-nav/DotNav";
import styles from "./StepModal.module.scss";

export interface Step {
  title: string;
  content: JSX.Element;
  id?: string;
}

interface StepContextProps {
  steps: Step[];
  stepCount: number;
  activeStepIndex: number;
  confirmationIsOpen?: boolean;
  closeConfirmationStep: () => void;
  setBack: (onBack?: () => void) => void;
  next: () => void;
  back: () => void;
  close: () => void;
  goTo: (stepID: string) => void;
}

export const StepContext = createContext<StepContextProps>({
  steps: [],
  stepCount: 0,
  activeStepIndex: 0,
  confirmationIsOpen: false,
  closeConfirmationStep: () => {},
  next: () => {},
  setBack: () => {},
  back: () => {},
  close: () => {},
  goTo: () => {}
});

interface StepModalProps extends StepModalContentProps {
  steps: Step[];
  activeStepIndex?: number;
  onClose: () => void;
  hasSuccessElement?: boolean;
}

export const StepModal = ({
  steps,
  activeStepIndex: activeStepIndexProp = 0,
  open,
  onClose,
  hideBack,
  className,
  hasSuccessElement = false,
  closeConfirmation,
  ...rest
}: StepModalProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(activeStepIndexProp);
  const onBack = useRef<() => void | undefined>();
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

  useEffect(() => {
    setActiveStepIndex(activeStepIndexProp);
  }, [activeStepIndexProp]);

  const next = () => {
    if (activeStepIndex === steps.length - 1) {
      return close();
    }
    setActiveStepIndex((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const back = () => {
    if (onBack.current) {
      onBack.current();
    } else {
      setActiveStepIndex((prev) => Math.max(prev - 1, 0));
    }
  };

  const setBack = (backOverride?: () => void) => {
    onBack.current = backOverride;
  };

  const close = () => {
    if (closeConfirmation?.shouldConfirm && !confirmationIsOpen) {
      setConfirmationIsOpen(true);
      return;
    }
    setActiveStepIndex(0);
    onClose();
  };

  const goTo = (stepID: string) => {
    const stepIndex = steps.findIndex((step) => step.id === stepID);

    if (stepIndex > -1) {
      setActiveStepIndex(stepIndex);
    }
  };

  const stepCount = hasSuccessElement ? steps.length - 1 : steps.length;

  return (
    <StepContext.Provider
      value={{
        steps,
        stepCount,
        activeStepIndex: activeStepIndex,
        confirmationIsOpen,
        closeConfirmationStep: () => setConfirmationIsOpen(false),
        next,
        back,
        setBack,
        close,
        goTo
      }}
    >
      <StepModal.Modal
        open={open}
        hideBack={hideBack || (hasSuccessElement && activeStepIndex === stepCount)}
        className={className}
        closeConfirmation={closeConfirmation}
        hideClose={hasSuccessElement && activeStepIndex === stepCount}
        {...rest}
      />
    </StepContext.Provider>
  );
};

export interface ConfirmationStep extends Step {
  shouldConfirm: boolean;
}

interface StepModalContentProps extends HTMLAttributes<HTMLDialogElement> {
  open: boolean;
  hideBack?: boolean;
  hideClose?: boolean;
  // Confirmation content to be shown on close when closeConfirmation.shouldConfirm is true.
  closeConfirmation?: ConfirmationStep;
}

StepModal.Modal = function StepModal({
  open,
  hideBack,
  hideClose,
  className,
  closeConfirmation,
  ...rest
}: StepModalContentProps) {
  const { steps, activeStepIndex, close, back, confirmationIsOpen } = useContext(StepContext);
  return (
    <Modal
      isOpen={open}
      onClose={close}
      hideClose={hideClose || confirmationIsOpen}
      className={clsx(className)}
      onBack={!hideBack && activeStepIndex !== 0 && !confirmationIsOpen ? back : undefined}
      {...rest}
    >
      <Modal.Header
        title={
          confirmationIsOpen && !!closeConfirmation
            ? closeConfirmation.title
            : steps[activeStepIndex].title
        }
      />
      {confirmationIsOpen && !!closeConfirmation
        ? closeConfirmation.content
        : steps[activeStepIndex].content}
    </Modal>
  );
};

interface StepProps {
  // The label for the submit button. Defaults to "Continue" if not provided, and "Finish" if it's the last step.
  submitLabel?: string;
  // If true, the progress bar will be hidden. Defaults to false.
  hideProgress?: boolean;
  // Callback for when the step is submitted.
  onSubmit?: () => void;
  // If true, the submit button will be disabled and a loading indicator will be shown.
  loading?: boolean;
  // Disable HTML5 validation on the form.
  noValidate?: boolean;
}

export const Step = function Step({
  submitLabel,
  onSubmit,
  children,
  loading,
  noValidate,
  ...rest
}: StepProps & HTMLAttributes<HTMLDivElement>) {
  const { stepCount, activeStepIndex, next } = useContext(StepContext);

  const nextStep = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit ? onSubmit() : next();
  };

  return (
    <form onSubmit={nextStep} noValidate={noValidate}>
      <Modal.Body {...rest}>{children}</Modal.Body>
      <Modal.Footer>
        <DotNav className={styles.dotNav} dots={stepCount} active={activeStepIndex} />
        <Button type="submit" buttonType="primary" fullWidth disabled={loading} isLoading={loading}>
          {submitLabel || activeStepIndex === stepCount - 1 ? "Finish" : "Continue"}
        </Button>
      </Modal.Footer>
    </form>
  );
};
