import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { REGEX } from "@moovfinancial/common/utils/regex";
import { AccountContext } from "contexts/AccountContext";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { UserContext } from "contexts/UserContext";

const { URL_ACCOUNT_ID, URL_WATCHLIST_ID } = REGEX;

// @TODO: This should be translated to a custom route rather than a hook

/** Updates Facilitator and Connected Account contexts based on the current route. */
export default function useRouteBasedAccounts() {
  const { activeUserAccountID } = useContext(UserContext);
  const { setFacilitatorByID } = useContext(FacilitatorContext);
  const { setAccountByID } = useContext(AccountContext);
  const currentLocation = useLocation();

  useEffect(() => {
    if (
      currentLocation.pathname.startsWith("/accounts/") ||
      currentLocation.pathname.startsWith("/admin/accounts/")
    ) {
      /* When any user views account details, load that account into the Account context (which autoloads the Facilitator) */
      const urlAccountIDMatch = currentLocation.pathname.match(URL_ACCOUNT_ID);
      const urlAccountID = urlAccountIDMatch?.groups?.accountID;
      if (urlAccountID) void setAccountByID(urlAccountID);
    } else if (
      currentLocation.pathname.startsWith("/settings") ||
      currentLocation.pathname.startsWith("/onboarding") ||
      currentLocation.pathname.startsWith("/wallet")
    ) {
      /* When anyone acting as a typical user views facilitator details or production access form, load that facilitator into the Account context */
      if (activeUserAccountID !== "moov-admin") void setAccountByID(activeUserAccountID);
    } else if (currentLocation.pathname.startsWith("/transfers")) {
      /* When a Moov Admin views a transfer, set facilitator using the facilitator-id query parameter, if available */
      if (
        activeUserAccountID === "moov-admin" &&
        currentLocation.search.includes("facilitator-id")
      ) {
        const searchParams = new URLSearchParams(currentLocation.search);
        const facilitatorID = searchParams.get("facilitator-id");
        if (facilitatorID) void setFacilitatorByID(facilitatorID);
      }
    } else if (currentLocation.pathname.startsWith("/admin/disputes")) {
      /* When a Moov Admin views dispute evidence, set facilitator using the facilitator-id query parameter, if available */
      if (
        activeUserAccountID === "moov-admin" &&
        currentLocation.search.includes("facilitator-id")
      ) {
        const searchParams = new URLSearchParams(currentLocation.search);
        const facilitatorID = searchParams.get("facilitator-id");
        if (facilitatorID) void setFacilitatorByID(facilitatorID);
      }
    } else if (currentLocation.pathname.startsWith("/admin/transfers")) {
      /* When a Moov Admin views transfer details, set facilitator using the facilitator-id query parameter, if available */
      if (
        activeUserAccountID === "moov-admin" &&
        currentLocation.search.includes("facilitator-id")
      ) {
        const searchParams = new URLSearchParams(currentLocation.search);
        const facilitatorID = searchParams.get("facilitator-id");
        if (facilitatorID) void setFacilitatorByID(facilitatorID);
      }
    } else if (currentLocation.pathname.startsWith("/admin/watchlist/")) {
      /* When a Moov Admin views a Watchlist hit for a specific account, load that account into the Account Context */
      const urlWatchlistAccountIDMatch = currentLocation.pathname.match(URL_WATCHLIST_ID);
      const urlWatchlistAccountID = urlWatchlistAccountIDMatch?.groups?.accountID;
      if (urlWatchlistAccountID) void setAccountByID(urlWatchlistAccountID);
    } else if (currentLocation.pathname.startsWith("/admin")) {
      /* When a Moov Admin views an admin page outside of those listed above, both contexts should be emptied */
      void setAccountByID("");
      void setFacilitatorByID("");
    } else {
      /* On any other page, the AccountContext alone should be empty, and the FacilitatorContext should be unchanged */
      void setAccountByID("");
    }
  }, [currentLocation, activeUserAccountID]);
}
