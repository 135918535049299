import { generateGUID } from "helpers/generateGuid";
import {
  NewTransfer,
  RefundAmount,
  Transfer,
  TransferListFilters,
  TransferOptions
} from "./Transfers.model";
import { http } from "./http";

function list({
  accountID,
  filters
}: {
  accountID: string;
  filters: TransferListFilters;
}): Promise<Transfer[]> {
  const parsedFilters: Record<string, string> = filters as Record<string, string>;
  if (filters.startDateTime) {
    parsedFilters.startDateTime = filters.startDateTime.toISOString();
  } else {
    delete parsedFilters.startDateTime;
  }
  if (filters.endDateTime) {
    parsedFilters.endDateTime = filters.endDateTime.toISOString();
  } else {
    delete parsedFilters.endDateTime;
  }
  if (!filters.status) delete parsedFilters.status;
  if (!filters.accountIDs) delete parsedFilters.accountIDs;

  return http("/transfers", {
    xAccountID: accountID,
    query: {
      count: "20",
      ...parsedFilters
    }
  });
}

function get({
  accountID,
  transferID
}: {
  accountID: string;
  transferID: string;
}): Promise<Transfer> {
  return http(`/transfers/${transferID}`, {
    xAccountID: accountID
  });
}

function create({
  accountID,
  transfer,
  idempotencyKey
}: {
  accountID: string;
  transfer: NewTransfer;
  idempotencyKey: string;
}): Promise<Transfer> {
  return http("/transfers", {
    xAccountID: accountID,
    method: "POST",
    json: transfer,
    headers: {
      "x-idempotency-Key": idempotencyKey,
      "x-wait-for": "rail-response"
    }
  });
}

function options({
  accountID,
  transfer
}: {
  accountID: string;
  transfer: Partial<NewTransfer>;
}): Promise<TransferOptions> {
  return http("/transfer-options", {
    method: "POST",
    xAccountID: accountID,
    json: transfer
  });
}

function updateMetadata(activeAccountID: string, transferID: string, transfer: Partial<Transfer>) {
  return http(`/transfers/${transferID}`, {
    xAccountID: activeAccountID,
    method: "PATCH",
    json: transfer
  });
}

function refundTransfer(facilitatorAccountID: string, transferID: string, amount: RefundAmount) {
  const idempotencyKey = generateGUID();

  return http(`/transfers/${transferID}/refunds`, {
    xAccountID: facilitatorAccountID,
    method: "POST",
    headers: {
      "x-idempotency-Key": idempotencyKey
    },
    json: amount
  });
}

function listRefunds(
  facilitatorAccountID: string,
  transferID: string
): Promise<{ transferID: string }> {
  return http(`/transfers/${transferID}/refunds`, { xAccountID: facilitatorAccountID });
}

const TransfersAPI = {
  list,
  get,
  create,
  options,
  updateMetadata,
  refundTransfer,
  listRefunds
};

export default TransfersAPI;
