import { clone, entries, pipe } from "remeda";
import type { DeepPartial } from "../types/DeepTypes";
import { isEmptyRecord, isRecord } from "./isRecord";

export const defaultShouldPrune = (_key: string, value: unknown): boolean =>
  value === null ||
  value === undefined ||
  value === "" ||
  // Prune empty objects but not arrays
  isEmptyRecord(value);

/**
 * Recursively travels an object and deletes any properties with null, undefined, or empty string values.
 * If a nested object is empty after pruning it will be removed from the parent object, but an empty object is always guaranteed to be returned at the top level.
 * The function will also traverse nested arrays and prune any objects found within them, but will never change the length of an array.
 * This behavior can be modified with the `shouldPrune` argument
 *
 * @param input The object to be pruned
 * @param shouldPrune A predicate function that returns true if the given property should be deleted from the object
 * @returns A new object with all prune-able properties deleted
 */
export default function pruneObject<T extends Record<string, unknown>>(
  input: T,
  shouldPrune: (key: string, value: unknown) => boolean = defaultShouldPrune
): DeepPartial<T> {
  // This function should only be called with objects
  if (!isRecord(input)) {
    throw new Error("pruneObject can only be called with objects");
  }
  /**
   * Recursive function that prunes the objects and arrays and primitives within objects
   */
  function recursiveClean(input: unknown): unknown;
  function recursiveClean(input: Array<unknown>): Array<unknown>;
  function recursiveClean(input: Record<string, unknown>): DeepPartial<T>;
  function recursiveClean(input: unknown): unknown {
    if (Array.isArray(input)) {
      return input.map(recursiveClean) as Array<unknown>;
    }
    if (isRecord(input)) {
      return entries(input).reduce((acc, [key, value]) => {
        if (shouldPrune(key, value)) return acc;
        const recursedValue = recursiveClean(value);
        return isEmptyRecord(recursedValue) ? acc : { ...acc, [key]: recursedValue };
      }, {} as DeepPartial<T>);
    }
    return input;
  }

  return pipe(input, clone, recursiveClean);
}
