import { LegacyRef, forwardRef } from "react";
import styles from "./Chip.module.scss";

interface ChipProps {
  /** Icon component displayed to the right of text content in the chip  */
  Icon?: React.ReactElement;
  /** Callback function called when the chip is clicked */
  onClick?: () => void;
  /** Text content of the chip */
  value: string;
}

/** Base chip component with onClick functionality */
export const Chip = forwardRef<HTMLDivElement, ChipProps>(function Chip(
  { Icon, onClick, value, ...rest }: ChipProps,
  ref
) {
  return onClick ? (
    <button
      type="button"
      ref={ref as LegacyRef<HTMLButtonElement>}
      className={styles.chip}
      tabIndex={0}
      onClick={onClick}
      {...rest}
    >
      <span className={styles.value}>{value}</span>
      {Icon ? Icon : null}
    </button>
  ) : (
    <div ref={ref} className={styles.chip} {...rest}>
      <span className={styles.value}>{value}</span>
      {Icon ? Icon : null}
    </div>
  );
});
