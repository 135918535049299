import { LiveMoovAPIClient } from "./api";
import { Capability, CapabilityName } from "./capabilities.model";
import { ErrorResponse } from "./request";

export interface CapabilitiesAPI {
  /** Lists the capabilities for an account**/
  list(
    facilitatorID: string,
    accountID: string
  ): Promise<[Capability[] | undefined, ErrorResponse | undefined]>;

  request(
    facilitatorID: string,
    accountID: string,
    capabilities: CapabilityName[]
  ): Promise<[Capability[] | undefined, ErrorResponse | undefined]>;

  adminRequest(
    facilitatorID: string,
    accountID: string,
    capabilities: CapabilityName[]
  ): Promise<[Capability[] | undefined, ErrorResponse | undefined]>;

  /** Requests production app access for a given account. **/
  requestProductionApp(
    facilitatorID?: string
  ): Promise<[Capability[] | undefined, ErrorResponse | undefined]>;

  /** Unrequests singular capability */
  unrequest(
    facilitatorID: string,
    accountID: string,
    capabilityID: CapabilityName
  ): Promise<[undefined, ErrorResponse | undefined]>;

  adminDisable(
    facilitatorID: string,
    accountID: string,
    capabilityName: CapabilityName,
    disableReason: string
  ): Promise<[Capability[] | undefined, ErrorResponse | undefined]>;

  /** Reprocesses capabilities stuck in pending */
  reprocess(
    facilitatorID: string,
    accountID: string,
    capability: CapabilityName
  ): Promise<[undefined, ErrorResponse | undefined]>;
}

export class LiveCapabilitiesAPI implements CapabilitiesAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async list(
    facilitatorID: string,
    accountID: string
  ): Promise<[Capability[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<Capability[]>(
      `/accounts/${accountID}/capabilities`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async request(
    facilitatorID: string,
    accountID: string,
    capabilities: CapabilityName[]
  ): Promise<[Capability[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<Capability[]>(
      `/accounts/${accountID}/capabilities`,
      {
        xAccountID: facilitatorID,
        method: "POST",
        json: {
          capabilities
        }
      }
    );
    return [result, err];
  }

  async adminRequest(
    facilitatorID: string,
    accountID: string,
    capabilities: CapabilityName[]
  ): Promise<[Capability[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<Capability[]>(
      `/admin/accounts/${accountID}/capabilities`,
      {
        xAccountID: facilitatorID,
        method: "POST",
        json: {
          capabilities
        }
      }
    );
    return [result, err];
  }

  // This is an moov admin endpoint, desppite not having the admin prefix
  async adminDisable(
    facilitatorID: string,
    accountID: string,
    capabilityName: CapabilityName,
    disableReason: string
  ): Promise<[Capability[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<Capability[]>(
      `/accounts/${accountID}/capabilities/${capabilityName}`,
      {
        xAccountID: facilitatorID,
        method: "PATCH",
        json: {
          status: "disabled",
          disabledReason: disableReason
        }
      }
    );
    return [result, err];
  }

  // Production app can not be requested VIA the request endpoints, it must be enabled via the PATCH endpoint.
  // This is an moov admin endpoint, desppite not having the admin prefix
  async requestProductionApp(
    facilitatorID: string
  ): Promise<[Capability[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<Capability[]>(
      `/accounts/${facilitatorID}/capabilities/production-app`,
      {
        xAccountID: facilitatorID,
        method: "PATCH",
        json: {
          status: "enabled"
        }
      }
    );
    return [result, err];
  }

  async unrequest(
    facilitatorID: string,
    accountID: string,
    capabilityID: CapabilityName
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/accounts/${accountID}/capabilities/${capabilityID}`,
      {
        xAccountID: facilitatorID,
        method: "DELETE"
      }
    );
    return [result, err];
  }

  async reprocess(
    facilitatorID: string,
    accountID: string,
    capability: CapabilityName
  ): Promise<[undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<undefined>(
      `/admin/accounts/${accountID}/capabilities/${capability}/reprocess`,
      {
        xAccountID: facilitatorID,
        method: "POST"
      }
    );
    return [result, err];
  }
}
