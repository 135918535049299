import clsx from "clsx";
import { useRef } from "react";
import { useResizeObserver } from "@moovfinancial/common/hooks/animation/useResizeObserver";
import styles from "./AnimateHeight.module.scss";

export const AnimateHeight = ({
  className,
  children,
  style,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => {
  const content = useRef(null);
  const { size } = useResizeObserver(content);

  return (
    <div
      className={clsx(styles.animateContainer, className)}
      style={{
        height: `${size?.height ?? 0}px`,
        ...style
      }}
      {...rest}
    >
      <div ref={content}>{children}</div>
    </div>
  );
};
