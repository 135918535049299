import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import { Representative } from "api/v2/accounts.model";

export const isRepresentativeComplete = (representative?: DeepPartial<Representative>) => {
  if (
    representative &&
    representative.name?.firstName &&
    representative.name?.lastName &&
    representative.email &&
    representative.responsibilities &&
    representative.address?.addressLine1 &&
    representative.birthDateProvided &&
    representative.governmentIDProvided &&
    representative.phone?.number
  ) {
    return true;
  }
  return false;
};

export const hasController = (representatives?: (DeepPartial<Representative> | undefined)[]) => {
  if (!representatives) return false;
  const hasController = representatives.find(
    (representative) => representative?.responsibilities?.isController === true
  );
  if (hasController) {
    return true;
  }
  return false;
};
