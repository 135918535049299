import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "@moovfinancial/cargo";
import useNoOp from "hooks/useNoOp";
import { Form } from "components/form/Form";
import InputFormGroup from "components/form/InputFormGroup";
import Toaster, { ToastInput } from "components/toaster/Toaster";
import * as UsersAPI from "api/Users";
import { SessionContext } from "contexts/SessionContext";
import { UserContext } from "contexts/UserContext";
import { is4xx, universalErrorHandlerToString } from "helpers/errorMessages";
import styles from "./SessionReminders.module.scss";

const ACTIVITY_EVENTS = ["keydown", "click"];
// This is the max amount of time since user's activity within which we'll automatically refresh the session
const ACTIVITY_THRESHOLD = 5 * 60 * 1000;
// We give the user 1 minute to refresh their session (after inactivity period) before logging them out
const SESSION_ENDING_DURATION = 60 * 1000;

type SessionEvents =
  | "SessionEnding"
  | "SessionEnded"
  | "GlobalSessionEnding"
  | "GlobalSessionEnded";

const timeouts: Record<SessionEvents, number> = {
  SessionEnding: 0,
  SessionEnded: 0,
  GlobalSessionEnding: 0,
  GlobalSessionEnded: 0
};

function clearTimeouts() {
  Object.entries(timeouts).forEach(([key, value]) => {
    if (value) {
      window.clearTimeout(value);
    }
    timeouts[key as SessionEvents] = 0;
  });
}

export default function SessionReminders(): React.ReactElement {
  const [showSessionEndingModal, setShowSessionEndingModal] = useState(false);
  const [showGlobalSessionEndingModal, setShowGlobalSessionEndingModal] = useState(false);
  const [password, setPassword] = useState("");
  const [toastMessage, setToastMessage] = useState<ToastInput>();
  const { signOut, session, fetchSession } = useContext(SessionContext);
  const { user } = useContext(UserContext);
  const noOp = useNoOp();

  const refreshSession = async () => fetchSession(true);

  let lastActivity = new Date().getTime();

  const userActivityHandler = () => {
    const now = new Date().getTime();
    lastActivity = now;
  };

  function attachUserActivityListeners() {
    ACTIVITY_EVENTS.forEach((e) => document.addEventListener(e, userActivityHandler));
  }

  function removeUserActivityListeners() {
    ACTIVITY_EVENTS.forEach((e) => document.removeEventListener(e, userActivityHandler));
  }

  // Set all the eventListeners and timers for session reminders every time the session changes
  useEffect(() => {
    if (!session) return; // should never happen
    clearTimeouts();
    // For each of the events which we consider as user activity, we'll update the lastActivity time
    attachUserActivityListeners();

    const now = new Date().getTime();
    const expiresOn = session.expiresOn * 1000;
    const globalExpiresOn = session.globalExpiresOn * 1000;
    const sessionEndingIn = expiresOn - now - SESSION_ENDING_DURATION;
    const globalSessionEndingIn = globalExpiresOn - now - SESSION_ENDING_DURATION;
    const sessionEndedIn = expiresOn - now;
    const globalSessionEndedIn = globalExpiresOn - now;

    timeouts.SessionEnding = window.setTimeout(() => {
      const now = new Date().getTime();
      // if the user's last activity was less than 5 minutes ago, refresh the session automatically without showing any Modal
      if (now - lastActivity < ACTIVITY_THRESHOLD) {
        refreshSession(); // will retrigger this same useEffect as it will get a new session in the SessionContext
      } else {
        setShowSessionEndingModal(true);
      }
    }, sessionEndingIn);
    timeouts.SessionEnded = window.setTimeout(() => {
      setShowSessionEndingModal(false);
      signOut({
        shouldSaveLocationBeforeSignout: true,
        showSessionExpiredMessageOnLogin: true
      });
    }, sessionEndedIn);
    timeouts.GlobalSessionEnding = window.setTimeout(() => {
      setToastMessage(undefined);
      setPassword("");
      setShowGlobalSessionEndingModal(true);
    }, globalSessionEndingIn);
    timeouts.GlobalSessionEnded = window.setTimeout(() => {
      setShowGlobalSessionEndingModal(false);
      signOut();
    }, globalSessionEndedIn);

    // cleanup
    return () => {
      clearTimeouts();
      removeUserActivityListeners();
    };
  }, [session]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        await UsersAPI.signIn(user?.email as string, password);
        setPassword("");
      } catch (err: any) {
        const message = is4xx(err?.status)
          ? "Incorrect password"
          : universalErrorHandlerToString(err);
        setToastMessage({
          message,
          status: "error",
          duration: "persist"
        });
        return;
      }

      try {
        // Possible but unlikely we'll fail here -- just sign out
        await UsersAPI.verifyDevice(window.sessionStorage.getItem("fingerprint"));
        await refreshSession();
        setShowGlobalSessionEndingModal(false);
      } catch (err: any) {
        toast("SessionReminders: reauthentication failed");
        signOut();
      }
    },
    [user, password, signOut]
  );

  const closeSessionModalAndRefreshSession = async () => {
    await refreshSession();
    setShowSessionEndingModal(false);
  };

  return (
    <>
      {showSessionEndingModal && (
        <Modal
          isOpen={showSessionEndingModal}
          onClose={closeSessionModalAndRefreshSession}
          className={styles.content}
        >
          <Modal.Header title="Still moving money?" />
          <Modal.Body>
            <p className={styles.timeoutCopy}>We'll sign you out in a few moments.</p>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                buttonType="primary"
                fullWidth
                data-testid="refreshSessionButton"
                onClick={closeSessionModalAndRefreshSession}
              >
                Stay signed in
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {showGlobalSessionEndingModal && (
        <Modal
          isOpen={showGlobalSessionEndingModal}
          onClose={noOp}
          hideClose={true}
          className={styles.content}
        >
          <Modal.Header title="Confirm your password to stay signed in" />
          <Modal.Body>
            {!!toastMessage && <Toaster toastInput={toastMessage} />}
            <Form onSubmit={handleSubmit}>
              <InputFormGroup
                label="Password"
                keyName="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                buttonType="primary"
                fullWidth
                data-testid="refreshSessionButton"
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
