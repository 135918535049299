declare global {
  interface Window {
    grecaptcha: any;
  }
}

const siteKeys = {
  development: "",
  staging: "6LdsCGUhAAAAAGAyWZHg3MVOrpYfc5nY8VILdQlj",
  production: "6LeuDWUhAAAAAFyDoI-FL327Q1gPcj1HurJcXks5"
};

interface InitOptions {
  environment: "development" | "staging" | "production";
}

/** Initializes the Google recaptcha script */
export function init(options: InitOptions): void {
  const { environment } = options;
  if (environment === "development") return;
  if (document.getElementById("google-recaptcha")) return;
  const siteKey = siteKeys[environment];
  const grecaptchaScript = document.createElement("script");
  grecaptchaScript.id = "google-recaptcha";
  grecaptchaScript.setAttribute("data-site-key", siteKey);
  grecaptchaScript.async = true;
  grecaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
  document.head.appendChild(grecaptchaScript);
}

type Action =
  | "signIn"
  | "signUp"
  | "changePassword"
  | "register"
  | "verify"
  | "sendVerify"
  | "recoverPassword"
  | "resetPassword"
  | "tokenRedeem";

/** Triggers a request to Google, which returns a recaptcha challenge token
 * @param action A name for the user action being performed. Organizes data in the recaptcha admin tool.
 * @returns A promise resolving to the recaptcha challenge token
 */
export async function execute(action: Action): Promise<string | undefined> {
  if (!window.grecaptcha) return undefined;
  const grecaptchaScript = document.getElementById("google-recaptcha");
  const siteKey = grecaptchaScript?.getAttribute("data-site-key");
  if (!siteKey) return undefined;
  let recaptchaToken = undefined;
  recaptchaToken = await window.grecaptcha.execute(siteKey, { action });
  return recaptchaToken;
}

const GoogleRecaptcha = {
  init,
  execute
};

export default GoogleRecaptcha;
