import type { Location } from "react-router-dom";
import type { User } from "api/User.model";

export type LocationState = {
  previousUserID?: string;
  redirectLocationAfterLogin: string;
  activeUserAccountID?: string;
};

export const calculatePathToRedirectToAfterVerification = (
  currentLocation: Location<LocationState>,
  user?: User | null
): string => {
  // We need to default redirectLocationAfterLogin to "/" in the case the user went to "/signin" directly
  const { previousUserID, redirectLocationAfterLogin = "/" } = currentLocation.state ?? {};
  // if we don't have a previousUserID, we know the user was not signed in so, if there is a redirectLocationAfterLogin
  // we redirect to that path, as it's the current user trying to access a protected route before being signed in
  if (!previousUserID) {
    return redirectLocationAfterLogin;
  } else {
    // if we have a previousUserID, we know some user was previously signed in with this browser
    if (user?.userID === previousUserID) {
      return redirectLocationAfterLogin;
    } else {
      return "/";
    }
  }
};
