import { MouseEvent, useContext, useEffect, useState } from "react";
import { BaseButton, EINInput, FormGroup, Icon } from "@moovfinancial/cargo";
import { IconInvisible } from "@moovfinancial/cargo/icons";
import { REGEX } from "@moovfinancial/common/utils/regex";
import { useAuthenticatedAdminRoute } from "hooks/useAuthenticatedAdminRoute";
import { useDecryptValues } from "hooks/useDecryptValues";
import { Label } from "components/form/Form";
import Tippy from "@tippyjs/react";
import { Action, MoovAdminResource } from "api/Role.model";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { UserContext } from "contexts/UserContext";
import { AccountSetupContext } from "../../AccountSetupContext";
import styles from "../../AccountSetup.module.scss";

interface EINFieldProps {
  warn?: boolean;
  errored?: boolean;
  locked?: boolean;
}

export const EINField = ({ warn, errored, locked }: EINFieldProps) => {
  const { userCan } = useContext(UserContext);
  const { facilitatorID } = useContext(FacilitatorContext);
  const { account, dispatch, isErrored, invalidInputs, resetInput, validate, setErrorMessages } =
    useContext(AccountSetupContext);
  const isAdminRoute = useAuthenticatedAdminRoute();
  const canDecrypt =
    userCan(Action.Read, MoovAdminResource.encryptedData) && isAdminRoute && account.accountID;
  const [isWarning, setIsWarning] = useState(warn);

  const {
    decryptedTaxID,
    decryptAccount,
    error: decryptError,
    loading: decryptLoading
  } = useDecryptValues();

  useEffect(() => {
    if (decryptError)
      setErrorMessages([
        {
          message: "There was an error decrypting the EIN.",
          status: "error",
          key: "decryptError"
        }
      ]);
  }, [decryptError]);

  const isErroring = isErrored("ein") || invalidInputs.includes("businessTaxID") || errored;

  const handleChange = (ein: string) => {
    resetInput("ein");
    resetInput("businessTaxID");
    dispatch({
      type: "businessTaxID",
      value: ein
    });

    // If the user has filled out the field and there is no error, remove warning.
    if (ein !== "" && !isErroring) {
      setIsWarning(false);
    } else if (warn && ein === "") {
      setIsWarning(true);
    }
  };

  const getErrorMessage = () => {
    if (isErroring) {
      return "This field is invalid.";
    }
  };

  const getWarningMessage = () => {
    if (isWarning) {
      return "EIN is missing.";
    }
  };

  const handleDecryptClick = (e: MouseEvent) => {
    e.preventDefault();
    if (account.accountID) {
      decryptAccount(account.accountID, facilitatorID);
    }
  };

  return (
    <FormGroup
      className={styles.decryptableInput}
      errorMessage={getErrorMessage()}
      warningMessage={getWarningMessage()}
    >
      <Label label="EIN" />
      <EINInput
        Decoration={
          canDecrypt &&
          account.accountID &&
          account.profile?.business?.taxIDProvided &&
          !decryptedTaxID && (
            <Tippy content="Show">
              <BaseButton
                className={styles.decryptableButton}
                onClick={handleDecryptClick}
                isLoading={decryptLoading}
              >
                <Icon iconComponent={IconInvisible} />
              </BaseButton>
            </Tippy>
          )
        }
        disabled={locked}
        isErroring={isErroring}
        isLocked={locked}
        isWarning={isWarning}
        minLength={9}
        name="businessTaxID"
        pattern={REGEX.EIN}
        placeholder={account.profile?.business?.taxIDProvided ? "••-•••••••" : "55-5555555"}
        value={
          decryptedTaxID?.value
            ? decryptedTaxID.value
            : account.profile?.business?.taxID?.ein?.number ?? ""
        }
        onBlur={(e) => validate(e.target)}
        onValueChange={handleChange}
      />
    </FormGroup>
  );
};
