import { clone, mapValues, mergeDeep, pickBy, pipe } from "remeda";
import type { DeepPartial } from "../types/DeepTypes";

// Recursively picks properties from an object based on a predicate f, building a new object with the deeply picked properties
const deepPickBy = <T extends object>(
  obj: T,
  predicate: (val: unknown, key: string) => boolean
): Record<string, unknown> =>
  pipe(
    obj,
    mapValues((value, key) => {
      if (typeof value === "object" && value !== null) {
        const nestedResult = deepPickBy(value as Record<string, unknown>, predicate);
        return Object.keys(nestedResult).length > 0 ? nestedResult : undefined;
      }
      return predicate(value, key) ? value : undefined;
    }),
    pickBy((value) => value !== undefined)
  );

/**
 * Recursively merges two objects, creating a new deep clone of both.
 * ℹ️ To create a clone of the source object, pass an empty object `{}` as the target, e.g. `deepMerge({}, source)`.
 * 👉 Although to be more clear, I recommend using `clone(source)` from Remeda instead for creating deep clones.
 * @param target The starting point for the merge
 * @param source Object with values that will override `target`
 * @param shouldPreserveInTarget A predicate function that returns true if the given target value should be maintained in the resulting object
 * @returns A new object created from merging the two given objects
 */
export default function deepMerge<T extends object, S extends DeepPartial<T>>(
  target: T = {} as T,
  source?: S,
  shouldPreserveInTarget?: (val: unknown, key: string) => boolean
): S {
  // We first extract the properties that should be preserved from the target object
  const preservedProps = deepPickBy(target, shouldPreserveInTarget ?? (() => false)); //buildPreservedProps(target, shouldPreserveInTarget);
  // Then we merge the source object with the preserved properties
  return clone(
    mergeDeep(target as Record<string, unknown>, mergeDeep(source ?? {}, preservedProps))
  ) as S;
}
