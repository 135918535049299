import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import styles from "./Form.module.scss";

interface RadioGroupContextType {
  name: string;
  selectedValue?: string;
  disabled?: boolean;
  onSelect?: (value: string) => void;
}

const RadioGroupContext = createContext<RadioGroupContextType>({
  name: "",
  selectedValue: "",
  disabled: false,
  onSelect: () => {}
});

function RadioGroupContextProvider({
  name,
  selectedValue,
  disabled,
  onSelect,
  children
}: PropsWithChildren<RadioGroupContextType>) {
  return (
    <RadioGroupContext.Provider value={{ name, selectedValue, disabled, onSelect }}>
      {children}
    </RadioGroupContext.Provider>
  );
}

export interface RadioGroupProps {
  name: string;
  initialSelectedValue?: string;
  disabled?: boolean;
  className?: string;
  onSelect?: (value: string) => void;
}

export function RadioGroup({
  name,
  initialSelectedValue,
  disabled,
  className,
  onSelect,
  children
}: PropsWithChildren<RadioGroupProps>): ReactElement {
  const [localSelectedValue, setLocalSelectedValue] = useState<string | undefined>(
    initialSelectedValue
  );

  useEffect(() => {
    if (!initialSelectedValue) return;
    setLocalSelectedValue(initialSelectedValue);
  }, []);

  useEffect(() => {
    if (disabled) setLocalSelectedValue(undefined);
  }, [disabled]);

  const handleSelect = useCallback(
    (value: string) => {
      if (localSelectedValue !== value) {
        setLocalSelectedValue(value);
        onSelect && onSelect(value);
      }
    },
    [localSelectedValue, onSelect]
  );

  return (
    <div className={className}>
      <RadioGroupContextProvider
        name={name}
        selectedValue={localSelectedValue}
        onSelect={handleSelect}
        disabled={disabled}
      >
        {children}
      </RadioGroupContextProvider>
    </div>
  );
}

export interface RadioProps {
  value: string;
  className?: string;
}

export function RadioInput({
  value,
  className,
  children
}: PropsWithChildren<RadioProps>): ReactElement {
  const { name, selectedValue, onSelect } = useContext(RadioGroupContext);

  const handleChange = useCallback(() => {
    onSelect && onSelect(value);
  }, [value, onSelect]);

  return (
    <label
      className={className}
      key={value}
      data-value={value}
      data-checked={selectedValue === value}
    >
      <input
        type="radio"
        name={name}
        value={value}
        checked={selectedValue === value}
        onChange={handleChange}
        className={styles.input}
      />
      {children}
    </label>
  );
}
