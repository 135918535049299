"use client";

import { ValidatedInput, ValidatedInputProps } from "..";
import { useCallback, useState } from "react";
import Tippy from "@tippyjs/react";
import { BaseButton } from "../../Actions";
import { IconHidden, IconVisible } from "../../Icons";
import { Icon } from "../../Icons/Icon";
import styles from "./PasswordInput.module.scss";

export interface PasswordInputProps extends Omit<ValidatedInputProps, "disabled" | "isLocked"> {}

export const PasswordInput = ({ ...rest }: PasswordInputProps) => {
  const [visible, setVisible] = useState(false);

  const onIconClick = useCallback(() => setVisible(!visible), [visible]);

  return (
    <>
      <ValidatedInput
        type={visible ? "text" : "password"}
        Decoration={
          <div>
            <Tippy content={visible ? "Hide" : "Show"} appendTo="parent">
              <span className={styles.showHideIcon}>
                <BaseButton
                  type="button"
                  className={styles.center}
                  tabIndex={-1}
                  onClick={onIconClick}
                >
                  <div className={styles.padded}>
                    <Icon iconComponent={visible ? IconHidden : IconVisible} />
                  </div>
                </BaseButton>
              </span>
            </Tippy>
          </div>
        }
        {...rest}
      />
    </>
  );
};
