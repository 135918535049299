import { useCallback, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { StepProgress } from "./StepProgress";
import { toToString } from "./StepperUtils";
import { NavigationStep } from "./step.model";

interface CurrentStepProps {
  steps: NavigationStep[];
}

export const CurrentStep = ({ steps }: CurrentStepProps) => {
  const currentLocation = useLocation();
  const [step, setStep] = useState<NavigationStep>();
  const [subStep, setSubStep] = useState<NavigationStep>();
  const [pageNumber, setPageNumber] = useState(1);

  const findRouteMatch = useCallback(() => {
    let match;
    let subMatch;
    const pathname = currentLocation.pathname.split("/");
    pathname.pop();
    const parentPathname = pathname.join("/");

    steps.forEach((currentStep) => {
      const isMatch = matchPath(currentLocation.pathname, toToString(currentStep.to));
      if (isMatch) {
        match = currentStep;
      } else if (currentStep.children?.length) {
        currentStep.children.forEach((subStep) => {
          const isMatch = matchPath(currentLocation.pathname, toToString(subStep.to));
          if (isMatch) {
            match = currentStep;
            subMatch = subStep;
          }
        });
      } else {
        const isMatch = matchPath(parentPathname, toToString(currentStep.to));
        if (isMatch) {
          match = currentStep;
        }
      }
    });
    setStep(match);
    setSubStep(subMatch);
  }, [currentLocation.pathname, steps]);

  useEffect(() => {
    if (step) {
      setPageNumber(steps.indexOf(step) + 1);
    } else {
      // Step not found
      setPageNumber(0);
    }
  }, [step, steps]);

  useEffect(() => {
    findRouteMatch();
  }, [findRouteMatch, currentLocation]);

  // If we're not on a known step, don't render the component
  return !pageNumber ? null : (
    <StepProgress
      currentStep={pageNumber}
      stepName={step?.Component}
      subStepName={typeof subStep?.Component === "string" ? subStep.Component : ""}
      totalSteps={steps.length}
    />
  );
};
