import { Helmet } from "react-helmet-async";
import { Icon } from "@moovfinancial/cargo";
import { IconOpenInNew } from "@moovfinancial/cargo/icons";
import styles from "./OwnersStepHeading.module.scss";

interface OwnersStepHeadingProps {
  subHeader?: string;
  header?: string;
}

export const OwnersStepHeading = ({ subHeader, header }: OwnersStepHeadingProps) => {
  return (
    <div>
      <Helmet>
        <title>{header ?? "Owners & officers"}</title>
      </Helmet>
      <h2 className={styles.header}>{header ?? "Owners & officers"}</h2>
      <p className={styles.subHeader}>
        {subHeader ?? (
          <>
            Add all owners with at least a 25% stake in your company and one control officer.
            Control officers can be executives, senior managers, or anyone with significant
            management authority.{" "}
            <a
              href="https://docs.moov.io/guides/accounts/requirements/business-representatives/"
              target="_blank" rel="noreferrer"
            >
              Learn more <Icon iconComponent={IconOpenInNew} size="XS" />
            </a>
          </>
        )}
      </p>
    </div>
  );
};
