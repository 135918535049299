export interface Queue<T> {
  enqueue(item: T): void;
  dequeue(): T;
  peek(): T;
  size(): T;
  isEmpty(): T;
}

export const Queue = class Queue<T> {
  private storage: T[] = [];

  constructor() {}

  // Adds an element to the end of the queue
  enqueue(item: T): void {
    this.storage.push(item);
  }

  // Removes and returns the element at the front of the queue
  dequeue(): T | undefined {
    if (this.isEmpty()) {
      throw new Error("Queue is empty");
    }
    return this.storage.shift();
  }

  // Returns the element at the front of the queue without removing it
  peek(): T | undefined {
    return this.storage[0];
  }

  // Returns the number of elements in the queue
  size(): number {
    return this.storage.length;
  }

  // Checks if the queue is empty
  isEmpty(): boolean {
    return this.size() === 0;
  }
};
