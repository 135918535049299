/* eslint-disable */
// Disabling eslint because this is the legacy fetcher, which will eventually be removed
import type { ErrorResponse } from "@moovfinancial/common/api/legacyRequest";

// Re-exporting stuff here from common just to avoid having to re-write a ton of imports
export type {
  HTTPError,
  NetworkError,
  LegacyHTTPErrorRawString,
  LegacyHTTPErrorRawObject,
  HTTPErrorWrappedString,
  HTTPErrorWrappedObject,
  LegacyHTTPRawErrorArray,
  LegacyHTTPWrappedErrorArray,
  PostProcessingError,
  ErrorResponse,
  APIResponse,
  RecursiveErrorObject
} from "@moovfinancial/common/api/legacyRequest";
export {
  isHTTPError,
  isLegacyHTTPErrorRawObject,
  isHTTPErrorWrappedString,
  isHTTPErrorWrappedObject,
  isLegacyHTTPRawErrorArray,
  isLegacyHTTPWrappedErrorArray,
  isNetworkError,
  isPostProcessingError,
  errorMessageArrayToObject,
  getErrorsAsObject,
  errorObjectToArray,
  cleanErrorMessage,
  normalizeErrorArray,
  errorArrayToString,
  errorObjectToString,
  errorStringtoErrorObject,
  getErrorsAsArray,
  getErrorsAsString
} from "@moovfinancial/common/api/legacyRequest";

export interface RequestOptions {
  /** The HTTP method to use. Defaults to "GET". */
  method?: string;
  /** The request headers. */
  headers?: Record<string, string>;
  /** The request query parameters. Passed to URLSearchParams constructor. */
  query?: any;
  /** The request body. If you're sending JSON, use the `json` property instead. */
  body?: any;
  /** The JSON request body. The fetch client will automatically stringify it and set the content-type header. */
  json?: any;
}

/**
 * Sends an asynchoronous request to a URL.
 *
 * @param url - Location to send the request to.
 * @param options - Configuration options for the request.
 * @param [fetchFn] - The fetch function to use. Defaults to the global fetch function.
 */

export async function request<T>(
  url: string,
  options?: RequestOptions,
  fetchFn: (
    input: RequestInfo | URL,
    init?: RequestInit | undefined
  ) => Promise<Response> = window.fetch
): Promise<[T | undefined, ErrorResponse | undefined, Response | undefined]> {
  // Configure the request
  const init: RequestInit = {
    method: options?.method || "GET"
  };
  if (options?.headers) {
    init.headers = options.headers;
  }
  if (options?.body) {
    init.body = options?.body;
  }
  if (options?.query) {
    url += "?" + new URLSearchParams(options.query).toString();
  }
  if (options?.json) {
    init.body = JSON.stringify(options.json);
    if (init.headers === undefined) init.headers = {};
    (init.headers as Record<string, string>)["content-type"] = "application/json";
  }

  // Make the request
  let resp: Response | null = null;
  try {
    resp = await fetchFn(url, init);
  } catch (err: any) {
    // Network error
    return [undefined, { type: "network", error: err }, undefined];
  }

  // Check for a response body
  let body: any | undefined, readErr: Error | undefined;
  const contentType = (resp.headers.get("content-type") || "").toLowerCase();

  try {
    // Check if the response has a body
    const clonedResp = resp.clone();
    const hasBody = await clonedResp.text().then((text) => text.length > 0);

    if (hasBody) {
      // Read the body if it's JSON or text
      if (isJSON(contentType)) {
        try {
          body = await resp.json();
        } catch (err: any) {
          readErr = err;
        }
      } else if (
        isImage(contentType) ||
        isPDF(contentType) ||
        (url.endsWith("/download") && isText(contentType))
      ) {
        try {
          body = await resp.blob();
        } catch (err: any) {
          readErr = err;
        }
      } else if (isText(contentType)) {
        try {
          body = await resp.text();
        } catch (err: any) {
          readErr = err;
        }
      }
    }
  } catch (e: unknown) {
    if (e instanceof Error) {
      readErr = e;
    } else {
      readErr = new Error(`An unknown error occurred: ${e}`);
    }
  }

  // Check for HTTP error
  if (!resp.ok) {
    return [undefined, { type: "http", resp, body }, resp];
  }

  // Check for post-processing error
  if (readErr) {
    return [undefined, { type: "post", resp, error: readErr }, resp];
  }

  // Success!
  return [body, undefined, resp];
}

function isJSON(contentType: string): boolean {
  return contentType.startsWith("application/json");
}

function isText(contentType: string): boolean {
  return contentType.startsWith("text/") || contentType.startsWith("application/xml");
}

function isImage(contentType: string): boolean {
  return contentType.startsWith("image/");
}

function isPDF(contentType: string): boolean {
  return contentType.endsWith("/pdf");
}
