import { ReactNode, forwardRef } from "react";
import { FormGroup, type FormGroupTheme } from "../FormGroup";
import { TextInput, TextInputProps, type TextInputTheme } from "./TextInput";

export interface ValidatedInputProps extends Omit<TextInputProps, "theme"> {
  /**
   * Error message, which will also set the input to an error state
   */
  error?: ReactNode;
  /**
   * Warning message, which will also set the input to a warning state
   */
  warning?: ReactNode;
  /**
   * Optional helper message to display. It can be text or a ReactNode
   */
  helper?: ReactNode;
  /**
   * should the input have no margins? Ideally, yes, so defaulting to `true`.
   */
  noMargins?: boolean;
  /**
   * Combined theme for the FormGroup and TextInput theme
   */
  theme?: FormGroupTheme & TextInputTheme;
}

/**
 * This is Moov's basic input component. It's a wrapper around the native HTML input element and we only add
 * styling and support for error and warning messages through wrapping it in a `FormGroup` component.
 */
export const ValidatedInput = forwardRef<HTMLInputElement, ValidatedInputProps>(
  (
    { error, helper, isErroring, isWarning, label, noMargins = true, warning, theme, ...rest },
    ref
  ) => (
    <FormGroup
      errorMessage={error}
      warningMessage={warning}
      helper={helper}
      noMargins={noMargins}
      noGap
      theme={theme}
    >
      <TextInput
        isErroring={!!error || isErroring}
        isWarning={!!warning || isWarning}
        label={label}
        ref={ref}
        theme={theme}
        {...rest}
      />
    </FormGroup>
  )
);

ValidatedInput.displayName = "ValidatedInput";
