import React from "react";
import { FormGroup, Select } from "@moovfinancial/cargo";
import {
  armedForcesOptions,
  stateOptions,
  territoryOptions
} from "@moovfinancial/common/constants/state";
import { Label } from "components/form/Form";

interface StateSelectProps extends React.AllHTMLAttributes<HTMLSelectElement> {
  value?: string;
  isLocked?: boolean;
  warn?: boolean;
}

export default function StateSelect({
  className,
  onChange,
  value,
  isLocked,
  name,
  ...rest
}: StateSelectProps) {
  return (
    <FormGroup>
      <Label htmlFor={name} label="State" />
      <Select
        name={name}
        value={value}
        placeholder="Select"
        autoComplete="off"
        className={className}
        isLocked={isLocked}
        onChange={onChange}
        {...rest}
      >
        {stateOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
        <optgroup label="US outlying territories">
          {territoryOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </optgroup>
        <optgroup label="Armed Forces">
          {armedForcesOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </optgroup>
      </Select>
    </FormGroup>
  );
}
