import clsx from "clsx";
import { Enum } from "@moovfinancial/common/types/Enum";
import styles from "./Text.module.scss";

export const TEXT_STYLES = {
  TITLE_1_BOLD: "title-1-bold",
  TITLE_1_SEMIBOLD: "title-1-semibold",
  TITLE_1_MEDIUM: "title-1-medium",
  TITLE_2_BOLD: "title-2-bold",
  TITLE_2_SEMIBOLD: "title-2-semibold",
  TITLE_3_REGULAR: "title-3-regular",
  XL_BODY_BOLD: "xl-body-bold",
  XL_BODY_SEMIBOLD: "xl-body-semibold",
  XL_BODY_MEDIUM: "xl-body-medium",
  XL_BODY_REGULAR: "xl-body-regular",
  BODY_BOLD: "body-bold",
  BODY_SEMIBOLD: "body-semibold",
  BODY_S_REGULAR: "body-s-regular",
  BODY_S_MEDIUM: "body-s-medium",
  BODY_S_SEMIBOLD: "body-s-semibold",
  BODY_M_MEDIUM: "body-m-medium",
  BODY_M_REGULAR: "body-m-regular",
  BODY_M_SEMIBOLD: "body-m-semibold",
  BODY_MEDIUM: "body-medium",
  BODY_MEDIUM_PARAGRAPH: "body-medium-paragraph",
  BODY_L_SEMIBOLD: "body-l-semibold",
  BODY_REGULAR: "body-m-regular",
  PARAGRAPH_S_REGULAR: "paragraph-s-regular",
  PARAGRAPH_M_REGULAR: "paragraph-m-regular",
  FOOTNOTE_BOLD: "footnote-bold",
  FOOTNOTE_MEDIUM: "footnote-medium",
  FOOTNOTE_REGULAR: "footnote-regular",
  XL_BODY_MONO_BOLD: "xl-body-mono-bold",
  XL_BODY_MONO_MEDIUM: "xl-body-mono-medium",
  BODY_MONO: "body-mono",
  FOOTNOTE_MONO: "footnote-mono",
  H2_MEDIUM: "h2-medium"
} as const;

export type TextStyle = Enum<typeof TEXT_STYLES>;

interface TextType extends React.HTMLAttributes<HTMLSpanElement> {
  textStyle: TextStyle;
  as?: "span" | "p";
  rightAlign?: boolean;
}

export function Text({ children, className, textStyle, as = "p", rightAlign, ...rest }: TextType) {
  const El = as;
  return (
    <El
      className={clsx(className && className, styles[textStyle], rightAlign && styles.rightAlign)}
      {...rest}
    >
      {children}
    </El>
  );
}
