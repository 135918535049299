import React from "react";
import { Button } from "@moovfinancial/cargo";
import styles from "./FooterButtons.module.scss";

interface FooterButtonsProps {
  onBack: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onContinue: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  disabled?: boolean;
  continueLabel?: string;
}

export const FooterButtons = ({
  onBack,
  onContinue,
  isLoading,
  disabled,
  continueLabel
}: FooterButtonsProps) => (
  <div className={styles.footerButtons}>
    <Button buttonType="secondary" className="btn btn-secondary" onClick={onBack}>
      Back
    </Button>
    <Button
      className="btn btn-primary"
      onClick={onContinue}
      isLoading={isLoading}
      disabled={disabled}
    >
      {continueLabel || "Save & continue"}
    </Button>
  </div>
);
