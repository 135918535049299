import { LiveMoovAPIClient } from "../api";
import { APIResponse } from "../request";
import { TOSAllowed } from "./tosAllowed.model";

export interface TOSAllowedAPI {
  /** Attempts to retrieve a facilitator account from the TOS allowed list */
  get(facilitatorID: string): APIResponse<TOSAllowed>;
  /** Adds a facilitator account to the TOS allowed list */
  create(facilitatorID: string): APIResponse<undefined>;
  /** Deletes a facilitator account from the TOS allowed list */
  disable(facilitatorID: string): APIResponse<undefined>;
}

export class LiveTOSAllowedAPI implements TOSAllowedAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async get(facilitatorID: string): APIResponse<TOSAllowed> {
    const [result, err] = await this._client.request<TOSAllowed>(
      `/ops/accounts/${facilitatorID}/tos-allowed`,
      {
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async create(facilitatorID: string): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(
      `/ops/accounts/${facilitatorID}/tos-allowed`,
      {
        method: "POST",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }

  async disable(facilitatorID: string): APIResponse<undefined> {
    const [result, err] = await this._client.request<undefined>(
      `/ops/accounts/${facilitatorID}/tos-allowed`,
      {
        method: "DELETE",
        xAccountID: facilitatorID
      }
    );
    return [result, err];
  }
}
