import { useContext } from "react";
import { Button, LinkAsButton, Modal } from "@moovfinancial/cargo";
import { StepContext } from "components/dialog/StepModal";
import { Text } from "cleanComponents/Typography/Text";
import { AccountSetupContext } from "../../AccountSetupContext";
import { CapabilitiesWarning } from "./CapabilitiesWarning";
import styles from "./Success.module.scss";

export const BusinessSuccess = () => {
  const { account } = useContext(AccountSetupContext);
  const { close } = useContext(StepContext);

  return (
    <>
      <Modal.Body>
        <Text textStyle="paragraph-m-regular">
          Your account is created and the transfers capability is enabled. Business representatives
          are required before enabling additional capabilities.
        </Text>
        <CapabilitiesWarning />
      </Modal.Body>
      <Modal.Footer className={styles.successActions}>
        <Button buttonType="secondary" buttonStyle="fill" onClick={close}>
          Done
        </Button>
        <LinkAsButton
          buttonType="primary"
          to={`/accounts/${account.accountID}/?representative=new`}
        >
          Add business representatives
        </LinkAsButton>
      </Modal.Footer>
    </>
  );
};
