import clsx from "clsx";
import React, { useRef } from "react";
import { BaseButton, TextInput, TextInputProps, ValidationMessage } from "@moovfinancial/cargo";
import styles from "./IconInput.module.scss";

export interface IconInputProps extends TextInputProps {
  Icon?: React.FunctionComponent<React.PropsWithChildren<React.SVGProps<any>>>;
  onIconClick?: () => void;
  error?: string | null;
  leftAlign?: boolean;
  isLocked?: boolean;
}

export default function IconInput({
  Icon,
  onIconClick,
  error,
  className,
  leftAlign,
  isLocked = false,
  ...rest
}: IconInputProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={clsx(styles.iconInput, className)}>
      {Icon && (
        <BaseButton
          className={clsx(styles.icon, { [styles.leftAlign]: leftAlign })}
          disabled={isLocked}
          type="button"
          onClick={onIconClick}
        >
          <Icon />
        </BaseButton>
      )}
      {/* <Input type="text" className={inputClassName} {...rest} /> */}
      <TextInput disabled={isLocked} isLocked={isLocked} ref={inputRef} type="text" {...rest} />
      {error && <ValidationMessage>{error}</ValidationMessage>}
    </div>
  );
}
