export interface PercentFormatOptions {
  decimalPlaces?: number;
  max?: number;
}

const DEFAULT_DECIMAL_PLACES = 2;

export const getEmptyState = (options?: PercentFormatOptions) => {
  const { decimalPlaces = DEFAULT_DECIMAL_PLACES } = options || {};
  return decimalPlaces ? `0.${"0".repeat(decimalPlaces)}%` : "0%";
};

export function floatToFormattedString(value?: number, options?: PercentFormatOptions) {
  const { max, decimalPlaces = DEFAULT_DECIMAL_PLACES } = options || {};
  if (!value) return getEmptyState(options);
  const capedValue = max && max <= value ? max : value;
  return capedValue.toFixed(decimalPlaces) + "%";
}

export function formattedStringToFloat(value?: string, options?: PercentFormatOptions) {
  const { max } = options || {};
  if (!value || value === getEmptyState(options)) return 0;

  const cleaned = parseFloat(value.replace(/%/g, ""));
  if (isNaN(cleaned)) return 0;

  return max && max <= cleaned ? max : cleaned;
}

/**
 * Re-formats a percent string (or undefined) into a string that meets the
 * specified options. It does NOT round the decimal places. It will truncate them instead.
 */
export const formatPercentInput = (value?: string, options?: PercentFormatOptions) => {
  const { decimalPlaces = DEFAULT_DECIMAL_PLACES } = options || {};
  const emptyState = getEmptyState(options);

  if (!value || value === emptyState) return emptyState;

  const cleaned = value.replace(/%/g, "");
  const [integer, decimals] = cleaned.split(".");

  let newDecimals = decimals;
  let newInteger = integer;
  // When this happens is because the user just deleted the decimal point
  // so we know that the last N digits of the integer are actually the decimals
  // So we split the integer into the last N digits and the rest and rebuild the float with
  // the decimals being the last N digits of the integer
  if (!decimals && decimalPlaces > 0 && integer.length > decimalPlaces) {
    newDecimals = integer.slice(-decimalPlaces);
    newInteger = integer.slice(0, -decimalPlaces);
  } else {
    // We need to make sure the decimals are exactly N digits long
    newDecimals = decimals
      ? decimals.length > decimalPlaces
        ? decimals.slice(0, decimalPlaces)
        : decimals
      : "0".repeat(decimalPlaces);
  }
  // Similarly for guided inputs

  const cleanString = `${newInteger}.${newDecimals}`;
  const num = parseFloat(cleanString);
  if (isNaN(num)) return emptyState;
  return floatToFormattedString(num, options);
};
