/**
 * Accepts a string of numbers and returns that string formatted as an SSN (###-##-####)
 *
 * @param value The string to format
 * @returns The value formatted as an SSN (###-##-####)
 */
export function stringToFormattedSSN(value: string) {
  if (!value) return value;
  const cleaned = value.replace(/\D/g, "");
  if (cleaned.length <= 4) return cleaned;
  const substrings = [cleaned.slice(0, 3), cleaned.slice(3, 5), cleaned.slice(5, 9)].filter(
    (s) => s.length > 0
  );
  return substrings.join("-");
}

/**
 * Accepts a formatted SSN string (###-##-####) and returns a string of numbers
 *
 * @param value The formatted SSN string to convert to a string of numbers
 * @returns An SSN string with all non-numeric characters removed
 */
export function formattedSSNToString(value: string) {
  return value.replace(/\D/g, "");
}
