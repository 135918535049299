import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";
import {
  ApplyAutoProperties,
  Sizes,
  StandardSizes
} from "@moovfinancial/common/styling/StandardSizes";
import SvgrReactComponent from "vite-plugin-svgr/*.svg?react";
import styles from "./Icon.module.scss";

interface IconProps {
  /**
   * The icon component to render.
   */
  iconComponent: typeof SvgrReactComponent;
  /**
   * Optional class name to apply to the icon.
   */
  className?: string;
  /**
   * Optional size to apply to the icon.
   */
  size?: Sizes;
  /**
   * Option to enable an auto size for height or width to override square aspect ratio.
   */
  applyAuto?: ApplyAutoProperties;
  /**
   * Optional inline styles to apply to the icon.
   */
  style?: React.CSSProperties;
}

const sizes = new StandardSizes(
  {
    XXS: 0.75,
    XS: 1,
    S: 1.5,
    M: 2,
    L: 2.5,
    XL: 3,
    XXL: 3.5
  },
  "XS"
);

export const Icon = ({
  iconComponent: Component,
  className,
  size,
  applyAuto,
  children,
  style: styleProp = {},
  ...rest
}: PropsWithChildren<IconProps>): ReactNode => {
  const style = { ...styleProp, ...sizes.getStyle(size, applyAuto) };
  const props = {
    className: clsx(styles.Icon, className),
    ...rest
  };
  return children ? (
    <Component style={style} data-testid={Component.name} {...props}>
      {children}
    </Component>
  ) : (
    <Component style={style} data-testid={Component.name} {...props} />
  );
};
