import { LiveMoovAPIClient } from "./api";
import { MoovFeeConfigGet, MoovFeeConfigPatch } from "./moovFeeConfig.model";
import { APIResponse } from "./request";

export interface MoovFeeConfigAPI {
  /**
   * Gets an array of Moov fee configs linked to the facilitator.
   * Each config corresponds to a payment rail.
   */
  list(facilitatorID: string): APIResponse<MoovFeeConfigGet[]>;
  /**
   * Gets a specific Moov fee config linked to the facilitator.
   */
  get(facilitatorID: string, moovFeeConfigID: string): APIResponse<MoovFeeConfigGet>;
  /**
   * Updates a Moov fee config linked to the facilitator.
   */
  patch(
    facilitatorAccountID: string,
    moovFeeConfigID: string,
    patch: Partial<MoovFeeConfigPatch>
  ): APIResponse<MoovFeeConfigGet>;
  /**
   * Generates default Moov fee configs for the given facilitator.
   */
  initialize(facilitatorAccountID: string): APIResponse<MoovFeeConfigGet[]>;
}

export class LiveMoovFeeConfigAPI implements MoovFeeConfigAPI {
  private client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this.client = client;
  }

  async list(facilitatorID: string): APIResponse<MoovFeeConfigGet[]> {
    return await this.client.request<MoovFeeConfigGet[]>("/ops/moov-fee-configs", {
      xAccountID: facilitatorID
    });
  }

  async get(facilitatorID: string, moovFeeConfigID: string): APIResponse<MoovFeeConfigGet> {
    return await this.client.request<MoovFeeConfigGet>(`/ops/moov-fee-configs/${moovFeeConfigID}`, {
      xAccountID: facilitatorID
    });
  }

  async patch(
    facilitatorAccountID: string,
    moovFeeConfigID: string,
    patch: Partial<MoovFeeConfigPatch>
  ): APIResponse<MoovFeeConfigGet> {
    return await this.client.request<MoovFeeConfigGet>(`/ops/moov-fee-configs/${moovFeeConfigID}`, {
      method: "PATCH",
      xAccountID: facilitatorAccountID,
      json: patch
    });
  }

  async initialize(facilitatorAccountID: string): APIResponse<MoovFeeConfigGet[]> {
    return await this.client.request<MoovFeeConfigGet[]>("/ops/moov-fee-configs", {
      method: "POST",
      xAccountID: facilitatorAccountID
    });
  }
}
