import clsx from "clsx";
import { type ComponentProps, ElementType } from "react";
import { Loading } from "../../Foundations";
import type { BaseButton } from "../BaseButton";
import { ButtonLikeModifiers, ButtonLikeSizing, ButtonLikeStyles } from "./ButtonLikeTypes";
import {
  generateButtonLikeModifierClasses,
  generateButtonLikeStyleClasses
} from "./ButtonLikeUtils";
import styles from "./ButtonLike.module.scss";

type ButtonLikeAppearance = ButtonLikeModifiers & ButtonLikeSizing & ButtonLikeStyles;
type BaseProps<C> = ButtonLikeAppearance & {
  /**
   * The component that should display the button-like styles.
   * Supports any component, but recommended usage is with `<button>` and `<Link>`
   * (see `Button` and `LinkAsButton` components).
   */
  as: C;
  /**
   * The text to be shown in the button. If passed along with children, it is ignored.
   */
  label?: string;
};
export type ButtonLikeProps<C extends ElementType = "button"> = BaseProps<C> &
  Omit<ComponentProps<C>, keyof BaseProps<C>>; // this allows all the props passed to the component to be typed instead of `any`

/**
 * ButtonLike is a component that handles styling elements to look like buttons.
 */
export const ButtonLike = <C extends ElementType = "button">({
  as: Component,
  label,
  buttonType,
  buttonStyle,
  buttonSize = "M",
  gap = 0,
  fullWidth,
  fullWidthOnMobile,
  isLoading = false,
  children,
  className,
  ...rest
}: ButtonLikeProps<C>) => {
  const classNames = clsx(
    styles.buttonLike,
    styles[`size-${buttonSize}`],
    generateButtonLikeStyleClasses({ buttonType, buttonStyle }),
    generateButtonLikeModifierClasses({
      fullWidth,
      fullWidthOnMobile,
      isLoading
    }),
    gap !== 0 && styles.withGap,
    className
  );
  const isButton =
    Component === "button" || (Component as typeof BaseButton).displayName === "BaseButton";
  const isDisabled = rest.disabled || isLoading;
  const style = gap !== 0 ? { gap: `${gap}rem` } : {};
  return (
    // @ts-expect-error - Same error as in FloatingLabelInput.tsx and CharCounterInput.tsx
    <Component className={classNames} disabled={isButton ? isDisabled : undefined} {...rest}>
      <span className={styles.outer} style={style}>
        <span className={styles.inner} style={style}>
          {children ?? label}
        </span>
      </span>
      {isLoading && (
        <div className={styles.loadingElement}>
          <Loading />
        </div>
      )}
    </Component>
  );
};
