import { useContext, useState } from "react";
import { FormGroup, Select } from "@moovfinancial/cargo";
import { Label } from "components/form/Form";
import { businessTypeMap } from "api/v2/accounts.model";
import { AccountSetupContext } from "../../AccountSetupContext";

interface BusinessTypeSelectorProps {
  isLocked?: boolean;
  warn?: boolean;
}

export const BusinessTypeSelector = ({ isLocked, warn }: BusinessTypeSelectorProps) => {
  const { dispatch, account, isErrored, resetInput } = useContext(AccountSetupContext);
  const [isValid, setIsValid] = useState(true);

  const validate = (e: React.FocusEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    resetInput("businessType");
    if (account.accountType === "business") {
      dispatch({ type: "business", value: { businessType: e.target.value } });
    }
  };

  const getWarningMessage = () => {
    if (warn) {
      return "Business type is missing.";
    }
  };

  const isErroring = isErrored("businessType") || !isValid;

  const getErrorMessage = () => {
    if (isErroring) {
      return "Business type is invalid.";
    }
  };

  return (
    <FormGroup
      data-testid="businessTypeSelector"
      errorMessage={getErrorMessage()}
      warningMessage={getWarningMessage()}
    >
      <Label label="Business type" />
      <Select
        autoComplete="off"
        disabled={isLocked}
        isErroring={isErroring}
        isWarning={warn}
        name="type"
        placeholder="--"
        value={account.profile?.business?.businessType}
        onBlur={validate}
        onChange={handleChange}
      >
        {Object.entries(businessTypeMap).map(([key, value]) => (
          <option key={key + value} value={key}>
            {value}
          </option>
        ))}
      </Select>
    </FormGroup>
  );
};
