import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSuper from "hooks/useSuper";
import { Status } from "api/Watchlist.model";
import { UnderwritingStatus } from "api/v2";
import { MatchStatus } from "api/v2/accounts/match.model";
import type { VerificationProcessStatus } from "api/v2/accounts/verification.model";
import { AdminAccountStatuses } from "api/v2/admin.model";
import { APIContext } from "./APIContext";
import { AccountContext } from "./AccountContext";
import { UserContext } from "./UserContext";

interface ContextProps {
  children: React.ReactNode;
}

export interface AdminAccountStatusContextType {
  underwritingStatus: UnderwritingStatus;
  verificationsStatus: VerificationProcessStatus | "notRequested";
  watchlistStatus: Status | "notRequested";
  matchStatus: MatchStatus;
  needsReview: boolean;
  refreshAdminAccountStatuses: (accountID?: string) => void;
  loadingStatuses: boolean;
}

export const AdminAccountStatusContext = createContext<AdminAccountStatusContextType>({
  underwritingStatus: "not_requested",
  verificationsStatus: "notRequested",
  watchlistStatus: "notRequested",
  matchStatus: "notRequested",
  needsReview: false,
  refreshAdminAccountStatuses: () => {},
  loadingStatuses: false
});

export default function AdminAccountStatusContextProvider({ children }: ContextProps) {
  const { activeUserAccountID } = useContext(UserContext);
  const { account } = useContext(AccountContext);
  const { moov } = useContext(APIContext);
  const isSuper = useSuper();
  const [statuses, setStatuses] = useState<Partial<AdminAccountStatuses>>({});
  const [loadingStatuses, setLoadingStatuses] = useState(true);
  const needsReview =
    statuses.underwriting === "pending_review" ||
    statuses.verification === "needsReview" ||
    statuses.verification === "errored" ||
    statuses.watchlist === "pending_review" ||
    statuses.match === "needsReview" ||
    statuses.match === "failed";

  useEffect(() => {
    void refreshAdminAccountStatuses();
  }, [activeUserAccountID, account]);

  const refreshAdminAccountStatuses = async (accountID?: string) => {
    setStatuses({});

    if (!isSuper || (!accountID && !account)) return;

    setLoadingStatuses(true);

    await moov.admin
      .get(accountID ? accountID : account!.accountID)
      .then(([result, error]) => {
        if (error) return toast("Failed to retrieve account statuses.");
        if (result) setStatuses(result);
      })
      .finally(() => {
        setLoadingStatuses(false);
      });
  };

  return (
    <AdminAccountStatusContext.Provider
      value={{
        underwritingStatus: statuses.underwriting || "not_requested",
        verificationsStatus: statuses.verification || "notRequested",
        watchlistStatus: statuses.watchlist || "notRequested",
        matchStatus: statuses.match || "notRequested",
        needsReview,
        refreshAdminAccountStatuses,
        loadingStatuses
      }}
    >
      {children}
    </AdminAccountStatusContext.Provider>
  );
}
