import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import pruneObject from "@moovfinancial/common/utils/pruneObject";
import { PatchRepresentative } from "api/v2";
import { Representative } from "api/v2/accounts.model";
import deepDiff from "./deepDiff";
import { objectIsEmpty } from "./objectIsEmpty";

const isEmptyRep = (rep: Partial<Representative>) => {
  const keys = Object.keys(rep);
  const nonIDValues = keys.filter((key) => key !== "representativeID" && key !== "accountID");
  return !nonIDValues.length;
};

export const pruneRepForPatch = (
  originalRep: DeepPartial<Representative> | undefined,
  repForUpdate: DeepPartial<Representative>
): PatchRepresentative => {
  if (!originalRep) {
    if (repForUpdate.representativeID) {
      return {
        ...repForUpdate,
        representativeID: repForUpdate.representativeID
      } as PatchRepresentative;
    }
    return {} as PatchRepresentative;
  }

  const repDiff = deepDiff(originalRep, repForUpdate) as PatchRepresentative;

  if (isEmptyRep(repDiff)) {
    return {} as PatchRepresentative;
  }
  repDiff.representativeID = originalRep.representativeID ?? "";

  const prunedRep = pruneRep(repDiff);

  if (!objectIsEmpty(prunedRep.address) && !prunedRep.address?.country) {
    prunedRep.address = { ...prunedRep.address, country: "US" };
  }

  return prunedRep as PatchRepresentative;
};

export const pruneRep = <T extends DeepPartial<Representative>>(representative: T) => {
  const shouldPrune = (key: string, value: unknown) => {
    if (value === null || value === undefined || value === "") return true;
    if (typeof value === "object") {
      if (key === "address" && objectIsEmpty(value)) return true;
      if (key === "birthDate" && objectIsEmpty(value)) return true;
      if (key === "responsibilities" && objectIsEmpty(value)) return true;
      if (key === "name" && objectIsEmpty(value)) return true;
      if (key === "phone" && !("number" in value)) return true;
    }
    if (key === "birthDateProvided") return true;
    if (key === "governmentIDProvided") return true;
    if (key === "updatedOn") return true;
    return false;
  };

  return pruneObject(representative, shouldPrune);
};

export const getName = (represenative?: Representative) => {
  if (!represenative) return;
  const { name } = represenative;
  return `${name.firstName} ${name.lastName}`;
};
