import { Chance } from "chance";
import { components } from "@moovfinancial/common/types/__generated-types__/operations-api";

type Adjustment = components["schemas"]["Adjustments.Adjustment"];

const chance = new Chance();

export const mockWalletAdjustment = (): Adjustment => {
  const mockAdjustmentStatus = chance.pickone([
    "approved",
    "completed",
    "failed",
    "pending-approval",
    "rejected"
  ]);

  return {
    accountID: chance.guid(),
    accountMode: chance.pickone(["sandbox", "production"]),
    accountName: chance.company(),
    adjustmentID: chance.guid(),
    amount: { currency: "USD", valueDecimal: chance.integer({ min: 5, max: 100000 }).toString() },
    createdBy: chance.guid(),
    createdByFamilyName: chance.last(),
    createdByGivenName: chance.first(),
    createdOn: chance.date().toISOString(),
    description: chance.sentence({ words: 3 }),
    direction: chance.pickone(["down", "up"]),
    partnerAccountID: chance.guid(),
    partnerAccountName: chance.company(),
    reviews: [],
    status: mockAdjustmentStatus,
    transferID: mockAdjustmentStatus === "completed" ? chance.guid() : null, // only completed adjustments have a transferID
    type: chance.pickone(["single-wallet", "wallet-to-wallet"]),
    updatedOn: chance.date().toISOString(),
    walletID: chance.guid(),
    walletPaymentMethodID: chance.guid(),
    walletTransactionMemo: chance.sentence()
  } as Adjustment;
};

export const mockWalletAdjustmentsList = (count: number = 10): Adjustment[] => {
  return Array.from({ length: count }, () => mockWalletAdjustment());
};

export const mockAdjustmentWithStaticValues = {
  ...mockWalletAdjustment(),
  accountName: "Whole Body Fitness",
  amount: { currency: "USD", valueDecimal: "100" },
  createdByFamilyName: "Doe",
  createdByGivenName: "John",
  createdOn: "2024-01-01",
  direction: "up",
  partnerAccountName: "Classbooker",
  status: "completed",
  type: "wallet-to-wallet"
} as Adjustment;
