import clsx from "clsx";
import React from "react";
import { Theme } from "@moovfinancial/common/types/Theme";
import { BaseButton, BaseButtonProps } from "../../Actions";
import { IconCheckmarkCircle, IconCircleOutlined } from "../../Icons";
import { Icon } from "../../Icons/Icon";
import styles from "./ButtonToggle.module.scss";

interface ButtonTogglePropsBase extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {
  /**
   * Optional: The name of the input
   */
  name?: string;
  /**
   * Optional: The currently-selected value of the input
   */
  value?: string;
  /**
   * Function that should be called when the value of the input changes
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Style of the ButtonToggle:
   *
   * - Default: with border
   * - Minimal: minimal borders and adds drop shadow
   *
   * @default "default"
   *
   */
  toggleStyle?: "default" | "minimal";
  /**
   * You can pass an array of `options` are `ButtonToggle.Option` components
   *
   * NOTE:
   *
   * - Passing children will override the `options` prop
   */
  children?:
    | React.ReactElement<ButtonToggleOptionProps>[]
    | React.ReactElement<ButtonToggleOptionProps>;
  /**
   * An array of `options` to render
   *
   * Note:
   *
   * - This option will be overriden if you pass children
   */
  options?: ButtonToggleOptionProps[];
  /**
   * Should the buttons be stacked?
   *
   * @default false
   */
  stacked?: boolean;
  /**
   * Should the input be disabled?
   *
   * @default false
   */
  disabled?: boolean;
}

/**
 * Either pass in an array of `options` or an array of `children` which are `ButtonToggle.Option` components
 */
export type ButtonToggleProps =
  | (ButtonTogglePropsBase & {
      children:
        | React.ReactElement<ButtonToggleOptionProps>[]
        | React.ReactElement<ButtonToggleOptionProps>;
      options?: never;
    })
  | (ButtonTogglePropsBase & {
      children?: never;
      options: ButtonToggleOptionProps[];
    });

const getToggleClassName = (toggleStyle: "default" | "minimal") => {
  switch (toggleStyle) {
    case "default":
      return styles.toggleDefault;
    case "minimal":
      return styles.toggleMinimal;
  }
};

const generateOptionElements = ({
  toggleStyle = "default",
  disabled,
  options,
  onChange,
  stacked,
  value
}: Omit<ButtonToggleProps, "options"> & { options: ButtonToggleOptionProps[] }) =>
  options.map((option, i) => (
    <ButtonToggleOption
      isSelected={option.value === value}
      value={option.value}
      label={option.label}
      secondaryLabel={option.secondaryLabel}
      key={`${option.label}-${i}`}
      onClick={(e) => {
        e.preventDefault();
        onChange &&
          onChange({
            ...e,
            target: { value: option.value, name: name }
          } as unknown as React.ChangeEvent<HTMLInputElement>);
      }}
      theme={{
        toggleButton: clsx(getToggleClassName(toggleStyle), stacked && styles.fullWidth),
        active: clsx(toggleStyle === "minimal" && styles.minimalActiveToggle),
        checkIcon: clsx(toggleStyle === "minimal" && styles.minimalCheckIcon)
      }}
      disabled={disabled}
      data-testid={`ButtonToggleOption-${option.value}`}
    />
  ));

// Helper function to decorate the passed-in children with the props that are affected by ButtonToggle parent component
const decorateChildren = (
  children:
    | React.ReactElement<ButtonToggleOptionProps>[]
    | React.ReactElement<ButtonToggleOptionProps>,
  { onChange, value, stacked, disabled, toggleStyle = "default" }: ButtonToggleProps
) =>
  React.Children.map(children, (child) =>
    React.cloneElement(child, {
      disabled,
      theme: {
        toggleButton: clsx(
          getToggleClassName(toggleStyle),
          stacked && styles.fullWidth,
          child.props.className
        ),
        active: clsx(toggleStyle === "minimal" && styles.minimalActiveToggle),
        checkIcon: clsx(toggleStyle === "minimal" && styles.minimalCheckIcon)
      },
      isSelected: child.props.value === value,
      "data-testid": `ButtonToggleOption-${child.props.value}`,
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        // we still allow the child to have its own onClick handler if the consumer wants it
        child.props.onClick && child.props.onClick(e);
        // then, we call the parent's onChange handler
        onChange &&
          onChange({
            ...e,
            target: { value: child.props.value }
          } as unknown as React.ChangeEvent<HTMLInputElement>);
      }
    })
  );

export const ButtonToggle = (props: ButtonToggleProps) => {
  const { name, value, stacked, className, children, toggleStyle: _toggleStyle, ...rest } = props;
  const options = children ? decorateChildren(children, props) : generateOptionElements(props);
  return (
    <div className={clsx(styles.container, stacked && styles.stacked, className)} {...rest}>
      {options}
      {/* Not sure why he have this hidden input here, but leaving it for now as it might be useful for something? */}
      <input hidden value={value} name={name} onChange={() => {}} />
    </div>
  );
};

export interface ButtonToggleOptionProps extends BaseButtonProps {
  /**
   * The value of the option
   */
  value: string;
  /**
   * Optional: The label of the option
   *
   * NOTE:
   *
   * - Passing in children will override this prop
   *
   */
  label?: string;
  /**
   * Optional: The secondary label of the option
   *
   * NOTE:
   *
   * - Passing in children will override this prop
   *
   */
  secondaryLabel?: React.ReactNode;
  /**
   * Is the option selected?
   *
   * Note:
   *
   * - 🔴 This will be overridden and controlled by the parent `ButtonToggle`
   * component, so you probably don't want to use this
   *
   */
  isSelected?: boolean;
  /**
   * Optional classNames to override the default styles
   */
  theme?: Partial<
    Pick<Theme<typeof styles>, "active" | "toggleButton" | "checkIcon" | "circleIcon">
  >;
  /**
   * Optional: The data-testid of the option
   */
  "data-testid"?: string;
}

const ButtonToggleOption = function ButtonToggleOption({
  label,
  secondaryLabel,
  isSelected,
  theme,
  children,
  ...rest
}: ButtonToggleOptionProps) {
  const CheckMarkIcon = (
    <Icon
      iconComponent={IconCheckmarkCircle}
      className={clsx(styles.checkIcon, theme?.checkIcon)}
    />
  );
  const CircleIcon = (
    <Icon
      iconComponent={IconCircleOutlined}
      className={clsx(styles.circleIcon, theme?.circleIcon)}
    />
  );
  const content = children ? (
    children
  ) : secondaryLabel ? (
    <div className={styles.labelGrid}>
      <span className={styles.labelOne}>{label}</span>
      <span className={styles.labelTwo}>{secondaryLabel}</span>
    </div>
  ) : (
    <span>{label}</span>
  );
  return (
    <BaseButton
      type="button"
      className={clsx(
        styles.toggleButton,
        isSelected && styles.active,
        isSelected && theme?.active,
        theme?.toggleButton
      )}
      {...rest}
    >
      {content}
      {isSelected ? CheckMarkIcon : CircleIcon}
    </BaseButton>
  );
};

ButtonToggle.Option = ButtonToggleOption;
